import React, { useState, useEffect, useCallback } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import FormRadio from "../../reusablecomponets/FormRadio";
import FormSelect from "../../reusablecomponets/FormSelect";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { actions } from '../../_redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { decryptText } from "../../helpers/DynamicFunctions";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import FormInputAd from "../../reusablecomponets/FormInputAd";
import reducersAndActions from "../../_redux/slices/index";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import FormTextArea from "../../reusablecomponets/FormTextArea";
import Modal from 'react-bootstrap/Modal'


function IndustryGasStation({ backPreviousTab }) {
    const dispatch = useDispatch();

    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);

    // Meta Title
    useEffect(() => {
        document.title = "Gas Station Information - Loan Application";
    }, []);

    const CurrentEmployeesOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: '1-10' },
        { value: '2', label: '11-20' },
        { value: '3', label: '21-30' },
        { value: '4', label: '31-40' },
        { value: '5', label: '41-50' },
        { value: '6', label: '51-60' },
        { value: '7', label: '61-70' },
        { value: '8', label: '71-80' },
        { value: '9', label: '81-90' },
        { value: '10', label: '91-100' }
    ];

    const PeakSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];

    const LowSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];
    const AvrgTermContractOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: '1 Years' },
        { value: '2', label: '2 Years' },
        { value: '3', label: '3 Years' },
        { value: '4', label: '4 Years' },
        { value: '5', label: '5 Years' },
        { value: '6', label: '6 Years' },
        { value: '7', label: '7 Years' },
        { value: '8', label: '8 Years' },
        { value: '9', label: '9 Years' },
        { value: '10', label: '10 Years' },
    ]

    const [form, setForm] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", Validators.required],
            franchiseGas: ["", Validators.required],
            franchiseService: ["", Validators.required],
            franchiseConvenience: ["", Validators.required],
            suppliersVendorTerms: ["", Validators.required],
            //customersSellingTerms: ["", Validators.required],
            competitiveAdvantages: ["", Validators.required],
            seasonalBusiness: ["", Validators.required],
            numberEmployees: ["", Validators.required],
            numberAfterLoan: ["", Validators.required],
            buildingPercentageOffice: ["", Validators.required],
            buildingPercentageService: ["", Validators.required],
            buildingPercentageConvenience: ["", Validators.required],
            hoursOperationGas: ["", Validators.required],
            hoursOperationService: ["", Validators.required],
            hoursOperationConvenience: ["", Validators.required],
            numberGasPumps: ["", Validators.required],
            numberFuelingPositions: ["", Validators.required],
            numberFullvsServicePumps: ["", Validators.required],
            numberServeDiesel: ["", Validators.required],
            numberServiceBays: ["", Validators.required],
            fleetCustomers: [""],
            //percentFleetCustomers: [""],
            approxRegular: [""],
            approxPlus: [""],
            approxPremium: [""],
            approxDiesel: [""],
            additionalComments: [""],
            modifiedBy: [0],
            customerVendors: ["", Validators.required],
            keyCompetitors: ["", Validators.required],
            averageTermContract: [""],
            peakSeason: [""],
            lowSeason: [""],
            peakSeasonName: [""]
        })
    );

    useEffect(() => {
        dispatch(actions.gasStationForm.fetchGasStationData({ loanId: parsedLoanId, type: 'Gas Station' }));
    }, [dispatch, parsedLoanId]);
    const { data, loading, error } = useSelector((state) => state.gasStationForm);

    useEffect(() => {
        setSelectOptions({
            geographicMarket: [],
            customerVendors: [],
            peakSeason: [],
            lowSeason: []
        });
    }, []);
    const [selectOptions, setSelectOptions] = useState({
        geographicMarket: [],
        customerVendors: [],
        peakSeason: [],
        lowSeason: []

    });

    useEffect(() => {
        if (data && data.resultObject) {
            try {
                const resultObject = JSON.parse(data.resultObject);
                if (Array.isArray(resultObject) && resultObject.length > 0) {
                    const gasStationData = resultObject[0];

                   
                    const geographicMarketOptions = gasStationData.GeographicMarket ? gasStationData.GeographicMarket.split(',').map(value => ({ label: value, value })) : [];
                    const customerVendorsOptions = gasStationData.CustomerVendors ? gasStationData.CustomerVendors.split(',').map(value => ({ label: value, value })) : [];
                    const peakSeasonOptions = gasStationData.PeakSeason ? gasStationData.PeakSeason.split(',').map(value => ({
                        value,
                        label: PeakSeasonOptions.find(option => option.value === value)?.label || value
                    })) : [];
                    const lowSeasonOptions = gasStationData.LowSeason ? gasStationData.LowSeason.split(',').map(value => ({
                        value,
                        label: LowSeasonOptions.find(option => option.value === value)?.label || value
                    })) : [];

                  
                    setSelectOptions({
                        geographicMarket: geographicMarketOptions,
                        customerVendors: customerVendorsOptions,
                        peakSeason: peakSeasonOptions,
                        lowSeason:lowSeasonOptions
                    });



                    form.patchValue({
                        id: gasStationData.Id,
                        loanId: gasStationData.LoanId,
                        geographicMarket: gasStationData.GeographicMarket,
                        franchiseGas: gasStationData.FranchiseGas,
                        franchiseService: gasStationData.FranchiseService,
                        franchiseConvenience: gasStationData.FranchiseConvenience,
                        suppliersVendorTerms: gasStationData.SuppliersVendorTerms,
                        //customersSellingTerms: gasStationData.customersSellingTerms,
                        competitiveAdvantages: gasStationData.CompetitiveAdvantages,
                        seasonalBusiness: gasStationData.SeasonalBusiness,
                        numberEmployees: gasStationData.NumberEmployees,
                        numberAfterLoan: gasStationData.NumberAfterLoan,
                        buildingPercentageOffice: gasStationData.BuildingPercentageOffice,
                        buildingPercentageService: gasStationData.BuildingPercentageService,
                        buildingPercentageConvenience: gasStationData.BuildingPercentageConvenience,
                        hoursOperationGas: gasStationData.HoursOperationGas,
                        hoursOperationService: gasStationData.HoursOperationService,
                        hoursOperationConvenience: gasStationData.HoursOperationConvenience,
                        numberGasPumps: gasStationData.NumberGasPumps,
                        numberFuelingPositions: gasStationData.NumberFuelingPositions,
                        numberFullvsServicePumps: gasStationData.NumberFullvsServicePumps,
                        numberServeDiesel: gasStationData.NumberServeDiesel,
                        numberServiceBays: gasStationData.NumberServiceBays,
                        fleetCustomers: gasStationData.FleetCustomers,
                        //percentFleetCustomers: gasStationData.percentFleetCustomers,
                        approxRegular: gasStationData.ApproxRegular,
                        approxPlus: gasStationData.ApproxPlus,
                        approxPremium: gasStationData.ApproxPremium,
                        approxDiesel: gasStationData.ApproxDiesel,
                        additionalComments: gasStationData.AdditionalComments,
                        modifiedBy: gasStationData.ModifiedBy,
                        customerVendors: gasStationData.CustomerVendors,
                        keyCompetitors: gasStationData.KeyCompetitors,
                        averageTermContract: gasStationData.AverageTermContract,
                        peakSeason: gasStationData.PeakSeason,
                        lowSeason: gasStationData.LowSeason,
                        peakSeasonName: gasStationData.PeakSeasonName
                    });

                    
                } else {
                    form.reset({
                        id: 0,
                        loanId: parsedLoanId,
                        modifiedBy: 0
                    });
                }
            } catch (error) {
                console.error("Error parsing resultObject", error);
                form.reset({
                    id: 0,
                    loanId: parsedLoanId,
                    modifiedBy: 0
                });
            }
        } else {
            form.reset({
                id: 0,
                loanId: parsedLoanId,
                modifiedBy: 0
            });
        }
    }, [data, setSelectOptions, form]);

    const handleSubmit = useCallback((e) => {
        if (form.value.seasonalBusiness == null || form.value.seasonalBusiness === "") {
            form.value.seasonalBusiness = 'false';
        }
        if (form.value.fleetCustomers == null || form.value.fleetCustomers === "") {
            form.value.fleetCustomers="false";
        }
        console.log(form.value);
        
        e.preventDefault();
        markAllAsTouched(form);
        if (form.valid) {
            const currentValues = form.value;
            dispatch(actions.gasStationForm.submitGasStationForm(currentValues))
                .then(() => {
                    if(!currentValues.id){
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form submitted successfully",
                            type: "success",
                        }));
                    }else{
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form updated successfully",
                            type: "success",
                        }));
                    }
                    dispatch(actions.gasStationForm.fetchGasStationData({ loanId: parsedLoanId, type: 'Gas Station' }));
                    setTimeout(() => {
                        dispatch(reducersAndActions.actions.toasterActions.hideToaster());
                    }, 5000);
                })
                .catch(() => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: "Error submitting form",
                        type: "error",
                    }));
                });
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: "Form validation failed",
                type: "error",
            }));
        }
    }, [dispatch, form, parsedLoanId]);




    const handleSelectChange = (name, selectedOption) => {
        const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
        setSelectOptions(prevOptions => ({ ...prevOptions, [name]: selectedOption }));
        form.controls[name].setValue(selectedValues.join(','));
    };


    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control) {
                control.markAsTouched();
                control.updateValueAndValidity();
            }
        });
    };
    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get }) => (
                            <form onSubmit={handleSubmit}>

                                {/* Gas Station Information section */}
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Gas Station Information</h3>
                                        </div>
                                        <div className="col-md-6">

                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic market"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            {form.value.geographicMarket==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="suppliersVendorTerms"
                                                label="Major suppliers & vendor terms"
                                                name="suppliersVendorTerms"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* {form.value.termsForPayments==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>} */}
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customerVendors"
                                                label="Major customers & vendor terms"
                                                value={selectOptions.customerVendors}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            {form.value.customerVendors==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="keyCompetitors"
                                                label="Key competitors"
                                                name="keyCompetitors"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="competitiveAdvantages"
                                                label="Competitive advantages"
                                                name="competitiveAdvantages"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormSelect
                                                name="averageTermContract"
                                                label="What is the average term of contract?"
                                                options={AvrgTermContractOptions}
                                                defaultValue={AvrgTermContractOptions[0]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                              {form.value.averageTermContract==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="numberFuelingPositions"
                                                label="How many fueling positions are there?"
                                                name="numberFuelingPositions"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="numberFullvsServicePumps"
                                                label="How many full vs self-service pumps?"
                                                name="numberFullvsServicePumps"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="numberServeDiesel"
                                                label="How many serve diesel?"
                                                name="numberServeDiesel"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="numberServiceBays"
                                                label="How many service days?"
                                                name="numberServiceBays"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormSelect
                                                name="numberEmployees"
                                                label="Number of current employees"
                                                options={CurrentEmployeesOptions}
                                                defaultValue={CurrentEmployeesOptions[0]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                               {form.value.numberEmployees==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="numberAfterLoan"
                                                label="Number after loan"
                                                name="numberAfterLoan"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="numberGasPumps"
                                                label="How many gas pumps are there?"
                                                name="numberGasPumps"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">

                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                       {form.value.seasonalBusiness=="true"&&<> <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {/* <FormSelect
                                                        name="peakSeason"
                                                        label="Peak season"
                                                        options={PeakSeasonOptions}
                                                        defaultValue={PeakSeasonOptions[0]}
                                                        //required={true}
                                                        errorMessage="Required field"
                                                    /> */}
                                                    <CreatableSelect
                                                        name="peakSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Peak Season"
                                                        value={selectOptions.peakSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={PeakSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {/* <FormSelect
                                                        name="lowSeason"
                                                        label="Low season"
                                                        options={LowSeasonOptions}
                                                        defaultValue={LowSeasonOptions[0]}
                                                        required={true}
                                                        errorMessage="Required field"
                                                    /> */}
                                                    <CreatableSelect
                                                        name="lowSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Low Season"
                                                        value={selectOptions.lowSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                         options={LowSeasonOptions}
                                                        isMulti
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        </div></>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}


                                        <div className="col-md-12">

                                            <FormRadio
                                                name="fleetCustomers"
                                                label="Are their “fleet” customers?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>

                                        {form.value.fleetCustomers=="true"&&  <div className="col-md-6">

                                            <FormInputAd
                                                id="peakSeasonName"
                                                label="Peak season"
                                                name="peakSeasonName"
                                                placeholder="Enter Text"
                                                //validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <FormTextArea
                                                id="additionalComments"
                                                label="Additional comments"
                                                name="additionalComments"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormTextArea
                                                id="additionalComments"
                                                label="Additional comments"
                                                name="additionalComments"
                                                placeholder="Enter Text"
                                                maxlength={500}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                {/* Gas Station Information section */}


                                {/* Franchise / Flag Section */}
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Franchise / Flag</h3>
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="franchiseGas"
                                                label="Gas"
                                                name="franchiseGas"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="franchiseService"
                                                label="Service"
                                                name="franchiseService"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="franchiseConvenience"
                                                label="Convenience"
                                                name="franchiseConvenience"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Franchise / Flag Section */}


                                {/* Building Percentage of Sq. Ft Section */}
                                <div className="lm-card">
                                    <h3 className="lm-card-heading">Building Percentage of Sq. Ft</h3>
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="buildingPercentageConvenience"
                                                label="Convenience"
                                                name="buildingPercentageConvenience"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="buildingPercentageService"
                                                label="Service"
                                                name="buildingPercentageService"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="buildingPercentageOffice"
                                                label="Office"
                                                name="buildingPercentageOffice"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Building Percentage of Sq. Ft Section */}


                                {/* What is your approximate general mark up on the following Section */}
                                <div className="lm-card">
                                    <h3 className="lm-card-heading">What is your approximate general mark up on the following</h3>
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="approxRegular"
                                                label="Regular"
                                                name="approxRegular"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="approxPlus"
                                                label="Plus"
                                                name="approxPlus"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="approxPremium"
                                                label="Premium"
                                                name="approxPremium"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="approxDiesel"
                                                label="Diesel"
                                                name="approxDiesel"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* What is your approximate general mark up on the following Section */}


                                {/* Hours of operation Section */}
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Hours of operation</h3>
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="hoursOperationGas"
                                                label="Gas"
                                                name="hoursOperationGas"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="hoursOperationService"
                                                label="Service"
                                                name="hoursOperationService"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="hoursOperationConvenience"
                                                label="Convenience"
                                                name="hoursOperationConvenience"
                                                placeholder="0.00"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Hours of operation Section */}


                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button2" onClick="">Back</button>
                                    <button type="submit" className="lm-button1 ml-3">Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>





            </div>)}

        </>
    );
}

export default IndustryGasStation;