// LocationCheck.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import AccessRestricted from './screens/AccessRestricted';
import Constants from '../src/helpers/Constants';

const LocationCheck = ({ children }) => {
    const [allowed, setAllowed] = useState(null);
    const apiKey = Constants.IPGEOLOCATION_API_KEY
    const fetchInitiated = useRef(false);

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const vpnIps = ["192.168.0.126", "203.122.20.218", "122.160.68.189", "103.176.17.37", "45.12.26.209", "103.167.99.113", "45.12.26.241"];

                const response = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`);
                const country = response.data.country_name;
                const clientIp = response.data.ip;

                if (country === 'US' || country === 'USA' || country === "United States" || vpnIps.includes(clientIp)) {
                    setAllowed(true);
                } else {
                    setAllowed(false);
                }
            } catch (error) {
                console.error('Error fetching location', error);
                setAllowed(false);
            }
        };

        // Only initiate fetch if it hasn't been done already
        if (!fetchInitiated.current) {
            fetchInitiated.current = true;
            if (Constants.isCheckForLocationAccess) {
                fetchLocation();
            } else {
                setAllowed(true);
            }
        }
    }, [apiKey]);

    if (allowed === null) {
        return <div>Loading...</div>; // or a loading spinner
    }

    if (!allowed) {
        return <AccessRestricted />;
    }

    return <>{children}</>;
};

export default LocationCheck;
