import React, { useState, useCallback, useMemo, useEffect } from 'react';
import axiosInstance from '../../../helpers/axiosConfig'; // Adjust the path to where your Axios instance is defined
import pass_sugn_tick from '../../../assets/icons/pass-sugn-tick.svg';
import pass_sugn_cross from '../../../assets/icons/pass-sugn-cross.svg';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';
import Preloader from '../../../plugins/Preloader';

const ChangePassword = ({
  activeTab,
  warning_icon,
   pass_eye,
}) => {
  const [passwordChanged, setPasswordChanged] = useState("");
  const [preloader,setPreloader]=useState(false)
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const dispatch = useDispatch();

  
// Show password hide password js
function showPassword() {
  var x = document.getElementById("password");
  var btn = document.getElementById("pass_eye");

  if (x.type === "password") {
      x.type = "text";
      btn.classList.add("active_pass");
  } else {
      x.type = "password";
      btn.classList.remove("active_pass");
  }
}

function showConfirmPassword() {
  var x = document.getElementById("confirm-password");
  var btn = document.getElementById("confirm_pass_eye");

  if (x.type === "password") {
      x.type = "text";
      btn.classList.add("active_pass");
  } else {
      x.type = "password";
      btn.classList.remove("active_pass");
  }
}


  // Function to validate the password based on given rules
  const validatePassword = (password) => {
    let errors = [];

    if (password.length < 8) {
      errors = ['Please enter at least 8 characters.'];
    } else if (!/[A-Z]/.test(password)) {
      errors = ['One uppercase letter is missing.'];
    } else if (!/[a-z]/.test(password)) {
      errors = ['One lowercase letter is missing.'];
    } else if (!/\d/.test(password)) {
      errors = ['One numeric digit is missing.'];
    } else if (!/[@$%^&*!#%]/.test(password)) {
      errors = ['One special character (!, #, %) is missing.'];
    }

    setPasswordErrors(errors);
  };


  // Function to check if the password and confirm password fields match
  const checkPasswordsMatch = useCallback((password, confirmPassword) => {
    setPasswordsMatch(password === confirmPassword);
  }, []);

  // Handler for password input change
  const handlePasswordChange = useCallback((e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    checkPasswordsMatch(newPassword, confirmPassword);
  }, [confirmPassword, checkPasswordsMatch]);

  // Handler for confirm password input change
  const handleConfirmPasswordChange = useCallback((e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    checkPasswordsMatch(password, newConfirmPassword);
  }, [password, checkPasswordsMatch]);

  // Handler for the save button
  const handleSave = useCallback(() => {
    
    if (!passwordsMatch || passwordErrors.length > 0) {
      console.error('Passwords do not match or there are validation errors');
      return;
    }

    const storedUserId = localStorage?.getItem('user');
    const parsedUserId = JSON.parse(storedUserId);
    const storedSessionId = localStorage?.getItem('sessionId');
    setPreloader(true)
    if (storedUserId && parsedUserId && storedSessionId) {
      const apiUrl = '/api/User/ChangePassword';
      const queryParams = `?userId=${parsedUserId.id}&NewPassword=${encodeURIComponent(password)}&RoleName=${parsedUserId?.roleName}&SessionId=${storedSessionId}`;

      axiosInstance.post(`${apiUrl}${queryParams}`)
        .then(response => {
          setPreloader(false)
          if (response.data.status == "200") {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message}));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          }else{
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type:"error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          
          }
        
        })
        .catch(error => {
          setPreloader(false)
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type:"error" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          console.error('Error:', error);
        });
    } else {
      console.error('User ID, Session ID, or token is not available');
    }
  }, [password, passwordsMatch, passwordErrors, dispatch]);

  // Helper text to guide users on password requirements
  const passwordHelperText = useMemo(() => (
    <div className="password-suggestion-box">
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> 8 digits.</div>
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> One numeric value.</div>
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> One uppercase letter.</div>
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> One lowercase letter.</div>
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> One special character (!,#,%).</div>
      <div className="d-flex"><img src={pass_sugn_cross} alt="" /> Personal Information.</div>
    </div>
  ), []);


  return (
    <div className={`tab-pane fade ${activeTab === "Change_Password" ? "show active" : ""}`} id="Change_Password">
   {  preloader?
   <Preloader/>
   :<div className="lm-card">
        {/* Show a success message if passwords match and there are no errors */}
        {passwordErrors.length === 0 && password && confirmPassword && passwordsMatch ? (
          <div className=" d-flex justify-content-center">
            
          </div>
        ) : (
          (password.length!==0)&&<div className={`password-match-toast d-flex justify-content-center ${passwordsMatch ? 'd-none' : ''}`}>
            <img className="mr-2" src={warning_icon} alt="" />
            Passwords must match
          </div>
        )}
        <h3 className="lm-card-heading">Change Password</h3>
        <form>
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div className={`form-group input-password ${passwordsMatch ? 'success' : ''}`}>
                <label htmlFor="password">Password</label>
                <div className="customtooltip">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Enter a password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                   {password && passwordErrors.length > 0 && (
      <div className="error-message">
        {passwordErrors[0]}
      </div>
    )}
                  <img
                    onClick={showPassword}
                    id="pass_eye"
                    className="pass_eye active_pass"
                    src={pass_eye}
                    alt=""
                  />
                  {passwordHelperText}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className={`form-group input-password ${passwordsMatch ? 'success' : ''}`}>
                <label htmlFor="confirm-password">Confirm Password</label>
                <div className="customtooltip">
                  <input
                    type="password"
                    className="form-control"
                    id="confirm-password"
                    placeholder="Enter a password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <img
                    onClick={showConfirmPassword}
                    id="confirm_pass_eye"
                    className="pass_eye active_pass"
                    src={pass_eye}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <button type="button" className="lm-button1" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </form>
      </div> 
      }
    </div>
  );
};

export default ChangePassword;
