import React, { useState } from "react";
import { FieldControl } from "react-reactive-form";

const FormField = ({ name, label, id, placeholder, required, errorMessage, patternMessage, disabled, patternRequired, maxlength, onlyCharacters, onlyNumbers }) => {

  return (
    <FieldControl
      name={name}
      render={({ handler, touched, hasError, meta }) => {
        const handlerProps = handler();

        const handleChange = (e) => {
          let rawValue = e.target.value;

          // Allow only letters and spaces, or numbers based on the condition
          if (onlyCharacters) {
            rawValue = rawValue.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
          } else if (onlyNumbers) {
            rawValue = rawValue.replace(/[^0-9]/g, ''); // Allow only numbers (digits)
          }

          e.target.value = rawValue;

          // Trigger the onChange handler
          if (handlerProps.onChange) {
            handlerProps.onChange(e.target.value);
          }
        };

        return (
          <div className="form-group">
            <label htmlFor={name}>
              {label}{required && <span className="asterisk">*</span>}
            </label>

            <input
              type="text"
              className="form-control"
              id={id}
              placeholder={placeholder}
              {...handler()}
              disabled={disabled ? true : false}
              maxLength={maxlength} // Set maxLength attribute on the input
              onChange={handleChange}
            />

            {/* Error messages below */}
            {required && touched && hasError("required") && <><span className="error-message">{`${errorMessage}`}</span><br /></>}
            {required && touched && hasError("pattern") && <span className="error-message">{`${patternMessage}`}</span>}
            {required && touched && hasError("email") && <span className="error-message">Email is incorrect</span>}

            {patternRequired && touched && hasError("invalidNumber") && <span className="error-message">{`${patternMessage}`}</span>}
            {required && touched && hasError("extraSpaces") && <span className="error-message">Please remove unwanted space</span>}
            {patternRequired && touched && hasError("contactNumberIssu") && <span className="error-message">{`${patternMessage}`}</span>}
            {patternRequired && touched && hasError("invalidEmail") && <span className="error-message">Email is incorrect</span>}
            {patternRequired && touched && hasError("zipCodeTooShort") && <span className="error-message">Zip code is incorrect</span>}
          </div>
        )
      }}
      meta={{ placeholder: placeholder }}
    />
  );
}

export default FormField;
