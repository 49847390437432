import React, { useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import pass_sugn_tick from '../../../assets/icons/pass-sugn-tick.svg';
import pass_sugn_cross from '../../../assets/icons/pass-sugn-cross.svg';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';
import pass_eye from '../../../assets/img/pass_eye.svg';
import close_popup from '../../../assets/icons/close_popup.svg';
import axiosInstance from '../../../helpers/axiosConfig';

const ChangePasswordModal = ({ changePassPopupShow, setChangePassPopup,userdetails }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const [preloader,setPreloader]=useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);
  
  const dispatch = useDispatch();

  const showPassword= () => {
    var x = document.getElementById("password");
  var btn = document.getElementById("pass_eye");

  if (x.type === "password") {
      x.type = "text";
      btn.classList.add("active_pass");
  } else {
      x.type = "password";
      btn.classList.remove("active_pass");
  }
  };

  const showConfirmPassword = () => {
    var x = document.getElementById("confirm-password");
    var btn = document.getElementById("confirm_pass_eye");
  
    if (x.type === "password") {
        x.type = "text";
        btn.classList.add("active_pass");
    } else {
        x.type = "password";
        btn.classList.remove("active_pass");
    }
  };

  const changePassPopupClose = () => {
    setChangePassPopup(false);
  };

  const validatePassword = (password) => {
    
    const errors = [];

    if (password.length < 8) {
      errors.push('Please enter at least 8 characters.');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('One uppercase letter is missing.');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('One lowercase letter is missing.');
    }
    if (!/\d/.test(password)) {
      errors.push('One numeric digit is missing.');
    }
    if (!/[@$%^&*!#%]/.test(password)) {
      errors.push('One special character (!, #, %) is missing.');
    }

    setPasswordErrors(errors);
    return errors.length === 0;
  };

  const handleSave = () => {
    if (password !== confirmPassword) {
      setPasswordsMatch(true)
      return;
    }
    if (!validatePassword(password)) {
      console.error('Passwords do not match or there are validation errors');
      return;
    }

    const storedUserId = localStorage?.getItem('user');
    const parsedUserId = JSON.parse(storedUserId);
    const storedSessionId = localStorage?.getItem('sessionId');

    if (storedUserId && parsedUserId && storedSessionId) {
      const apiUrl = '/api/User/ChangePassword';
      
      const queryParams = `?userId=${userdetails?.id}&NewPassword=${encodeURIComponent(password)}&RoleName=${userdetails?.roleName}&SessionId=${storedSessionId}`;

      axiosInstance.post(`${apiUrl}${queryParams}`)
        .then(response => {
          if (response.data.status == "200") {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            setChangePassPopup(false);
          } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          }
        })
        .catch(error => {
          
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: "error" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          console.error('Error:', error);
        });
    }
  };

  const passwordHelperText = useMemo(() => (
    <div className="password-suggestion-box">
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> 8 digits.</div>
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> One numeric value.</div>
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> One uppercase letter.</div>
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> One lowercase letter.</div>
      <div className="d-flex"><img src={pass_sugn_tick} alt="" /> One special character (!,#,%).</div>
      <div className="d-flex"><img src={pass_sugn_cross} alt="" /> Personal Information.</div>
    </div>
  ), []);

  // Handler for password input change
  const handlePasswordChange = useCallback((e) => {
    const newPassword = e.target.value;
    
    setPassword(newPassword);
    validatePassword(newPassword);
  }, [confirmPassword]);

  // Handler for confirm password input change
  const handleConfirmPasswordChange = useCallback((e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (password == e.target.value) {
      setPasswordsMatch(false)
      
    }
  }, [password]);
  return (
    <Modal
      size="md"
      show={changePassPopupShow}
      onHide={() => setChangePassPopup(false)}
      aria-labelledby="changePassPopupLabel"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ChangePasswordPopupLabel">
              Change Password
            </h5>
            <button
              type="button"
              className="close"
              onClick={changePassPopupClose}
            >
              <span aria-hidden="true">
                <img src={close_popup} alt="" />
              </span>
            </button>
          </div>
          <form>
            <Modal.Body>
              <div className="form-group input-password">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  
                  onChange={handlePasswordChange}
                  placeholder="Enter a password"
                />
                <img
                  onClick={showPassword}
                  id="pass_eye"
                  className={"pass_eye active_pass"}
                  src={pass_eye}
                  alt=""
                />
                    {password && passwordErrors.length > 0 && (
      <div className="error-message">
        {passwordErrors[0]}
      </div>
    )}
                 {passwordHelperText}
              </div>
              <div className="form-group input-password">
                <label htmlFor="confirm-password">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirm-password"
                  value={confirmPassword}
                  
                  onChange={handleConfirmPasswordChange}
                  placeholder="Enter a password"
                />
                <img
                  onClick={showConfirmPassword}
                  id="confirm_pass_eye"
                  className="pass_eye active_pass"
                  src={pass_eye}
                  alt=""
                />
                {passwordsMatch==true&& (
                <div className="error-message">
               Password Mismatch
              </div>
                )}
              </div>
              
            </Modal.Body>
            <div className="modal-footer">
              <button
                type="button"
                className="lm-button2"
                onClick={changePassPopupClose}
              >
                Cancel
              </button>
              <button type="button" className="lm-button1" onClick={handleSave}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
