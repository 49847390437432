import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidePanel, ProfileHeader } from "@components";
import { actions } from '../../_redux/store';
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import { useParams } from "react-router-dom";
import FormInputAd from "../../reusablecomponets/FormInputAd";
import FormSelect from "../../reusablecomponets/FormSelect";
import FormRadio from "../../reusablecomponets/FormRadio";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { decryptText } from "../../helpers/DynamicFunctions";
import reducersAndActions from "../../_redux/slices/index";
import FormTextArea from "../../reusablecomponets/FormTextArea";
import Modal from 'react-bootstrap/Modal'
import Preloader from '../../plugins/Preloader';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import FormTextarea from '../../reusablecomponets/FormTextArea';

function IndustryHotel({ loanId }) {

    const dispatch = useDispatch();
    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);
    const [formData, setFormData] = useState({
      
        // other form fields
    });
    const CurrentEmployeesOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: '1-10' },
        { value: '2', label: '11-20' },
        { value: '3', label: '21-30' },
        { value: '4', label: '31-40' },
        { value: '5', label: '41-50' },
        { value: '6', label: '51-60' },
        { value: '7', label: '61-70' },
        { value: '8', label: '71-80' },
        { value: '9', label: '81-90' },
        { value: '10', label: '91-100' }
    ];

    const LastRenovationOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'About 1 year ago' },
        { value: '2', label: 'About 2 years ago' },
        { value: '3', label: 'About 3 years ago' },
        { value: '4', label: 'About 4 years ago' },
        { value: '5', label: 'About 5 years ago' },
        { value: '6', label: 'About 6 years ago' },
        { value: '7', label: 'About 7 years ago' },
        { value: '8', label: 'About 8 years ago' },
        { value: '9', label: 'About 9 years ago' },
        { value: '10', label: 'About 10 years ago' }
    ];

    const PeakSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];


    const LowSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];

    const [form, setForm] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", Validators.required],
            suppliersVendorTerms: ["", Validators.required],
            customerVendors: [""], // New
            patientsReferred: [""], // New
            customersSellingTerms: ["", Validators.required],
            keyCompetitors: [""], // New
            competitiveAdvantages: ["", Validators.required],
            hoursOperation: [""],
            seasonalBusiness: ["", Validators.required],
            numberEmployees: ["", Validators.required],
            numberAfterLoan: ["", Validators.required],
            numberOfRooms: ["", Validators.required],
            numberOfFloors: ["", Validators.required],
            elevatorService: [""], // New
            lastRenovation: [""],
            performed: [""],
            HotelOrMotel: [""],
            peakSeason: [""], // New
            lowSeason: [""], // New
            fullOrLimitedService: ["", Validators.required],
            additionalComments: [""],
            modifiedBy: [0]
        })
    );



    useEffect(() => {
        document.title = "Hotel Information - Loan Application";
        dispatch(actions.hotelForm.fetchHotelData({ loanId: parsedLoanId, type: 'Hotel' }));
    }, [dispatch, parsedLoanId]);


    const { data, loading, error } = useSelector((state) => state.hotelForm);

    useEffect(() => {
        setSelectOptions({
            geographicMarket: [],
            customerVendors: [],
            customersSellingTerms: [],
            peakSeason: [],
            lowSeason: []
        });
    }, []);

    const [selectOptions, setSelectOptions] = useState({
        geographicMarket: [],
        customerVendors: [],
        customersSellingTerms: [],
        lowSeason: [],
        peakSeason: []
    });

    useEffect(() => {
        if (data && data.resultObject) {

            const resultObject = JSON.parse(data.resultObject);

            if (Array.isArray(resultObject) && resultObject.length > 0) {
                const hotelData = resultObject[0];

                // console.log("hotelData" + hotelData, resultObject[0]);

                const geographicMarketOptions = hotelData.GeographicMarket ? hotelData.GeographicMarket.split(',').map(value => ({ label: value, value })) : [];
                const customerVendorsOptions = hotelData.CustomerVendors ? hotelData.CustomerVendors.split(',').map(value => ({ label: value, value })) : [];
                const customersSellingTermsOptions = hotelData.CustomersSellingTerms ? hotelData.CustomersSellingTerms.split(',').map(value => ({ label: value, value })) : [];
                const peakSeasonOptions = hotelData.PeakSeason ? hotelData.PeakSeason.split(',').map(value => ({
                    value,
                    label: PeakSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];
                const lowSeasonOptions = hotelData.LowSeason ? hotelData.LowSeason.split(',').map(value => ({
                    value,
                    label: LowSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];

                setSelectOptions({
                    geographicMarket: geographicMarketOptions,
                    customerVendors: customerVendorsOptions,
                    customersSellingTerms: customersSellingTermsOptions,
                    peakSeason: peakSeasonOptions,
                    lowSeason:lowSeasonOptions
                });

                form.patchValue({
                    id: hotelData.Id,
                    loanId: hotelData.LoanId,
                    geographicMarket: hotelData.GeographicMarket,
                    suppliersVendorTerms: hotelData.SuppliersVendorTerms,
                    customerVendors: hotelData.CustomerVendors, // New
                    patientsReferred: hotelData.PatientsReferred, // New
                    customersSellingTerms: hotelData.CustomersSellingTerms,
                    keyCompetitors: hotelData.KeyCompetitors, // New
                    competitiveAdvantages: hotelData.CompetitiveAdvantages,
                    hoursOperation: hotelData.HoursOperation,
                    seasonalBusiness: hotelData.SeasonalBusiness === 'true' ? 'true' : 'false',
                    numberEmployees: hotelData.NumberEmployees,
                    numberAfterLoan: hotelData.NumberAfterLoan,
                    numberOfRooms: hotelData.NumberOfRooms,
                    numberOfFloors: hotelData.NumberOfFloors,
                    elevatorService: hotelData.ElevatorService === 'true' ? 'true' : 'false',
                    lastRenovation: hotelData.LastRenovation,
                    performed: hotelData.Performed,
                    HotelOrMotel: hotelData.HotelOrMotel,
                    peakSeason: hotelData.PeakSeason, // New
                    lowSeason: hotelData.LowSeason, // New
                    fullOrLimitedService: hotelData.FullOrLimitedService === 'true' ? 'true' : 'false',
                    additionalComments: hotelData.AdditionalComments,
                    modifiedBy: 0
                });
                // console.log("Patch Hotel values" + form.value);
            } else {
                form.reset({
                    id: 0,
                    loanId: parsedLoanId,
                    modifiedBy: 0
                });
            }
        } else {
            form.reset({
                id: 0,
                loanId: parsedLoanId,
                modifiedBy: 0
            });
        }
    }, [data, setSelectOptions, form]);



    const handleSubmit = useCallback((e) => {
        if (form.value.seasonalBusiness == null || form.value.seasonalBusiness === "") {
            form.value.seasonalBusiness = 'false';
        }
        if (form.value.fullOrLimitedService == null || form.value.fullOrLimitedService === "") {
            form.value.fullOrLimitedService="false";
        }
        if (form.value.elevatorService == null || form.value.elevatorService === "") {
            form.value.elevatorService="false";
        }
        e.preventDefault();
        markAllAsTouched(form);
        if (form.valid) {
            const currentValues = form.value;
            // console.log("Hotel Form Values" + form.value);
            dispatch(actions.hotelForm.submitHotelForm(currentValues))
                .then(() => {
                    if (!currentValues.id) {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form submitted successfully",
                            type: "success",
                        }));
                    } else {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form updated successfully",
                            type: "success",
                        }));
                    }
                    dispatch(actions.hotelForm.fetchHotelData({ loanId: parsedLoanId, type: 'Hotel' }));

                    setTimeout(() => {
                        dispatch(reducersAndActions.actions.toasterActions.hideToaster());
                    }, 5000);


                })
                .catch(() => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: "Error submitting form",
                        type: "error",
                    }));
                });
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: "Form validation failed",
                type: "error",
            }));
        }
    }, [dispatch, form, parsedLoanId]);


    const handleSelectChange = (name, selectedOption) => {
        const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
        setSelectOptions(prevOptions => ({ ...prevOptions, [name]: selectedOption }));
        form.controls[name].setValue(selectedValues.join(','));
    };

    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control) {
                control.markAsTouched();
                control.updateValueAndValidity();
            }
        });
    };

     console.log(form);
    
    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Hotel Information</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic market"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            {form.value.geographicMarket==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="suppliersVendorTerms"
                                                label="Major suppliers & vendor terms"
                                                name="suppliersVendorTerms"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customerVendors"
                                                label="Major customers & vendor terms"
                                                value={selectOptions.customerVendors}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            {form.value.customerVendors==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="patientsReferred"
                                                label="How are patients referred? "
                                                name="patientsReferred"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.patientsReferred==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customersSellingTerms"
                                                label="Major customers & selling Terms"
                                                value={selectOptions.customersSellingTerms}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            {form.value.customersSellingTerms==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="keyCompetitors"
                                                label="Key competitors"
                                                name="keyCompetitors"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.keyCompetitors==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="competitiveAdvantages"
                                                label="Competitive advantages"
                                                name="competitiveAdvantages"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="hoursOperation"
                                                label="Hours of operation"
                                                name="hoursOperation"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.hoursOperation==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormSelect
                                                name="numberEmployees"
                                                label="Number of current employees"
                                                options={CurrentEmployeesOptions}
                                                defaultValue={CurrentEmployeesOptions[0]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.numberEmployees==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="numberAfterLoan"
                                                label="Number after loan"
                                                name="numberAfterLoan"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="numberOfRooms"
                                                label="Number of rooms/suites?"
                                                name="numberOfRooms"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="numberOfFloors"
                                                label="Number of floors/stories"
                                                name="numberOfFloors"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-9">
                                            <FormRadio
                                                name="elevatorService"
                                                label="Does the property have elevator service?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={false}
                                                errorMessage="This field is required."
                                                onChange={(e) => {    
        
        
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        seasonalBusiness: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        {form.value.elevatorService=="true"&&<><div className="col-md-4 col-lg-3">
                                            <FormSelect
                                                name="lastRenovation"
                                                label="When was last renovation?"
                                                options={LastRenovationOptions}
                                                defaultValue={LastRenovationOptions[0]}
                                                required={false}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-9">
                                            <FormInputAd
                                                id="performed"
                                                label="What was performed?"
                                                name="performed"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div></>}
                                        <div className="col-md-9">

                                            <div className="form-group mb-1">
                                                <div className="label pb-1">Is this a hotel with a central corridor or a motel?</div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="HotelOrMotel"
                                                        id="central_corridor_or_motel_central_corridor"
                                                        value="Central corridor"
                                                        checked={form.get('HotelOrMotel').value === 'Central corridor'}
                                                        onChange={(e) => form.patchValue({ HotelOrMotel: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="central_corridor_or_motel_central_corridor">Central corridor</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="HotelOrMotel"
                                                        id="central_corridor_or_motel_Motel"
                                                        value="Motel"
                                                        checked={form.get('HotelOrMotel').value === 'Motel'}
                                                        onChange={(e) => form.patchValue({ HotelOrMotel: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="central_corridor_or_motel_Motel">Motel</label>
                                                </div>
                                            </div>


                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">

                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                                onChange={(e) => {    
        
        
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        seasonalBusiness: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>

                                 {  form.value.seasonalBusiness=="true"&&<>     <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <CreatableSelect
                                                        name="peakSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Peak season"
                                                        value={selectOptions.peakSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={PeakSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <CreatableSelect
                                                        name="lowSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Low season"
                                                        value={selectOptions.lowSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={LowSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />
                                                </div>
                                            </div>
                                        </div></>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="fullOrLimitedService"
                                                label="Is this a full or limited-service property?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">

                                            <FormTextarea
                                                id="additionalComments"
                                                label="Additional comments"
                                                name="additionalComments"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>


                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button2" onClick="">Back</button>
                                    <button type="submit" className="lm-button1 ml-3">Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>


                {/* <!--back to Previous Tab Popup / Modal --> */}
                {/* <Modal
                    className='mark-comp-popup-cust-size'
                    show={PreviousTabPopupShow}
                    onHide={() => setPreviousTabPopup(false)}
                    aria-labelledby="PreviousTabPopupLabel"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body signout-modal">
                                <h3 className="pt-4">
                                    Going back will lead to the loss of unsaved data
                                </h3>
                            </div>
                            <div className="modal-footer la-app-modal-footer">
                                <button type="button" className="lm-button2" onClick={PreviousTabPopupClose}>Cancel</button>
                                <button type="button" className="lm-button1" onClick={backPreviousTab}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </Modal> */}
                {/* <!--back to Previous Tab Popup / Modal --> */}

            </div>)}

        </>

    );



}

export default IndustryHotel;