import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../_redux/store';
import { SidePanel, ProfileHeader } from "@components";
import Modal from 'react-bootstrap/Modal'
import Preloader from '../../plugins/Preloader';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Constants from "../../helpers/Constants";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import FormInputAd from "../../reusablecomponets/FormInputAd";
import FormSelect from "../../reusablecomponets/FormSelect";
import FormRadio from "../../reusablecomponets/FormRadio";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { useParams } from "react-router-dom";
import { decryptText } from "../../helpers/DynamicFunctions";
import reducersAndActions from "../../_redux/slices/index";
import FormTextarea from '../../reusablecomponets/FormTextArea';

function IndustryContractor({ backPreviousTab }) {

    //const numberPatternValidator = Validators.pattern(/^\d+$/);

    const dispatch = useDispatch();
    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);

    const CurrentEmployeesOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: '1-10' },
        { value: '2', label: '11-20' },
        { value: '3', label: '21-30' },
        { value: '4', label: '31-40' },
        { value: '5', label: '41-50' },
        { value: '6', label: '51-60' },
        { value: '7', label: '61-70' },
        { value: '8', label: '71-80' },
        { value: '9', label: '81-90' },
        { value: '10', label: '91-100' }
    ];

    const PeakSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];
    const LowSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];
    const AvrgTermContractOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: '1 Years' },
        { value: '2', label: '2 Years' },
        { value: '3', label: '3 Years' },
        { value: '4', label: '4 Years' },
        { value: '5', label: '5 Years' },
        { value: '6', label: '6 Years' },
        { value: '7', label: '7 Years' },
        { value: '8', label: '8 Years' },
        { value: '9', label: '9 Years' },
        { value: '10', label: '10 Years' },
    ]

    const TermsForPaymentOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'Yearly' },
        { value: '2', label: 'Monthly' },
    ]

    const [form, setForm] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", Validators.required],
            suppliersVendorTerms: ["", Validators.required],
            customersSellingTerms: ["", Validators.required],
            keyCompetitors: ["", Validators.required], // New
            competitiveAdvantages: ["", Validators.required],
            contractorSpecialty: ["", Validators.required],
            hoursOperation: ["", Validators.required],
            numberEmployees: ["", Validators.required],
            termsForPayments: [""], // New
            seasonalBusiness: ["", Validators.required],
            peakSeason: [""], // New
            lowSeason: [""], // New
            specialLicensing: ["", Validators.required],
            whatLicencing: [""], // New
            generalContractor: ["", Validators.required],
            averageTermContract: [""], // New
            minarityDesignation: [""], // New
            whatDesignations: [""], // New
            directNegotiation: ["", Validators.required],
            explainDetail: [""], // New
            isCompanyBonded: [""], // New
            bondingCompany: [""],
            particularUnions: ["", Validators.required],
            whatUnions: [""], // New
            additionalComments: [""],
            modifiedBy: [0]
        })
    );

    useEffect(() => {
        document.title = "Contractor Information - Loan Application";
        dispatch(actions.contractorForm.fetchContractorData({ loanId: parsedLoanId, type: 'Contractor' }));
    }, [dispatch, parsedLoanId]);

    const { data, loading, error } = useSelector((state) => state.contractorForm);

    useEffect(() => {
        setSelectOptions({
            geographicMarket: [],
            customerVendors: [],
            customersSellingTerms: [],
            peakSeason: [],
            lowSeason: []
        });
    }, []);

    const [selectOptions, setSelectOptions] = useState({
        geographicMarket: [],
        customerVendors: [],
        customersSellingTerms: [],
        peakSeason: [],
        lowSeason: []
    });

    useEffect(() => {
        if (data && data.resultObject) {

            const resultObject = JSON.parse(data.resultObject);
            if (Array.isArray(resultObject) && resultObject.length > 0) {
                const contractorData = resultObject[0];

                const geographicMarketOptions = contractorData.GeographicMarket ? contractorData.GeographicMarket.split(',').map(value => ({ label: value, value })) : [];
                const customerVendorsOptions = contractorData.CustomerVendors ? contractorData.CustomerVendors.split(',').map(value => ({ label: value, value })) : [];
                const customersSellingTermsOptions = contractorData.CustomersSellingTerms ? contractorData.CustomersSellingTerms.split(',').map(value => ({ label: value, value })) : [];
                const peakSeasonOptions = contractorData.PeakSeason ? contractorData.PeakSeason.split(',').map(value => ({
                    value,
                    label: PeakSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];
                const lowSeasonOptions = contractorData.LowSeason ? contractorData.LowSeason.split(',').map(value => ({
                    value,
                    label: LowSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];

                setSelectOptions({
                    geographicMarket: geographicMarketOptions,
                    customerVendors: customerVendorsOptions,
                    customersSellingTerms: customersSellingTermsOptions,
                    peakSeason:peakSeasonOptions,
                    lowSeason:lowSeasonOptions
                });

                form.patchValue({
                    id: contractorData.Id,
                    loanId: contractorData.LoanId,
                    geographicMarket: contractorData.GeographicMarket,
                    suppliersVendorTerms: contractorData.SuppliersVendorTerms,
                    customersSellingTerms: contractorData.CustomersSellingTerms,
                    keyCompetitors: contractorData.KeyCompetitors, // New
                    competitiveAdvantages: contractorData.CompetitiveAdvantages,
                    contractorSpecialty: contractorData.ContractorSpecialty,
                    hoursOperation: contractorData.HoursOperation,
                    numberEmployees: contractorData.NumberEmployees,
                    termsForPayments: contractorData.TermsForPayments, // New
                    seasonalBusiness: contractorData.SeasonalBusiness === 'true' ? 'true' : 'false',
                    peakSeason: contractorData.PeakSeason, // New
                    lowSeason: contractorData.LowSeason, // New
                    specialLicensing: contractorData.SpecialLicensing === 'true' ? 'true' : 'false',
                    whatLicencing: contractorData.WhatLicencing, // New
                    generalContractor: contractorData.GeneralContractor,
                    averageTermContract: contractorData.AverageTermContract, // New
                    minarityDesignation: contractorData.MinarityDesignation === 'true' ? 'true' : 'false',
                    whatDesignations: contractorData.WhatDesignations, // New
                    directNegotiation: contractorData.DirectNegotiation,
                    explainDetail: contractorData.ExplainDetail, // New
                    isCompanyBonded: contractorData.IsCompanyBonded, // New
                    bondingCompany: contractorData.BondingCompany,
                    particularUnions: contractorData.ParticularUnions,
                    whatUnions: contractorData.WhatUnions, // New
                    additionalComments: contractorData.AdditionalComments,
                    modifiedBy: 0
                });
            } else {
                form.reset({
                    id: 0,
                    loanId: parsedLoanId,
                    modifiedBy: 0
                });
            }
        } else {
            form.reset({
                id: 0,
                loanId: parsedLoanId,
                modifiedBy: 0
            });
        }
    }, [data, setSelectOptions, form]);


console.log(form.value);

    const handleSubmit = useCallback((e) => {
        if (form.value.seasonalBusiness == null || form.value.seasonalBusiness === "") {
            form.value.seasonalBusiness = 'false';
        }
        if (form.value.specialLicensing == null || form.value.specialLicensing === "") {
            form.value.specialLicensing="false";
        }
        if (form.value.minarityDesignation == null || form.value.minarityDesignation === "") {
            form.value.minarityDesignation="false";
        }
        if (form.value.particularUnions == null || form.value.particularUnions === "") {
            form.value.particularUnions="false";
        }
        if (form.value.isCompanyBonded == null || form.value.isCompanyBonded === "") {
            form.value.isCompanyBonded="false";
        }
        e.preventDefault();
        markAllAsTouched(form);
        //console.log("Contractor form values:",form.value);
        if (form.valid) {
            const currentValues = form.value;
            console.log("Contractor" + currentValues);
            dispatch(actions.contractorForm.submitForm(form.value))
                .then(() => {
                    if (!currentValues.id) {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form submitted successfully",
                            type: "success",
                        }));
                    } else {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form updated successfully",
                            type: "success",
                        }));
                    }

                    dispatch(actions.contractorForm.fetchContractorData({ loanId: parsedLoanId, type: 'Contractor' }));

                    setTimeout(() => {
                        dispatch(reducersAndActions.actions.toasterActions.hideToaster());
                    }, 5000);

                })
                .catch((error) => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: "Error submitting form",
                        type: "error",
                    }));
                });
        } else {

            dispatch(reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: "Form validation failed",
                type: "error",
            }));
        }
    }, [dispatch, form, parsedLoanId]);

    const handleSelectChange = (name, selectedOption) => {
        const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
        setSelectOptions(prevOptions => ({ ...prevOptions, [name]: selectedOption }));
        form.controls[name].setValue(selectedValues.join(','));
    };

    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control) {
                control.markAsTouched();
                control.updateValueAndValidity();
            }
        });
    };



    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Contractor Profile</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic market"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                                {form.value.geographicMarket==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="suppliersVendorTerms"
                                                label="Major suppliers"
                                                name="suppliersVendorTerms"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customersSellingTerms"
                                                label="Major customers"
                                                value={selectOptions.customersSellingTerms}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            {form.value.customersSellingTerms==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="keyCompetitors"
                                                label="Key competitors"
                                                name="keyCompetitors"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="competitiveAdvantages"
                                                label="Competitive advantages"
                                                name="competitiveAdvantages"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="contractorSpecialty"
                                                label="What is this contractor’s specialty?"
                                                name="contractorSpecialty"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="hoursOperation"
                                                label="Hours of operation"
                                                name="hoursOperation"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormSelect
                                                name="numberEmployees"
                                                label="Number of current employees"
                                                options={CurrentEmployeesOptions}
                                                defaultValue={CurrentEmployeesOptions[0]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.numberEmployees==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormSelect
                                                name="averageTermContract"
                                                label="What is the average term of contract?"
                                                options={AvrgTermContractOptions}
                                                defaultValue={AvrgTermContractOptions[0]}
                                                required={false}
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.averageTermContract==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormSelect
                                                name="termsForPayments"
                                                label="What are the terms for payment?"
                                                options={TermsForPaymentOptions}
                                                defaultValue={TermsForPaymentOptions[0]}
                                                required={false}
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.termsForPayments==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                            
                                        </div>

                                       {form.value.seasonalBusiness=="true"&&<> <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <CreatableSelect
                                                        name="peakSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Peak season"
                                                        value={selectOptions.peakSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={PeakSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">

                                                    <CreatableSelect
                                                        name="lowSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Low season"
                                                        value={selectOptions.lowSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={LowSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        </>}
                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="specialLicensing"
                                                label="Is any special licensing required?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                        {
                                            form.value.specialLicensing=="true"&&<div className="col-md-6">

                                            <FormInputAd
                                                id="whatLicencing"
                                                label="What licensing?"
                                                name="whatLicencing"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
            }
                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">

                                            <div className="form-group mb-1">
                                                <div className="label pb-1">Does it typically operate as a subcontractor or as a general contractor?</div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="generalContractor"
                                                        id="subcontractor"
                                                        value="Subcontractor"
                                                        checked={form.get('generalContractor').value === 'Subcontractor'}
                                                        onChange={(e) => form.patchValue({ generalContractor: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="subcontractor">Subcontractor</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="generalContractor"
                                                        id="general_contractor"
                                                        value="General contractor"
                                                        checked={form.get('generalContractor').value === 'General contractor'}
                                                        onChange={(e) => form.patchValue({ generalContractor: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="general_contractor">General contractor</label>
                                                </div>
                                            </div>

                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="minarityDesignation"
                                                label="Does the company have minority-designation or preferred status?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                      {form.value.minarityDesignation=="true"&&  <div className="col-md-6">
                                            <FormInputAd
                                                id="whatDesignations"
                                                label="If so, what designation/s?"
                                                name="whatDesignations"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>}


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">

                                            <div className="form-group">
                                                <div className="label pb-1">Are jobs awarded via the bidding process, through direct negotiation or some combination of the two?</div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="directNegotiation"
                                                        id="bidding"
                                                        value="bidding"
                                                        checked={form.get('directNegotiation').value === 'bidding'}
                                                        onChange={(e) => form.patchValue({ directNegotiation: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="bidding">Bidding</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="directNegotiation"
                                                        id="Direct_negotiation"
                                                        value="Direct_negotiation"
                                                        checked={form.get('directNegotiation').value === 'Direct_negotiation'}
                                                        onChange={(e) => form.patchValue({ directNegotiation: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="Direct_negotiation">Direct negotiation</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="directNegotiation"
                                                        id="Both"
                                                        value="Both"
                                                        checked={form.get('directNegotiation').value === 'Both'}
                                                        onChange={(e) => form.patchValue({ directNegotiation: e.target.value })}
                                                    />
                                                    <label className="form-check-label" htmlFor="Both">Both</label>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="explainDetail"
                                                label="Explain in more details"
                                                name="explainDetail"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="isCompanyBonded"
                                                label="Is the company bonded?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>
                                       { form.value.isCompanyBonded=="true"&&
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="bondingCompany"
                                                label="Who is the bonding company and what is the maximum coverage?"
                                                name="bondingCompany"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="particularUnions"
                                                label="Are employees affiliated with any particular unions?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>
{form.value.particularUnions=="true"&&
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="whatUnions"
                                                label="What unions?"
                                                name="whatUnions"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">

                                            <FormTextarea
                                                id="additionalComments"
                                                label="Additional comments"
                                                name="additionalComments"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>


                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    {/* <button type="button" className="lm-button2" onClick="">Back</button> */}
                                    <button type="submit" className="lm-button1 ml-3">Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>


                {/* <!--back to Previous Tab Popup / Modal --> */}
                {/* <Modal
                    className='mark-comp-popup-cust-size'
                    show={PreviousTabPopupShow}
                    onHide={() => setPreviousTabPopup(false)}
                    aria-labelledby="PreviousTabPopupLabel"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body signout-modal">
                                <h3 className="pt-4">
                                    Going back will lead to the loss of unsaved data
                                </h3>
                            </div>
                            <div className="modal-footer la-app-modal-footer">
                                <button type="button" className="lm-button2" onClick={PreviousTabPopupClose}>Cancel</button>
                                <button type="button" className="lm-button1" onClick={backPreviousTab}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </Modal> */}
                {/* <!--back to Previous Tab Popup / Modal --> */}

            </div>)}

        </>
    )
}

export default IndustryContractor;