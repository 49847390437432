import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Header, Footer } from "@components";
import { InsertUpdateBasicInfoMailSend, UpdateBorrowerEmailAddress } from '../services/loanApplicationServices';
import { getSessionId } from '../services/storageData';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../_redux/slices';
import Constants from '../helpers/Constants';

function UpdateBorrowerEmailAddressLink() {
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const loanId = searchParams.get('LoanId');
    const dispatch=useDispatch()
    
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        Emailsender()
    },[])
    const Emailsender = async () => {
        
        let queryObj = {
            LoanId: loanId,
            BorrowerEmail: email,
            
        };
        //console.log(queryObj);
        
        InsertUpdateBasicInfoMailSend(queryObj)
        .then((data) => {
            console.log(data);
            
            setLoading(false);
            if (data.status == 200) {
                dispatch(
                    reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: data.message,
                        type: "success",
                    })
                );
                setTimeout(
                    () =>
                        dispatch(
                            reducersAndActions.actions.toasterActions.hideToaster()
                        ),
                    Constants.TOASTERHIDETIME
                );
            }
            else {
                setLoading(false);
                dispatch(
                    reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: data.message,
                        type: "error",
                    })
                );
                setTimeout(
                    () =>
                        dispatch(
                            reducersAndActions.actions.toasterActions.hideToaster()
                        ),
                    Constants.TOASTERHIDETIME
                );
            }
        })
        .catch((err) => {
            console.log(err);
            dispatch(
                reducersAndActions.actions.toasterActions.showToaster({
                    visible: true,
                    message: err.message,
                    type: "error",
                })
            );
            setTimeout(
                () =>
                    dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                Constants.TOASTERHIDETIME
            );
        });
    
    }
    return (
        <>
            <Header />
            <div className="website-wrap">
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                    textAlign: "center"
                }}>
                    <h1>Thank you for verifying the email address.</h1>
                    <h1>Business Email Update</h1>
                    <p>The email address for the business has been updated.</p>
                    <br />
                    <p>Please <Link to="/" className="back-btn">LOGIN</Link> to continue.</p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default UpdateBorrowerEmailAddressLink;
