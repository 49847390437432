import axios from "../helpers/axiosConfig";
import { getLocalStorageData, getSessionId } from "./storageData";


export const getOwnerListofBusinessCashflow = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetBusinessListById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const GetOwnersListofPersonalCashflowById = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetOwnersListofPersonalCashflowById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const GetGlobalCashFlowDetailsById = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetGlobalCashFlowDetailsById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const GetIncomeStatmentOfBusinessCashflowDetails = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetIncomeStatmentOfBusinessCashflowDetails`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetBalanceSheetOfBusinessCashflowDetails = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetBalanceSheetOfBusinessCashflowDetails`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetPersonalCashFlowDetailsById = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetPersonalCashFlowDetailsById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


// Insert and Update API for Cashflow
export const InsertAndUpdateBusinessCashflow = async (data, APIUrl) => {
    try {
        const instance = axios.create();
        
        data['modifiedBy'] = JSON.parse(localStorage.getItem('user')).id;
        const response = await instance.post(`/api/LoanApplication/${APIUrl}`, data,
            {
                params: { SessionId: getSessionId() },
                headers: {
                    'Content-Type': 'application/json', // Overwrite Content-Type here
                    'Authorization': `Bearer ${getLocalStorageData("token")}`
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const GetCashflowDetailsReport = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetCashflowDetailsReport`, {
            params: data,
            responseType: 'arraybuffer'  // Ensure the response is treated as binary data
        });

        if (response.data) {
            const blob = new Blob([response.data], { type: 'application/ms-excel' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `cashflowReport-${data.LoanId}.xlsx`;
            link.click();
            window.URL.revokeObjectURL(link.href);  // Clean up the URL object
            return response;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const GetDebtScheduleReport = async (data) => {
    try {
        const response = await axios.get(`/api/LoanApplication/GetDebtScheduleReport`, {
            params: data,
            responseType: 'arraybuffer'  // Ensure the response is treated as binary data
        });
        if (response.data) {
            const blob = new Blob([response.data], { type: 'application/ms-excel' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `cashflowDebtReport-${data.LoanId}.xlsx`;
            link.click();
            window.URL.revokeObjectURL(link.href);  // Clean up the URL object
            return response;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}