import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'; // Import necessary react-router-dom components
import { useDispatch, useSelector } from 'react-redux';
import reducersAndActions from '../../_redux/slices';
import { toggleMenu } from '../SidePanel/SidePanel'; // Assuming this is imported correctly
import Modal from 'react-bootstrap/Modal';
import './ProfileHeader.css';
import dummy_avatar from '../../assets/img/dummy_avtar.png';
import profile_setting_icon from '../../assets/icons/profile-setting-icon.svg';
import signout_icon from '../../assets/icons/signout-icon.svg';
import { logout } from '../../services/commonService';
import { flushLocalStorageData, getLocalStorageData } from '../../services/storageData';
import axiosInstance from '../../helpers/axiosConfig';
import Preloader from '../../plugins/Preloader';
const ProfileHeader = ({ pathname }) => {
  // Popup function
  const user = useSelector((state) => state.auth.user);

  const toaster = useSelector(state => state.toaster);
  const profileData = useSelector((state) => state.editProfile.data.message);

  const refreshData = useSelector((state) => state.editProfile.profileDetailsrefresh)
  var getStoredImageData = useCallback(() => localStorage.getItem('avatarImage'), []);

  const [imageData, setImageData] = useState(getStoredImageData);
  var storedImageData = getStoredImageData();

  const [userAllDetails, setUserAllDetails] = useState(null)
  const [previousUserDetails, setPreviousUserDetails] = useState(null);
  const navigate = useNavigate("/");
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const storedUserId = localStorage.getItem('user');
    const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;
    const storedSessionId = localStorage.getItem('sessionId');
    if (parsedUserId && storedSessionId) {
      const apiUrl = '/api/User/MyProfile';
      const queryParams = `?userId=${parsedUserId.id}&SessionId=${storedSessionId}`;

      axiosInstance.get(`${apiUrl}${queryParams}`)
        .then(response => {

          const data = response?.data.resultObject;
          if (JSON.stringify(data) !== JSON.stringify(previousUserDetails)) {
            dispatch(reducersAndActions.actions.profileAction.profileUpdate({ data: data }));
            setUserAllDetails(data)
            setPreviousUserDetails(data);
            if (data?.imagePath) {
              // localStorage.setItem("profileData", data)
              localStorage.setItem('avatarImage', `data:image/png;base64,${data?.imagePath}`);
              if (`data:image/png;base64,${data?.imagePath}` !== dummy_avatar) {
                return setImageData(`data:image/png;base64,${data?.imagePath}`);
              } else {
                setImageData("")
              }
            }
          }
        })
        .catch(error => {
          console.error('Error fetching profile data:', error);
        });
    }
  }, [refreshData, toaster]);

  const [signOutPopupShow, setSignOutPopup] = useState(false);
  const signOutPopupClose = () => setSignOutPopup(false);
  const confirmLogout = () => {
    let email = getLocalStorageData("email");

    email = JSON.parse(email);
    logout(email).then(
      (success) => {
        dispatch(reducersAndActions.actions.authActions.removeUser());
        setSignOutPopup(false);
        flushLocalStorageData()
        navigate("/");
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => {
      console.log(err);
      throw err;
    });
  }

  const sectionNamesMap = {
    '/dashboard': 'Dashboard',
    '/loan-application': 'Loan Applications',
    '/application-detail': 'Loan Application',
    '/personal-profile': 'Loan Application',
    '/personal-profile': 'Loan Application',
    '/pfs-detail': 'Loan Application',
    '/cash-flow-business': 'Loan Application',
    '/cash-flow-personal': 'Loan Application',
    '/users/advisor-admin': 'Users',
    '/users/borrowers': 'Users',
    '/users/lenders': 'Users',
    '/users/partners': 'Users',
    '/users/user-form': 'Users',
    '/inquiries': 'Inquiries',
    '/logs': 'Logs',
    '/templates': 'Templates',
    '/settings': 'Settings',
    // Add more mappings as needed
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  const sectionName = sectionNamesMap[location.pathname] || 'Loan Mantra'; // Get the section name

  const [preloader, setPreloader] = useState(true)
  const ProfileComponent = ({ user, profileData, dummy_avatar, imageData }) => {

    const [preloader, setPreloader] = useState(true);



    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const renderProfileDisplay = () => {


      if (!user.name) {
        return <div className="profile-text-img mr-2"><h2>LM</h2></div>;
      }

      const nameParts = user.name.split(" ");
      const capitalizedParts = nameParts.map(part => capitalizeFirstLetter(part));

      const firstNameInitial = capitalizedParts[0]?.charAt(0);
      const lastNameInitial = capitalizedParts[1]?.charAt(0);

      const initials = `${firstNameInitial !== undefined ? firstNameInitial : ""}${lastNameInitial !== undefined ? lastNameInitial : ""}`;

      if (profileData === dummy_avatar || imageData === "data:image/png;base64,null" || imageData == "undefined" || imageData == "null") {
        return (
          <div className="profile-text-img mr-2">
            <h2>{initials}</h2>
          </div>
        );
      }

      if ((profileData === imageData || imageData === storedImageData) && imageData !== undefined) {
        return (
          <img
            src={profileData && profileData !== "data:image/png;base64,null" ? profileData : imageData}
            alt={`${initials}`}
          />
        );
      }

      if (imageData?.length > 0 && storedImageData !== imageData) {
        return (
          <img
            src={profileData && profileData !== "data:image/png;base64,null" ? profileData : imageData}
            alt={`${initials}`}
          />
        );
      }

      return (
        <div className="profile-text-img mr-2">
          <h2>{initials}</h2>
        </div>
      );
    };

    return (
      <>
        {renderProfileDisplay()}
      </>
    );
  };
  return (
    <div>
      <div className="profile-header">
        <div className="hamburger" onClick={toggleMenu}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <div className="section-name">
          <h6>{sectionName}</h6>
        </div>
        <div className="user-dtl">
          <div className="user-pro-pic">
            <ProfileComponent
              user={user}
              profileData={profileData}
              dummy_avatar={dummy_avatar}
              imageData={imageData}
            />


            {/* {imageData==dummy_avatar? <div className="profile-text-img mr-2"><h2>{firstNameFirstword}{lastNameFirstword}</h2></div>:<img src={imageData} alt='LM'/> } */}
          </div>
          <div className="dtl-sec">
            {/* <div className="user-name">{firstName?`${firstName} ${lastName}`:userAllDetails?.firstName?`${userAllDetails.firstName} ${userAllDetails.lastName}`:user.name}</div> */}

            <div className="user-name">{userAllDetails == null || userAllDetails == undefined ? user.name : userAllDetails.firstName} {userAllDetails?.lastName}</div>

            <p className="user-designation mb-0">{user.roleName}</p>
            <div className="logout-dropdown">
              <Link to="/settings">
                <img src={profile_setting_icon} alt="Profile Settings" />
                Profile Settings
              </Link>
              <Link
                to="#"
                onClick={() => setSignOutPopup(true)}
              >
                <img src={signout_icon} alt="Sign Out" />
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div onClick={toggleMenu} className="menu-overlay"></div>

      <Modal
        className="signout-modal"
        show={signOutPopupShow}
        onHide={() => setSignOutPopup(false)}
        aria-labelledby="signOutPopupLabel"
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="modal-body">
          <h3 className="pt-4">Are you sure you want to Sign Out from this account?</h3>
        </div>
        <div className="modal-footer">
          <button type="button" className="lm-button2" onClick={signOutPopupClose}>Cancel</button>
          <button type="button" className="lm-button1" onClick={confirmLogout}>Confirm</button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileHeader;
