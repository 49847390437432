import React from 'react';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';

const CopyLinkComponent = ({ url, text, imgSrc, data, code }) => {
  const dispatch = useDispatch();

  const copyToClipboard = async (e) => {
    e.preventDefault();

    let fullUrl;

    try {
      if (code) {
        fullUrl = `${url}?code=${code}`;
      } else {
        fullUrl = `${url}?data=${encodeURIComponent(JSON.stringify(data))}`;
      }

      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(fullUrl);
        dispatch(reducersAndActions.actions.toasterActions.showToaster({
          visible: true,
          message: 'Link copied to clipboard!',
        }));

        setTimeout(() => {
          dispatch(reducersAndActions.actions.toasterActions.hideToaster());
        }, Constants.TOASTERHIDETIME);
      } else {
        // Fallback for unsupported browsers
        const textArea = document.createElement("textarea");
        textArea.value = fullUrl;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          dispatch(reducersAndActions.actions.toasterActions.showToaster({
            visible: true,
            message: 'Link copied to clipboard!',
          }));
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
          dispatch(reducersAndActions.actions.toasterActions.showToaster({
            visible: true,
            message: 'Failed to copy link!',
          }));
        }
        document.body.removeChild(textArea);

        setTimeout(() => {
          dispatch(reducersAndActions.actions.toasterActions.hideToaster());
        }, Constants.TOASTERHIDETIME);
      }
    } catch (error) {
      console.error('Failed to copy to clipboard: ', error);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({
        visible: true,
        message: 'Failed to copy link!',
      }));

      setTimeout(() => {
        dispatch(reducersAndActions.actions.toasterActions.hideToaster());
      }, Constants.TOASTERHIDETIME);
    }
  };

  return (
    <a href={url} onClick={copyToClipboard}>
      <div>
        <img className="mr-1" src={imgSrc} alt={text} /> {text}
      </div>
    </a>
  );
};

export default CopyLinkComponent;
