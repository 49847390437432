import React from 'react';
import { FieldControl, Validators } from 'react-reactive-form';
import Select from 'react-select';

const FormSelect = ({
    name,
    label,
    options,
    defaultValue,
    required,
    errorMessage
}) => {
    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <FieldControl
                name={name}
                render={({ handler, touched, hasError, value }) => (
                    <div>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            id={name}
                            value={options.find(option => option.value === value) || defaultValue}
                            onChange={selectedOption => {
                                handler().onChange(selectedOption ? selectedOption.value : '');
                            }}
                            onBlur={() => handler().onBlur()}
                            options={options}
                        />
                        {touched && hasError('required') && (
                            <div className="invalid-feedback">{errorMessage || 'This field is required.'}</div>
                        )}
                    </div>
                )}
                validators={required ? [Validators.required] : []}
            />
        </div>
    );
};

export default FormSelect;
    

