import React, { useState, useEffect } from 'react';
import dummy_avtar from '../../../assets/img/dummy_avtar.png';
import Delete from '../../../assets/img/delete.svg';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import axiosInstance from '../../../helpers/axiosConfig';
import Constants from '../../../helpers/Constants';
import Preloader from '../../../plugins/Preloader';
import EditIcon from '../../../assets/icons/edit-pen.svg';


const EditProfile = ({ activeTab }) => {

    // State for managing profile data and preloader
    const [profileData, setProfileData] = useState({});
    const [preloader, setPreloader] = useState(true)
    const [isEditing, setIsEditing] = useState(false);


    // Get stored image and profile data from localStorage
    const storedImage = localStorage.getItem('avatarImage');
    const profileDataAll = localStorage.getItem('profileData');
    const profileDetails = profileDataAll ? JSON.parse(profileDataAll) : {};

    // State for managing form data
    const [formData, setFormData] = useState({
        avatarImage: storedImage ? storedImage : dummy_avtar,
        firstName: profileDetails?.firstName || '',
        lastName: profileDetails?.lastName || '',
        company: profileDetails?.companyEntity || '',
        email: profileDetails?.email || '',
        officeNumber: profileDetails?.officeNumber || '',
        mobileNumber: profileDetails?.mobileNumber || ''
    });

    // Updated UpdateProfileData function
    const UpdateProfileData = () => {
        setPreloader(true)
        const storedUserId = localStorage.getItem('user');
        const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;
        const storedSessionId = localStorage.getItem('sessionId');

        if (parsedUserId && storedSessionId) {
            const apiUrl = '/api/User/MyProfile';
            const queryParams = `?userId=${parsedUserId.id}&SessionId=${storedSessionId}`;

            axiosInstance.get(`${apiUrl}${queryParams}`)
                .then(response => {
                    setPreloader(false);
                    const data = response?.data.resultObject;
                    setProfileData(data);

                    // Store only essential data in localStorage
                    const essentialData = {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email
                    };

                    localStorage.setItem("profileData", JSON.stringify(essentialData));
                    setFormData({
                        avatarImage: `data:image/png;base64,${data?.imagePath}`,
                        firstName: data.firstName || '',
                        lastName: data.lastName || '',
                        company: data.companyEntity || '',
                        email: data.email || '',
                        officeNumber: standardizePhoneNumber(data.officeNumber) || '',
                        mobileNumber: standardizePhoneNumber(data.mobileNumber) || ''
                    });
                })
                .catch(error => {
                    setPreloader(false);
                    console.error('Error fetching profile data:', error);
                });
        } else {
            console.error('User ID or Session ID not available');
        }
    };







    // Fetch profile data on component mount
    useEffect(() => {
        UpdateProfileData()
    }, []);

    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();


    // Update localStorage and dispatch action when avatar image changes
    useEffect(() => {
        localStorage.setItem('avatarImage', formData.avatarImage);
        dispatch(reducersAndActions.actions.profileAction.uplaodData({ visible: true, message: formData.avatarImage }));
    }, [formData.avatarImage]);


    // Handle image change
    const handleImageChange = (event) => {

        const file = event.target?.files?.[0];
        if (!file) {
            console.log('No file selected');
            return;
        }

        const reader = new FileReader();
        // const file = event.target.files[0];

        const allowedTypes = ['image/jpeg', 'image/png'];
        if (!allowedTypes.includes(file.type)) {
            alert('Please select a valid image file (JPEG or PNG).');
            return;
        }

        const maxSize = 15 * 1024 * 1024; // Changed from 2MB to 15MB
        if (file.size > maxSize) {
            alert('File size exceeds 15MB. Please choose a smaller file.');
            return;
        }

        reader.onloadend = () => {


            setFormData(prevFormData => ({
                ...prevFormData,
                avatarImage: reader.result
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }

        event.target.value = null;
    };


    // Handle image removal
    const handleRemoveImage = async () => {
        setPreloader(true)
        setFormData(prevFormData => ({
            ...prevFormData,
            avatarImage: dummy_avtar
        }));

        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = null;
        }

        localStorage.removeItem('avatarImage');
        if (true) {
            try {
                const storedUserId = localStorage.getItem('user');
                const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;
                const storedSessionId = localStorage.getItem('sessionId');

                if (parsedUserId && storedSessionId) {
                    const apiUrl = '/api/User/MyProfile';
                    const formDataToSend = await new FormData();
                    formDataToSend.append('Id', parsedUserId.id);
                    formDataToSend.append('Email', formData.email);
                    formDataToSend.append('FirstName', formData.firstName);
                    formDataToSend.append('LastName', formData.lastName);
                    formDataToSend.append('CompanyEntity', formData.company);
                    formDataToSend.append('OfficeNumber', formData.officeNumber);
                    formDataToSend.append('MobileNumber', formData.mobileNumber);
                    formDataToSend.append('SessionId', storedSessionId);

                    if (dummy_avtar) {
                        const byteString = atob(dummy_avtar.split(',')[1]);
                        const mimeString = dummy_avtar.split(',')[0].split(':')[1].split(';')[0];
                        const ab = new ArrayBuffer(byteString.length);
                        const ia = new Uint8Array(ab);
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }
                        const blob = new Blob([ab], { type: mimeString });
                        formDataToSend.append('postedFile', blob, 'avatar.png');
                    }


                    const queryParams = await `?userId=${parsedUserId.id}&SessionId=${storedSessionId}`;
                    const response = await axiosInstance.post(`${apiUrl}${queryParams}`, formDataToSend, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (response.data.result?.status == "200") {

                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.result.message }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    } else {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.result.message, type: "error" }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    }
                    setPreloader(false)
                } else {

                    setPreloader(false)
                    console.error('User ID or Session ID not available');
                }
            } catch (error) {

                setPreloader(false)
                console.error('Error updating profile data:', error);
            }
        }
    };



    // Validate form fields
    const validateField = (field, value) => {
        let error = '';
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\d{3}\d{3}\d{4}$/;
        const namePattern = /^[A-Za-z]+$/;

        switch (field) {
            case 'firstName':
            case 'lastName':
                if (!namePattern.test(value) && value.length !== 0) {
                    error = `${field === 'firstName' ? 'First' : 'Last'} Name should contain only letters`;
                }
                break;
            case 'email':
                if (!emailPattern.test(value) && value.length !== 0) {
                    error = 'Invalid email address';
                }
                break;
            case 'officeNumber':
            case 'mobileNumber':
                if (!phonePattern.test(value) && value.length !== 0) {
                    error = ` ${field === 'officeNumber' ? 'Office' : 'Mobile'} number should be of 10 digits.`;
                }
                break;
            default:
                break;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [field]: error
        }));
    };


    // Handle form input change
    const handleInputChange = (e) => {
        const { id, value } = e.target;

        if (id === 'officeNumber' || id === 'mobileNumber') {
            const formattedValue = standardizePhoneNumber(value);
            setFormData({
                ...formData,
                [id]: formattedValue
            });
        } else {
            setFormData({
                ...formData,
                [id]: value
            });
        }
        validateField(id, value);
    };


    // Standardize phone number format
    const standardizePhoneNumber = (phoneNumber) => {
        // Strip all non-numeric characters from the input
        let formattedNumber = phoneNumber.replace(/\D/g, '');

        // Apply formatting based on the length of the number
        if (formattedNumber.length === 10) {
            formattedNumber = `(${formattedNumber.slice(0, 3)})${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6)}`;
        }

        return formattedNumber;
    };


    // Handle form submission
    const handleSubmit = async (e) => {

        e.preventDefault();
        let newErrors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern =    /^\d{3}\d{3}\d{4}$/;       
        const phonePatternNew = /^\(\d{3}\)\d{3}-\d{4}$/;
        const namePattern = /^[A-Za-z]+$/;
        console.log(formData)
        if (!formData.firstName) {
            newErrors.firstName = "First Name is required";
        } else if (!namePattern.test(formData.firstName)) {
            newErrors.firstName = "First Name should contain only letters";
        }
        if (!formData.lastName) {
            newErrors.lastName = "Last Name is required";
        } else if (!namePattern.test(formData.lastName)) {
            newErrors.lastName = "Last Name should contain only letters";
        }
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!emailPattern.test(formData.email)) {
            newErrors.email = "Enter Valid Email Address";
        }

        if (!formData.officeNumber) {
            newErrors.officeNumber = "Office Number is required";
        } else if (!phonePatternNew.test(formData.officeNumber)) {
            newErrors.officeNumber = "Office number should be of 10 digits.";
        }

        if (!formData.mobileNumber) {
            newErrors.mobileNumber = "Mobile Number is required";
        } else if (!phonePatternNew.test(formData.mobileNumber)) {
            newErrors.mobileNumber = "Mobile number should be of 10 digits.";
        }
        if (!formData.company) {
            newErrors.company = "Company Name is required";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }
        if (true) {

            setPreloader(true)
            try {
                const storedUserId = localStorage.getItem('user');
                const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;
                const storedSessionId = localStorage.getItem('sessionId');

                if (parsedUserId && storedSessionId) {
                    const apiUrl = '/api/User/MyProfile';
                    const formDataToSend = await new FormData();
                    formDataToSend.append('Id', parsedUserId.id);
                    formDataToSend.append('Email', formData.email);
                    formDataToSend.append('FirstName', formData.firstName);
                    formDataToSend.append('LastName', formData.lastName);
                    formDataToSend.append('CompanyEntity', formData.company);
                    formDataToSend.append('OfficeNumber', formData.officeNumber);
                    formDataToSend.append('MobileNumber', formData.mobileNumber);
                    formDataToSend.append('SessionId', storedSessionId);

                    if (formData.avatarImage && formData.avatarImage !== dummy_avtar) {

                        setFormData(formData.avatarImage)
                        localStorage.setItem('avatarImage', formData.avatarImage);
                        const byteString = atob(formData.avatarImage.split(',')[1]);
                        const mimeString = formData.avatarImage.split(',')[0].split(':')[1].split(';')[0];
                        const ab = new ArrayBuffer(byteString.length);
                        const ia = new Uint8Array(ab);
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }
                        const blob = new Blob([ab], { type: mimeString });
                        formDataToSend.append('postedFile', blob, 'avatar.png');
                    } else {
                        const byteString = atob(dummy_avtar.split(',')[1]);
                        const mimeString = dummy_avtar.split(',')[0].split(':')[1].split(';')[0];
                        const ab = new ArrayBuffer(byteString.length);
                        const ia = new Uint8Array(ab);
                        for (let i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }
                        const blob = new Blob([ab], { type: mimeString });
                        formDataToSend.append('postedFile', blob, 'avatar.png');
                    }


                    const queryParams = await `?userId=${parsedUserId.id}&SessionId=${storedSessionId}`;
                    const response = await axiosInstance.post(`${apiUrl}${queryParams}`, formDataToSend, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    setPreloader(false)
                    if (response.data.status == "200") {
                        UpdateProfileData()
                        dispatch(reducersAndActions.actions.profileAction.refreshData({ formDataToSend }));

                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    } else {

                        setPreloader(false)
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "error" }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    }
                } else {

                    setPreloader(false)
                    console.error('User ID or Session ID not available');
                }
            } catch (error) {
                setPreloader(false)
                console.error('Error updating profile data:', error);
            }
        }
    };

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const firstName = formData?.firstName ?? "";
    const firstNameFirstword = firstName.charAt(0).toUpperCase();
    const lastName = formData?.lastName ?? "";
    const lastNameFirstword = lastName.charAt(0).toUpperCase();
    return (
        <div className="tab-content" id="laTabContent">
            {preloader ? <Preloader /> : <div className={`tab-pane fade ${activeTab === "Edit_Profile" ? "show active" : ""}`} id="Edit_Profile">
                <div className="lm-card edit-profile-box">
                    <h3 className="lm-card-heading">Edit Profile</h3>
                    <div className="setting-profile-sec">
                        <div className="d-flex">
                            <div className="profile-img">
                                {firstNameFirstword === "" ? (
                                    <img src={dummy_avtar} alt="User Avatar" />
                                ) : formData.avatarImage !== dummy_avtar ? (
                                    <img
                                        src={
                                            formData.avatarImage !== "data:image/png;base64,null"
                                                ? formData.avatarImage
                                                : dummy_avtar
                                        }
                                        alt="Profile"
                                    />
                                ) : (
                                    <div className="profile-text-img">
                                        <h2>{firstNameFirstword}
                                            {lastNameFirstword}</h2>
                                    </div>
                                )}
                                <input
                                    type="file"
                                    id="profileImageInput"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                    accept=".jpg, .jpeg, .png"
                                />
                                {formData.avatarImage && formData.avatarImage !== dummy_avtar && (
                                    <button className="delete-btn" onClick={handleRemoveImage}>
                                        <img className="delete" src={Delete} alt="Delete" />
                                    </button>
                                )}
                                <button className="edit-btn" onClick={() => document.getElementById('profileImageInput').click()}>
                                    <img className="edit" src={EditIcon} alt="" />
                                </button>
                            </div>
                            {/* {isEditing && (
                                    <>
                                            <button type="button" className="lm-button2 text-red ml-4" onClick={handleRemoveImage}>Remove</button> 
                                        <div className="ml-3 customtooltip">
                                            <input className="fileInput2" type="file" id="fileInput" onChange={handleImageChange} accept=".jpg, .jpeg, .png" />
                                            <label className="lm-upload2" htmlFor="fileInput">
                                                Change
                                            </label>
                                        </div>
                                    </>
                                )} */}
                        </div>
                        {/* <button type="button" className="lm-button2">Verify</button> */}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-4">
                            <div className="col-md-4 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        placeholder="First Name"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                    {<div className='error-message'>{errors.firstName}</div>}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        placeholder="Last Name"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />

                                    {<div className="error-message">{errors.lastName}</div>}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="company">Company / Entity</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="company"
                                        placeholder="Company"
                                        value={formData.company}
                                        onChange={handleInputChange}
                                    />

                                    {<div className="error-message">{errors.company}</div>}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        placeholder='Email'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    {errors.email && <div className="error-message">{errors.email}</div>}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="officeNumber">Office Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="officeNumber"
                                        placeholder="(808)356-4367"
                                        value={formData.officeNumber}
                                        onChange={handleInputChange}
                                        maxLength={10}
                                    />
                                    {<div className="error-message">{errors.officeNumber}</div>}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="mobileNumber">Mobile Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="mobileNumber"
                                        placeholder="(997)356-4367"
                                        value={formData.mobileNumber}
                                        onChange={handleInputChange}
                                        maxLength={10}
                                    />
                                    {<div className="error-message">{errors.mobileNumber}</div>}
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="lm-button1">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    );
};

export default EditProfile;
