import { SidePanel, ProfileHeader } from "@components";
import Select from 'react-select';
import Preloader from '../plugins/Preloader';
import React, { useState, useEffect } from 'react';
import './styles/Users.css';
import back_arrow from '../assets/icons/back_arrow.svg';
import brdcrm_nav from '../assets/icons/brdcrm_nav.svg';
import axiosInstance from '../helpers/axiosConfig';
import { useLocation } from 'react-router-dom';
import ChangePasswordModal from "./user/adminAndAdvisor/ChangePasswordModal";
import SelectAdminModal from "./user/adminAndAdvisor/SelectAdminModal";
import { useDispatch, useSelector } from "react-redux";
import reducersAndActions from "../_redux/slices";
import Constants from "../helpers/Constants";
import { useNavigate } from 'react-router-dom';
import convertToIST from "./user/commonFunctions/User";

function UserForm() {
  const location = useLocation();
  const userdetails = location.state?.data
  // Set document title
  useEffect(() => {
    document.title = "User Detail";
  }, []);

  const [loading, setLoading] = useState(true);
  // Dropdown options for role and status
  const DropdownOptionsForRole = [
    { value: 2, label: 'Advisor' },
    { value: 1, label: 'Admin' }
  ];
  const DropdownOptionsForStatus = [
    { value: false, label: 'Inactive' },
    { value: true, label: 'Active' }
  ];
  const [formData, setFormData] = useState({
    id: userdetails?.id,
    firstName: userdetails?.firstName ? userdetails.firstName : "",
    lastName: userdetails?.lastName ? userdetails.lastName : "",
    company: userdetails?.companyName ? userdetails.companyName : "",
    institution: '',
    email: userdetails?.email ? userdetails.email : "",
    officeNumber: userdetails?.telephone ? userdetails.telephone : "",
    mobileNumber: userdetails?.mobile ? userdetails.mobile : "",
    program: '',
    role: userdetails?.roleName === "Admin" ? DropdownOptionsForRole[1] : DropdownOptionsForRole[0],
    status: userdetails?.statusDesc === "Active" ? DropdownOptionsForStatus[0] : DropdownOptionsForStatus[1],
    code: '',
    allowESignature: false,
    creditReports: false,
    thirdPartyReports: false,
    lockAccount: false,
    allowPasswordReset: true,
    RoleId: 1,
    DemoCode: ""
  });


  useEffect(() => {
    setLoading(true)
    document.title = "User Detail";
    const storedSessionId = localStorage.getItem('sessionId');
    if (userdetails?.id) {
      const apiUrl = '/api/User/GetUserDetailsById';
      const queryParams = `?userId=${userdetails.id}&SessionId=${storedSessionId}`;
      axiosInstance.get(`${apiUrl}${queryParams}`).then((res) => {
        const resultObject = JSON.parse(res.data.resultObject)[0];
        console.log(resultObject);
        setFormData(prevFormData => ({
          ...prevFormData,
          firstName: resultObject.FirstName,
          lastName: resultObject.LastName,
          company: resultObject.CompanyEntity,
          email: resultObject.Email,
          officeNumber: resultObject.OfficeNumber,
          mobileNumber: resultObject.MobileNumber,
          role: resultObject.ROLENAME === "Admin" ? DropdownOptionsForRole[1] : DropdownOptionsForRole[0],
          status: userdetails?.statusDesc === "Active" ? DropdownOptionsForStatus[1] : DropdownOptionsForStatus[0],
          allowESignature: resultObject.AdvisorDocuSign === "True",
          creditReports: resultObject.CreditReports === "True",
          thirdPartyReports: resultObject.ThirdPartyReports === "True",
          lockAccount: resultObject.IsUserLocked === "True",
          allowPasswordReset: resultObject.IsAllowChange === "True",
          RoleId: parseInt(resultObject.RoleId, 10),
          DemoCode: resultObject.DemoCode || ""
        }));
      })
    }
    setLoading(false);
  }, []);

  const [changePassPopupShow, setChangePassPopup] = useState(false);
  const [adminPopup, setAdminPopup] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  // Go back to previous page
  const goBack = () => {
    window.history.back();
  };

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    officeNumber: '',
    mobileNumber: '',
    company: ''
  });

  // Validate input fields
  const validateField = (field, value) => {
    let error = '';
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;
    const phonePatternNew = /^\(\d{3}\)\d{3}-\d{4}$/;
    const namePattern = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;


    switch (field) {
      case 'firstName':
      case 'lastName':
        if (!namePattern.test(value) && value.length !== 0) {
          error = `${field === 'firstName' ? 'First' : 'Last'} Name should contain only letters`;
        }
        break;
      case 'email':
        if (!emailPattern.test(value) && value.length !== 0) {
          error = 'Invalid email address';
        }
        break;
      case 'officeNumber':
      case 'mobileNumber':
        if ((!phonePattern.test(value) && !phonePatternNew.test(value)) && value.length !== 0) {
          error = ` ${field === 'officeNumber' ? 'office' : 'mobile'} number should be of 10 digits.`;
        }
        break;
      default:
        break;
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [field]: error
    }));
  };


  // Handle input field change
  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    if (id === 'officeNumber' || id === 'mobileNumber') {
      const formattedValue = standardizePhoneNumber(newValue);
      setFormData({
        ...formData,
        [id]: formattedValue
      });
    } else {
      setFormData({
        ...formData,
        [id]: newValue
      });
    }
    // Validate the changed field
    validateField(id, newValue);
  };
  const standardizePhoneNumber = (phoneNumber) => {
    // Strip all non-numeric characters from the input
    let formattedNumber = phoneNumber.replace(/\D/g, '');

    // Apply formatting based on the length of the number
    if (formattedNumber.length === 10) {
      formattedNumber = `(${formattedNumber.slice(0, 3)})${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6)}`;
    }

    return formattedNumber;
  };

  // Handle select field change
  const handleSelectChange = (selectedOption, actionMeta) => {
    if (selectedOption.label == "Admin") {
      setAdminPopup(true)
    } else {
      setFormData({ ...formData, [actionMeta.name]: selectedOption });
      setIsConfirm(false)
    }
  };

  useEffect(() => {
    if (isConfirm) {
      setFormData({ ...formData, role: { value: 1, label: "Admin" } });
    }
  }, [isConfirm]);

  const dispatch = useDispatch()
  const navigate = useNavigate();
  // Handle form submission
  const handleSubmit = async (e) => {

    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;
    const phonePatternNew = /^\(\d{3}\)\d{3}-\d{4}$/;
    const namePattern = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;


    let newErrors = {};
    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
    } else if (!namePattern.test(formData.firstName)) {
      newErrors.firstName = "First Name should contain only letters";
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
    } else if (!namePattern.test(formData.lastName)) {
      newErrors.lastName = "Last Name should contain only letters";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Enter Valid Email Address";
    }

    if (!formData.officeNumber) {
      newErrors.officeNumber = "Office Number is required";
    } else if (!phonePatternNew.test(formData.officeNumber)) {
      newErrors.officeNumber = "Office number should be of 10 digits.";
    }

    if (!formData.mobileNumber) {
      newErrors.mobileNumber = "Mobile Number is required";
    } else if (!phonePatternNew.test(formData.mobileNumber)) {
      newErrors.mobileNumber = "Mobile number should be of 10 digits.";
    }
    if (!formData.company) {
      newErrors.company = "Company Name is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    if (Object.values(errors).every(error => error === '')) {
      try {
        setLoading(true)
        const storedSessionId = localStorage.getItem('sessionId');
        if (!storedSessionId) {
          console.error('Session ID not found');
          return;
        }
        const formDataToSend = new FormData();

        // Append form data 
        formDataToSend.append("id", formData.id == undefined ? 0 : formData.id);
        formDataToSend.append("FirstName", formData.firstName);
        formDataToSend.append("LastName", formData.lastName);
        formDataToSend.append("EmailAddress", formData.email);
        formDataToSend.append("CompanyOrEntity", formData.company);
        formDataToSend.append("Telephone", formData.officeNumber);
        formDataToSend.append("Mobile", formData.mobileNumber);
        formDataToSend.append("RoleId", formData.role.value);
        formDataToSend.append("IsPasswordResetAllowed", formData.allowPasswordReset);
        formDataToSend.append("CreditReports", formData.creditReports);
        formDataToSend.append("ThirdPartyReports", formData.thirdPartyReports);
        formDataToSend.append("AdvisorDocuSign", formData.allowESignature);
        formDataToSend.append("IsUserLocked", formData.lockAccount);
        formDataToSend.append("IsPasswordResetAllowed", formData.allowPasswordReset);
        formDataToSend.append("Status", formData.status.value);

        if (formData.role.label === "Admin") {
          if (formData.DemoCode !== "" && formData.DemoCode.length !== 5) {
            setLoading(false)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please enter 5 digit code", type: 'error' }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
          }
          formDataToSend.append("DemoCode", formData.DemoCode)
        }

        const response = await axiosInstance.post("/api/User/InsertUpdateAdminUserInfo", formDataToSend, {
          params: {
            WebURL: "http://localhost:3000/",
            SessionId: storedSessionId
          }
        });;
        setLoading(false)
        if (response.status == '200') {
          setLoading(false)
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          setAdminPopup(false);
          navigate('/users/advisor-admin')
        } else {
          setLoading(false)
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: 'error' }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
      } catch (error) {
        setLoading(false)

        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: 'error' }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        console.error('Error submitting form:', error, error.response ? error.response.data : error.message);
      }
    }
  };

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (
        <Preloader />
      ) : (
        <div>
          <div className="dashboard-wrap users-screen">
            {/* User breadcrumb */}
            <div className="lm-breadcrumb d-flex">
              <a onClick={goBack} className="back-btn mr-2" >
                <img src={back_arrow} alt="" />
              </a>
              <span onClick={goBack} className="text-light mr-1 cursor-pointer">
                Users
              </span>
              <img src={brdcrm_nav} alt="" />
              <span className="text-dark ml-1">
                {userdetails?.firstName} {userdetails?.lastName}
              </span>
            </div>
            {/* End User breadcrumb */}

            <div className="last-login-box">
              <span>Last Login: {convertToIST(userdetails?.lastLoginDateTime)}</span>
              {/* <span>Last Login: NA</span> */}
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row mt-4 mb-5 pb-2">
                <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      placeholder="Enter First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    {
                      <small className="error-message-user">{errors.firstName}</small>
                    }
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder="Enter Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    {
                      <small className="error-message-user">{errors.lastName}</small>
                    }
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="company">Company / Entity</label>
                    <input
                      type="text"
                      className="form-control"
                      id="company"
                      placeholder="Enter Company / Entity"
                      value={formData.company}
                      onChange={handleChange}
                    />
                    {
                      <small className="error-message-user">{errors.company}</small>
                    }
                  </div>
                </div>
                {location.pathname !== "/users/advisor-admin-detail" ?
                  <div className="col-md-4 col-lg-3">
                    <div className="form-group">
                      <label htmlFor="institution">Institution</label>
                      <input
                        type="text"
                        className="form-control"
                        id="institution"
                        placeholder="Enter Institution"
                        value={formData.institution}
                        onChange={handleChange}
                      />
                    </div>
                  </div> : ""}
                <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {
                      <small className="error-message-user">{errors.email}</small>
                    }
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="officeNumber">Office Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="officeNumber"
                      placeholder="Enter Office Number"
                      value={formData.officeNumber}
                      onChange={handleChange}
                    />
                    {
                      <small className="error-message-user">{errors.officeNumber}</small>
                    }
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobileNumber"
                      placeholder="Enter Mobile Number"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                    />
                    {
                      <small className="error-message-user">{errors.mobileNumber}</small>
                    }
                  </div>
                </div>
                {location.pathname !== "/users/advisor-admin-detail" ? <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="program">Program</label>
                    <input
                      type="text"
                      className="form-control"
                      id="program"
                      placeholder="Enter a Program"
                      value={formData.program}
                      onChange={handleChange}
                    />
                  </div>
                </div> : ""}
                <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="role">Role</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      options={DropdownOptionsForRole}
                      value={DropdownOptionsForRole.find(option => option.label === formData.role.label)}
                      onChange={handleSelectChange}
                      name="role"
                    />
                  </div>
                </div>
                {
                  location.pathname === "/users/advisor-admin-detail" && formData.role.label === "Admin" ?
                    (
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="code">Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="DemoCode"
                            placeholder="Enter Code"
                            value={formData.DemoCode}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    ) : ""
                }
                <div className="col-md-4 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      options={DropdownOptionsForStatus}
                      value={DropdownOptionsForStatus.find(option => option.label === formData.status.label)}
                      onChange={handleSelectChange}
                      name="status"
                    />
                  </div>
                </div>

                <div className="col-12">
                  {/* Checkboxes for various options */}
                  <div className="form-group mb-2 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="allowESignature"
                      checked={formData.allowESignature}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="allowESignature">
                      Allow E-Signature
                    </label>
                  </div>
                  <div className="form-group mb-2 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="creditReports"
                      checked={formData.creditReports}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="creditReports">
                      Credit Reports
                    </label>
                  </div>
                  <div className="form-group mb-2 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="thirdPartyReports"
                      checked={formData.thirdPartyReports}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="thirdPartyReports"
                    >
                      Third Party Reports
                    </label>
                  </div>
                  <div className="form-group mb-2 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="lockAccount"
                      checked={formData.lockAccount}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="lockAccount">
                      Lock Account
                    </label>
                  </div>
                  <div className="form-group mb-2 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="allowPasswordReset"
                      checked={formData.allowPasswordReset}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="allowPasswordReset"
                    >
                      Allow Password Reset
                    </label>
                  </div>
                </div>
                {/* End Checkboxes */}

                {userdetails && <div className="col-12 mt-4">
                  <button
                    onClick={() => setChangePassPopup(true)}
                    type="button"
                    className="lm-button2"
                  >
                    Change Password
                  </button>
                </div>}
              </div>

              {/* Sticky buttons at the bottom */}
              <div className="sticky-btn-footer d-flex justify-content-end">
                <button onClick={goBack} type="button" className="lm-button2">
                  Back
                </button>
                <button type="submit" className="lm-button1 ml-3">
                  Save
                </button>
              </div>
              {/* End Sticky buttons */}
            </form>
            {/* Change Password Popup / Modal */}
            <ChangePasswordModal
              userdetails={userdetails}
              changePassPopupShow={changePassPopupShow}
              setChangePassPopup={setChangePassPopup}
            />

            {/* End Change Password Popup / Modal */}
            <SelectAdminModal
              adminPopup={adminPopup}
              setAdminPopup={setAdminPopup}
              setIsConfirm={setIsConfirm}
            />
          </div>
        </div>
      )}
    </>


  );
}

export default UserForm;
