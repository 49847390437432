import React from 'react';
import CreatableSelect from 'react-select/creatable';

const CreatableSelectComponent = ({ name, label, value, onChange, placeholder,options , isMulti = true, isClearable = true}) => {
  const handleChange = (selectedOption) => {
    onChange(name, selectedOption);
  };

  return (
    <div>
      {label && <label>{label}</label>}
      <CreatableSelect
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        options={options || []}
        isMulti={isMulti}
        isClearable={isClearable}
      />
    </div>
  );
};

export default CreatableSelectComponent;

