import React, { useState, useEffect, useCallback } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { decryptText } from "../../helpers/DynamicFunctions";
import Preloader from '../../plugins/Preloader';
import FormTextArea from "../../reusablecomponets/FormTextArea";
import FormRadio from "../../reusablecomponets/FormRadio";
import FormSelect from "../../reusablecomponets/FormSelect";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { actions } from '../../_redux/store';
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import FormInputAd from "../../reusablecomponets/FormInputAd";
import reducersAndActions from "../../_redux/slices/index";
import Modal from 'react-bootstrap/Modal'
import makeAnimated from 'react-select/animated';


function IndustryRestaurant({ backPreviousTab }) {

    const dispatch = useDispatch();

    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);
    const [formData, setFormData] = useState({
      
        // other form fields
    });
    console.log(formData);
    
    useEffect(() => {
        document.title = "Restaurant Information - Loan Application";
    }, []);

    const TablesSeatingOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: '1-10' },
        { value: '2', label: '11-20' },
        { value: '3', label: '21-30' },
        { value: '4', label: '31-40' },
        { value: '5', label: '41-50' },
        { value: '6', label: '51-60' },
        { value: '7', label: '61-70' },
        { value: '8', label: '71-80' },
        { value: '9', label: '81-90' },
        { value: '10', label: '91-100' },
        { value: '11', label: '100 Plus' }
    ];

    const CurrentEmployeesOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: '1-10' },
        { value: '2', label: '11-20' },
        { value: '3', label: '21-30' },
        { value: '4', label: '31-40' },
        { value: '5', label: '41-50' },
        { value: '6', label: '51-60' },
        { value: '7', label: '61-70' },
        { value: '8', label: '71-80' },
        { value: '9', label: '81-90' },
        { value: '10', label: '91-100' },
        { value: '11', label: '100 Plus' }
    ];

    const PeakSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];

    const LowSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];

    const [form] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", Validators.required],
            suppliersVendorTerms: ["", Validators.required],
            customersSellingTerms: ["", Validators.required],
            competitiveAdvantages: ["", Validators.required],
            hoursOperation: ["", Validators.required],
            seasonalBusiness: [""],
            numberEmployees: ["", Validators.required],
            numberAfterLoan: ["", Validators.required],
            deliveryInstallation: [""],
            //employeesTrained: [""],
            downTime: [""],
            restaurantSpecialty: ["", Validators.required],
            knownForAnything: [""],
            tablesSeating: ["", Validators.required],
            privateDiningRoom: [""],
            turnsWeekdayLunch: [""],
            isWeekendLunch: [""],
            isBarArea: [""],
            cateringPerformed: [""],
            isOffsite: [""],
            isPriceFix: [""],
            comments: [""],
            newEquipment: [""],
            existingEquipment: [""],
            //removalDisposal: [""],
            modifiedBy: [0],
            customerVendors: ["", Validators.required],
            keyCompetitors: ["", Validators.required],
            insuranceAccepted: [""],
            peakSeason: [""],
            lowSeason: [""],
            exactlyKnown: [""]
        })
    );

    useEffect(() => {
        dispatch(actions.restaurantForm.fetchRestaurantData({ loanId: parsedLoanId, type: 'Restaurant' }));
    }, [dispatch, parsedLoanId]);

    const { data, loading, error } = useSelector((state) => state.restaurantForm);

    useEffect(() => {
        setSelectOptions({
            geographicMarket: [],
            customerVendors: [],
            customersSellingTerms: [],
            peakSeason: [],
            lowSeason: []
        });
    }, []);

    const [selectOptions, setSelectOptions] = useState({
        geographicMarket: [],
        customerVendors: [],
        customersSellingTerms: [],
        peakSeason: [],
        lowSeason: []
    });

    useEffect(() => {
        console.log("ddddddd");
        
        form.patchValue({
                        
            // id: restaurantData.Id,
            // loanId: restaurantData.LoanId,
            seasonalBusiness: "false",
        })
        if (data && data.resultObject) {
            try {
                const resultObject = JSON.parse(data.resultObject);
                
                if (Array.isArray(resultObject) && resultObject.length > 0) {
                    const restaurantData = resultObject[0];
                    console.log("resultObject:" + restaurantData);
                    const geographicMarketOptions = restaurantData.GeographicMarket ? restaurantData.GeographicMarket.split(',').map(value => ({ label: value, value })) : [];
                    const customerVendorsOptions = restaurantData.CustomerVendors ? restaurantData.CustomerVendors.split(',').map(value => ({ label: value, value })) : [];
                    const customersSellingTermsOptions = restaurantData.CustomersSellingTerms ? restaurantData.CustomersSellingTerms.split(',').map(value => ({ label: value, value })) : [];
                    
                    // const peakSeasonOptions = restaurantData.PeakSeason ? restaurantData.PeakSeason.split(',').map(value => ({ label: value, value })) : [];
                    const peakSeasonOptions = restaurantData.PeakSeason ? restaurantData.PeakSeason.split(',').map(value => ({
                        value,
                        label: PeakSeasonOptions.find(option => option.value === value)?.label || value
                    })) : [];
                    const lowSeasonOptions = restaurantData.LowSeason ? restaurantData.LowSeason.split(',').map(value => ({
                        value,
                        label: LowSeasonOptions.find(option => option.value === value)?.label || value
                    })) : [];
        
                    setSelectOptions({
                        geographicMarket: geographicMarketOptions,
                        customerVendors: customerVendorsOptions,
                        customersSellingTerms: customersSellingTermsOptions,
                        peakSeason: peakSeasonOptions,
                        lowSeason: lowSeasonOptions
                    });
        
                    console.log('Delivery Installation:', restaurantData.DeliveryInstallation);
                    form.patchValue({
                        id: restaurantData.Id,
                        loanId: restaurantData.LoanId,
                        geographicMarket: restaurantData.GeographicMarket,
                        suppliersVendorTerms: restaurantData.SuppliersVendorTerms,
                        customersSellingTerms: restaurantData.CustomersSellingTerms,
                        competitiveAdvantages: restaurantData.CompetitiveAdvantages,
                        hoursOperation: restaurantData.HoursOperation,
                        seasonalBusiness: restaurantData.SeasonalBusiness === 'true' ? 'true' : 'false',
                        numberEmployees: restaurantData.NumberEmployees,
                        numberAfterLoan: restaurantData.NumberAfterLoan,
                        deliveryInstallation: restaurantData.DeliveryInstallation === 'true' ? 'true' : 'false',
                        // employeesTrained: restaurantData.EmployeesTrained,
                        downTime: restaurantData.DownTime,
                        restaurantSpecialty: restaurantData.RestaurantSpecialty,
                        knownForAnything: restaurantData.KnownForAnything === 'true' ? 'true' : 'false',
                        tablesSeating: restaurantData.TablesSeating,
                        privateDiningRoom: restaurantData.PrivateDiningRoom === 'true' ? 'true' : 'false',
                        turnsWeekdayLunch: restaurantData.TurnsWeekdayLunch === 'true' ? 'true' : 'false',
                        isWeekendLunch: restaurantData.IsWeekendLunch === 'true' ? 'true' : 'false',
                        isBarArea: restaurantData.IsBarArea === 'true' ? 'true' : 'false',
                        cateringPerformed: restaurantData.CateringPerformed === 'true' ? 'true' : 'false',
                        isOffsite: restaurantData.IsOffsite === 'true' ? 'true' : 'false',
                        isPriceFix: restaurantData.IsPriceFix === 'true' ? 'true' : 'false',
                        comments: restaurantData.Comments,
                        newEquipment: restaurantData.NewEquipment === 'true' ? 'true' : 'false',
                        existingEquipment: restaurantData.ExistingEquipment,
                        //removalDisposal: restaurantData.RemovalDisposal,
                        modifiedBy: 0,
                        customerVendors: restaurantData.CustomerVendors,
                        keyCompetitors: restaurantData.KeyCompetitors,
                        insuranceAccepted: restaurantData.InsuranceAccepted,
                        peakSeason: restaurantData.PeakSeason,
                        lowSeason: restaurantData.LowSeason,
                        exactlyKnown: restaurantData.ExactlyKnown
                    });
                    // setSelectOptions({
                    //     geographicMarket: restaurantData.GeographicMarket.split(',').map(value => ({ label: value, value })),
                    //     customerVendors: restaurantData.CustomerVendors.split(',').map(value => ({ label: value, value })),
                    //     customersSellingTerms: restaurantData.CustomersSellingTerms.split(',').map(value => ({ label: value, value })),
                    //     peakSeason: restaurantData.PeakSeason.split(',').map(value => ({
                    //         value,
                    //         label: PeakSeasonOptions.find(option => option.value === value)?.label || value
                    //     })),
                    //     lowSeason: restaurantData.LowSeason.split(',').map(value => ({
                    //         value,
                    //         label: LowSeasonOptions.find(option => option.value === value)?.label || value
                    //     }))

                    // });
                } else {
                    console.log("fffffff");
                    
                    form.reset({
                        id: 0,
                        loanId: parsedLoanId,
                        modifiedBy: 0
                    });
                }
            } catch (error) {
                console.error("Error parsing resultObject", error);
                form.reset({
                    id: 0,
                    loanId: parsedLoanId,
                    modifiedBy: 0
                });
            }
        } else {
            form.reset({
                id: 0,
                loanId: parsedLoanId,
                modifiedBy: 0
            });
        }
    }, [data, setSelectOptions, form]);

    const handleSubmit = useCallback((e) => {
        if (form.value.seasonalBusiness == null ) {
            form.value.seasonalBusiness = 'false';
        }
        if (form.value.isBarArea == null || form.value.isBarArea === "") {
            form.value.isBarArea="false";
        }
        if (form.value.isWeekendLunch == null || form.value.isWeekendLunch === "") {
            form.value.isWeekendLunch="false";
        }
        if (form.value.privateDiningRoom == null || form.value.privateDiningRoom === "") {
            form.value.privateDiningRoom = 'false';
        }
        if (form.value.isPriceFix == null || form.value.isPriceFix === "") {
            form.value.isPriceFix="false";
        }
        if (form.value.cateringPerformed == null || form.value.cateringPerformed === "") {
            form.value.cateringPerformed="false";
        }
        if (form.value.isOffsite == null || form.value.isOffsite === "") {
            form.value.isOffsite="false";
        }
        if (form.value.newEquipment == null || form.value.newEquipment === "") {
            form.value.newEquipment="false";
        }if (form.value.knownForAnything == null || form.value.knownForAnything === "") {
            form.value.knownForAnything="false";
        }
        console.log(form.value);
        e.preventDefault();
        markAllAsTouched(form);
        
        
        if (form.valid) {
            const currentValues = form.value;
            dispatch(actions.restaurantForm.submitRestaurantForm(currentValues))
                .then(() => {

                    if (!currentValues.id) {  
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form submitted successfully", 
                            type: "success",
                        }));
                    } else {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form updated successfully",
                            type: "success",
                        }));
                    }
                    dispatch(actions.restaurantForm.fetchRestaurantData({ loanId: parsedLoanId, type: 'Restaurant' }));
                    setTimeout(() => {
                        dispatch(reducersAndActions.actions.toasterActions.hideToaster());
                    }, 5000);
                })
                .catch(() => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: "Error submitting form",
                        type: "error",
                    }));
                });
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: "Form validation failed",
                type: "error",
            }));
        }
    }, [dispatch, form, parsedLoanId]);


    const handleSelectChange = (name, selectedOption) => {
        const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
        setSelectOptions(prevOptions => ({ ...prevOptions, [name]: selectedOption }));
        form.controls[name].setValue(selectedValues.join(','));
    };


    

    const markAllAsTouched = (formGroup) => {
        console.log(formGroup);
        
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control) {
                control.markAsTouched();
                control.updateValueAndValidity();
            }
        });
    };
    console.log(form.value);
    const [selectedOption, setSelectedOption] = useState('');

    const handleRadioChange = (value) => {
        setSelectedOption(value);
        console.log('Selected Value:', value); // For debugging
    };
    
    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get, invalid }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Restaurant Information </h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                //value={form.get('geographicMarket').value}
                                                // value={{ label: form.controls.geographicMarket.value, value: form.controls.geographicMarket.value }}
                                                label="Geographic market"
                                                //value={geographicMarketOptions}
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                 errorMessage="This field is required."
                                            />
                                            
                                            {form.value.geographicMarket==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}

                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="suppliersVendorTerms"
                                                label="Major suppliers & vendor terms"
                                                name="suppliersVendorTerms"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customerVendors"
                                                label="Major customers & vendor terms"
                                                value={selectOptions.customerVendors}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            {form.value.customerVendors==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormRadio
                                                name="deliveryInstallation"
                                                label="Does purchase include delivery and installation? Training?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customersSellingTerms"
                                                label="Major customers & selling terms"
                                                value={selectOptions.customersSellingTerms}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable

                                            />
                                            
                                            {form.value.customersSellingTerms==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="keyCompetitors"
                                                label="Key competitors"
                                                name="keyCompetitors"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="competitiveAdvantages"
                                                label="Competitive advantages"
                                                name="competitiveAdvantages"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="hoursOperation"
                                                label="Competitive advantages"
                                                name="hoursOperation"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />

                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormSelect
                                                name="numberEmployees"
                                                label="Number of current employees"
                                                options={CurrentEmployeesOptions}
                                                defaultValue={CurrentEmployeesOptions[0]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.numberEmployees==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="numberAfterLoan"
                                                label="Number after loan"
                                                name="numberAfterLoan"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-9">
                                            <FormInputAd
                                                id="insuranceAccepted"
                                                label="What insurances are accepted?"
                                                name="insuranceAccepted"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.insuranceAccepted==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="downTime"
                                                label="How much downtime is anticipated during equipment installation?"
                                                name="downTime"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.downTime==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="restaurantSpecialty"
                                                label="What is this restaurant's cuisine/specialty?"
                                                name="restaurantSpecialty"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormSelect
                                                name="tablesSeating"
                                                label="How many tables/seating?"
                                                options={TablesSeatingOptions}
                                                defaultValue={TablesSeatingOptions[0]}
                                                required={true}
                                                errorMessage="Required field"
                                            // validators={[Validators.required]}
                                            />
                                            
                                            {form.value.tablesSeating==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}

                                        </div>

                                        <div className="col-md-4 col-lg-3">

                                            <FormRadio
                                                name="privateDiningRoom"
                                                label="Is there a private dining room or bar area? Tables/Seating?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                            />

                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormInputAd
                                                id="turnsWeekdayLunch"
                                                label="How many turns weekday lunch, dinner?"
                                                name="turnsWeekdayLunch"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.turnsWeekdayLunch==null&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormRadio
                                                name="isWeekendLunch"
                                                label="Weekend lunch, dinner?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormRadio
                                                name="isBarArea"
                                                label="Bar area?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormRadio
                                                name="cateringPerformed"
                                                label="Is catering performed on-site?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormRadio
                                                name="isOffsite"
                                                label="Offsite?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormRadio
                                                name="isPriceFix"
                                                label="Is there price-fix or early-bird specials?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                                // onChange={handleRadioChange}
                                            />
                                        </div>


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">

                                        <FormRadio
    name="seasonalBusiness"
    label="Is this a seasonal business?"
    options={[
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
    ]}
    required={true}
    errorMessage="Required field"
    // newValue={form.value.seasonalBusiness=="true"?true:false}
    onChange={(e) => {    
        
        
        setFormData(prevState => ({
            ...prevState,
            seasonalBusiness: e.target.value,
        }));
    }}
/>
                                        </div>

                                       {form.value.seasonalBusiness=="true"&& <><div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">

                                                    {/* <FormSelect
                                                        name="peakSeason"
                                                        label="Peak Season"
                                                        options={PeakSeasonOptions}
                                                        defaultValue={PeakSeasonOptions[0]}
                                                        required={true}
                                                        errorMessage="Required field"
                                                    /> */}

                                                    <CreatableSelect
                                                        name="peakSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Peak season"
                                                        value={selectOptions.peakSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={PeakSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {/* <FormSelect
                                                        name="lowSeason"
                                                        label="Low Season"
                                                        options={LowSeasonOptions}
                                                        defaultValue={LowSeasonOptions[0]}
                                                        required={true}
                                                        errorMessage="Required field"
                                                    /> */}
                                                    <CreatableSelect
                                                        name="lowSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Low season"
                                                        value={selectOptions.lowSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={LowSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />
                                                </div>
                                            </div>
                                        </div></>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="knownForAnything"
                                                label="Is it known for anything in particular?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"

                                                onChange={(e) => {  
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        seasonalBusiness: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>

                                        {form.value.knownForAnything=="true"&&<div className="col-md-6">
                                            {/* <div className="form-group mb-1">
                                                <label htmlFor="What_is_known">What exactly is known?</label>
                                                <input type="text" className="form-control" id="What_is_known"
                                                    placeholder="Enter Text" />
                                            </div> */}
                                            <FormInputAd
                                                id="exactlyKnown"
                                                label="What exactly is known?"
                                                name="exactlyKnown"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">

                                            <FormRadio
                                                name="newEquipment"
                                                label="Will the new equipment replace existing equipment?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                                onChange={(e) => {  
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        seasonalBusiness: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>

                                        {form.value.newEquipment=="true"&&
                                            <div className="col-md-6">

                                            <FormInputAd
                                                id="existingEquipment"
                                                label="How will the existing equipment be removed and disposed of?"
                                                name="existingEquipment"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                        </div>}


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <FormTextArea
                                                id="comments"
                                                label="Additional comments"
                                                name="comments"
                                                placeholder="Enter Text"
                                                maxlength={500}
                                            />
                                        </div>


                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button2" onClick="">Back</button>
                                    <button type="submit" className="lm-button1 ml-3">Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>



            </div>)}

        </>
    );
}

export default IndustryRestaurant;