import { createSlice } from '@reduxjs/toolkit';

const toasterSlice = createSlice({
  name: 'toaster',
  initialState: {
    visible: false,
    message: "",
    type: ""
  },
  reducers: {
    showToaster: (state, user) => {
        state.visible = true;
        state.message = user.payload.message;
        state.type = user.payload.type;
    },
    hideToaster: (state) => {
        state.visible = false;
        state.message = "";
        state.type = "";
    }
  }
});

export const toasterActions = toasterSlice.actions;
export default toasterSlice.reducer;