import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SidePanel, ProfileHeader } from "@components";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import Preloader from "../../plugins/Preloader";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FieldGroup, FormBuilder, Validators, FieldControl, } from "react-reactive-form";
import FormField from "../../components/FormField/FormField";
import { GetLoanAmountDetail, InsertUpdateLoanAmount, } from "../../services/loanApplicationServices";
import { getSessionId } from "../../services/storageData";
import { decryptText, formatNumberWithCommas } from "../../helpers/DynamicFunctions";
import Constants from "../../helpers/Constants";
import reducersAndActions from "../../_redux/slices/index";
import lm_logo from '../../assets/img/lm_logo 1.png';

const validateNumber = (value) => {
  // Allow zero, and positive numbers
  const pattern = /^\d*\.?\d*$/;
  return pattern.test(value);
};

const formatEmiStartDate = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return "";
};


// Reactive Form Group
let loanAmountForm = FormBuilder.group({
  PurchaseRealProperty: ["", [Validators.pattern("^[0-9,.]*$")]],
  PurchaseImprovements: ["", [Validators.pattern("^[0-9,.]*$")]],
  PurchaseEquipment: ["", Validators.pattern("^[0-9,.]*$")],
  PurchaseFurnitureAndFixtures: ["", Validators.pattern("^[0-9,.]*$")],
  PurchaseInventory: ["", Validators.pattern("^[0-9,.]*$")],
  DebtRefinance: ["", Validators.pattern("^[0-9,.]*$")],
  BusinessAcquisition: ["", Validators.pattern("^[0-9,.]*$")],
  WorkingCapital: ["", Validators.pattern("^[0-9,.]*$")],
  ClosingCosts: ["", Validators.pattern("^[0-9,.]*$")],
  LessBorrowerDownPayment: ["", Validators.pattern("^[0-9,.]*$")],
  TotalLoanRequest: ["", Validators.pattern("^[0-9,.]*$")],
  SourceOfDownPayment: [""],
  SubTotal: ["", Validators.pattern("^[0-9,.]*$")],
  ClosingCostsPercentage: ["", Validators.pattern("^[0-9,.]*$")],
  SBAGuaranteeFees: ["", Validators.pattern("^[0-9,.]*$")],
  SBAGuaranteeFeesPercentage: ["", Validators.pattern("^[0-9,.]*$")],
  PersonalFunds: ["", Validators.pattern("^[0-9,.]*$")],
  OthersInvestment: ["", Validators.pattern("^[0-9,.]*$")],
  DownPaymentOthers: [""],
  EquityPercentage: ["", Validators.pattern("^[0-9,.]*$")],
  TotalAmount: ["", Validators.pattern("^[0-9,.]*$")],
  EquityDeal: ["", Validators.pattern("^[0-9,.]*$")],
  EquityClosing: ["", Validators.pattern("^[0-9,.]*$")],
  EquityLoanAmount: ["", Validators.pattern("^[0-9,.]*$")],
  TotalEquityAmount: ["", Validators.pattern("^[0-9,.]*$")],
  isAmortizationScheduleTableShow: [""],
  TermYear: ["", Validators.pattern('^[0-9]+$')],
  InterestRate: [""],
  EmiStartDate: [""],
});

const checkPercentageKeys = ["ClosingCostsPercentage", "SBAGuaranteeFeesPercentage", "EquityPercentage"]
const fieldsToSum = ["PurchaseRealProperty", "PurchaseImprovements", "PurchaseEquipment", "PurchaseFurnitureAndFixtures", "PurchaseInventory", "DebtRefinance", "BusinessAcquisition", "WorkingCapital", "OthersInvestment",];

const AmountContainer = ({ name, label, placeholder, required, isDisabled = false, withoutDollar = false, setAmortizationData, setIsAmortizationScheduleTableShow, showErrorMessage }) => (
  <FieldControl
    name={name}
    render={({ handler, touched, hasError, meta }) => {
      const handlerProps = handler();
      handlerProps.value = handlerProps.value !== "0.00" ? formatNumberWithCommas(handlerProps.value) : null;

      const handleChange = (e) => {
        let rawValue = e.target.value.replace(/,/g, ''); // Remove existing commas
        if (validateNumber(rawValue)) {

          const formattedValue = formatNumberWithCommas(rawValue);
          e.target.value = formattedValue;

          if (handlerProps.onChange) {
            handlerProps.onChange(e);

            if (name === "InterestRate") {
              if (rawValue > 100) {
                showErrorMessage("Interest Rate should not be greater than 100.");
                loanAmountForm.patchValue({ InterestRate: 100 });
              }
              setAmortizationData([]);
              setIsAmortizationScheduleTableShow(false);
            } else {
              if (fieldsToSum.includes(name)) {
                calculateSumTotal();
                calculateClosingCostDollor();
                calculateSBAGuaranteeFeesDollor();
                handleCalculateLoanAmount("EquityPercentage", getValue(loanAmountForm['value']['EquityPercentage']), showErrorMessage);
              }
              if (name == "ClosingCostsPercentage" || name == "ClosingCosts") {
                calculateClosingCosts(name, getValue(rawValue), showErrorMessage);
                handleCalculateLoanAmount("EquityPercentage", getValue(loanAmountForm['value']['EquityPercentage']), showErrorMessage);
              }
              if (name == "SBAGuaranteeFeesPercentage" || name == "SBAGuaranteeFees") {
                calculateSBAGuaranteeFees(name, getValue(rawValue), showErrorMessage);
                handleCalculateLoanAmount("EquityPercentage", getValue(loanAmountForm['value']['EquityPercentage']), showErrorMessage);
              }

              if (name == "EquityPercentage" || name == "EquityDeal" || name == "LessBorrowerDownPayment") {
                handleCalculateLoanAmount(name, getValue(rawValue), showErrorMessage);
              }

              if (checkPercentageKeys.includes(name)) {
                validatePercentage(getValue(rawValue), name, showErrorMessage);
              }
            }
          }
        }
      };

      return (
        <div className={withoutDollar ? "form-group" : "form-group input-dollar"}>
          <label htmlFor={name}>{label}{required && <span className="asterisk">*</span>}</label>
          <input
            type="text"
            className="form-control"
            id={name}
            placeholder={placeholder}
            {...handlerProps}
            disabled={isDisabled}
            maxLength={14}
            onChange={handleChange}
          />

          {/* Error messages below */}
          {touched && hasError("pattern") && <><span className="error-message">Amount should be only numeric values</span><br /></>}
          {touched && hasError("borrowAmountMaxIssue") && <><span className="error-message">Amount should be less than or equal to 10000000000</span><br /></>}
          {touched && hasError("required") && <><span className="error-message">Amount is required</span><br /></>}
        </div>)
    }
    }
    meta={{ placeholder: placeholder }}
  />
)

// ---------------------------------------------------------------------------------------------------------------------------

const getValue = (value) => {
  if (typeof value === "string") {
    value = value.replace(/,/g, '');
  }
  value = parseFloat(value);
  return isNaN(value) ? 0 : value;
};


const vicedVersaKeys = {
  "ClosingCostsPercentage": "ClosingCosts",
  "EquityPercentage": "EquityDeal",
  "SBAGuaranteeFeesPercentage": "SBAGuaranteeFees"
}

// Calculate sum of the fields
const calculateSumTotal = () => {
  const totalValue = fieldsToSum.reduce((sum, field) => sum + getValue(loanAmountForm['value'][field]), 0);
  loanAmountForm.patchValue({ SubTotal: totalValue.toFixed(2) });
  return totalValue.toFixed(2);
};

// Validate percentages
const validatePercentage = (value, name, showErrorMessage) => {
  if (value > 100) {
    showErrorMessage(`${name.replace(/([A-Z])/g, ' $1')} should not be greater than 100.`);
    loanAmountForm.patchValue({ [name]: "" });

    loanAmountForm.patchValue({ [vicedVersaKeys[name]]: "" });
    if (name === "ClosingCostsPercentage") {
      calculateSBAGuaranteeFeesDollor();
    }

    handleCalculateLoanAmount("EquityPercentage", getValue(loanAmountForm['value']['EquityPercentage']));
    return 0;
  }
  return value;
};

const calculateTotalAmount = () => {
  const form = loanAmountForm.value;
  const totalAmount = getValue(form.SubTotal) + getValue(form.ClosingCosts) + getValue(form.SBAGuaranteeFees);
  return formatNumberWithCommas(totalAmount.toFixed(2));
};

const calculateClosingCostDollor = () => {
  const newSubTotalValue = getValue(loanAmountForm.value.SubTotal);
  const closingCostPercentage = getValue(loanAmountForm.value.ClosingCostsPercentage);
  const closingCostDollor = newSubTotalValue * closingCostPercentage / 100;
  loanAmountForm.patchValue({ ClosingCosts: closingCostDollor.toFixed(2) })
}

const calculateSBAGuaranteeFeesDollor = () => {
  const newSubTotalValue = getValue(loanAmountForm.value.SubTotal);
  const newClosingCostValue = getValue(loanAmountForm.value.ClosingCosts);
  const sbaGuaranteeFeesPercentage = getValue(loanAmountForm.value.SBAGuaranteeFeesPercentage);
  const totalWithClosingCost = newSubTotalValue + newClosingCostValue
  const sbaGuaranteeFeesDollor = totalWithClosingCost * sbaGuaranteeFeesPercentage / 100;
  loanAmountForm.patchValue({ SBAGuaranteeFees: sbaGuaranteeFeesDollor.toFixed(2) })
}

const calculateClosingCosts = (name, newAmount, showErrorMessage) => {
  const form = loanAmountForm.value;

  const sumTotal = getValue(form.SubTotal);
  let closingCost = getValue(form.ClosingCosts);

  if (name === "ClosingCostsPercentage") {
    closingCost = (newAmount * sumTotal) / 100;
    if (closingCost > sumTotal) {
      showErrorMessage("Closing Costs should not exceed the subtotal.");
      closingCost = sumTotal;
    }
    loanAmountForm.patchValue({ ClosingCosts: closingCost == 0 ? "" : closingCost.toFixed(2) });
  } else if (name === "ClosingCosts") {
    if (newAmount > sumTotal) {
      showErrorMessage("Closing Costs should not exceed the subtotal.");
      loanAmountForm.patchValue({ ClosingCosts: "" });
      closingCost = 0;
    }

    const closingCostPercentage = (closingCost * 100) / sumTotal;
    loanAmountForm.patchValue({ ClosingCostsPercentage: closingCost == 0 ? "" : closingCostPercentage.toFixed(2) });
  }

  calculateSBAGuaranteeFees("SBAGuaranteeFeesPercentage", showErrorMessage);
};

// Calculate SBA Guarantee Fees and Percentage
const calculateSBAGuaranteeFees = (name, value, showErrorMessage) => {
  const form = loanAmountForm.value;
  const totalWithClosingCost = getValue(form.SubTotal) + getValue(form.ClosingCosts);
  const sbaGuaranteeFeesPercentage = getValue(form.SBAGuaranteeFeesPercentage)
  let SBAGuaranteeFee = 0;

  if (sbaGuaranteeFeesPercentage >= 0 && name !== "SBAGuaranteeFees") {
    SBAGuaranteeFee = (sbaGuaranteeFeesPercentage * totalWithClosingCost) / 100;
    loanAmountForm.patchValue({ SBAGuaranteeFees: SBAGuaranteeFee == 0 ? "" : SBAGuaranteeFee.toFixed(2) });
  }

  const sbaGuaranteeFees = getValue(form.SBAGuaranteeFees) || 0;
  if (sbaGuaranteeFees >= 0 && name === "SBAGuaranteeFees") {
    if (sbaGuaranteeFees > totalWithClosingCost) {
      showErrorMessage("SBA Guarantee Fees should not exceed the total amount.");
      loanAmountForm.patchValue({ SBAGuaranteeFees: "", SBAGuaranteeFeesPercentage: "" });
      return 0;
    }
    const calculatedPercentage = (sbaGuaranteeFees * 100) / totalWithClosingCost;
    loanAmountForm.patchValue({ SBAGuaranteeFeesPercentage: calculatedPercentage == 0 ? "" : calculatedPercentage.toFixed(2) });
  }
};


// Calculate Equity and Down Payment
const calculateEquity = (name, totalcost, rawValue, showErrorMessage) => {
  const form = loanAmountForm.value

  let equityDeal = getValue(form.EquityDeal);
  let calculatedPercentage = 0;
  const LessBorrowerDownPayment = getValue(form.LessBorrowerDownPayment);

  if (name === "EquityPercentage") {
    equityDeal = (rawValue * totalcost) / 100;
    loanAmountForm.patchValue({ EquityDeal: equityDeal == 0 ? "" : equityDeal.toFixed(2) });
  }
  if (name === "EquityDeal") {
    if (rawValue > totalcost) {
      showErrorMessage("Equity required for Deal should not exceed the total amount.");
      loanAmountForm.patchValue({ EquityDeal: "", EquityPercentage: "", LessBorrowerDownPayment: "" });
      return 0;
    }
    calculatedPercentage = (rawValue * 100) / totalcost;
    loanAmountForm.patchValue({ EquityPercentage: calculatedPercentage == 0 ? "" : calculatedPercentage.toFixed(2) });
  }
  if (LessBorrowerDownPayment > equityDeal) {
    loanAmountForm.patchValue({ LessBorrowerDownPayment: "" });
    showErrorMessage("Down payment should not be greater than Equity required for Deal.");
  }

  return equityDeal;
};

// Main function to handle loan amount calculation
const handleCalculateLoanAmount = (loanFieldName, rawValue, showErrorMessage) => {
  const sumTotal = getValue(loanAmountForm.value.SubTotal);
  const ClosingCost = getValue(loanAmountForm.value.ClosingCosts);
  const SBAGuaranteeFee = getValue(loanAmountForm.value.SBAGuaranteeFees);

  const totalWithClosingCost = sumTotal + ClosingCost;
  const totalcost = totalWithClosingCost + SBAGuaranteeFee;

  const Equitycost = calculateEquity(loanFieldName, totalcost, rawValue, showErrorMessage);

  loanAmountForm.patchValue({
    EquityClosing: (Equitycost - getValue(loanAmountForm.value.LessBorrowerDownPayment)),
    EquityLoanAmount: totalcost - Equitycost,
    TotalEquityAmount: totalcost,
  });
};


// ---------------------------------------------------------------------------------------------------------------------------


let loanId;
function LoanAmount({ backPreviousTab }) {
  const dispatch = useDispatch();
  const params = useParams();

  // Preloader Start
  const [loading, setLoading] = useState(true);

  // Popup Function
  const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
  const PreviousTabPopupClose = () => setPreviousTabPopup(false);

  const [isAmortizationScheduleTableShow, setIsAmortizationScheduleTableShow] = useState(false);
  const [amortizationData, setAmortizationData] = useState([]);

  const showErrorMessage = (errorMessage) => {
    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: errorMessage, type: "error" }));
    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
  }

  useEffect(() => {
    document.title = "Loan Amount - Loan Application";
    loanId = decryptText(params.loanId);
    getLoanAmountInfo();
  }, []);

  const getLoanAmountInfo = async () => {
    try {
      setLoading(true);
      loanAmountForm.reset()
      if (loanId != "" && parseInt(loanId) > 0) {
        let queryObj = {
          Id: loanId,
          SessionId: getSessionId(),
        };

        const modelLoanAmountDetail = await GetLoanAmountDetail(queryObj);
        if (modelLoanAmountDetail.status == 200) {
          let finalData = modelLoanAmountDetail.resultObject ? JSON.parse(modelLoanAmountDetail.resultObject) : "";

          if (finalData && Array.isArray(finalData) && finalData.length > 0) {
            loanAmountForm.patchValue({
              PurchaseRealProperty: finalData[0].PurchaseRealProperty,
              PurchaseImprovements: finalData[0].PurchaseImprovements,
              PurchaseEquipment: finalData[0].PurchaseEquipment,
              PurchaseFurnitureAndFixtures: finalData[0].PurchaseFurnitureAndFixtures,
              PurchaseInventory: finalData[0].PurchaseInventory,
              DebtRefinance: finalData[0].DebtRefinance,
              BusinessAcquisition: finalData[0].BusinessAcquisition,
              WorkingCapital: finalData[0].WorkingCapital,
              ClosingCosts: finalData[0].ClosingCosts,
              LessBorrowerDownPayment: finalData[0].LessBorrowerDownPayment,
              TotalLoanRequest: finalData[0].TotalLoanRequest,
              SourceOfDownPayment: finalData[0].SourceOfDownPayment || "",
              SubTotal: finalData[0].SubTotal,
              ClosingCostsPercentage: finalData[0].ClosingCostsPercentage,
              SBAGuaranteeFees: finalData[0].SBAGuaranteeFees,
              SBAGuaranteeFeesPercentage: finalData[0].SBAGuaranteeFeesPercentage,
              PersonalFunds: finalData[0].PersonalFunds,
              OthersInvestment: finalData[0].OthersInvestment,
              DownPaymentOthers: finalData[0].DownPaymentOthers,
              EquityPercentage: finalData[0].EquityPercentage,
              TotalAmount: finalData[0].TotalAmount,
              EquityDeal: finalData[0].EquityDeal,
              EquityClosing: finalData[0].EquityClosing,
              EquityLoanAmount: finalData[0].EquityLoanAmount,
              TotalEquityAmount: finalData[0].TotalEquityAmount,
              TermYear: finalData[0].Terms,
              InterestRate: finalData[0].Rate,
              EmiStartDate: finalData[0].EmiStartDate,
            });
          }
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();

    const { LessBorrowerDownPayment, EquityDeal } = loanAmountForm.value;

    if (getValue(LessBorrowerDownPayment) > getValue(EquityDeal)) {
      showErrorMessage("Down payment should not be greater than Equity required for Deal.");
      return;
    }

    if (loanAmountForm.status !== "INVALID") {
      setLoading(true);

      let finalObj = {
        ...loanAmountForm.value,
        CustID: loanId,
        LoanAmountID: 0,
        SessionId: getSessionId(),
        TermYear: loanAmountForm.value.TermYear || 0,
        InterestRate: loanAmountForm.value.InterestRate || 0,
        EmiStartDate: formatEmiStartDate(loanAmountForm.value.EmiStartDate),
        LoanAmount: loanAmountForm.value.TotalEquityAmount,
        modifiedBy: JSON.parse(localStorage.getItem("user"))?.id || 0
      };

      InsertUpdateLoanAmount(finalObj)
        .then((data) => {
          setLoading(false);
          if (data.status == 200) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          } else {
            showErrorMessage(data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          showErrorMessage(err.message);
        });
    } else {
      markAllAsTouched(loanAmountForm);
      setLoading(false);
    }
  }

  const checkRequiredFields = () => {
    const requiredFields = ['TermYear', 'InterestRate', 'EmiStartDate'];
    let isValid = true;
    let errorDisplayed = false;

    requiredFields.forEach(field => {
      if (!loanAmountForm.get(field).value) {
        if (!errorDisplayed) {
          loanAmountForm.get(field).markAsTouched();
          isValid = false;
          showErrorMessage(`${field === 'TermYear' ? 'Term year' : field === 'InterestRate' ? 'Interest Rate' : 'EMI Start Date'} is a required field.`);
          errorDisplayed = true;
        }
      }
    });

    return isValid;
  };

  const toggleAmortizationScheduleTableShow = () => {

    if (!checkRequiredFields()) {
      return;
    }

    let finalObj = {
      ...loanAmountForm.value,
      TermYear: loanAmountForm.value.TermYear,
      InterestRate: loanAmountForm.value.InterestRate,
      EmiStartDate: formatEmiStartDate(loanAmountForm.value.EmiStartDate),
      LoanAmount: loanAmountForm.value.EquityLoanAmount,
    };

    const amortizationData = calculateAmortization(finalObj.TermYear, finalObj.InterestRate, new Date(finalObj.EmiStartDate), finalObj.LoanAmount);

    setAmortizationData(amortizationData);
    setIsAmortizationScheduleTableShow(prevState => !prevState);
    loanAmountForm.patchValue({ isAmortizationScheduleTableShow: isAmortizationScheduleTableShow });
  };

  const DateFormatter = {
    GetUtcDate: () => new Date(),
  };

  function calculateAmortization(terms, intRate, startYear, loanAmount) {
    if (startYear === null) {
      const utcDate = DateFormatter.GetUtcDate();
      startYear = new Date(utcDate.getFullYear(), utcDate.getMonth(), 1);
    }

    // Check for valid inputs
    if (terms && intRate && startYear && loanAmount) {
      const term = parseInt(terms);               // Loan term in years
      const totalNumberOfMonths = term * 12;      // Total number of months
      const rate = (parseFloat(intRate) / 100) / 12; // Monthly interest rate

      if (rate > 0) {
        let period = 0;
        let totInterest = 0;
        let totPrincipal = 0;
        let date = new Date(startYear);
        let balance = parseFloat(loanAmount);

        const calc = [];
        const denominator = Math.pow(1 + rate, totalNumberOfMonths) - 1;
        const fixedPandl = balance * (rate * Math.pow(1 + rate, totalNumberOfMonths)) / denominator;

        const startMonth = {
          Period: 0,
          Balance: balance,
          Date: new Date(date),
          Interest: 0,
          Principal: 0,
          Pandl: fixedPandl,
        };
        calc.push(startMonth);

        // Continue only if the balance is greater than 0
        if (balance > 0) {
          for (let i = 0; i < totalNumberOfMonths; i++) {
            const monthData = {};
            period += 1;
            monthData.Date = new Date(date.setMonth(date.getMonth() + 1));
            monthData.Period = period;

            monthData.Interest = balance * rate;
            totInterest += monthData.Interest;
            monthData.Principal = fixedPandl - monthData.Interest;
            totPrincipal += monthData.Principal;

            balance -= monthData.Principal;
            monthData.Balance = balance;
            monthData.Pandl = fixedPandl;

            calc.push(monthData);
          }

          calc[0].TotalInterest = parseFloat(totInterest.toFixed(2));
          calc[0].TotalPrincipal = parseFloat(totPrincipal.toFixed(2));
        }
        calc[0].Pandl = "0.00"
        return calc;
      }
    }
    return [];
  }
  const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const generatePDF = () => {
    if (!checkRequiredFields()) {
      return;
    }

    const finalObj = {
      ...loanAmountForm.value,
      TermYear: loanAmountForm.value.TermYear,
      InterestRate: loanAmountForm.value.InterestRate,
      EmiStartDate: loanAmountForm.value.EmiStartDate,
      LoanAmount: loanAmountForm.value.EquityLoanAmount,
    };

    const amortizationData = calculateAmortization(
      finalObj.TermYear,
      finalObj.InterestRate,
      finalObj.EmiStartDate,
      finalObj.LoanAmount
    );

    const doc = new jsPDF();
    doc.addImage(lm_logo, 'PNG', 80, 10, 50, 20);
    doc.setFontSize(14);

    // Add loan details table
    const amortizationTableData = [
      ['LOAN AMOUNT', 'LOAN TERMS (IN YEAR)', 'INTEREST RATE (%)', 'LOAN START DATE']
    ];
    const amortizationTableColumns = [
      [finalObj.LoanAmount, finalObj.TermYear, finalObj.InterestRate, formatEmiStartDate(finalObj.EmiStartDate)]
    ];

    doc.autoTable({
      head: amortizationTableData,
      body: amortizationTableColumns,
      theme: 'grid',
      headStyles: { fillColor: [64, 87, 227] }, // Dark blue for header
      bodyStyles: { fillColor: [248, 249, 250] }, // Light gray for table body
      margin: { top: 40 }, // Adjust top margin for the logo and title
    });

    // Prepare amortization data table
    const amortizationDataColumns = [
      ['DATE', 'PERIOD (MONTHS)', 'BALANCE', 'P&I', 'INTEREST', 'PRINCIPAL']
    ];
    const amortizationCalculatedData = amortizationData.map((data) => [
      data.Date instanceof Date ? new Date(data.Date).toLocaleDateString() : data.Date,
      data.Period,
      formatNumber(getValue(data.Balance).toFixed(2)),
      formatNumber(getValue(data.Pandl).toFixed(2)),
      formatNumber(getValue(data.Interest).toFixed(2)),
      formatNumber(getValue(data.Principal).toFixed(2)),
    ]);

    // Add amortization data table to the PDF
    doc.autoTable({
      head: amortizationDataColumns,
      body: amortizationCalculatedData,
      theme: 'grid',
      headStyles: { fillColor: [64, 87, 227] }, // Dark blue for header
      bodyStyles: { fillColor: [248, 249, 250] }, // Light gray for table body
      margin: { top: 10 }, // Add margin
    });

    // Add footer and page numbers to each page
    doc.setFontSize(8);
    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);

      const pageText = `Page ${i} of ${totalPages}`;
      doc.text(pageText, doc.internal.pageSize.getWidth() - 30, 290); // Adjust positioning as needed
      doc.text(
        '+1 855-700-BLUE(2583) | info@loanmantra.com | WWW.LOANMANTRA.COM',
        doc.internal.pageSize.getWidth() / 2,
        290,
        { align: 'center' }
      );
    }

    doc.save('Amortization_Schedule.pdf');
  };


  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control && control.status === "INVALID") {
        control.active = true;
        control.touched = true;
        if (document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
      }
    });
  };

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (
        <Preloader />
      ) : (
        <div>
          <div className="lm-form-box pb-5">
            <FieldGroup
              control={loanAmountForm}
              render={({ get, invalid }) => (
                <form action="">
                  <div className="lm-card">
                    <h3 className="lm-card-heading">
                      Use of proceeds or loan amount
                    </h3>
                    <div className="row">
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="PurchaseRealProperty"
                            label="Purchase Real Property"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="PurchaseImprovements"
                            label="Purchase Improvements"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="PurchaseEquipment"
                            label="Purchase Equipment"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="PurchaseFurnitureAndFixtures"
                            label="Purchase Furniture"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="PurchaseInventory"
                            label="Purchase Inventory"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="DebtRefinance"
                            label="Debt Refinance"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="BusinessAcquisition"
                            label="Business Acquisition"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="WorkingCapital"
                            label="Working Capital"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="OthersInvestment"
                            label="Others"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="SubTotal"
                            label="Sub Total"
                            placeholder="0.00"
                            isDisabled={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group"></div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group"></div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-percentage">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="ClosingCostsPercentage"
                            label="Closing Cost (%)"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="ClosingCosts"
                            label="Closing Cost"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-percentage">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="SBAGuaranteeFeesPercentage"
                            label="SBA Guarantee Fee (%)"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="SBAGuaranteeFees"
                            label="SBA Guarantee Fee ($)"
                            placeholder="0.00"
                          />
                        </div>
                      </div>

                      {/* <!-- lm common divider to break the section  --> */}
                      <div className="lm-divider my-3 mx-3"></div>
                      {/* <!-- lm common divider to break the section  --> */}

                      <div className="col-12 d-flex justify-content-between">
                        <span className="sub-total-label text-light">
                          Total Cost
                        </span>
                        <h3 className="total-amount">
                          ${calculateTotalAmount()}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="lm-card">
                    <h3 className="lm-card-heading">
                      Source of equity or borrower's capital injection.
                    </h3>
                    <div className="row">
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-percentage">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="EquityPercentage"
                            label="Equity Percentage"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="EquityDeal"
                            label="Required Equity"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group input-dollar">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            name="LessBorrowerDownPayment"
                            label="Less: Down Payment"
                            placeholder="0.00"
                          />
                        </div>
                      </div>

                      {/* <!-- lm common divider to break the section  --> */}
                      <div className="lm-divider my-3 mx-3"></div>
                      {/* <!-- lm common divider to break the section  --> */}

                      <div className="col-12 d-flex justify-content-between mb-1">
                        <span className="sub-total-label text-light">
                          Capital Required for Equity
                        </span>
                        <h3 className="sub-total-amount">
                          ${formatNumberWithCommas(loanAmountForm.value.EquityClosing)}
                        </h3>
                      </div>
                      <div className="col-12 d-flex justify-content-between mb-1">
                        <span className="sub-total-label text-light">
                          Loan Amount
                        </span>
                        <h3 className="sub-total-amount">
                          ${formatNumberWithCommas(loanAmountForm.value.EquityLoanAmount)}
                        </h3>
                      </div>
                      <div className="col-12 d-flex justify-content-between">
                        <span className="total-label">Total</span>
                        <h3 className="total-amount">
                          ${formatNumberWithCommas(calculateTotalAmount())}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="lm-card">
                    <h3 className="lm-card-heading">Other</h3>
                    <div className="row">
                      <div className="col-md-4 col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="SourceOfDownPayment"
                            label="Source Of Down Payment"
                            placeholder="Enter Source Of Down Payment"
                            id="SourceOfDownPayment"
                            required={false}
                            errorMessage={""}
                            maxLength={15}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Amortization section */}
                  <div className="lm-card">
                    <h3 className="lm-card-heading">Amortization</h3>
                    <div className="row">
                      <div className="col-md-3 col-lg-3">

                        <div className="form-group">
                          <FieldControl
                            name="TermYear"
                            render={({ handler, touched, hasError, meta }) => {
                              const handlerProps = handler();
                              const handleChange = (e) => {
                                // Remove everything that is not a digit (0-9)
                                const rawValue = e.target.value.replace(/[^0-9]/g, ""); // Keeps only whole numbers
                                e.target.value = rawValue;
                                if (handlerProps.onChange) {
                                  setAmortizationData([]);
                                  setIsAmortizationScheduleTableShow(false);
                                  handlerProps.onChange(e);
                                }
                              };


                              return (
                                <div className="form-group">
                                  <label htmlFor="TermYear">Term (years)</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="TermYear"
                                    placeholder="4"
                                    {...handlerProps}
                                    onChange={handleChange}
                                    name="TermYear"
                                    required={true}
                                    maxLength={3}
                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("required") && <><span className="error-message">Term (years) is required</span><br /></>}
                                  {touched && hasError("pattern") && (<><span className="error-message">  Term Year should be numeric & whole values</span><br /></>)}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "0.00" }}
                          />
                        </div>

                      </div>
                      <div className="col-md-3 col-lg-3">
                        <div className="form-group input-percentage">
                          <AmountContainer
                            showErrorMessage={showErrorMessage}
                            setAmortizationData={setAmortizationData}
                            setIsAmortizationScheduleTableShow={setIsAmortizationScheduleTableShow}
                            label="Interest Rate (%)"
                            name="InterestRate"
                            id="InterestRate"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-lg-3">
                        <div className="form-group input-date">
                          <FieldControl
                            name="EmiStartDate"
                            render={({ handler, touched, hasError, meta }) => (
                              <div className="form-group input-date">
                                <label htmlFor="First_Payment_Date">
                                  First Payment Date
                                </label>
                                <DatePicker
                                  showIcon
                                  toggleCalendarOnIconClick
                                  selected={loanAmountForm.value.EmiStartDate}
                                  onChange={(date) => {
                                    setAmortizationData([]);
                                    setIsAmortizationScheduleTableShow(false);
                                    loanAmountForm.patchValue({ EmiStartDate: date });
                                  }}
                                  isClearable={loanAmountForm.value.EmiStartDate ? true : false}
                                  id="EmiStartDate"
                                  dateFormat="MM/dd/yyyy"
                                  placeholderText="MM/DD/YYYY"
                                  showTimeSelect={false}
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  showYearDropdown
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 d-flex mt-2">
                        <button onClick={toggleAmortizationScheduleTableShow}
                          type="button"
                          className="lm-button2 mr-3"
                        >
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 10C19 11.5 16.3 17 10 17C3.7 17 1 11.5 1 10C1 8.5 3.7 3 10 3C16.3 3 19 8.5 19 10Z" stroke="#4057E3" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M12.7 10C12.7 11.4497 11.4912 12.625 10 12.625C8.50883 12.625 7.3 11.4497 7.3 10C7.3 8.55025 8.50883 7.375 10 7.375C11.4912 7.375 12.7 8.55025 12.7 10Z" stroke="#4057E3" strokeWidth="1.5" strokeLinecap="round" />
                          </svg>
                          {isAmortizationScheduleTableShow ? 'Hide' : 'See'}  Schedule
                        </button>
                        <button
                          type="button"
                          onClick={generatePDF}
                          className="lm-button2"
                        >
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.6001 10.9L10 14.5M10 14.5L6.4 10.9M10 14.5L10 5.5M9 19H11C13.8003 19 15.2004 19 16.27 18.455C17.2108 17.9757 17.9757 17.2108 18.455 16.27C19 15.2004 19 13.8003 19 11V9C19 6.19974 19 4.79961 18.455 3.73005C17.9757 2.78924 17.2108 2.02433 16.27 1.54497C15.2004 1 13.8003 1 11 1H9C6.19974 1 4.79961 1 3.73005 1.54497C2.78924 2.02433 2.02433 2.78924 1.54497 3.73005C1 4.79961 1 6.19974 1 9V11C1 13.8003 1 15.2004 1.54497 16.27C2.02433 17.2108 2.78924 17.9757 3.73005 18.455C4.79961 19 6.19974 19 9 19Z" stroke="#4057E3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          Download Schedule
                        </button>
                      </div>

                      {isAmortizationScheduleTableShow && (
                        <div className="col-12">
                          <div className="lm-divider my-4"></div>
                          <table className="lm-grid-table amortization-schedule-table w-100" id="myTable">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Period (Month)</th>
                                <th>Balance</th>
                                <th>Pandl.</th>
                                <th>Interest</th>
                                <th>Principal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {amortizationData.map((data, index) => (
                                <tr key={index}>
                                  <td>{data.Date instanceof Date ? new Date(data.Date).toLocaleDateString() : data.Date}</td>
                                  <td>{data.Period}</td>
                                  <td>{formatNumber(getValue(data.Balance).toFixed(2))}</td>
                                  <td>{formatNumber(getValue(data.Pandl).toFixed(2))}</td>
                                  <td>{formatNumber(getValue(data.Interest).toFixed(2))}</td>
                                  <td>{formatNumber(getValue(data.Principal).toFixed(2))}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sticky-btn-footer d-flex justify-content-end">
                    <button
                      type="button"
                      className="lm-button2"
                      onClick={() => setPreviousTabPopup(true)}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="lm-button1 ml-3"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
            />
          </div>

          {/* <!--back to Previous Tab Popup / Modal --> */}
          <Modal
            className="mark-comp-popup-cust-size"
            show={PreviousTabPopupShow}
            onHide={() => setPreviousTabPopup(false)}
            aria-labelledby="PreviousTabPopupLabel"
            backdrop="static"
            keyboard={false}
            centered
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body signout-modal">
                  <h3 className="pt-4">
                    Going back will lead to the loss of unsaved data
                  </h3>
                </div>
                <div className="modal-footer la-app-modal-footer">
                  <button
                    type="button"
                    className="lm-button2"
                    onClick={PreviousTabPopupClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="lm-button1"
                    onClick={backPreviousTab}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* <!--back to Previous Tab Popup / Modal --> */}
        </div>
      )}
    </>
  );
}

export default LoanAmount;
