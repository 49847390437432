import React from 'react';
import { FieldControl, Validators } from 'react-reactive-form';

const FormInputAd = ({
    id,
    label,
    name,
    placeholder,
    patternValidators = [],
    required,
    errorMessage,
    disabled,
    maxlength
}) => {
    // Combine validators including required and any custom pattern validators
    const appliedValidators = [
        ...(required ? [Validators.required] : []),
        ...patternValidators
    ];

    return (
        <div className="form-group">
            <label htmlFor={id}>{label}</label>
            <FieldControl
                name={name}
                validators={appliedValidators}
                render={({ handler, touched, hasError }) => (
                    <div>
                        <input
                            type="text"
                            className={`form-control ${touched && (hasError('required') || hasError('pattern')) ? 'is-invalid' : ''}`}
                            id={id}
                            placeholder={placeholder}
                            {...handler()}
                            disabled={disabled}
                            maxLength={maxlength}
                        />
                        {touched && hasError('required') && (
                            <div className="invalid-feedback">{errorMessage || 'This field is required.'}</div>
                        )}
                        {touched && hasError('pattern') && (
                            <div className="invalid-feedback">Invalid input.</div>
                        )}
                    </div>
                )}
            />
        </div>
    );
};

export default FormInputAd;
