import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InsertUpdateGasStation ,GetIndustryDetailsById } from '../../services/IndustriesServices'; 


// Async thunk for fetching existing Contractor data
export const fetchGasStationData = createAsyncThunk('gasStationForm/fetchGasStationData', async ({ loanId, type }) => {
    const response = await GetIndustryDetailsById(loanId, type);
    return response;
  });



// Async thunk for form submission (save and update)
export const submitGasStationForm = createAsyncThunk('gasStationForm/submitGasStationForm', async (formData) => {
    const response = await InsertUpdateGasStation(formData);
    return response;
});

const gasStationSlice = createSlice({
    name: 'gasStationForm',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        resetGasStationFormState: (state) => {
          state.data = null;
          state.loading = false;
          state.error = null;
        }
      },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGasStationData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGasStationData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchGasStationData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(submitGasStationForm.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitGasStationForm.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(submitGasStationForm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { resetGasStationFormState } = gasStationSlice.actions;
export default gasStationSlice.reducer;

