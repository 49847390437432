import counter , {counterActions} from './counter';
import auth, {authActions} from './auth';
import toaster, {toasterActions} from './toasterSlice';
import errorbox, {errorActions} from './errorSlice';
import editProfile, { profileAction } from './editProfile';

const reducersAndActions =  {
    reducers: {
        counter,
        auth,
        toaster,
        errorbox,
        editProfile
    },
    actions: {
        counterActions,
        authActions,
        toasterActions,
        errorActions,
        profileAction
    }
}

export default reducersAndActions