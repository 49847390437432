import React, { useState, useEffect, useCallback } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import FormTextArea from "../../reusablecomponets/FormTextArea";
import FormRadio from "../../reusablecomponets/FormRadio";
import FormSelect from "../../reusablecomponets/FormSelect";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { actions } from '../../_redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { decryptText } from "../../helpers/DynamicFunctions";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import FormInput from "../../reusablecomponets/FormInput";
import FormInputAd from "../../reusablecomponets/FormInputAd";
import reducersAndActions from "../../_redux/slices/index";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Modal from 'react-bootstrap/Modal'


function IndustryMedical({ backPreviousTab }) {

    const dispatch = useDispatch();

    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);

    useEffect(() => {
        document.title = "Medical Information - Loan Application";
    }, []);

    const CurrentEmployeesOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: '1-10' },
        { value: '2', label: '11-20' },
        { value: '3', label: '21-30' },
        { value: '4', label: '31-40' },
        { value: '5', label: '41-50' },
        { value: '6', label: '51-60' },
        { value: '7', label: '61-70' },
        { value: '8', label: '71-80' },
        { value: '9', label: '81-90' },
        { value: '10', label: '91-100' }
    ];

    const PeakSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];
    

    const LowSeasonOptions = [
        { value: '0', label: 'Select...' },
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];
    //const LowSeasonOptions = PeakSeasonOptions;
    const [form, setForm] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", Validators.required],
            suppliersVendorTerms: ["", Validators.required],
            customersSellingTerms: ["", Validators.required],
            competitiveAdvantages: ["", Validators.required],
            hoursOperation: ["", Validators.required],
            seasonalBusiness: [""],
            numberEmployees: ["", Validators.required],
            numberAfterLoan: ["", Validators.required],
            //medicalSpeciality: [""],
            numberOfPatients: ["", Validators.required],
            patientsReferred: ["", Validators.required],
            affiliatedWithHospital: ["", Validators.required],
            insurancesAccepted: ["", Validators.required],
            billingVsCollection: [""],
            fullOrCopayment: [""],
            inuranceTimeFrame: [""],
            additionalServices: [""],
            additionalComments: [""],
            modifiedBy: [0],
            customerVendors: ["", Validators.required],
            keyCompetitors: ["", Validators.required],
            peakSeason: [""],
            lowSeason: [""],
            hospitalName: [""],
            serviceName: [""]
        })
    );
    console.log(form);
    
    useEffect(() => {
        dispatch(actions.medicalForm.fetchMedicalData({ loanId: parsedLoanId, type: 'Medical' }));
    }, [dispatch, parsedLoanId]);

    const { data, loading, error } = useSelector((state) => state.medicalForm);

    useEffect(() => {
        setSelectOptions({
            geographicMarket: [],
            customerVendors: [],
            customersSellingTerms: [],
            peakSeason: [],
            lowSeason: []
        });
    }, []);

    const [selectOptions, setSelectOptions] = useState({
        geographicMarket: [],
        customerVendors: [],
        customersSellingTerms: [],
        peakSeason: [],
        lowSeason: []
    });
   
    useEffect(() => {
        try {
            const resultObject = JSON.parse(data.resultObject);
            if (Array.isArray(resultObject) && resultObject.length > 0) {
                const medicalData = resultObject[0];
    console.log(medicalData);
    
                const geographicMarketOptions = medicalData.GeographicMarket ? medicalData.GeographicMarket.split(',').map(value => ({ label: value, value })) : [];
                const customerVendorsOptions = medicalData.CustomerVendors ? medicalData.CustomerVendors.split(',').map(value => ({ label: value, value })) : [];
                const customersSellingTermsOptions = medicalData.CustomersSellingTerms ? medicalData.CustomersSellingTerms.split(',').map(value => ({ label: value, value })) : [];
                const peakSeasonOptions = medicalData.PeakSeason ? medicalData.PeakSeason.split(',').map(value => ({
                    value,
                    label: PeakSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];
                const lowSeasonOptions = medicalData.LowSeason ? medicalData.LowSeason.split(',').map(value => ({
                    value,
                    label: LowSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];

    
                setSelectOptions({
                    geographicMarket: geographicMarketOptions,
                    customerVendors: customerVendorsOptions,
                    customersSellingTerms: customersSellingTermsOptions,
                    
                    peakSeason: peakSeasonOptions,
                    lowSeason: lowSeasonOptions
                });
    
              
                form.patchValue({
                    id: medicalData.Id,
                    loanId: medicalData.LoanId,
                    geographicMarket: medicalData.GeographicMarket,
                    suppliersVendorTerms: medicalData.SuppliersVendorTerms,
                    customersSellingTerms: medicalData.CustomersSellingTerms,
                    competitiveAdvantages: medicalData.CompetitiveAdvantages,
                    hoursOperation: medicalData.HoursOperation,
                    seasonalBusiness: medicalData.SeasonalBusiness === 'true' ? 'true' : 'false',
                    numberAfterLoan: medicalData.NumberAfterLoan,
                    numberOfPatients: medicalData.NumberOfPatients,
                    patientsReferred: medicalData.PatientsReferred,
                    affiliatedWithHospital: medicalData.AffiliatedWithHospital === 'true' ? 'true' : 'false',
                    insurancesAccepted: medicalData.InsurancesAccepted,
                    billingVsCollection: medicalData.BillingVsCollection,
                    fullOrCopayment: medicalData.FullOrCopayment,
                    inuranceTimeFrame: medicalData.InuranceTimeFrame,
                    additionalServices: medicalData.AdditionalServices === 'true' ? 'true' : 'false',
                    additionalComments: medicalData.AdditionalComments,
                    modifiedBy: medicalData.ModifiedBy,
                    customerVendors: medicalData.CustomerVendors,
                    keyCompetitors: medicalData.KeyCompetitors,
                    peakSeason: medicalData.PeakSeason,
                    lowSeason: medicalData.LowSeason,
                    hospitalName: medicalData.HospitalName,
                    serviceName: medicalData.ServiceName,
                    numberEmployees: medicalData.NumberEmployees,
                });
    
                //console.log("Form values after patch:", form.value);
            } else {
                form.reset({
                    id: 0,
                    loanId: parsedLoanId,
                    modifiedBy: 0
                });
            }
        } catch (error) {
            console.error("Error parsing or updating form:", error);
        }
    }, [data, setSelectOptions, form]);
    
    const handleSubmit = useCallback((e) => {
        if (form.value.seasonalBusiness == null || form.value.seasonalBusiness === "") {
            form.value.seasonalBusiness = 'false';
        }
        if (form.value.affiliatedWithHospital == null || form.value.affiliatedWithHospital === "") {
            form.value.affiliatedWithHospital="false";
        }
        if (form.value.additionalServices == null || form.value.additionalServices === "") {
            form.value.additionalServices="false";
        }
        e.preventDefault();
        markAllAsTouched(form);
        console.log(form);
        
        if (form.valid) {
            const currentValues = form.value;
            dispatch(actions.medicalForm.submitMedicalForm(currentValues))
                .then(() => {
                
                    if (!currentValues.id) {  
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form submitted successfully", 
                            type: "success",
                        }));
                    } else {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: "Form updated successfully",
                            type: "success",
                        }));
                    }
                    dispatch(actions.medicalForm.fetchMedicalData({ loanId: parsedLoanId, type: 'Medical' }));
                    setTimeout(() => {
                        dispatch(reducersAndActions.actions.toasterActions.hideToaster());
                    }, 5000);
                })
                .catch(() => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: "Error submitting form",
                        type: "error",
                    }));
                });
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: "Form validation failed",
                type: "error",
            }));
        }
    }, [dispatch, form, parsedLoanId]);



    const handleSelectChange = (name, selectedOption) => {
        const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
        setSelectOptions(prevOptions => ({ ...prevOptions, [name]: selectedOption }));
        form.controls[name].setValue(selectedValues.join(','));
    };

    
    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control) {
                control.markAsTouched();
                control.updateValueAndValidity();
            }
        });
    };

    // Custom Validator
    // const numberOnlyValidator = (control) => {
    //     const value = control.value;
    //     if (value && !/^\d*$/.test(value)) {
    //         return { numberOnly: true };
    //     }
    //     return null;
    // };
console.log(form);

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Medical Information </h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic market"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            
                                            {form.value.geographicMarket==[]&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}

                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="suppliersVendorTerms"
                                                label="Major suppliers & vendor Terms"
                                                name="suppliersVendorTerms"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"

                                            />
                                            {/* <FormInput
                                                id="suppliersVendorTerms"
                                                label="Major Suppliers & Vendor Terms"
                                                name="suppliersVendorTerms"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-6">

                                            <CreatableSelect
                                                name="customerVendors"
                                                label="Major customers & vendor terms"
                                                value={selectOptions.customerVendors}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            {form.value.customerVendors==[]&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                            
                                        </div>
                                        <div className="col-md-6">
                                            <FormInputAd
                                                id="numberOfPatients"
                                                label="Number of total / active patients?"
                                                name="numberOfPatients"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="numberOfPatients"
                                                label="Number of Total / Active Patients?"
                                                name="numberOfPatients"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-6">

                                            <CreatableSelect
                                                name="customersSellingTerms"
                                                label="Major customers & selling terms"
                                                value={selectOptions.customersSellingTerms}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                            />
                                            
                                            {form.value.customersSellingTerms==[]&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                            {/* {form.value.customersSellingTerms==[]&&<div className='error-message'>Required field</div>} */}
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="keyCompetitors"
                                                label="Key competitors?"
                                                name="keyCompetitors"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="keyCompetitors"
                                                label="Key Competitors?"
                                                name="keyCompetitors"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="competitiveAdvantages"
                                                label="Competitive advantages"
                                                name="competitiveAdvantages"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="competitiveAdvantages"
                                                label="Competitive Advantages"
                                                name="competitiveAdvantages"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="patientsReferred"
                                                label="How are patients referred?"
                                                name="patientsReferred"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="patientsReferred"
                                                label="How are patients referred?"
                                                name="patientsReferred"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="hoursOperation"
                                                label="Hours of operation"
                                                name="hoursOperation"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="hoursOperation"
                                                label="Hours of Operation"
                                                name="hoursOperation"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormSelect
                                                name="numberEmployees"
                                                label="Number of current employees"
                                                options={CurrentEmployeesOptions}
                                                defaultValue={CurrentEmployeesOptions[0]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                            
                                            
                                            {form.value.numberEmployees==""&&<div  style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="numberAfterLoan"
                                                label="Number after loan"
                                                name="numberAfterLoan"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="numberAfterLoan"
                                                label="Number after loan"
                                                name="numberAfterLoan"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                                pattern={/^\d+$/}  // Only digits are allowed
                                                patternErrorMessage="Please enter a valid phone number."
                                            /> */}
                                        </div>
                                        <div className="col-md-4 col-lg-3">

                                            <FormInputAd
                                                id="insurancesAccepted"
                                                label="What insurances are accepted?"
                                                name="insurancesAccepted"
                                                placeholder="Enter Text"
                                                validators={[Validators.required]}
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="insurancesAccepted"
                                                label="What insurances are accepted?"
                                                name="insurancesAccepted"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="billingVsCollection"
                                                label="What is typical billing versus collection ratio?"
                                                name="billingVsCollection"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.billingVsCollection==""&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                            {/* <FormInput
                                                id="billingVsCollection"
                                                label="What is typical billing versus collection ratio?"
                                                name="billingVsCollection"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="fullOrCopayment"
                                                label="Does it require full payment or copayment during visit?"
                                                name="fullOrCopayment"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.fullOrCopayment==""&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                            {/* <FormInput
                                                id="fullOrCopayment"
                                                label="Does it require full payment or copayment during visit?"
                                                name="fullOrCopayment"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>
                                        <div className="col-md-6">

                                            <FormInputAd
                                                id="inuranceTimeFrame"
                                                label="What is the typical insurance payment timeframe?"
                                                name="inuranceTimeFrame"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            
                                            {form.value.inuranceTimeFrame==""&&<div style={{color:"#dc3545",fontSize:"14px"}}>Required field</div>}
                                            {/* <FormInput
                                                id="inuranceTimeFrame"
                                                label="What is the typical insurance payment timeframe?"
                                                name="inuranceTimeFrame"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">

                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                        {form.value.seasonalBusiness=="true"&&<><div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">

                                                    {/* <FormSelect
                                                        name="peakSeason"
                                                        label="Peak season"
                                                        options={PeakSeasonOptions}
                                                        defaultValue={PeakSeasonOptions[0]}
                                                        required={true}
                                                        er
                                                        rorMessage="Required field"
                                                    /> */}

                                                    <CreatableSelect
                                                        name="peakSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Peak season"
                                                        value={selectOptions.peakSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={PeakSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {/* <FormSelect
                                                        name="lowSeason"
                                                        label="Low season"
                                                        options={LowSeasonOptions}
                                                        defaultValue={LowSeasonOptions[0]}
                                                        required={true}
                                                        errorMessage="Required field"
                                                    /> */}

                                                    <CreatableSelect
                                                        name="lowSeason"
                                                        //value={get('geographicMarket').value}
                                                        label="Low season"
                                                        value={selectOptions.lowSeason}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select..."
                                                        options={LowSeasonOptions}
                                                        isMulti
                                                        isClearable

                                                    />
                                                </div>
                                            </div>
                                        </div></>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">

                                            <FormRadio
                                                name="affiliatedWithHospital"
                                                label="Is it affiliated with a particular hospital?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                       {form.value.affiliatedWithHospital=="true"&& <div className="col-md-6">

                                            <FormInputAd
                                                id="hospitalName"
                                                label="Hospital name"
                                                name="hospitalName"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="hospitalName"
                                                label="Hospital Name"
                                                name="hospitalName"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">

                                            <FormRadio
                                                name="additionalServices"
                                                label="Are additional services done on premises (i.e. blood work, Xrays)?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                       { form.value.additionalServices=="true"&&<div className="col-md-6">
                                            <FormInputAd
                                                id="serviceName"
                                                label="Service name"
                                                name="serviceName"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            />
                                            {/* <FormInput
                                                id="serviceName"
                                                label="Service Name"
                                                name="serviceName"
                                                placeholder="Enter Text"
                                                required
                                                errorMessage="Required field"
                                            /> */}
                                        </div>}


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">

                                            <FormTextArea
                                                id="additionalComments"
                                                label="Additional comments"
                                                name="additionalComments"
                                                placeholder="Enter Text"
                                                maxlength={500}
                                            />
                                        </div>


                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button2" onClick="">Back</button>
                                    <button type="submit" className="lm-button1 ml-3">Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>



            </div>)}

        </>
    );
}

export default IndustryMedical;




