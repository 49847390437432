import { Header, Footer } from "@components";
import "./styles/ForgotPassword.css";
import './styles/WebsiteStyle.css';
import pass_eye from '../assets/img/pass_eye.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, encryptEmail } from "../services/commonService";
import { useEffect, useState } from "react";
import { getSessionId } from "../services/storageData";
import reducersAndActions from "../_redux/slices";
import Constants from "../helpers/Constants";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import Preloader from "../plugins/Preloader";
import login_error_icon from '../assets/icons/login-error-icon.svg';
import pass_sugn_tick from '../assets/icons/pass-sugn-tick.svg';
import pass_sugn_cross from '../assets/icons/pass-sugn-cross.svg';
import { decryptText } from "../helpers/DynamicFunctions";

// Reactive Form Group
const passwordGroup = FormBuilder.group({
  NewPassword: ["", [Validators.required]],
  ConfirmPassword: ["", [Validators.required]],
});

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const errorbox = useSelector((state) => state.errorbox);

  let passwordMapper = {
    showNewPassword: false,
    showConfirmPassword: false,
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('data')) {
      const securedEmail = decryptText(queryParams.get('data'))
      setEmail(securedEmail);
    } else {
      const securedEmail = queryParams.get('email').split(" ").join("+");
      encryptEmail(securedEmail).then(resp => setEmail(resp)).catch(err => console.log(err));
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (passwordGroup.status !== "INVALID") {
      if (passwordGroup.value.NewPassword !== passwordGroup.value.ConfirmPassword) {
        dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: "New password and confirm password doesn't match." }));
        setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
        setLoading(false);
        return;
      } else {
        let requestData = {
          EmailAddress: email,
          SessionId: getSessionId(),
          Password: passwordGroup.value.NewPassword
        }
        changePassword(requestData).then(data => {
          if (data.status === 200) {
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            navigate('/');
          } else {
            setLoading(false);
            dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: data.message }));
            setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
          }
        }).catch(err => {
          console.log(err, err.response.data.message);
          setLoading(false);
          dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: err.response.data.message }));
          setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
        });
      }
    } else {
      markAllAsTouched(passwordGroup);
      setLoading(false);
    }
  }

  const markAllAsTouched = (formGroup) => {
    Object.keys(formGroup.controls).forEach(controlName => {
      if (controlName) {
        let mapper = {
          NewPassword: "showNewPassword",
          ConfirmPassword: "showConfirmPassword",
        }
        const control = formGroup.get(controlName);
        control.active = true;
        control.touched = true;
        document.getElementById(mapper[controlName]).focus();
      }
    });
  }

  const togglePassword = (type) => {
    if (type) {
      passwordMapper[type] = !passwordMapper[type];
      document.getElementById(type).focus();
    }
  }

  return (
    <>
      {/* Header */}
      <Header />

      {/* Loader */}
      {loading ? <Preloader /> : null}

      {/* Main Body */}
      <div className="website-wrap">
        <section className="forgot-body">
          <div className="container">
            {/* validation-box start*/}
            {
              errorbox.visible && <div className="login-validation-box">
                <img className="mr-2" src={login_error_icon} alt="" />
                {errorbox.message}
              </div>
            }
            <div className="forgot-box verification-box">
              <h4>Reset password</h4>
              <p>{email}</p>
              <FieldGroup
                control={passwordGroup}
                render={({ get, invalid }) => (
                  <form onSubmit={handleSubmit}>
                    <FieldControl
                      name="NewPassword"
                      render={({ handler, touched, hasError, meta }) =>
                      (
                        <div className={(touched && hasError("required")) ? "form-group input-password error-field" : "form-group input-password"}>
                          <label htmlFor="NewPassword">New Password</label>
                          <input type={!passwordMapper.showNewPassword ? "password" : "text"} className="form-control" id="showNewPassword" placeholder="Enter a password" {...handler()} />
                          <img id="pass_eye" className={passwordMapper.showNewPassword ? 'pass_eye active_pass' : 'pass_eye'} src={pass_eye} alt="" onClick={() => togglePassword("showNewPassword")} />
                          <div className="password-suggestion-box">
                            <div className="d-flex"><img src={pass_sugn_tick} alt="" /> Supporting text</div>
                            <div className="d-flex"><img src={pass_sugn_tick} alt="" /> Supporting text</div>
                            <div className="d-flex"><img src={pass_sugn_tick} alt="" /> Supporting text</div>
                            <div className="d-flex"><img src={pass_sugn_cross} alt="" /> Supporting text</div>
                          </div>
                          {/* Error messages below */}
                          <span className="error-message">{touched && hasError("required") && `New Password is required`}</span>
                        </div>)
                      }
                      meta={{ placeholder: "Enter a password" }}
                    />
                    <FieldControl
                      name="ConfirmPassword"
                      render={({ handler, touched, hasError, meta }) =>
                      (
                        <div className={(touched && hasError("required")) ? "form-group input-password error-field" : "form-group input-password"}>
                          <label htmlFor="confirm-password">Confirm Password</label>
                          <input type={!passwordMapper.showConfirmPassword ? "password" : "text"} className="form-control" id="showConfirmPassword" placeholder="Enter a password" {...handler()} />
                          <img id="confirm_pass_eye" className={passwordMapper.showConfirmPassword ? 'pass_eye active_pass' : 'pass_eye'} src={pass_eye} alt="" onClick={() => togglePassword("showConfirmPassword")} />
                          <div className="password-suggestion-box">
                            <div className="d-flex"><img src={pass_sugn_tick} alt="" /> Supporting text</div>
                            <div className="d-flex"><img src={pass_sugn_tick} alt="" /> Supporting text</div>
                            <div className="d-flex"><img src={pass_sugn_tick} alt="" /> Supporting text</div>
                            <div className="d-flex"><img src={pass_sugn_cross} alt="" /> Supporting text</div>
                          </div>
                          {/* Error messages below */}
                          <span className="error-message">{touched && hasError("required") && `Confirm Password is required`}</span>
                        </div>)
                      }
                      meta={{ placeholder: "Enter a password" }}
                    />
                    <button type="submit" className="btn btn-forgot" onClick={handleSubmit}>
                      Reset Password
                    </button>
                  </form>
                )}
              />
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}

export default ResetPassword;
