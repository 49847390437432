import React, { useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';

const DynamicTableLogs = ({
    data,
    dynamicTableDisplay,
    columns,
    changePageSize,
    pageSize,
    total,
    setList,
    setPageIndex,
    pageIndex,
    extraRow,
    entityList,
    selectedOption
}) => {
    // Ensure total and pageSize are numbers and have default values
    const validTotal = Number(total) || 0;
    const validPageSize = Number(pageSize) || 10; // Default pageSize to 10 if not set

    // Manage currentPageSize state
    const [currentPageSize, setCurrentPageSize] = useState(validPageSize);

    // Calculate pageCount
    const pageCount = validPageSize > 0 ? Math.ceil(validTotal / validPageSize) : 0;

    // Sort data by the latest date and time
    const sortedData = useMemo(() => {
        return data.slice().sort((a, b) => new Date(b.DATE) - new Date(a.DATE));
    }, [data]);

    const handlePageClick = (event) => {
        if (setPageIndex) {
            const selectedPageIndex = event.selected + 1; // ReactPaginate uses zero-based indexing
            setPageIndex(selectedPageIndex);
        } else {
            console.error("setPageIndex is not a function");
        }
    };

    return (
        <table className="lm-grid-table" id="loanAppTable">
            <thead>
                <tr>
                    {columns.map((head, index) => (
                        <th key={index + new Date().getTime()}>
                            <div className="d-flex pointer-cursor">
                                {head.name}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {extraRow ? extraRow(entityList, selectedOption) : ""}
                {sortedData.map((row, index) => dynamicTableDisplay(row, index))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={columns.length}>
                        <div className="pagination pagination-r-tbl">
                            <div className="d-flex">
                                <ReactPaginate
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    initialPage={pageIndex - 1}
                                    className="d-flex"
                                    pageClassName="mx-2 text-primary"
                                    pageLinkClassName="text-secondary"
                                    activeClassName="active-page"
                                />
                            </div>
                            <div>
                                <input
                                    className="text-center page-number-box"
                                    type="number"
                                    min="0"
                                    max="100"
                                    onChange={(e) => {
                                        const size = Math.max(0, Math.min(100, Number(e.target.value)));
                                        setCurrentPageSize(size);
                                    }}
                                    onBlur={(e) => {
                                        const size = Math.max(0, Math.min(100, Number(e.target.value)));
                                        changePageSize(size || 1);
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            const size = Math.max(0, Math.min(100, Number(e.target.value)));
                                            changePageSize(size || 1);
                                        }
                                    }}
                                    value={currentPageSize}
                                />
                                <span className="text-light ml-2">Rows per page</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

export default DynamicTableLogs;
