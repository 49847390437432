import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../plugins/Preloader';
import DatePicker from "react-datepicker";
import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import './styles/Users.css';
import { useDispatch } from "react-redux";
import reducersAndActions from "../_redux/slices";
import axiosInstance from '../helpers/axiosConfig';
import Constants from "../helpers/Constants";
import { getUSAStates } from "../services/commonService";
import { useLocation } from "react-router-dom";

function LendingCriteria({
    showSidePanel = true,
    showProfileHeader = true,
    dashboardWrapClass = "dashboard-wrap users-screen pb-5 mb-3"
}) {
    const location = useLocation();
    const dispatch = useDispatch()

    const userdetails = location.state?.data || {}

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [additionalFields, setAdditionalFields] = useState([]);

    const [usastate, setUsastates] = useState([])
    const [propertyType, setPropertyType] = useState([])
    const [prescreenRequirement, setPrescreenRequirement] = useState([])

    const GeographicCoverageAnimated = makeAnimated();

    const getCountryStates = async () => {
        const states = await getUSAStates();
        setUsastates(states);
    }

    const PropertyTypeHandler = async () => {
        try {
            const response = await axiosInstance.get(`/api/User/GetMasterList?Identifier=PropertyType`);
            const propertyData = JSON.parse(response.data.resultObject)
            const propertyOptions = propertyData.map(data => ({ label: data.label, value: data.value }))
            setPropertyType(propertyOptions)
            return propertyOptions
        } catch (error) {
            console.log(error);
        }
    }
    const PrescreenRequirementHandler = async () => {
        try {


            const response = await axiosInstance.get(`/api/User/GetMasterList?Identifier=PrescreenRequirement`);
            const propertyData = JSON.parse(response.data.resultObject)
            const stateOptions = propertyData.map(data => ({ label: data.label, value: data.value }))
            setPrescreenRequirement(stateOptions)
            return stateOptions
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        document.title = "Lending Criteria";
        getCountryStates()
        PropertyTypeHandler()
        PrescreenRequirementHandler()
        GetLenderData()
    }, []);


    const [formData, setFormData] = useState({
        id: "",
        userId: "",
        lendingInstitution: "",
        contact: "",
        Contact_Name: "",
        officeNumber: "",
        mobileNumber: "",
        cellNumber: "",
        fax: "",
        email: "",
        website: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        geographicCoverage: "",
        programs: "",
        fico: "",
        FICOmin: "",
        propertyType: "",
        prescreenRequirement: "",
        referralFees: "",
        notes: "",
        comments: "",
        verified: "",
        updated: "",
        dealClosed: "",
        dealClosingDate: "",
        StreetAddress: "",
        StreetAddress2: "",
        dealType: "",
        min: "",
        max: "",
        term: "",
        amortization: "",
        rate: "",
        ltv: "",
        dscr: "",
        maxAmortization: "",
        titles: "",
        values: "",
        industry: "",
        collateral: "",
        equity: "",
        minimumPrepayment: "",
        MinLoanSize: "",
        InterestRate: "",
        MaxLoanSize: "",
        MaxLTV: "",
        penaltyMin: "",
        isPrimary_CellNumber: false,
        isPrimary_OfficeNumber: false,
        isPrimary_SBA: false,
        isPrimary_Conventional: false,
        isPrimary_MCA: false,
        isPrimary_FixAndFlip: false,
        isPrimary_AlternativeRealState: false,
        isPrimary_AlternativeOthers: false
    });


    const DealClosedOptions = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
    ]


    const convertGeographicCoverage = (coverage) => {
        if (typeof coverage === 'string') {
            return coverage.split(',').map(item => {
                const trimmedItem = item.trim();
                return { label: trimmedItem, value: trimmedItem };
            });
        }
        return [];
    };

    const GetLenderData = async () => {
        setLoading(true)
        const storedUserId = localStorage.getItem('user');
        const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;
        const storedSessionId = localStorage.getItem('sessionId');

        let idApi = userdetails?.id ? userdetails.id : parsedUserId.id

        const response = await axiosInstance.get(`/api/User/GetLenderConsoleDetailsById?Id=${idApi}&SessionId=${storedSessionId}`)
        const data = JSON.parse(response.data.resultObject)[0]

        let conevrtgeograph = data.GeographicCoverage.length > 0 && data.GeographicCoverage
        const convertedCoverage = convertGeographicCoverage(conevrtgeograph);
        const newFields = convertGeographicCoverage(data.AdditionalField)

        const filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: "start"
        };

        setAdditionalFields(newFields)
        setFormData({
            id: data.id,
            userId: data.UserId,
            lendingInstitution: data.LendingInstitution,
            contact: "",
            Contact_Name: data.Contact,
            officeNumber: data.OfficeNumber,
            mobileNumber: "",
            cellNumber: data.CellNumber,
            fax: data.Fax,
            email: data.Email,
            website: data.Website,
            addressLine1: data.AddressLine1,
            addressLine2: data.AddressLine2,
            city: data.City,
            state: usastate.find(option => option.value === data.State),
            zipCode: data.ZIPCode,
            geographicCoverage: convertedCoverage,
            programs: data.Programs,
            fico: data.FICO,
            FICOmin: data.FICO,
            propertyType: propertyType.find(option => option.value === data.PropertyType),
            prescreenRequirement: prescreenRequirement.find(option => option.value === data.PrescreenRequirement),
            referralFees: "",
            notes: data.Notes,
            comments: data.Comments,
            verified: data.Verified,
            updated: data.Updated,
            dealClosed: DealClosedOptions.find(option => option.value === data.DealClosed),
            dealClosingDate: data.DealClosingDate,
            StreetAddress: data.AddressLine1,
            StreetAddress2: data.AddressLine2,
            dealType: data.DealType,
            min: data.Min,
            max: data.Max,
            term: data.Term,
            //  amortization: data.Amortization,
            rate: data.Rate,
            ltv: data.LTV,
            dscr: data.DSCR,
            maxAmortization: data.Amortization,
            titles: data.Titles,
            values: data.Values,
            industry: data.Industry,
            collateral: data.Collateral,
            equity: data.Equity,
            minimumPrepayment: data.MinimumPrepayment,
            MinLoanSize: data.Min,
            InterestRate: data.Rate,
            MaxLoanSize: data.Max,
            MaxLTV: data.LTV,
            penaltyMin: data.PenaltyMin,
            isPrimary_CellNumber: data.IsPrimary_CellNumber == "True" ? true : false,
            isPrimary_OfficeNumber: data.IsPrimary_OfficeNumber == "True" ? true : false,
            isPrimary_SBA: data.IsPrimary_SBA == "True" ? true : false,
            isPrimary_Conventional: data.IsPrimary_Conventional == "True" ? true : false,
            isPrimary_MCA: data.IsPrimary_MCA == "True" ? true : false,
            isPrimary_FixAndFlip: data.IsPrimary_FixAndFlip == "True" ? true : false,
            isPrimary_AlternativeRealState: data.IsPrimary_AlternativeRealState == "True" ? true : false,
            isPrimary_AlternativeOthers: data.IsPrimary_AlternativeOthers == "True" ? true : false
        })
        setLoading(false)
    }

    // Requirements tabs
    const [activeTab, setActiveTab] = useState("Lender_Information");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const validateFunction = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePatternNew = /^\(\d{3}\)\d{3}-\d{4}$/;

        let newErrors = {};

        if (!formData.lendingInstitution) newErrors.lendingInstitution = "Lending institution is required";
        if (!formData.Contact_Name) newErrors.Contact_Name = "Contact name is required";
        if (!formData.email) newErrors.email = "Email is required";
        else if (!emailPattern.test(formData.email)) newErrors.email = "Enter valid email address";

        if (!formData.officeNumber) newErrors.officeNumber = "Office number is required";
        else if (!phonePatternNew.test(formData.officeNumber)) newErrors.officeNumber = "Office number should be of 10 digits.";

        if (!formData.cellNumber) newErrors.cellNumber = "Cell number is required";
        else if (!phonePatternNew.test(formData.cellNumber)) newErrors.cellNumber = "Cell number should be of 10 digits.";

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return false;
        } else {
            return true
        }

    }
    const [geographicCoverage, setGeographicCoverage] = useState()
    const [additionalFieldnew, setAdditionalFieldnew] = useState()

    useEffect(() => {
        if (Array.isArray(formData.geographicCoverage)) {
            const valuesString = formData.geographicCoverage.map(location => location.value).join(', ');
            setGeographicCoverage(valuesString)
        }
        if (Array.isArray(additionalFields)) {
            const valuesString = additionalFields.map(location => location.value).join(', ');
            setAdditionalFieldnew(valuesString)
        }
    }, [formData.geographicCoverage, additionalFields])

    const getInquiries = async () => {
        const validate = await validateFunction()

        const formDataToSend = new FormData();
        const LenderData = {
            "id": formData.id,
            "UserId": formData.userId,
            "LendingInstitution": formData.lendingInstitution,
            "Contact": formData.Contact_Name,
            "OfficeNumber": formData.officeNumber,
            "MobileNumber": formData.mobileNumber,
            "CellNumber": formData.cellNumber,
            "Fax": formData.fax,
            "Email": formData.email,
            "Website": formData.website == undefined ? "" : formData.website,
            "AddressLine1": formData.StreetAddress,
            "AddressLine2": formData.StreetAddress2,
            "City": formData.city,
            "State": formData.state?.value,
            "ZIPCode": formData.zipCode,
            "GeographicCoverage": geographicCoverage,
            "Programs": formData.programs,
            "FICO": formData.FICOmin,
            "PropertyType": formData.propertyType?.value,
            "PrescreenRequirement": formData.prescreenRequirement?.value,
            "ReferalFees": formData.referralFees,
            "Notes": formData.notes,
            "Comments": formData.comments,
            "Verified": formData.verified,
            "Updated": formData.updated,
            "DealClosed": formData.dealClosed?.value,
            "DealClosingDate": formData.dealClosingDate,
            "DealType": formData.dealType,
            "Min": formData.MinLoanSize,
            "Max": formData.MaxLoanSize,
            "Term": formData.term,
            "Amortization": formData.maxAmortization,
            "Rate": formData.InterestRate,
            "LTV": formData.MaxLTV,
            "DSCR": formData.dscr,
            "Titles": formData.titles,
            "Values": formData.values,
            "Industry": formData.industry,
            "Collateral": formData.collateral,
            "Equity": formData.equity,
            "MinimumPrepayment": formData.minimumPrepayment,
            "PenaltyMin": formData.penaltyMin,
            "IsPrimary_CellNumber": formData.isPrimary_CellNumber == undefined ? false : formData.isPrimary_CellNumber,
            "IsPrimary_OfficeNumber": formData.isPrimary_OfficeNumber == undefined ? false : formData.isPrimary_OfficeNumber,
            "IsPrimary_SBA": formData.isPrimary_SBA == undefined ? false : formData.isPrimary_SBA,
            "IsPrimary_Conventional": formData.isPrimary_Conventional == undefined ? false : formData.isPrimary_Conventional,
            "IsPrimary_MCA": formData.isPrimary_MCA == undefined ? false : formData.isPrimary_MCA,
            "IsPrimary_FixAndFlip": formData.isPrimary_FixAndFlip == undefined ? false : formData.isPrimary_FixAndFlip,
            "IsPrimary_AlternativeRealState": formData.isPrimary_AlternativeRealState == undefined ? false : formData.isPrimary_AlternativeRealState,
            "IsPrimary_AlternativeOthers": formData.isPrimary_AlternativeOthers == undefined ? false : formData.isPrimary_AlternativeOthers,
            "additionalField": additionalFieldnew
        }
        for (const key in LenderData) {
            if (LenderData[key] === undefined) {
                LenderData[key] = "";
            }
        }
        for (const key in LenderData) {
            if (LenderData.hasOwnProperty(key)) {
                if (Array.isArray(LenderData[key])) {
                    LenderData[key].forEach((item, index) => {
                        formDataToSend.append(`${key}[${index}]`, item);
                    });
                } else {
                    formDataToSend.append(key, LenderData[key]);
                }
            }
        }

        if (validate) {
            setLoading(true)
            const response = await axiosInstance.post(`/api/User/UpdateLenderConsole?SessionId=${localStorage.getItem('sessionId')}`, formDataToSend);
            setLoading(false)
            if (response.data.status == 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setLoading(false);

            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setLoading(false);

            }
        }
    }


    const handleAdditionalFieldChange = (index, value) => {
        const updatedFields = additionalFields.map((field, i) =>
            i === index ? { ...field, value } : field
        );
        setAdditionalFields(updatedFields);
    };
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        validateField(id, value)
    };

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setFormData({ ...formData, [id]: checked });
        if (id === "isPrimary_OfficeNumber") setFormData({ ...formData, isPrimary_OfficeNumber: true, isPrimary_CellNumber: false });
        if (id === "isPrimary_CellNumber") setFormData({ ...formData, isPrimary_CellNumber: true, isPrimary_OfficeNumber: false });
    };

    const handleSelectChange = (name, selectedOption) => {
        setFormData({ ...formData, [name]: selectedOption });
        validateField(name, selectedOption)
    };

    const formatDatehandler = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const handleDateChange = (date) => {
        const formattedDate = formatDatehandler(date);
        setFormData({ ...formData, dealClosingDate: formattedDate });
        validateField("dealClosingDate", date)
    };
    const validateField = (field, value) => {
        let error = '';
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\d{3}\d{3}\d{4}$/;

        const spacecheckpattern = /^(?!.*\s{2,})(?!\s)(?!.*\s$)[a-zA-Z0-9\s,.-]+$/;
        switch (field) {
            case 'lendingInstitution':
                if (!spacecheckpattern.test(value) && value.length !== 0) {
                    error = 'Field contain valid data';
                }
                break;
            case 'email':
                if (!emailPattern.test(value) && value.length !== 0) {
                    error = 'Invalid email address';
                }
                break;
            case 'officeNumber':
            case 'cellNumber':
                if (!phonePattern.test(value) && value.length !== 0) {
                    error = ` ${field === 'officeNumber' ? 'Office' : 'Mobile'} number should be of 10 digits.`;
                }
                break;
            default:
                break;
        }

        setErrors(prevErrors => ({ ...prevErrors, [field]: error }));
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === 'officeNumber' || id === 'cellNumber') {
            const formattedValue = standardizePhoneNumber(value);
            setFormData({ ...formData, [id]: formattedValue });
        } else {
            setFormData({ ...formData, [id]: value });
        }
        validateField(id, value);
    };
    const standardizePhoneNumber = (phoneNumber) => {
        // Strip all non-numeric characters from the input
        let formattedNumber = phoneNumber.replace(/\D/g, '');

        // Apply formatting based on the length of the number
        if (formattedNumber.length === 10) {
            formattedNumber = `(${formattedNumber.slice(0, 3)})${formattedNumber.slice(3, 6)}-${formattedNumber.slice(6)}`;
        }

        return formattedNumber;
    };

    const handleAddField = () => {
        setAdditionalFields([
            ...additionalFields,
            { name: `additionalField${additionalFields.length}`, value: '' }
        ]);
    };
    const [errorADD, setErrorADD] = useState(false)
    const addError = () => {
        setErrorADD(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        getInquiries()
    };


    return (
        <>
            {/* SidePanel */}
            {showSidePanel && <SidePanel />}

            {/* ProfileHeader */}
            {showProfileHeader && <ProfileHeader />}


            {loading ? (<Preloader />) : (<div>

                <div className={dashboardWrapClass}>
                    <div className="lm-nav-tabs pfs-radio-tabs  radio-tabs">
                        <ul className="nav nav-tabs" id="laTab" role="tablist">
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === "Lender_Information" ? "active" : ""}`} onClick={() => handleTabClick("Lender_Information")}>
                                    Lender’s Information
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === "Program_Detail" ? "active" : ""}`} onClick={() => handleTabClick("Program_Detail")}>
                                    Program Detail
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content pt-3" id="laTabContent">
                        <div className={`tab-pane fade ${activeTab === "Lender_Information" ? "show active" : ""}`} id="Lender_Information">

                            <form onSubmit={handleSubmit} >
                                <div className="row">
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Lending_nstitution">Lending Institution </label>
                                            <input type="text" className="form-control" id="lendingInstitution"
                                                value={formData.lendingInstitution}
                                                placeholder="Lending Institution "
                                                onChange={handleChange} />

                                            {<div className='error-message'>{errors.lendingInstitution}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Contact_Name">Contact Name </label>
                                            <input type="text" className="form-control" id="Contact_Name"
                                                placeholder="Contact Name"
                                                value={formData.Contact_Name}
                                                onChange={handleInputChange}
                                            />

                                            {<div className='error-message'>{errors.Contact_Name}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group mb-2">
                                            <label htmlFor="Office_Number">Office Number </label>
                                            <input type="text" className="form-control" id="officeNumber"
                                                placeholder="(___) ___-____"
                                                value={formData.officeNumber}
                                                onChange={handleInputChange}
                                            />

                                            {<div className='error-message'>{errors.officeNumber}</div>}
                                        </div>
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input"
                                                id="isPrimary_OfficeNumber"
                                                checked={formData.isPrimary_OfficeNumber}
                                                value={formData.isPrimary_OfficeNumber}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label" htmlFor="Mark_primary_Office_Number">Mark as
                                                primary </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group mb-2">
                                            <label htmlFor="Cell_Number">Cell Number </label>
                                            <input type="text" className="form-control" id="cellNumber"
                                                placeholder="(___) ___-____"

                                                value={formData.cellNumber}
                                                onChange={handleInputChange}
                                            />

                                            {<div className='error-message'>{errors.cellNumber}</div>}
                                        </div>
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input"
                                                id="isPrimary_CellNumber"

                                                checked={formData.isPrimary_CellNumber}
                                                value={formData.isPrimary_CellNumber}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label" htmlFor="Mark_primary_Cell_Number">Mark as
                                                primary </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="label mb-2">Progarm</div>
                                        <div className="d-flex flex-wrap">
                                            <div className="form-group form-check mr-4">
                                                <input type="checkbox" className="form-check-input" id="isPrimary_SBA"
                                                    checked={formData.isPrimary_SBA}
                                                    value={formData.isPrimary_SBA}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="SBA">SBA</label>
                                            </div>
                                            <div className="form-group form-check mr-4">
                                                <input type="checkbox" className="form-check-input" id="isPrimary_Conventional"

                                                    checked={formData.isPrimary_Conventional}
                                                    value={formData.isPrimary_Conventional}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="Conventional">Conventional</label>
                                            </div>
                                            <div className="form-group form-check mr-4">
                                                <input type="checkbox" className="form-check-input" id="isPrimary_MCA"

                                                    checked={formData.isPrimary_MCA}
                                                    value={formData.isPrimary_MCA}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="MCA">MCA</label>
                                            </div>
                                            <div className="form-group form-check mr-4">
                                                <input type="checkbox" className="form-check-input" id="isPrimary_FixAndFlip"

                                                    checked={formData.isPrimary_FixAndFlip}
                                                    value={formData.isPrimary_FixAndFlip}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="Fix_Flip">Fix & Flip</label>
                                            </div>
                                            <div className="form-group form-check mr-4">
                                                <input type="checkbox" className="form-check-input"
                                                    id="isPrimary_AlternativeRealState"

                                                    checked={formData.isPrimary_AlternativeRealState}
                                                    value={formData.isPrimary_AlternativeRealState}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label"
                                                    htmlFor="Alternative_Real_Estate">Alternative - Real Estate</label>
                                            </div>
                                            <div className="form-group form-check mr-4">
                                                <input type="checkbox" className="form-check-input" id="isPrimary_AlternativeOthers"

                                                    checked={formData.isPrimary_AlternativeOthers}
                                                    value={formData.isPrimary_AlternativeOthers}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label" htmlFor="Alternative_Others">Alternative -
                                                    Others</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Fax">Fax</label>
                                            <input type="text" className="form-control" id="fax" placeholder="Fax"

                                                value={formData.fax}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Email">Email Address</label>
                                            <input type="text" className="form-control" id="email"
                                                placeholder="Email Address"

                                                value={formData.email}
                                                onChange={handleChange}
                                            />

                                            {<div className='error-message'>{errors.email}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Website">Website</label>
                                            <input type="text" className="form-control" id="website"
                                                placeholder="https://example.com"

                                                value={formData.website}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* <!-- lm common divider to break the section  --> */}
                                    <div className="lm-divider mx-3 mt-2"></div>
                                    {/* <!-- lm common divider to break the section  --> */}

                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Street_Address">Street Address</label>
                                            <input type="text" className="form-control" id="StreetAddress"
                                                placeholder="Enter an Address"

                                                value={formData.StreetAddress}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Street_Address_2">Street Address 2</label>
                                            <input type="text" className="form-control" id="StreetAddress2"
                                                placeholder="Enter an Address"

                                                value={formData.StreetAddress2}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="City">City</label>
                                            <input type="text" className="form-control" id="city" placeholder="City"

                                                value={formData.city}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="State">State</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                id="State"
                                                name="State"
                                                options={usastate}
                                                defaultValue={usastate[0]}
                                                value={formData.state}
                                                onChange={(selectedOption) => handleSelectChange("state", selectedOption)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="ZIP_Code">ZIP Code</label>
                                            <input type="text" className="form-control" id="zipCode"
                                                placeholder="ZIP Code"

                                                value={formData.zipCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* <!-- lm common divider to break the section  --> */}
                                    <div className="lm-divider mx-3 mt-2"></div>
                                    {/* <!-- lm common divider to break the section  --> */}

                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Geographic_Coverage">Geographic Coverage</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                closeMenuOnSelect={false}
                                                components={GeographicCoverageAnimated}
                                                isMulti
                                                options={usastate}
                                                value={formData.geographicCoverage}
                                                onChange={(selectedOption) => handleSelectChange("geographicCoverage", selectedOption)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="FICO_min">FICO min.</label>
                                            <input type="text" className="form-control" id="FICOmin"
                                                placeholder="Enter a minimum FICO score"

                                                value={formData.FICOmin}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Property_Type">Property Type</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                id="Property_Type"
                                                name="Property_Type"
                                                options={propertyType}
                                                defaultValue={propertyType[0]}
                                                value={formData.propertyType}
                                                onChange={(selectedOption) => handleSelectChange("propertyType", selectedOption)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Prescreen_Requirement">Prescreen Requirement</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                id="Prescreen_Requirement"
                                                name="Prescreen_Requirement"
                                                options={prescreenRequirement}
                                                defaultValue={prescreenRequirement[0]}
                                                value={formData.prescreenRequirement}
                                                onChange={(selectedOption) => handleSelectChange("prescreenRequirement", selectedOption)}
                                            />
                                        </div>
                                    </div>

                                    {/* <!-- lm common divider to break the section  --> */}
                                    <div className="lm-divider mx-3 mt-2"></div>
                                    {/* <!-- lm common divider to break the section  --> */}

                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-percentage">
                                            <label htmlFor="FICO_min">Required Equity (%)</label>
                                            <input type="text" className="form-control" id="equity"
                                                placeholder="Enter a Required Equity (%)"

                                                value={formData.equity}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Deal_Closed">Deal Closed</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                id="Deal_Closed"
                                                name="Deal_Closed"
                                                options={DealClosedOptions}
                                                defaultValue={DealClosedOptions[0]}
                                                value={formData.dealClosed}
                                                onChange={(selectedOption) => handleSelectChange("dealClosed", selectedOption)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-date">
                                            <label htmlFor="Deal_Closing_Date">Deal Closing Date</label>
                                            <DatePicker
                                                showIcon
                                                toggleCalendarOnIconClick
                                                selected={formData.dealClosingDate}
                                                isClearable={true}
                                                dateFormat="MMM d, yyyy"
                                                placeholderText="Month DD, YYYY"
                                                onChange={handleDateChange}
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown
                                                showMonthDropdown
                                                showYearDropdown
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-lg-3">

                                        <div className="form-group">
                                            <label htmlFor="Deal_Type">Deal Type</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="dealType"
                                                value={formData.dealType}
                                                placeholder="Enter a deal type"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* <!-- lm common divider to break the section  --> */}
                                    <div className="lm-divider mx-3 mt-2"></div>
                                    {/* <!-- lm common divider to break the section  --> */}


                                    {/* Verified By */}
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Verified_By">Verified By</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="verified"
                                                name="Verified By"
                                                value={formData.verified}
                                                placeholder="Verified_By"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* Updated By */}
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Updated_By">Updated By</label>

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="updated"
                                                name="Updated By"
                                                value={formData.updated}
                                                placeholder="Updated By"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* <!-- lm common divider to break the section  --> */}
                                    <div className="lm-divider mx-3 mt-2"></div>
                                    {/* <!-- lm common divider to break the section  --> */}

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Notes">Notes</label>
                                            <textarea className="form-control" id="notes" placeholder="Enter notes"

                                                value={formData.notes}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Comments">Comments</label>
                                            <textarea className="form-control" id="comments" placeholder="Enter a comment"

                                                value={formData.comments}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="submit" className="lm-button1 ml-auto">Save</button>
                                </div>
                            </form>
                        </div>
                        <div className={`tab-pane fade ${activeTab === "Program_Detail" ? "show active" : ""}`} id="Program_Detail">
                            <form onSubmit={handleSubmit} >
                                <div className="row">
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-dollar">
                                            <label htmlFor="Min_Loan_Size">Min Loan Size</label>
                                            <input type="number" className="form-control" id="MinLoanSize"
                                                placeholder="0.00"

                                                value={formData.MinLoanSize}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-dollar">
                                            <label htmlFor="Interest_Rate">Interest Rate</label>
                                            <input type="number" className="form-control" id="InterestRate"
                                                placeholder="0.00"

                                                value={formData.InterestRate}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-dollar">
                                            <label htmlFor="Max_Loan_Size">Max Loan Size ($)</label>
                                            <input type="number" className="form-control" id="MaxLoanSize"
                                                placeholder="0.00"

                                                value={formData.MaxLoanSize}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-dollar">
                                            <label htmlFor="Max_LTV">Max LTV</label>
                                            <input type="number" className="form-control" id="MaxLTV"
                                                placeholder="0.00"

                                                value={formData.MaxLTV}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="term">Term (years) </label>
                                            <input type="text" className="form-control" id="term"
                                                placeholder="Enter term (years) "

                                                value={formData.term}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-percentage">
                                            <label htmlFor="DSCR">DSCR</label>
                                            <input type="number" className="form-control" id="dscr" placeholder="0.00"

                                                value={formData.dscr}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-dollar">
                                            <label htmlFor="Max_Amortization">Max Amortization</label>
                                            <input type="number" className="form-control" id="maxAmortization"
                                                placeholder="0.00"
                                                value={formData.maxAmortization}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="Industry">Industry</label>

                                            <input type="text" className="form-control" id="industry"
                                                placeholder="industry"
                                                value={formData.industry}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-dollar">
                                            <label htmlFor="Collateral">Collateral</label>
                                            <input type="number" className="form-control" id="collateral"
                                                placeholder="0.00"
                                                value={formData.collateral}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-percentage">
                                            <label htmlFor="Equity">Equity (%)</label>
                                            <input type="number" className="form-control" id="equity"
                                                placeholder="0.00"
                                                value={formData.equity}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-dollar">
                                            <label htmlFor="Minimum_Prepayment">Minimum Prepayment</label>
                                            <input type="number" className="form-control" id="minimumPrepayment"
                                                placeholder="0.00"
                                                value={formData.minimumPrepayment}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group input-dollar">
                                            <label htmlFor="Penalty_Min">Penalty Min.</label>
                                            <input type="number" className="form-control" id="penaltyMin"
                                                placeholder="0.00"
                                                value={formData.penaltyMin}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                    {
                                        additionalFields.map((field, index) => (
                                            <div key={index} className="col-md-4 col-lg-3">
                                                <div className="form-group ">
                                                    <label htmlFor={field.name}>Additional Field {index + 1}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id={field.name}
                                                        name={field.name}
                                                        placeholder="Additional Field"
                                                        value={field.value}
                                                        onChange={(e) => handleAdditionalFieldChange(index, e.target.value)}
                                                    />
                                                    {errors[field.name] && <div className='error-message'>{errors[field.name]}</div>}
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="col-12 mb-3">

                                        {
                                            (additionalFields.length > 0 &&
                                                additionalFields[additionalFields.length - 1]?.value !== undefined &&
                                                additionalFields[additionalFields.length - 1].value !== "")
                                                ? <a className="lm-button3" onClick={handleAddField}><span>+</span> Add new field</a>
                                                : additionalFields.length == 0 ? <a className="lm-button3" onClick={handleAddField}><span>+</span> Add new field</a> : <><a className="lm-button3" onClick={addError} ><span>+</span> Add new field

                                                </a>
                                                    {errorADD && <span className="error-message">Please fill Previous field</span>}
                                                </>
                                        }
                                    </div>
                                </div>


                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="submit" className="lm-button1 ml-auto">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


            </div>)}


        </>
    );
}

export default LendingCriteria;