import { createBrowserRouter } from "react-router-dom";
import {
    LoginScreen, Register, ForgotPassword, EmailVerification, ResetPassword, SuccessScreen, LoanApplication, Settings, UserAdminAdvisor,
    UserForm, UserBorrowers, UserLenders, UserPartners, Inquiries, Templates, Dashboard, Logs, ApplicationDetail, NotFound, LendingCriteria,
    DashboardBorrower, AccessRestricted, DownloadZipDocument, UpdateHelloSignDocument
} from '@screens';
import { PersonalProfileDetail, PFSDetail, CashFlowBusiness, CashFlowPersonal, IndustryHotel, IndustryContractor } from '@loan-app-tabs'
import ProtectedRoute from "../screens/ProtectedRoute";
import RegisterLink from "../screens/RegisterLink";
import UserLenderform from "../screens/user/lender/UserLenderform";
import UserPartenerForm from "../screens/user/partener/UserPartenerForm";
import UserBorrowersForm from "../screens/user/borrowers/UserBorrowersForm";
import ApplicationRegistration from "../screens/ApplicationRegistration";
import UpdateBorrowerEmailAddressLink from "../screens/UpdateBorrowerEmailAddressLink";
import LocationCheck from "../LocationCheck";

const router = createBrowserRouter([
    { path: "/", element: <LocationCheck><LoginScreen /></LocationCheck> },
    { path: "/signup", element: <LocationCheck><Register /></LocationCheck> },
    { path: "/UpdateBorrowerEmailAddressLink", element: <UpdateBorrowerEmailAddressLink /> },

    { path: "/register", element: <ApplicationRegistration /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/email-verification", element: <EmailVerification /> },
    { path: "/ResetPasswordLink", element: <ResetPassword /> },
    { path: "/success-screen", element: <SuccessScreen /> },
    { path: "/dashboard", element: <ProtectedRoute><Dashboard /></ProtectedRoute> },
    { path: "/dashboard-borrower", element: <ProtectedRoute><DashboardBorrower /></ProtectedRoute> },
    { path: "/loan-application", element: <ProtectedRoute><LoanApplication /></ProtectedRoute> },
    { path: "/application-detail/:loanId", element: <ProtectedRoute><ApplicationDetail /></ProtectedRoute> },
    { path: "/settings", element: <Settings /> },
    { path: "/users/advisor-admin", element: <ProtectedRoute><UserAdminAdvisor /></ProtectedRoute> },
    { path: "/users/advisor-admin-detail", element: <ProtectedRoute><UserForm /></ProtectedRoute> },
    { path: "/users/borrowers", element: <ProtectedRoute><UserBorrowers /></ProtectedRoute> },
    { path: "/users/borrowers-detail", element: <ProtectedRoute><UserBorrowersForm /></ProtectedRoute> },
    { path: "/users/lenders", element: <ProtectedRoute><UserLenders /></ProtectedRoute> },
    { path: "/users/lenders-detail", element: <ProtectedRoute><UserLenderform /></ProtectedRoute> },
    { path: "/users/partners", element: <ProtectedRoute><UserPartners /></ProtectedRoute> },
    { path: "/users/partners-detail", element: <ProtectedRoute><UserPartenerForm /></ProtectedRoute> },
    { path: "/lending-criteria", element: <ProtectedRoute><LendingCriteria /></ProtectedRoute> },
    { path: "/inquiries", element: <Inquiries /> },
    { path: "/logs", element: <Logs /> },
    { path: "/templates", element: <Templates /> },
    { path: "/not-found", element: <NotFound /> },
    { path: "/personal-profile/:loanId", element: <PersonalProfileDetail /> },
    { path: "/pfs-detail", element: <PFSDetail /> },
    { path: "/cash-flow-business", element: <CashFlowBusiness /> },
    { path: "/cash-flow-personal", element: <CashFlowPersonal /> },
    { path: "/RegisterLink", element: <RegisterLink /> },
    { path: "/loan-hotel", element: <ProtectedRoute><IndustryHotel /></ProtectedRoute> },
    { path: "/loan-contractor", element: <ProtectedRoute><IndustryContractor /></ProtectedRoute> },
    { path: "/access-restricted", element: <AccessRestricted /> },

    { path: "/download-zip-document", element: <DownloadZipDocument /> },
    { path: "/update-hellosign-doc", element: <UpdateHelloSignDocument /> }
]);

export default router