import React, { useState, useEffect } from 'react';
import colse_menu_icon from '../../src/assets/icons/colse_menu_icon.svg';

function Toaster({ message, type }) {
  const [isVisible, setIsVisible] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const toggleToaster = () => {
    setIsFadingOut(true);
  };

  useEffect(() => {
    if (isFadingOut) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isFadingOut]);

  return (
    <div className={type}>
      {isVisible && (
        <div
          id="lm_toaster"
          className={`lm-toaster ${isFadingOut ? 'fadeOutUp' : 'fadeInDown'}`}>
          <div className="lm-toaster-body">
            {message}
            <button onClick={toggleToaster}>
              <img className="close" src={colse_menu_icon} alt="Close" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Toaster;