import './App.css';
import { useSelector } from 'react-redux';
import router from '@lm-router';
import { RouterProvider } from 'react-router-dom';
import { autoSetSessionId } from './services/storageData';
import { generateUniqueId } from './services/commonService';
import { useEffect } from 'react';
import Toaster from './plugins/Toaster';

function App() {
  const toaster = useSelector(state => state.toaster);
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    autoSetSessionId(generateUniqueId());
  }, []);

  return (
      <div className={!user.isLoggedIn ? "website-main" : ""}>
        {toaster.visible && <Toaster visible={toaster.visible} message={toaster.message} type={toaster.type} />}
        <RouterProvider router={router} />
      </div>
  );
}

export default App;