import { Header, Footer } from "@components";
import "./styles/SuccessScreen.css";
import './styles/WebsiteStyle.css';
import SuccessIcon from '../assets/img/success_icon.svg'
import success_msg_icon from '../assets/img/success_msg_icon.svg'
import { Link, useLocation } from "react-router-dom";


function SuccessScreen() {
  const location = useLocation();

  return (
    <>
      {/* Header */}
      <Header />

      {/* Main Body */}
      <div className="website-wrap">
        <section className="success-page-body">
          <div className="container">
            <div className="success-page-box">
              <img src={SuccessIcon} alt="SuccesIcon" />
              <h2>{location.state.title}</h2>
              <p>{location.state.subtitle}</p>
              <Link to="/" className="back-btn">
                Back to Homepage
              </Link>
            </div>
            <div className="msg-box">
              <img src={success_msg_icon} alt="" />
              <p>
                An email has been sent to your address on file to reset your
                account password. If you have questions or need additional help to
                retrieve your account, please reach out to{" "}
                <a href="mailto:info@loanmantra.com">info@loanmantra.com.</a>
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}

export default SuccessScreen;
