import React, { createRef, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { SidePanel, ProfileHeader } from "@components";
import axiosInstance from '../helpers/axiosConfig';

import {
    Affiliates,
    LoanAmount,
    BusinessProfile,
    BusinessHistory,
    PersonalProfile,
    PFS,
    CashFlow,
    IndustryHotel,
    IndustryContractor,
    IndustryGasStation,
    IndustryMedical,
    IndustryRestaurant,
    Documents,
    Notes,
    Verification,
} from "@loan-app-tabs";
import "./styles/ApplicationDetail.css";
import Preloader from "../plugins/Preloader";
import back_arrow from "../assets/icons/back_arrow.svg";
import brdcrm_nav from "../assets/icons/brdcrm_nav.svg";
import verify_icon_blue from "../assets/icons/verify-icon-blue.svg";
import application_icon_blue from "../assets/icons/application-icon-blue.svg";
import close_popup from "../assets/icons/close_popup.svg";
import scroll_left_icon from "../assets/icons/scroll-left-icon.svg";
import scroll_right_icon from "../assets/icons/scroll-right-icon.svg";
import { getMasterList } from "../services/utilServices";
import {
    GetBasicInfoLoanApplicationById,
    InsertUpdateBasicInfo,
    UpdateBorrowerEmailAddress,
} from "../services/loanApplicationServices";
import {
    FieldGroup,
    FormBuilder,
    Validators,
    FieldControl,
} from "react-reactive-form";
import FormField from "../components/FormField/FormField";
import { decryptText, businessNameValidator, borrowAmountValidator, emailValidator, formatNumberWithCommas } from "../helpers/DynamicFunctions";
import { getSessionId } from "../services/storageData";
import Constants from "../helpers/Constants";
import reducersAndActions from "../_redux/slices/index";
import { Modal } from "react-bootstrap";
import { getUSAStates } from "../services/commonService";
import AmountContainer from "../components/Amount/AmountContainer";
import { InsertOrUpdateNewLoanExistingUser } from "../services/loanApplicationServices";
import moment from 'moment';

let NewApplicationFormGroup = FormBuilder.group({
    Borrowamount: ["", [Validators.required, borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    Loandate: ["", Validators.required],
    purpose: ["", Validators.required],
    TypeOfbusiness: ["", Validators.required],
    Businessname: ["", [Validators.required, businessNameValidator]],
    BState: ["", Validators.required],
    BCity: ["", Validators.required],
    UserId: [""],
    Category: [""]
});

// Reactive Form Group
let BasicInfoForm = FormBuilder.group({
    BorrowAmount: ["", [Validators.required, borrowAmountValidator]],
    Purpose: ["", [Validators.required]],
    BusinessName: ["", [Validators.required]],
    BorrowerFirstName: ["", [Validators.required]],
    BorrowerLastName: ["", [Validators.required]],
    BorrowerContactNumber: ["", [Validators.required]],
    BorrowerName: [""],
    DBA: [""],
    TypeOfBusiness: ["", [Validators.required]],
    BusinessCategory: [""],
    TaxID: ["", [Validators.required]],
    BusinessContactNo: ["", [Validators.required]],
    NAICSCode: [0],
    BusinessEmail: ["", emailValidator],
    ApplicationNumber: [""],
    ApplicationStatus: [""],
    VerfiedStatus: [""]
});


let loanId;
let stateLists = [];
let stateListstemp = [];

function ApplicationDetail() {
    const params = useParams();
    const dispatch = useDispatch();

    let TypeOfBusnessOptionsTemp = [];
    const [TypeOfBusnessOptions, setTypeOfBusnessOptions] = useState([]);
    const [BusinessCategoriesOptions, setBusinessCategoriesOptions] = useState([]);
    const [newApplicationData, setNewApplicationData] = useState(null);
    const [isHiddenBusinessCategary, setIsHiddenBusinessCategary] = useState(false);
    const [isHiddenBusinessCategaryCaparision, setIsHiddenBusinessCategaryCaparision] = useState(false);
    const [formData, setFormData] = useState({ BusinessCategory: null })

    const handleSelectChange = (name, selectedOption) => {
        if (formData.BusinessCategory == null) {
            setFormData({
                ...formData,
                [name]: selectedOption
            });
        }
        if (formData.BusinessCategory !== null && isHiddenBusinessCategary.value !== selectedOption.value) {
            setNewTabPopupShow(true)
        }
        setIsHiddenBusinessCategary({ value: selectedOption.value })
        if (isHiddenBusinessCategary.value == formData.BusinessCategory?.value) {
            setFormData({
                ...formData,
                [name]: selectedOption
            });
        }

    };


    let BusinessCategoriesOptionsTemp = [];
    const [LoanReasonOptions, setLoanReasonOptions] = useState([]);

    let LoanReasonOptionsTemp = [];
    const objectiveRef = createRef();
    const businessRef = createRef();
    const categoryRef = createRef();
    // Preloader Start
    const [loading, setLoading] = useState(true);
    // Preloader End

    // sticky header in application page js
    const [isHidden, setIsHidden] = useState(false);
    const [newTabPopupShow, setNewTabPopupShow] = useState(false);


    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 0) {
            setIsHidden(true); // Hide the header when not at the top
        } else {
            setIsHidden(false); // Show the header when at the top
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Set initial active tab
    const [activeTab, setActiveTab] = useState("affiliates");

    const location = useLocation();
    const navigate = useNavigate();

    // Parse the query parameters to get the tab
    useEffect(() => {
        const { tabName } = location.state || {};
        if (tabName && tabComponents[tabName]) {
            setActiveTab(tabName);
            navigate(location.pathname, { state: null, replace: true }); // Clear the state
        }
    }, [location.search, navigate]);



    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const showIndustryHotel = isHiddenBusinessCategary.value == "Hotel" ? true : false; // Replace with your condition
    const showIndustryContractor = isHiddenBusinessCategary.value == "Contractor" ? true : false;; // Replace with your condition
    const showIndustryGasStation = isHiddenBusinessCategary.value == "Gas Station" ? true : false;; // Replace with your condition
    const showIndustryMedical = isHiddenBusinessCategary.value == "Medical" ? true : false; // Replace with your condition
    const showIndustryRestaurant = isHiddenBusinessCategary.value == "Restaurant" ? true : false;; // Replace with your condition

    const tabComponents = {
        affiliates: <Affiliates />,
        loan_amount: (
            <LoanAmount backPreviousTab={() => handleTabClick("affiliates")} />
        ),
        business_profile: (
            <BusinessProfile backPreviousTab={() => handleTabClick("loan_amount")} />
        ),
        business_history: (
            <BusinessHistory backPreviousTab={() => handleTabClick("business_profile")} />
        ),
        personal_profile: <PersonalProfile />,
        PFS: (
            <PFS loanid={decryptText(params.loanId)} applicationNumber={BasicInfoForm.value.ApplicationNumber} />
        ),
        cash_flow: (
            <CashFlow
                backPreviousTab={() => handleTabClick("PFS")}
                loanid={decryptText(params.loanId)}
                applicationNumber={BasicInfoForm.value.ApplicationNumber}
                applicationName={BasicInfoForm.value.BusinessName}
            />
        ),
        ...(showIndustryHotel && {
            industry_hotel: (
                <IndustryHotel backPreviousTab={() => handleTabClick("industry_hotel")} />
            ),
        }),
        ...(showIndustryContractor && {
            industry_Contractor: (
                <IndustryContractor backPreviousTab={() => handleTabClick("industry_Contractor")} />
            ),
        }),
        ...(showIndustryGasStation && {
            Industry_Gas_Station: (
                <IndustryGasStation backPreviousTab={() => handleTabClick("Industry_Gas_Station")} />
            ),
        }),
        ...(showIndustryMedical && {
            Industry_Medical: (
                <IndustryMedical backPreviousTab={() => handleTabClick("Industry_Medical")} />
            ),
        }),
        ...(showIndustryRestaurant && {
            Industry_Restaurant: (
                <IndustryRestaurant backPreviousTab={() => handleTabClick("Industry_Restaurant")} />
            ),
        }),
        documents: (
            <Documents
                loanid={decryptText(params.loanId)}
                applicationNumber={BasicInfoForm.value.ApplicationNumber}
            />
        ),
        notes: <Notes />,
        verification: <Verification />,
    };

    useEffect(() => {


    }, [isHiddenBusinessCategary]);

    const tabTitles = {
        affiliates: "Affiliates",
        loan_amount: "Loan Amount",
        business_profile: "Business Profile",
        business_history: "Business History",
        personal_profile: "Personal Profile",
        PFS: "PFS",
        cash_flow: "Cash Flow",
        industry_hotel: "Hotel",
        industry_Contractor: "Contractor",
        Industry_Gas_Station: "Gas Station",
        Industry_Medical: "Medical",
        Industry_Restaurant: "Restaurant",
        documents: "Documents",
        notes: "Notes",
        verification: "Verification",
    };

    // Vertical tabs scroll button
    const laTabRef = useRef(null);
    const [naics, setNaics] = useState("")
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);
    const [contactNum, setContactNum] = useState("")
    const [contactEmail, setContactEmail] = useState("")
    const [contactPhone, setContactPhone] = useState("")
    const [contactTaxId, setContactTaxId] = useState("")

    const scrollLeft = () => {
        if (laTabRef.current) {
            laTabRef.current.scrollBy({ left: -120, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (laTabRef.current) {
            laTabRef.current.scrollBy({ left: 120, behavior: "smooth" });
        }
    };

    const updateButtonsVisibility = () => {
        if (laTabRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = laTabRef.current;
            setShowLeftButton(scrollLeft > 0);
            setShowRightButton(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        const currentRef = laTabRef.current;

        const handleResize = () => {
            updateButtonsVisibility();
        };

        const handleScroll = () => {
            updateButtonsVisibility();
        };

        if (currentRef) {
            updateButtonsVisibility(); // Update button visibility on mount
            currentRef.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", handleResize);

            return () => {
                currentRef.removeEventListener("scroll", handleScroll);
                window.removeEventListener("resize", handleResize);
            };
        }
    }, [tabComponents, tabTitles]); // Include all relevant dependencies

    // Accordion state
    const [accordionOpen, setAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    const customHandleScroll = () => {
        if (accordionOpen) {
            setAccordionOpen(false);
        }
        handleScroll();
    };

    useEffect(() => {
        let oid = decryptText(params.loanId);
        loanId = oid;
        const fetchData = async () => {
            await Promise.all([getTypesOfBusiness(), getLoanReasonList(), getBusinessCategory(), GetBasicInfoLoanApplication(), getStates()]);
        };
        fetchData();
    }, []);


    const getStates = async () => {
        const states = await getUSAStates();
        stateLists = states;
        stateListstemp = states;
    }

    const getTypesOfBusiness = async () => {
        const data = await getMasterList("TypeOfBusness");
        if (data && data.status == 200) {
            let responseData1 = JSON.parse(data.resultObject);
            const desiredOrder = ["Sole Proprietor", "Limited Liability Company (LLC)", "C-Corporation", "Partnership", "Independent Contractor", "S-Corporation"];
            responseData1.sort((a, b) => {
                return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
            });

            TypeOfBusnessOptionsTemp = responseData1;
            setTypeOfBusnessOptions(responseData1);
        }
    }

    const getBusinessCategory = async () => {
        let data = await getMasterList("BusinessCategories");
        if (data && data.status == 200) {
            let responseData2 = JSON.parse(data.resultObject);
            const desiredOrder = ["Contractor", "Gas Station", "Hotel", "Medical", "Restaurant", "Transportation", "Others"];
            responseData2.sort((a, b) => {
                return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
            });
            BusinessCategoriesOptionsTemp = responseData2;
            setBusinessCategoriesOptions(responseData2);
        }
    }

    const getLoanReasonList = async () => {
        const data = await getMasterList("LoanReason");
        if (data && data.status == 200) {
            let responseData1 = JSON.parse(data.resultObject);
            const desiredOrder = [
                "Accounts Receivables Financing",
                "Business Acquisition",
                "Business Expansion",
                "Construction or Remodeling",
                "Equipment Financing",
                "Franchise Financing",
                "Partner Buyout",
                "Real Estate Financing",
                "Refinance Existing Debt",
                "Start a Business",
                "Working Capital",
                "Other"
            ];
            responseData1.sort((a, b) => {
                return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
            });

            LoanReasonOptionsTemp = responseData1;
            setLoanReasonOptions(responseData1);
        }
    }


    const userdetails = location.state?.data
    const [detailLoan, setDetailLoan] = useState()
    const [firstEmail, setFirstEmail] = useState()

    const GetBasicInfoLoanApplication = async () => {
        try {
            BasicInfoForm.reset();
            if (loanId != "" && parseInt(loanId) > 0) {

                let queryObj = { Id: loanId, SessionId: getSessionId() };
                const modelBasicInfoDetails = await GetBasicInfoLoanApplicationById(queryObj);

                if (modelBasicInfoDetails.status == 200) {
                    let finalData = modelBasicInfoDetails.resultObject
                        ? JSON.parse(modelBasicInfoDetails.resultObject)
                        : "";

                    setDetailLoan(finalData)

                    if (finalData[0] && finalData[0].BusinessCategory !== "undefined" && finalData[0].BusinessCategory.length > 0) {
                        setFormData(prevState => ({
                            ...prevState,
                            BusinessCategory: {
                                label: finalData[0].BusinessCategory,
                                value: finalData[0].BusinessCategory
                            }
                        }));
                    }

                    setIsHiddenBusinessCategaryCaparision({ value: finalData[0].BusinessCategory })
                    setIsHiddenBusinessCategary({ value: finalData[0].BusinessCategory })
                    if (finalData && Array.isArray(finalData) && finalData.length > 0) {
                        BasicInfoForm.patchValue({
                            BusinessName: finalData[0].BusinessName,
                            DBA: finalData[0].DBA,
                            TypeOfBusiness: finalData[0].TypeOfBusiness,
                            BusinessCategory: finalData[0].BusinessCategory,
                            TaxID: finalData[0].TaxID.replace(/^(\d{2})(\d+)$/, '$1-$2'),
                            BusinessContactNo: finalData[0].BusinessContactNo.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
                            NAICSCode: finalData[0].NAICSCode,
                            BorrowAmount: finalData[0].BorrowAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            BusinessEmail: finalData[0].BusinessEmail,
                            Purpose: finalData[0].Purpose,
                            BorrowerFirstName: finalData[0].BorrowerFirstName,
                            BorrowerLastName: finalData[0].BorrowerLastName,
                            ApplicationNumber: finalData[0].ApplicationNumber,
                            ApplicationStatus: finalData[0].ApplicationStatus,
                            BorrowerContactNumber: finalData[0].BorrowerContactNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
                            BorrowerName: finalData[0].BorrowerFirstName,
                            VerfiedStatus: finalData[0].VerfiedStatus,
                            UserId: finalData[0].UserId,
                        });

                        const userId = finalData[0].UserId;
                        setNewApplicationData(userId);

                        for (let elem = 0; elem < TypeOfBusnessOptionsTemp.length; elem++) {
                            let elemvalue = TypeOfBusnessOptionsTemp[elem];
                            if (elemvalue.value == finalData[0].TypeOfBusiness) {
                                let key =
                                    elemvalue.Identifier == "TypeOfBusness"
                                        ? "TypeOfBusiness"
                                        : null;
                                BasicInfoForm.patchValue({
                                    [key]: elemvalue,
                                });
                            }
                        }

                        for (let elem = 0; elem < BusinessCategoriesOptionsTemp.length; elem++) {
                            let elemvalue = BusinessCategoriesOptionsTemp[elem];
                            if (elemvalue.value == finalData[0].BusinessCategory) {
                                let key =
                                    elemvalue.Identifier == "BusinessCategories"
                                        ? "BusinessCategory"
                                        : null;
                                BasicInfoForm.patchValue({
                                    [key]: elemvalue,
                                });
                            }
                        }

                        for (let elem = 0; elem < LoanReasonOptionsTemp.length; elem++) {
                            let elemvalue = LoanReasonOptionsTemp[elem];
                            if (elemvalue.value == finalData[0].Purpose) {
                                let key =
                                    elemvalue.Identifier == "LoanReason" ? "Purpose" : null;
                                BasicInfoForm.patchValue({
                                    [key]: elemvalue,
                                });
                            }
                        }
                        setLoading(false);
                    }
                }
            }
        } catch (err) {
            console.log(err);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    };

    const [sentEmailPopupShow, setSentEmailPopup] = useState(false);

    const ChangePreviusTab = () => {

        setFormData({ BusinessCategory: null })
        const previousData = BusinessCategoriesOptions.find(data => data.value === isHiddenBusinessCategaryCaparision.value);
        setNewTabPopupShow(false);

        setFormData(prevState => ({
            ...prevState,
            BusinessCategory: previousData // Store the entire previousData object
        }));

        setIsHiddenBusinessCategary({ value: isHiddenBusinessCategaryCaparision.value });
    };

    const ChangeNewTab = () => {
        setNewTabPopupShow(false)
    }
    const sentEmailPopupConfirm = () => {
        Emailsender()
        setSentEmailPopup(false)
    }
    const sentEmailPopupClose = () => {
        handleSubmit("AddNewmailPreviusMail")
        setSentEmailPopup(false);
    }
    const Emailsender = async () => {
        handleSubmit("AddNewmail")
        let queryObj = {
            LoanId: loanId,
            BorrowerEmail: firstEmail,
            SessionId: getSessionId()
        };

        UpdateBorrowerEmailAddress(queryObj)
            .then((data) => {
                setLoading(false);
                if (data.status == 200) {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
                else {
                    setLoading(false);
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            });

    }

    const handleSubmit = (e) => {
        if (e !== "AddNewmail" && e !== "AddNewmailPreviusMail") {
            e?.preventDefault();
        }
        let validate = BasicInfoForm.value

        if (BasicInfoForm.status !== "INVALID" && validate.BorrowAmount !== "" && validate.BorrowerLastName !== ""
            && validate.BorrowerFirstName !== ""
            && validate.BorrowerContactNumber !== ""
            && contactTaxId == "" && contactPhone == ""

            && contactNum == ""
            && contactEmail == ""

        ) {
            if (e !== "AddNewmail" && e !== "AddNewmailPreviusMail") {
                if (validate.BusinessEmail !== detailLoan[0].BusinessEmail) {
                    return setSentEmailPopup(true)
                }


            }
            setLoading(true);

            let finalObj = {
                ...BasicInfoForm.value,
                IsFlagSetForBusinessCategoryChange_BasicInfo: false,
                // BusinessCategory : finalObj.BusinessCategory.value,
                UserId: 1,
                Id: loanId,
                SessionId: getSessionId(),
                modifiedBy: JSON.parse(localStorage.getItem("user"))?.id || 0
            };
            if (e == "AddNewmailPreviusMail" || e == "AddNewmail") {
                finalObj = {
                    ...finalObj,
                    BusinessEmail: detailLoan[0].BusinessEmail,
                    TypeOfBusiness: BasicInfoForm.value.TypeOfBusiness.value,
                    BusinessCategory: formData.BusinessCategory === null ? "" : formData.BusinessCategory?.value,
                    Purpose: BasicInfoForm.value.Purpose.value,

                };
            } else {
                finalObj = {
                    ...finalObj,
                    TypeOfBusiness: BasicInfoForm.value.TypeOfBusiness.value,
                    BusinessCategory: formData.BusinessCategory === null ? "" : formData.BusinessCategory?.value,
                    Purpose: BasicInfoForm.value.Purpose.value,
                };

            }

            InsertUpdateBasicInfo(finalObj)
                .then((data) => {
                    setLoading(false);
                    if (data.status == 200) {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Basic information has been updated successfully", type: "success", }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    }
                    else {
                        setLoading(false);
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(BasicInfoForm);
        }
    };

    // Marking all input fields as touched if the form is not valid
    const markAllAsTouched = (formGroup) => {
        if (objectiveRef.current) objectiveRef.current.focus();
        if (businessRef.current) businessRef.current.focus();
        if (categoryRef.current) categoryRef.current.focus();

        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control) {
                control.active = true;
                control.touched = true;
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", customHandleScroll);
        return () => window.removeEventListener("scroll", customHandleScroll);
    }, [accordionOpen]);

    const accordionClass = accordionOpen
        ? "open-basic-form-accordion"
        : "closed-basic-form-accordion";
    const toggleButton = accordionOpen ? "show-btn" : "hide-btn";

    //customized  Popup function
    const [isModalOpen, setApplicationPopup] = useState(false);

    const applicationPopupShow = () => {
        setApplicationPopup(true);
        document.body.style.overflow = "hidden"; // Prevent scrolling
    };

    const applicationPopupClose = () => {
        setApplicationPopup(false);
        document.body.style.overflow = ""; // Allow scrolling
    };

    // datepicker function
    const [startDate, setStartDate] = useState(new Date());
    const StateHandlerData = () => {
        dispatch(reducersAndActions.actions.profileAction.stateUpdateReducer({ stateUpdate: "upadte", value: true }));

    }
    const ConfirmEmail = () => {
        let queryObj = {
            Id: detailLoan[0].Id,
            SessionId: getSessionId(),
        };

        axiosInstance.get(`/api/LoanApplication/ResendUserInvitation?LoanId=${detailLoan[0].Id}&SessionId=${getSessionId()}&WebURL=${Constants.FRONTEND_URL}`)

            .then((data) => {
                setLoading(false);
                if (data.status == 200) {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.data.message, type: "success", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
                else {
                    setLoading(false);
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            });
    }

    let classMapper = {
        "NEW": "new",
        "New": "new",
        "Documents Required": "document-required",
        "On Hold": "new",
        "Review Required": "review-required",
        "Closed": "closed",
        "Completed": "closed",
        "In Progress": "in-progress",
    };
    function convertFirstLetterToLowercase(str) {
        if (!str) return str; // Check if the string is empty or undefined
        return str.charAt(0).toLowerCase() + str.slice(1);
    }
    const convertedStatus = convertFirstLetterToLowercase(BasicInfoForm.value.ApplicationStatus);

    const handleNewApplication = async (event) => {
        event.preventDefault();
        if (NewApplicationFormGroup.status !== "INVALID") {
            setLoading(true);
            let finalObj = {
                UserId: newApplicationData,
                Borrowamount: NewApplicationFormGroup.value.Borrowamount.replace(/,/g, ""),
                Loandate: NewApplicationFormGroup.value.Loandate,
                purpose: NewApplicationFormGroup.value.purpose.value,
                TypeOfbusiness: NewApplicationFormGroup.value.TypeOfbusiness.value,
                Businessname: NewApplicationFormGroup.value.Businessname,
                BState: NewApplicationFormGroup.value.BState.value,
                BCity: NewApplicationFormGroup.value.BCity,
                Category: NewApplicationFormGroup.value.Category || "",
            };

            InsertOrUpdateNewLoanExistingUser(finalObj)
                .then((data) => {
                    if (data.status == 200) {
                        setLoading(false);
                        applicationPopupClose();
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    } else {
                        setLoading(false);
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(NewApplicationFormGroup);
        }
    }

    const userId = localStorage.getItem('user');
    const userdata = userId ? JSON.parse(userId) : null;
    const userRole = userdata?.roleName || '';

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <div className="dashboard-wrap application-detail">
                        {/* <!--Header Progress Bar --> */}
                        <div className="lm-progress-bar">
                            <div className="lm-progress"></div>
                        </div>
                        {/* <!--Header Progress Bar --> */}

                        {/* <!-- appliaction header sec start  --> */}
                        <div className="appliaction-header padding-reg pt-0 pb-0">
                            {/* <!-- appliaction breadcrumb   --> */}
                            <div className="lm-breadcrumb d-flex pt-4" onClick={StateHandlerData}>
                                <Link className="back-btn mr-2" to="/loan-application">
                                    <img src={back_arrow} alt="" />
                                </Link>
                                <Link
                                    className="text-light mr-1 cursor-pointer"
                                    to="/loan-application"
                                >
                                    Loan Applications
                                </Link>
                                <img src={brdcrm_nav} alt="" />
                                <span className="text-dark ml-1">
                                    {BasicInfoForm.value.ApplicationNumber}
                                </span>
                            </div>
                            {/* <!-- appliaction breadcrumb   --> */}

                            {/* <!-- basic information form start  --> */}
                            <div
                                id="dynamic_sticky_form"
                                className={`dynamic_sticky_form ${isHidden ? "hide-sticky-form" : ""
                                    }`}
                            >

                                <div className="header-btn-sec">
                                    <div className="d-flex">
                                        <h1 className="appliaction-no mr-2">
                                            {BasicInfoForm.value.ApplicationNumber}
                                        </h1>
                                        <div className={`status ${classMapper[BasicInfoForm.value.ApplicationStatus]}`}>

                                            {BasicInfoForm.value.ApplicationStatus}
                                        </div>
                                    </div>
                                    <div className="d-flex header-btns">
                                        {BasicInfoForm.value.VerfiedStatus == "False" && (<button type="button" onClick={ConfirmEmail} className="lm-button2">
                                            <img src={verify_icon_blue} alt="" /> Confirm e-mail
                                            address
                                        </button>)}

                                        {userRole !== 'Borrower' && (
                                            <button
                                                onClick={applicationPopupShow}
                                                type="button"
                                                className="lm-button2"
                                            >
                                                <img src={application_icon_blue} alt="" /> Add application
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className={`lm-button1 ${toggleButton}`}
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <div className="accordion">
                                    <div
                                        className={`cursor-pointer form-accordian-button ${accordionClass}`}
                                        onClick={toggleAccordion}
                                    >
                                        Show Basic Information
                                    </div>
                                    {accordionOpen && (
                                        <div
                                            className={`basic-info-form ${accordionOpen ? "open" : "closed"
                                                }`}
                                        >
                                            <FieldGroup
                                                control={BasicInfoForm}
                                                render={({ get, invalid }) => (
                                                    <form action="">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group input-dollar">
                                                                    <FieldControl
                                                                        name="BorrowAmount"
                                                                        render={({
                                                                            handler,
                                                                            touched,
                                                                            hasError,
                                                                            meta,
                                                                        }) => {
                                                                            const handlerProps = handler();
                                                                            const handleChange = (e) => {


                                                                                const rawValue = e.target.value.replace(
                                                                                    /,/g,
                                                                                    ""
                                                                                ); // Remove existing commas
                                                                                const formattedValue =
                                                                                    formatNumberWithCommas(rawValue);
                                                                                e.target.value = formattedValue;
                                                                                if (handlerProps.onChange) {
                                                                                    handlerProps.onChange(e);
                                                                                }
                                                                            };

                                                                            return (
                                                                                <div className="form-group input-dollar">
                                                                                    <label htmlFor="BorrowAmount">
                                                                                        How much would you like to borrow?
                                                                                        <span className="asterisk">*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="BorrowAmount"
                                                                                        placeholder="400,000.00"
                                                                                        {...handlerProps}
                                                                                        onChange={handleChange}
                                                                                        required={true}
                                                                                    />

                                                                                    {/* Error messages below */}
                                                                                    {touched && hasError("required") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Borrow amount is required
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {
                                                                                        hasError("borrowAmountIssue") && (
                                                                                            <>
                                                                                                <span className="error-message">
                                                                                                    Amount should be greater than
                                                                                                    $25,000
                                                                                                </span>
                                                                                                <br />
                                                                                            </>
                                                                                        )}
                                                                                    {hasError("pattern") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Amount should be only numeric
                                                                                                values
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {
                                                                                        hasError(
                                                                                            "borrowAmountMaxIssue"
                                                                                        ) && (
                                                                                            <>
                                                                                                <span className="error-message">
                                                                                                    Amount should be less than or
                                                                                                    equal to 10000000000
                                                                                                </span>
                                                                                                <br />
                                                                                            </>
                                                                                        )}
                                                                                </div>
                                                                            );
                                                                        }}
                                                                        meta={{ placeholder: "400,000.00" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    <FieldControl
                                                                        name="Purpose"
                                                                        render={({
                                                                            handler,
                                                                            touched,
                                                                            hasError,
                                                                            meta,
                                                                        }) => (
                                                                            <div className="form-group">
                                                                                <label htmlFor="Purpose">
                                                                                    How will you use the loan?{" "}
                                                                                    <span className="asterisk">*</span>
                                                                                </label>
                                                                                <Select
                                                                                    className="basic-single"
                                                                                    classNamePrefix="select"
                                                                                    id="Purpose"
                                                                                    name="Purpose"
                                                                                    menuPortalTarget={document.body}
                                                                                    styles={{
                                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                                    }}

                                                                                    ref={objectiveRef}
                                                                                    options={LoanReasonOptions}
                                                                                    required={true}
                                                                                    {...handler()}
                                                                                />

                                                                                {/* Error messages below */}
                                                                                <span className="error-message">
                                                                                    {touched &&
                                                                                        hasError("required") &&
                                                                                        `Objective is required`}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group">
                                                                    <label htmlFor="BusinessCategory"> Business Category </label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        id="BusinessCategory"
                                                                        name="BusinessCategory"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}

                                                                        options={BusinessCategoriesOptions}
                                                                        defaultValue={formData.BusinessCategory}
                                                                        //   placeholder={formData.BusinessCategory.label}
                                                                        //   value={formData.BusinessCategory.value} // Ensure this matches the structure in state
                                                                        onChange={(selectedOption) => handleSelectChange("BusinessCategory", selectedOption)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    <FieldControl
                                                                        name="TypeOfBusiness"
                                                                        render={({
                                                                            handler,
                                                                            touched,
                                                                            hasError,
                                                                            meta,
                                                                        }) => (
                                                                            <div className="form-group">
                                                                                <label htmlFor="TypeOfBusiness">
                                                                                    Type of Business{" "}
                                                                                    <span className="asterisk">*</span>
                                                                                </label>
                                                                                <Select
                                                                                    className="basic-single"
                                                                                    classNamePrefix="select"
                                                                                    id="TypeOfBusiness"
                                                                                    name="TypeOfBusiness"
                                                                                    menuPortalTarget={document.body}
                                                                                    styles={{
                                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                                    }}

                                                                                    ref={businessRef}
                                                                                    required={true}
                                                                                    options={TypeOfBusnessOptions}
                                                                                    {...handler()}
                                                                                />
                                                                                {/* Error messages below */}
                                                                                <span className="error-message">
                                                                                    {touched &&
                                                                                        hasError("required") &&
                                                                                        `Business type is required`}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    {/* <label htmlFor="business_name">Business name </label>
                                                        <input type="text" className="form-control" id="business_name" placeholder="Enter Business name" /> */}
                                                                    <FormField
                                                                        name="BusinessName"
                                                                        label="Business Name"
                                                                        placeholder="Enter Business Name"

                                                                        id="BusinessName"
                                                                        required={true}
                                                                        errorMessage={"Business Name is required"}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="row">
                                                                    <div className="col-6 pr-2">
                                                                        <div className="form-group">
                                                                            {/* <label htmlFor="NAICS_code">NAICS Code </label>
                                                                <input type="text" className="form-control" id="NAICS_code" placeholder="Enter NAICS Code" /> */}
                                                                            {/* <FormField
                                                                                name="NAICSCode"
                                                                                label="NAICS Code"
                                                                                placeholder="Enter NAICS Code"
                                                                                id="NAICSCode"
                                                                                required={false}
                                                                                
                                                                                errorMessage={""}
                                                                            /> */}
                                                                            <FieldControl
                                                                                name="NAICSCode"
                                                                                render={({
                                                                                    handler,
                                                                                    touched,
                                                                                    hasError,
                                                                                    meta,
                                                                                }) => {
                                                                                    const handlerProps = handler();
                                                                                    const standardizeTaxID = (e) => {
                                                                                        let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                                                                        // Truncate value to a maximum of 14 characters
                                                                                        if (value.length > 9) {
                                                                                            value = value.slice(0, 9);
                                                                                        }

                                                                                        e.target.value = value;

                                                                                        if (handlerProps.onChange) {
                                                                                            handlerProps.onChange(e);
                                                                                        }

                                                                                        if (value.length === 0) {
                                                                                            setNaics("");
                                                                                        }
                                                                                    };

                                                                                    return (
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="NAICSCode">
                                                                                                NAICS Code
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="NAICSCode"
                                                                                                label="NAICS Code"
                                                                                                placeholder="44110"
                                                                                                id="NAICSCode"
                                                                                                {...handlerProps}
                                                                                                onChange={standardizeTaxID}
                                                                                            />

                                                                                            {/* Error messages below */}
                                                                                            <span className="error-message">
                                                                                                {touched &&
                                                                                                    hasError("required") &&
                                                                                                    `NAICSCode is required`}
                                                                                            </span>
                                                                                            {contactTaxId && (
                                                                                                <>
                                                                                                    <span className="error-message">
                                                                                                        {naics}
                                                                                                    </span>
                                                                                                    <br />
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                }}
                                                                                meta={{ placeholder: "47-113478" }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 pl-2">
                                                                        <div className="form-group">
                                                                            {/* <label htmlFor="dba">D/B/A </label>
                                                                <input type="text" className="form-control" id="dba" placeholder="Enter d/b/a" /> */}
                                                                            <FormField
                                                                                name="DBA"
                                                                                label="D/B/A"
                                                                                placeholder="Enter d/b/a"
                                                                                id="DBA"
                                                                                required={false}
                                                                                errorMessage={""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    {/* <label htmlFor="borrower_first_name">Borrower First name </label>
                                                        <input type="text" className="form-control" id="borrower_first_name" placeholder="Enter First name" /> */}
                                                                    <FormField
                                                                        name="BorrowerFirstName"
                                                                        label="Borrower First Name"
                                                                        placeholder="Borrower First Name"
                                                                        id="BorrowerFirstName"
                                                                        required={true}
                                                                        errorMessage={
                                                                            "Borrower First name is required"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    {/* <label htmlFor="borrower_last_name">Borrower Last name </label>
                                                        <input type="text" className="form-control" id="borrower_last_name" placeholder="Enter Last name" /> */}
                                                                    <FormField
                                                                        name="BorrowerLastName"
                                                                        label="Borrower Last Name"
                                                                        placeholder="Borrower Last Name"
                                                                        id="BorrowerLastName"
                                                                        required={true}
                                                                        errorMessage={
                                                                            "Borrower Last name is required"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    <FieldControl
                                                                        name="BorrowerContactNumber"
                                                                        render={({
                                                                            handler,
                                                                            touched,
                                                                            hasError,
                                                                            meta,
                                                                        }) => {
                                                                            const handlerProps = handler();

                                                                            const standardizePhoneNumber = (e) => {
                                                                                let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                                                                // Check if the user is trying to delete (backspace)
                                                                                if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                                                                    if (handlerProps.onChange) {
                                                                                        handlerProps.onChange(e);  // Update the input without reformatting
                                                                                    }
                                                                                    return;
                                                                                }

                                                                                // Format value as (XXX) XXX-XXXX when not deleting
                                                                                if (value.length > 2) {
                                                                                    value = "(" + value.slice(0, 3) + ')' + value.slice(3, 6) + "-" + value.slice(6, 10);
                                                                                }

                                                                                // Update the input value
                                                                                e.target.value = value;
                                                                                if (handlerProps.onChange) {
                                                                                    handlerProps.onChange(e);
                                                                                }

                                                                                // Validate the phone number pattern
                                                                                const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
                                                                                const input = e.target.value;
                                                                                const numericInput = input.replace(/\D/g, '');

                                                                                if (input.length === 0) {
                                                                                    setContactPhone("");
                                                                                } else if (/[^0-9()-.\s]/.test(input)) {
                                                                                    setContactPhone("Text is not allowed in the Business Phone Number.");
                                                                                    return;
                                                                                } else if (numericInput.length !== 10) {
                                                                                    setContactPhone("Business Phone Number should be of 10 digits.");
                                                                                } else {
                                                                                    setContactPhone("");
                                                                                }
                                                                            };

                                                                            return (
                                                                                <div className="form-group">
                                                                                    <label htmlFor="BorrowerContactNumber">
                                                                                        Borrower Contact Number
                                                                                        <span className="asterisk">*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="BorrowerContactNumber"
                                                                                        placeholder="(877) 847-1134"
                                                                                        {...handlerProps}
                                                                                        onChange={standardizePhoneNumber}
                                                                                    />

                                                                                    {/* Error messages below */}
                                                                                    {touched && hasError("required") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Borrower Contact Number is required
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {contactNum && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                {contactNum}
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {touched && hasError("pattern") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Borrower Contact Number should be of numeric value.
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        }}
                                                                        meta={{ placeholder: "(877) 847-1134" }}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    {/* <label htmlFor="email">Borrower Email address </label>
                                                        <input type="email" className="form-control" id="email" placeholder="Enter Email address" /> */}
                                                                    {/* <FormField
                                                                        name="BusinessEmail"
                                                                        label="Borrower Email address"
                                                                        placeholder="Enter Email address"
                                                                        id="BusinessEmail"
                                                                        required={true}
                                                                        errorMessage={"Business Name is required"}
                                                                    /> */}
                                                                    <FieldControl
                                                                        name="BusinessEmail"
                                                                        render={({
                                                                            handler,
                                                                            touched,
                                                                            hasError,
                                                                            meta,
                                                                        }) => {
                                                                            const handlerProps = handler();
                                                                            const validateEmail = (e) => {
                                                                                const email = e.target.value;
                                                                                setFirstEmail(email);

                                                                                // Regular expression for basic email validation
                                                                                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                                                                const isValidEmail = emailPattern.test(email);

                                                                                if (handlerProps.onChange) {
                                                                                    handlerProps.onChange(e);
                                                                                }

                                                                                // Optionally handle invalid email case
                                                                                if (!isValidEmail) {
                                                                                    setContactEmail("Borrower Email Address is invalid.");
                                                                                } else {
                                                                                    setContactEmail("");
                                                                                }
                                                                            };

                                                                            return (
                                                                                <div className="form-group">
                                                                                    <label htmlFor="BusinessEmail">
                                                                                        Borrower Email Address
                                                                                        <span className="asterisk">*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        type="email"
                                                                                        className="form-control"
                                                                                        id="BusinessEmail"
                                                                                        placeholder="Enter Email address"
                                                                                        {...handlerProps}
                                                                                        onChange={validateEmail}
                                                                                    />

                                                                                    {/* Error messages below */}
                                                                                    {touched && hasError("required") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Borrower Email Address is required
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {contactEmail && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                {contactEmail}
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {touched && hasError("invalidEmail") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Borrower Email Address is invalid.
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        }}
                                                                        meta={{ placeholder: "example@example.com" }}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    {/* <label htmlFor="phone_number">Borrower Phone number </label>
                                                        <input type="text" className="form-control" id="phone_number" placeholder="Enter Phone number" /> */}
                                                                    <FieldControl
                                                                        name="BusinessContactNo"
                                                                        render={({
                                                                            handler,
                                                                            touched,
                                                                            hasError,
                                                                            meta,
                                                                        }) => {
                                                                            const handlerProps = handler();
                                                                            const standardizePhoneNumber = (e) => {
                                                                                let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                                                                // Check if the user is trying to delete (backspace)
                                                                                if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                                                                    if (handlerProps.onChange) {
                                                                                        handlerProps.onChange(e);  // Update the input without reformatting
                                                                                    }
                                                                                    return;
                                                                                }

                                                                                // Format value as (XXX) XXX-XXXX when not deleting
                                                                                if (value.length > 2) {
                                                                                    value = "(" + value.slice(0, 3) + ')' + value.slice(3, 6) + "-" + value.slice(6, 10);
                                                                                }

                                                                                // Update the input value
                                                                                e.target.value = value;
                                                                                if (handlerProps.onChange) {
                                                                                    handlerProps.onChange(e);
                                                                                }

                                                                                // Validate the phone number pattern
                                                                                const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
                                                                                const input = e.target.value;
                                                                                const numericInput = input.replace(/\D/g, '');

                                                                                if (input.length === 0) {
                                                                                    setContactPhone("");
                                                                                } else if (/[^0-9()-.\s]/.test(input)) {
                                                                                    setContactPhone("Text is not allowed in the Business Phone Number.");
                                                                                    return;
                                                                                } else if (numericInput.length !== 10) {
                                                                                    setContactPhone("Business Phone Number should be of 10 digits.");
                                                                                } else {
                                                                                    setContactPhone("");
                                                                                }
                                                                            };


                                                                            return (
                                                                                <div className="form-group">
                                                                                    <label htmlFor="BusinessContactNo">
                                                                                        Business Phone Number
                                                                                        <span className="asterisk">*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="BusinessContactNo"
                                                                                        placeholder="(877) 847-1134"
                                                                                        {...handlerProps}
                                                                                        onChange={standardizePhoneNumber}
                                                                                    />

                                                                                    {/* Error messages below */}
                                                                                    {touched && hasError("required") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Business Phone Number is
                                                                                                required
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {contactPhone && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                {contactPhone}
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {touched && hasError("pattern") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Business Phone Number should be
                                                                                                of numeric value.
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        }}
                                                                        meta={{ placeholder: "(877) 847-1134" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    {/* <label htmlFor="phone_number">Borrower Phone number </label>
                                                        <input type="text" className="form-control" id="phone_number" placeholder="Enter Phone number" /> */}
                                                                    <FieldControl
                                                                        name="TaxID"
                                                                        render={({
                                                                            handler,
                                                                            touched,
                                                                            hasError,
                                                                            meta,
                                                                        }) => {
                                                                            const handlerProps = handler();
                                                                            const standardizeTaxID = (e) => {
                                                                                let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                                                                // Format value as XX-XXXXXXX
                                                                                if (value.length > 2) {
                                                                                    value = value.slice(0, 2) + '-' + value.slice(2, 9); // Limit to 9 digits
                                                                                }

                                                                                // Update the input value
                                                                                e.target.value = value;
                                                                                if (handlerProps.onChange) {
                                                                                    handlerProps.onChange(e);
                                                                                }

                                                                                const taxIDPattern = /^\d{2}-\d{7}$/;
                                                                                if (value.length === 0) {
                                                                                    setContactTaxId("");
                                                                                } else if (!taxIDPattern.test(value)) {
                                                                                    setContactTaxId("Tax ID should be of 9 digits.");
                                                                                } else {
                                                                                    setContactTaxId("");
                                                                                }
                                                                            };

                                                                            return (
                                                                                <div className="form-group">
                                                                                    <label htmlFor="TaxID">
                                                                                        Tax ID
                                                                                        <span className="asterisk">*</span>
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        name="TaxID"
                                                                                        label="Business Tax ID"
                                                                                        placeholder="Enter Business Tax ID"
                                                                                        id="TaxID"
                                                                                        {...handlerProps}
                                                                                        onChange={standardizeTaxID}
                                                                                    />

                                                                                    {/* Error messages below */}
                                                                                    {touched && hasError("required") && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                Business Tax ID is required
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                    {contactTaxId && (
                                                                                        <>
                                                                                            <span className="error-message">
                                                                                                {contactTaxId}
                                                                                            </span>
                                                                                            <br />
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        }}
                                                                        meta={{ placeholder: "47-113478" }}
                                                                    />


                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-4">
                                                                <div className="form-group">
                                                                    {/* <label htmlFor="tax_id">Business Tax ID </label>
                                                        <input type="text" className="form-control" id="tax_id" placeholder="Enter Business Tax ID" /> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>

                            </div>
                            {/* <!-- basic information form end  --> */}

                            {/* <!-- loan application tabs start  --> */}
                            <div className="lm-nav-tabs application-tabs">
                                {showLeftButton && (
                                    <div id="slideLeft" onClick={scrollLeft}>
                                        <img src={scroll_left_icon} alt="" />
                                    </div>
                                )}
                                <ul
                                    className="nav nav-tabs"
                                    id="laTab"
                                    ref={laTabRef}
                                    style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                                >
                                    {Object.keys(tabComponents).map((tabId) => (
                                        <li className="nav-item" key={tabId}>
                                            <button
                                                className={`nav-link ${activeTab === tabId ? "active" : ""
                                                    }`}
                                                onClick={() => handleTabClick(tabId)}
                                            >
                                                {tabTitles[tabId]}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                {showRightButton && (
                                    <div id="slideRight" onClick={scrollRight}>
                                        <img src={scroll_right_icon} alt="" />
                                    </div>
                                )}
                            </div>

                            {/* <!-- loan application tabs end  --> */}
                        </div>
                        {/* <!-- appliaction header sec end  --> */}

                        {/* <!-- loan application tab content start  --> */}
                        <div className="application-wrapper padding-reg">
                            <div className="tab-content" id="laTabContent">
                                {activeTab && (
                                    <div
                                        className={`tab-pane fade show active`}
                                        id={activeTab}
                                    >
                                        {tabComponents[activeTab] == undefined ? tabComponents["affiliates"] : tabComponents[activeTab]}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <!-- loan application tab content start  --> */}
                    </div>

                    {/*Add New Application Popup / Modal */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}

                    <div
                        className={`custom-modal cust-modal-lg modal-open modal-backdrop ${isModalOpen ? "cust-modal-show" : "cust-modal-hide"
                            }`}
                    >
                        <div className="modal-box">
                            <div className="modal-header">
                                <h5 className="modal-title" id="applicationPopupLabel">
                                    New application
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={applicationPopupClose}
                                >
                                    <span aria-hidden="true">
                                        <img src={close_popup} alt="" />
                                    </span>
                                </button>
                            </div>
                            <form>
                                <FieldGroup
                                    control={NewApplicationFormGroup}
                                    render={({ get, invalid }) => (
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group input-dollar">
                                                        <FieldControl
                                                            name="Borrowamount"
                                                            render={({
                                                                handler,
                                                                touched,
                                                                hasError,
                                                                meta,
                                                            }) => {
                                                                const handlerProps = handler();
                                                                const handleChange = (e) => {


                                                                    const rawValue = e.target.value.replace(
                                                                        /,/g,
                                                                        ""
                                                                    ); // Remove existing commas
                                                                    const formattedValue =
                                                                        formatNumberWithCommas(rawValue);
                                                                    e.target.value = formattedValue;
                                                                    if (handlerProps.onChange) {
                                                                        handlerProps.onChange(e);
                                                                    }
                                                                };

                                                                return (
                                                                    <div className="form-group input-dollar">
                                                                        <label htmlFor="Borrowamount">
                                                                            How much would you like to borrow?
                                                                            <span className="asterisk">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="Borrowamount"
                                                                            placeholder="400,000.00"
                                                                            {...handlerProps}
                                                                            onChange={handleChange}
                                                                            required={true}
                                                                        />

                                                                        {/* Error messages below */}
                                                                        {touched && hasError("required") && (
                                                                            <>
                                                                                <span className="error-message">
                                                                                    Borrow amount is required
                                                                                </span>
                                                                                <br />
                                                                            </>
                                                                        )}
                                                                        {
                                                                            hasError("borrowAmountIssue") && (
                                                                                <>
                                                                                    <span className="error-message">
                                                                                        Amount should be greater than
                                                                                        $25,000
                                                                                    </span>
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                        {hasError("pattern") && (
                                                                            <>
                                                                                <span className="error-message">
                                                                                    Amount should be only numeric
                                                                                    values
                                                                                </span>
                                                                                <br />
                                                                            </>
                                                                        )}
                                                                        {
                                                                            hasError(
                                                                                "borrowAmountMaxIssue"
                                                                            ) && (
                                                                                <>
                                                                                    <span className="error-message">
                                                                                        Amount should be less than or
                                                                                        equal to 10000000000
                                                                                    </span>
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                );
                                                            }}
                                                            meta={{ placeholder: "400,000.00" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="Loandate"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group input-date">
                                                                <label htmlFor="date">Choose the date by which you want to get the loan<span className="asterisk">*</span></label>
                                                                <DatePicker
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                    {...handler()}
                                                                    selected={NewApplicationFormGroup.value.Loandate ? new Date(NewApplicationFormGroup.value.Loandate) : null}
                                                                    isClearable={true}
                                                                    id='Loandate'
                                                                    placeholderText="Month DD, YYYY"
                                                                    yearDropdownItemNumber={100}
                                                                    scrollableYearDropdown
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onChange={(date) => {
                                                                        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                        handler().onChange(formattedDate);
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                                {/* Error messages below */}
                                                                {touched && hasError("required") && (
                                                                    <span className="error-message">Business state is required</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="purpose"
                                                        render={({
                                                            handler,
                                                            touched,
                                                            hasError,
                                                            meta,
                                                        }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="purpose">
                                                                    How will you use the loan?{" "}
                                                                    <span className="asterisk">*</span>
                                                                </label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    id="purpose"
                                                                    name="purpose"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    ref={objectiveRef}
                                                                    options={LoanReasonOptions}
                                                                    onChange={selectedOption => {
                                                                        handler().onChange(selectedOption?.value);
                                                                    }}
                                                                    {...handler()}

                                                                />

                                                                {/* Error messages below */}
                                                                {touched && hasError("required") && (
                                                                    <span className="error-message">Loan Reason is required</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="TypeOfbusiness"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="TypeOfbusiness">
                                                                    Type of Business <span className="asterisk">*</span>
                                                                </label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    id="TypeOfbusiness"
                                                                    name="TypeOfbusiness"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    }}
                                                                    ref={businessRef}
                                                                    options={TypeOfBusnessOptions}
                                                                    onChange={(selectedOption) => {
                                                                        handler().onChange(selectedOption?.value);
                                                                    }}
                                                                    {...handler()}
                                                                />
                                                                <span className="error-message">
                                                                    {touched && hasError("required") && `Business type is required`}
                                                                </span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FormField
                                                        name="Businessname"
                                                        label="Business Name"
                                                        id="Businessname"
                                                        placeholder="Enter Business Name"
                                                        required={true}
                                                        errorMessage="Business name is required"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FormField
                                                        name="BCity"
                                                        label="Business City"
                                                        id="BCity"
                                                        placeholder="Enter a Business City"
                                                        required={true}
                                                        errorMessage="City is required"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="BState"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="business_state">Business state </label>
                                                                <span className="asterisk">*</span>
                                                                <Select
                                                                    id="BState"
                                                                    name="BState"
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    }}
                                                                    options={stateLists}
                                                                    onChange={(selectedOption) => {
                                                                        handler().onChange(selectedOption?.value);
                                                                    }}
                                                                    {...handler()}
                                                                    required={true}
                                                                />
                                                                {touched && hasError("required") && (
                                                                    <span className="error-message">Business state is required</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                />
                                <div className="modal-footer la-app-modal-footer">
                                    <button
                                        onClick={applicationPopupClose}
                                        type="button"
                                        className="lm-button2"
                                    >
                                        Cancel
                                    </button>
                                    <button type="button" onClick={handleNewApplication} className="lm-button1">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/*Add New Application Popup / Modal */}

                    {/* <!--sent an email Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={sentEmailPopupShow}
                        onHide={() => setSentEmailPopup(false)}
                        aria-labelledby="sentEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to update the email address?
                                        <br />
                                        <br />

                                        If “Yes, ” email address will be updated after you click and verify link sent to updated email address.
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={sentEmailPopupClose}>No</button>
                                    <button type="button" className="lm-button1" onClick={sentEmailPopupConfirm}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--sent an email Popup / Modal --> */}
                    {/* <!--sent an setNewTabPopupShow Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={newTabPopupShow}
                        onHide={() => setNewTabPopupShow(false)}
                        aria-labelledby="sentEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Changing business category may result in loss of documents uploaded under Advisor Generated Documents. Do you wish to continue?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={ChangePreviusTab}>No</button>
                                    <button type="button" className="lm-button1" onClick={ChangeNewTab}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--sent an setNewTabPopupShow Popup / Modal --> */}
                </div>
            )}
        </>
    );
}

export default ApplicationDetail;
