import { Header, Footer } from "@components";
import { useEffect, useState } from 'react';
import "./styles/ForgotPassword.css";
import './styles/WebsiteStyle.css';
import { Validators, FormBuilder, FieldGroup } from "react-reactive-form";
import FormField from '../components/FormField/FormField'
import { resetPassword } from "../services/commonService";
import Constants from "../helpers/Constants";
import { useDispatch, useSelector } from "react-redux";
import reducersAndActions from '../_redux/slices/index';
import { useNavigate } from "react-router-dom";
import login_error_icon from '../assets/icons/login-error-icon.svg';
import Preloader from "../plugins/Preloader";

// Reactive Form Group
const formGroup = FormBuilder.group({
  EmailAddress: ["", [Validators.email, Validators.required]],
  WebURL: [Constants.FRONTEND_URL]
});

function ForgotPassword() {
    // Meta Title
    useEffect(() => {
      document.title = "Forgot Password - Loan Mantra";
      window.scrollTo(0, 0);
    }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorbox = useSelector((state) => state.errorbox);
  const [loading, setLoading] = useState(false);

  // Submitting the form data for forgot password
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (formGroup.status !== "INVALID") {
      let requestBody = {
        ...formGroup.value,
        WebURL: Constants.FRONTEND_URL
      };
      resetPassword(requestBody).then(response => {
        if (response.status == 200) {
          let email = formGroup.value.EmailAddress;
          dispatch(reducersAndActions.actions.toasterActions.showToaster({visible: true, message: response.message, type: "success"}));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          setLoading(false);
          navigate("/success-screen", { state: { title: "Reset your password", subtitle: `Your request has been sent to ${email}`} });
          formGroup.reset();
        } else {
          setLoading(false);
          dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: response.message }));
          setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
        }
      }).catch(err => {
        setLoading(false);
        dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: err.response.data.message }));
        setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
      });
    } else {
      markAllAsTouched(formGroup);
      setLoading(false);
    }
  }

  // Marking all input fields as touched if the form is not valid
const markAllAsTouched = (formGroup) => {
  Object.keys(formGroup.controls).forEach(controlName => {
    const control = formGroup.get(controlName);
    if (control) {
      control.active = true;
      control.touched = true;
      if (document.getElementById(controlName)) {
        document.getElementById(controlName).focus();
      }
    }
  });
}

  return (
    <>
      {/* Header */}
      <Header />

      {/* Loader */}
      {loading ? <Preloader /> : null}

      {/* Main Body */}
      <div className="website-wrap">
        <section className="forgot-body">
          <div className="container">
            {/* validation-box start*/}
            {
              errorbox.visible && <div className="login-validation-box">
                <img className="mr-2" src={login_error_icon} alt="" />
                {errorbox.message}
              </div>
            }
            <div className="forgot-box">
              <h4>Reset your password</h4>
              <FieldGroup
                control={formGroup}
                render={({ get, invalid }) => (
                  <form onSubmit={handleSubmit}>
                    <FormField
                       name="EmailAddress"
                       label="Email address"
                       placeholder="Enter email"
                       id="EmailAddress"
                       required={true}
                       errorMessage={"Email is required"}
                    />
                    <button type="submit" className="btn btn-forgot" onClick={handleSubmit}>
                      Reset Via Email
                    </button>
                  </form>
                )}
              />
            </div>
          </div>
        </section>
      </div>
      
      {/* Footer */}
      <Footer />
    </>
  );
}

export default ForgotPassword;
