import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import edit_pen from '../../../../assets/icons/edit-pen.svg';
import close_popup from '../../../../assets/icons/close_popup.svg';
import moment from 'moment';

const WorkExperience = ({ onUpdate, workExperiencesData }) => {

    const [workExperiencePopupShow, setWorkExperiencePopupShow] = useState(false);
    const [experienceStartDate, setExperienceStartDate] = useState(null);
    const [experienceEndDate, setExperienceEndDate] = useState(null);
    const [workExperiences, setWorkExperiences] = useState([]);
    const [type, setType] = useState('Add');

    const [editIndex, setEditIndex] = useState(null);
    const [formData, setFormData] = useState({
        companyName: '',
        startDate: null,
        endDate: null,
        address: '',
        position: '',
        title: ''
    });

    useEffect(() => {
        setWorkExperiences(workExperiencesData);
    }, [workExperiencesData]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleDateChange = (update) => {
        setDateRange(update);
        setFormData({ ...formData, startDate: moment(update[0]).format("YYYY-MM-DD"), endDate: moment(update[1]).format("YYYY-MM-DD") });
    };

    const setDateRange = (update) => {
        const [start, end] = update;
        setExperienceStartDate(start);
        setExperienceEndDate(end);
    };

    const handleSubmit = () => {
        setWorkExperiencePopupShow(false);
        if (formData.address.length > 0 || formData.companyName.length > 0 || formData.title.length > 0 || formData.position.length > 0 || (formData.startDate !== null && formData.endDate !== null)) {
            const updatedExperiences = [...workExperiences];
            if (editIndex !== null) {
                updatedExperiences[editIndex] = formData;
            } else {
                updatedExperiences.push(formData);
            }
            setWorkExperiences(updatedExperiences);
            onUpdate(updatedExperiences);
            setFormData({
                companyName: '',
                startDate: null,
                endDate: null,
                address: '',
                position: '',
                title: ''
            });
            setExperienceStartDate(null);
            setExperienceEndDate(null);
            setEditIndex(null);
        }
    };

    const handleEdit = (index) => {
        const experience = workExperiences[index];
        setFormData(experience);
        setDateRange([experience.startDate, experience.endDate]);
        setEditIndex(index);
        setType('Edit');
        setWorkExperiencePopupShow(true);

    };

    const handleRemove = () => {
        const updatedExperiences = workExperiences.filter((_, index) => index !== editIndex);
        setWorkExperiences(updatedExperiences);
        onUpdate(updatedExperiences);
        setWorkExperiencePopupShow(false);
        setFormData({
            companyName: '',
            startDate: null,
            endDate: null,
            address: '',
            position: '',
            title: ''
        });
        setEditIndex(null);
    };

    const showAddExpericenceModal = () => {
        setFormData({
            companyName: '',
            startDate: null,
            endDate: null,
            address: '',
            position: '',
            title: ''
        });
        setEditIndex(null);
        setType('Add');
        setWorkExperiencePopupShow(true);
    };

    return (
        <div className="col-12 mt-3">
            <div className="lm-card p-0">
                <div className="d-flex justify-content-between p-3">
                    <h3 className="lm-card-heading mb-0">Work Experience</h3>
                    <a className="lm-button3" onClick={showAddExpericenceModal}><span>+</span> Add experience</a>
                </div>
                <table className="lm-inner-form-grid">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Period</th>
                            <th>Location</th>
                            <th>Responsibilities</th>
                            <th>title</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            workExperiences?.map((experience, index) => (
                                <tr key={index}>
                                    <td><b>{experience.companyName}</b></td>
                                    <td>{experience.startDate ? new Date(experience.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short' }) : ''} - {experience.endDate ? new Date(experience.endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short' }) : ''}</td>
                                    <td>{experience.address}</td>
                                    <td>{experience.position}</td>
                                    <td>{experience.title}</td>
                                    <td>
                                        <img title="Edit" className="lm-grid-edit-icon" onClick={() => handleEdit(index)} src={edit_pen} alt="Edit" />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <Modal
                size="md"
                show={workExperiencePopupShow}
                onHide={() => setWorkExperiencePopupShow(false)}
                aria-labelledby="workExperiencePopupLabel"
                backdrop="static"
                keyboard={false}
                centered
            >
                <div className="modal-dialog modal-lg modal-lg-work-exp modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="workExperiencePopupLabel">{type} Work Experience</h5>
                            <button type="button" className="close" onClick={() => setWorkExperiencePopupShow(false)}>
                                <span aria-hidden="true"><img src={close_popup} alt="Close" /></span>
                            </button>
                        </div>
                        <form>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="companyName">Company Name</label>
                                            <input type="text"
                                                className="form-control"
                                                id="companyName"
                                                value={formData.companyName}
                                                onChange={handleInputChange}
                                                maxLength={100}
                                                placeholder="Enter a Company Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group input-date two-month-datepicker">
                                            <label htmlFor="startingEndDates">Starting/End Dates</label>
                                            <DatePicker
                                                showIcon
                                                toggleCalendarOnIconClick
                                                selected={experienceStartDate}
                                                selectsRange={true}
                                                startDate={experienceStartDate}
                                                endDate={experienceEndDate}
                                                onChange={handleDateChange}
                                                monthsShown={2}
                                                isClearable={true}
                                                dateFormat="MMM d, yyyy"
                                                placeholderText="Month DD, YYYY - Month DD, YYYY"
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown
                                                showMonthDropdown
                                                showYearDropdown
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="location">Location</label>
                                            <input type="text" className="form-control"
                                                maxLength={100}
                                                id="address" value={formData.address} onChange={handleInputChange} placeholder="Enter a Location Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="position">Responsibilities</label>
                                            <textarea className="form-control" id="position"
                                                maxLength={250}
                                                value={formData.position} onChange={handleInputChange} placeholder="Enter text"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="title">Title</label>
                                            <input type="text"
                                                maxLength={100}
                                                className="form-control" id="title" value={formData.title} onChange={handleInputChange} placeholder="Enter a Title" />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <div className="modal-footer la-app-modal-footer">
                                {
                                    editIndex !== null ? (
                                        <button onClick={handleRemove} type="button" className="lm-button2 text-red">Remove</button>
                                    ) : <button type="button" className="lm-button2" onClick={() => setWorkExperiencePopupShow(false)}>Cancel</button>
                                }
                                <button onClick={handleSubmit} type="button" className="lm-button1">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default WorkExperience;

