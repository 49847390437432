import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import '../../screens/styles/ApplicationDetail.css';
import Preloader from '../../plugins/Preloader';
import dummy_avtar from '../../assets/img/dummy_avtar.png';
import lm_trash_icon from '../../assets/icons/lm-trash-icon.svg';
import note_reply_icon from '../../assets/icons/note-reply-icon.svg';
import close_popup from '../../assets/icons/close_popup.svg';
import axios from '../../helpers/axiosConfig';
import reducersAndActions from "../../_redux/slices/index";
import Constants from '../../helpers/Constants';
import { useSelector } from 'react-redux';
import {
    FieldGroup,
    FormBuilder,
    Validators,
    FieldControl,
} from "react-reactive-form";
import FormField from "../../components/FormField/FormField";
import { decryptText, encryptText, formatDate } from "../../helpers/DynamicFunctions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSessionId } from "../../services/storageData";
import { format } from 'date-fns';
import {
    GetsNotesListByLoanId,
    GetsMyProfile,
} from "../../services/NotesServices";
import {
    GetBasicInfoLoanApplicationById,
} from "../../services/loanApplicationServices";

import { getMasterList } from "../../services/utilServices";

let loanId;
let ApplicationStatusTemp = [];
let BorrowerApplicationStatusTemp = [];
const NotesForm = FormBuilder.group({
    CommentID: [""],
    CustID: [""],
    UserId: [""],
    UserRole: [""],
    ApplicationStatus: ["", [Validators.required]],
    ApplicationComment: ["", [Validators.required]],
    visible_All: [""],
    visible_Advisors: [""],
    visible_Partners: [""],
    visible_Self: [""],
    visible_Lenders: [""],
    visible_borrowers: [""],
    isReminderApplied: [""],
    ReminderDate: [""],
    ReminderTime: [""],
    CommentedBy: [""],
    ModifiedBy: [""],
    CommentedOn: [""],
    CurrentStatus: [""],
    Comments: [[]],
    LocalZoneTime: [""],
    lstCommentHistory: [[]],
});


// Utility function to get initials
const getInitials = (name) => {
    if (!name) return { firstNameFirstLetter: '', lastNameFirstLetter: '' };
    const nameParts = name.split(' ');
    const firstNameFirstLetter = nameParts[0] ? nameParts[0].charAt(0).toUpperCase() : '';
    const lastNameFirstLetter = nameParts[1] ? nameParts[1].charAt(0).toUpperCase() : '';
    return { firstNameFirstLetter, lastNameFirstLetter };
};

function Notes() {
    // Meta Title
    useEffect(() => {
        document.title = "Notes - Loan Application";
    }, []);

    // Preloader Start
    const [loading, setLoading] = useState(true);
    const [notesLoading, setNotesLoading] = useState(true);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);
    // Preloader End

    // popup function    
    const dispatch = useDispatch();
    const params = useParams();

    const [confirmDeletePopupShow, setConfirmDeletePopupShow] = useState(false);
    const [selectedNote, setSelectedNote] = useState({ commentID: null, ParentId: null, commentedBy: null });
    const [replies, setReplies] = useState({});

    const [affiliatesDrawerShow, setAffiliatesDrawer] = useState(false);
    const affiliatesDrawerClose = () => setAffiliatesDrawer(false);
    const [startDate, setStartDate] = useState(new Date());
    const [applicationStatus, setApplicationStatus] = useState();
    const [borrowerApplicationStatus, setBorrowerApplicationStatus] = useState();

    const [sendTimeOption, setSendTimeOption] = useState('send_now');
    const [selectedTime, setSelectedTime] = useState('12:00');

    const [comment, setComment] = useState('');
    const [notes, setNotes] = useState([]);
    const [lstCommentHistory, setLstCommentHistory] = useState('');
    const [user, setCurrentUser] = useState('');

    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [showReplyInput, setShowReplyInput] = useState({});

    const [visibleTo, setVisibleTo] = useState({
        Everyone: false,
        Borrower: false,
        Lenders: false,
        Partners: false,
        Advisors: false,
        Note_Self: false
    });

    const visible_All = "";
    const visible_borrowers = "";
    const visible_Lenders = "";
    const visible_Partners = "";
    const visible_Advisors = "";
    const visible_Self = "";

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);


    useEffect(() => {
        let oid = decryptText(params.loanId);
        loanId = oid;

        NotesForm.reset();

        Promise.all([getApplicationStatus(), getsNotesListByLoanId(), getsMyProfile(), GetBasicInfoLoanApplication()]);
    }, []);

    const GetBasicInfoLoanApplication = async () => {
        try {
            if (loanId !== "" && parseInt(loanId) > 0) {
                let queryObj = {
                    Id: loanId,
                    SessionId: getSessionId(),
                };
                const modelBasicInfoDetails = await GetBasicInfoLoanApplicationById(queryObj);
                const modelBasicInfoArray = JSON.parse(modelBasicInfoDetails.resultObject);
                BorrowerApplicationStatusTemp = modelBasicInfoArray[0].ApplicationStatus;
                setBorrowerApplicationStatus(modelBasicInfoArray[0].ApplicationStatus);

            } else {
                throw new Error("Invalid loanId");
            }
        } catch (err) {
            console.error("Error in GetBasicInfoLoanApplication:", err);
            throw err;
        }
    };

    const getsMyProfile = async () => {
        try {
            setLoading(true);
            const storedUserId = localStorage.getItem('user');
            const userprofile = storedUserId ? JSON.parse(storedUserId) : null;
            const userId = userprofile?.id;

            if (userId) {
                let queryObj = {
                    userId: userId,
                    SessionId: getSessionId(),
                };

                const profileResponse = await GetsMyProfile(queryObj);

                if (profileResponse.status === 200) {
                    let profileData = profileResponse.resultObject;
                    setCurrentUser(profileData);
                }
            }
        } catch (error) {
            console.error("Error in fetchCurrentUserProfile:", error);
            if (error.response) {
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
            }
        } finally {
            setLoading(false);
        }
    }

    const getApplicationStatus = async () => {
        const data = await getMasterList("ApplicationStatus");
        if (data && data.status == 200) {
            let responseData1 = JSON.parse(data.resultObject);
            const desiredOrder = ["All", "Closed", "Completed", "Documents Required", "In Progress", "New", "On Hold", "Review Required"];
            responseData1.sort((a, b) => {
                return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
            });

            ApplicationStatusTemp = responseData1;
            setApplicationStatus(responseData1);
        }
    }

    const getsNotesListByLoanId = async () => {
        try {
            setNotesLoading(true);
            setLoading(true);
            NotesForm.reset();

            if (loanId && parseInt(loanId) > 0) {
                let queryObj = {
                    LoanId: loanId,
                    SessionId: getSessionId(),
                };
                NotesForm.patchValue({
                    LoanId: loanId,
                });

                const response = await GetsNotesListByLoanId(queryObj);

                const boolToString = (value) => value === true ? "True" : "False";

                if (response.status == 200) {
                    let finalData = response.resultObject

                    setNotes(response.resultObject);

                    NotesForm.patchValue({
                        CommentID: finalData.CommentID,
                        CustID: finalData.CustID,
                        userId: finalData.UserId,
                        UserRole: finalData.UserRole,
                        ApplicationStatus: finalData.ApplicationStatus,
                        ApplicationComment: finalData.ApplicationComment,
                        Visible_All: finalData.Visible_All,
                        Visible_Advisors: finalData.Visible_Advisors,
                        Visible_Partners: finalData.Visible_Partners,
                        Visible_Self: finalData.Visible_Self,
                        Visible_Lenders: finalData.Visible_Lenders,
                        Visible_borrowers: finalData.Visible_borrowers,
                        isReminderApplied: finalData.isReminderApplied,
                        ReminderDate: finalData.ReminderDate,
                        ReminderTime: finalData.ReminderTime,
                        CommentedBy: finalData.CommentedBy,
                        ModifiedBy: finalData.ModifiedBy,
                        CommentedOn: finalData.CommentedOn,
                        CurrentStatus: finalData.CurrentStatus,
                        Comments: finalData.Comments,
                        LocalZoneTime: finalData.LocalZoneTime,
                        lstCommentHistory: finalData.lstCommentHistory,
                    });

                    if (finalData.ApplicationStatus) {
                        setApplicationStatus(finalData.ApplicationStatus);
                        NotesForm.patchValue({
                            ApplicationStatus: finalData.ApplicationStatus
                        });
                    }
                }

                setLoading(false);
            }
        } catch (error) {
            console.error("Error in GetSelectedBusinessDetail:", error);
            if (error.response) {
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
            }
        } finally {
            setLoading(false);
            setNotesLoading(false);
        }
    }

    const toggleReplyInput = (commentID) => {
        setShowReplyInput(prevState => ({
            ...prevState,
            [commentID]: !prevState[commentID]
        }));
    };

    const Name = user?.name || '';
    const firstName = user?.firstName || '';
    const firstNameFirstword = firstName ? firstName.charAt(0).toUpperCase() : '';
    const lastName = user?.lastName || '';
    const lastNameFirstword = lastName ? lastName.charAt(0).toUpperCase() : '';
    const profileLogo = user?.imagePath;
    const id = user?.id || '';

    const userId = localStorage.getItem('user');
    const profile = userId ? JSON.parse(userId) : null;
    const userRole = profile?.roleName || '';

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        if (id === 'Everyone') {
            setVisibleTo({
                Everyone: checked,
                Borrower: false,
                Lenders: false,
                Partners: false,
                Advisors: false,
                Note_Self: false
            });
        } else {
            setVisibleTo(prev => ({
                ...prev,
                [id]: checked,
                Everyone: false
            }));
        }
    };

    const handleSendTimeChange = (event) => {
        setSendTimeOption(event.target.value);
    };


    const newCommentID = 0;
    const commentedOn = new Date().toISOString();
    const myTimeZoneId = "India Standard Time";

    const now = new Date();

    const localZoneTime = now.toISOString();

    const handleSave = async () => {

        let reminderDate, reminderTime, isReminderApplied;

        if (sendTimeOption === 'send_now') {
            reminderDate = "";
            reminderTime = "";
            isReminderApplied = false;
        } else {
            reminderDate = format(startDate, 'MM/dd/yyyy');
            reminderTime = selectedTime;
            isReminderApplied = true;
        }

        const selectedStatus = NotesForm.get('ApplicationStatus').value;
        if (userRole !== 'Borrower' && !selectedStatus) {
            dispatch(
                reducersAndActions.actions.toasterActions.showToaster({
                    visible: true,
                    message: "Please select an Application Status",
                    type: "error",
                })
            );
            setTimeout(
                () =>
                    dispatch(
                        reducersAndActions.actions.toasterActions.hideToaster()
                    ),
                Constants.TOASTERHIDETIME
            );
            return;
        }

        if (!comment.trim()) {
            dispatch(
                reducersAndActions.actions.toasterActions.showToaster({
                    visible: true,
                    message: "Please enter a comment",
                    type: "error",
                })
            );
            setTimeout(
                () =>
                    dispatch(
                        reducersAndActions.actions.toasterActions.hideToaster()
                    ),
                Constants.TOASTERHIDETIME
            );
            return;
        }

        setLoading(true);
        const storedUserId = localStorage.getItem('user');
        const parsedUserId = storedUserId ? JSON.parse(storedUserId) : null;

        const formData = new FormData();
        formData.append('CommentID', newCommentID.toString());
        formData.append('CustID', loanId.toString());
        formData.append('UserId', parsedUserId.id.toString());
        formData.append('UserRole', parsedUserId.roleName);
        formData.append('ApplicationStatus', selectedStatus || borrowerApplicationStatus);
        formData.append('ApplicationComment', comment);
        formData.append('Visible_All', visibleTo.Everyone.toString());
        formData.append('Visible_Advisors', visibleTo.Advisors.toString());
        formData.append('Visible_Partners', visibleTo.Partners.toString());
        formData.append('Visible_Self', visibleTo.Note_Self.toString());
        formData.append('Visible_Lenders', visibleTo.Lenders.toString());
        formData.append('Visible_borrowers', visibleTo.Borrower.toString());
        formData.append('isReminderApplied', isReminderApplied.toString());
        formData.append('ReminderDate', reminderDate);
        formData.append('ReminderTime', reminderTime);
        formData.append('CommentedBy', parsedUserId.id.toString());
        formData.append('ModifiedBy', parsedUserId.id.toString());
        formData.append('CommentedOn', format(new Date(), 'MM/dd/yyyy'));
        formData.append('CurrentStatus', 'New');
        formData.append('LocalZoneTime', format(new Date(), 'MM/dd/yyyy'));

        try {
            const response = await axios.post("/api/LoanApplication/InsertUpdateNotes", formData, {
                params: {
                    WebURL: `/application-detail/${encryptText(params.loanId)}`,
                    SessionId: getSessionId(),
                    myTimeZoneId: myTimeZoneId
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setLoading(false);
            if (response.data.status === 200) {
                dispatch(
                    reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: "Note Added successfully",
                        type: "success",
                    })
                );
                setTimeout(
                    () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                    Constants.TOASTERHIDETIME
                );

                // Create a new note object
                const newNote = {
                    commentID: response.data.commentID || Date.now(),
                    createdName: Name,
                    userRole: parsedUserId.roleName,
                    commentedOn: format(new Date(), 'MMM dd, yyyy, h:mm a'),
                    currentStatus: selectedStatus,
                    applicationComment: comment,
                    profileImage: profileLogo,
                    replies: 0,
                    lstCommentHistory: []
                };

                // Update the notes state
                setNotes(prevNotes => [newNote, ...prevNotes]);

                setComment('');
                setAffiliatesDrawer(false);
                getsNotesListByLoanId();
            } else {
                throw new Error(response.data.message || "Error occurred while saving the note");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error saving note:", error);
            dispatch(
                reducersAndActions.actions.toasterActions.showToaster({
                    visible: true,
                    message: error.response?.data?.message || error.message || "Error occurred while saving the note",
                    type: "error",
                })
            );
            setTimeout(
                () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                Constants.TOASTERHIDETIME
            );
        }
    };


    const confirmDeletePopupClose = () => {
        setConfirmDeletePopupShow(false);
        setSelectedNote({ commentID: null, ParentId: null, commentedBy: null });
    };

    // Function to handle click on delete icon
    const onDeleteClick = (commentID, ParentId, commentedBy) => {
        setSelectedNote({ commentID, ParentId, commentedBy });
        setConfirmDeletePopupShow(true);
    };

    // Delete confirmation handler
    const handleConfirmDelete = () => {
        handleDelete(selectedNote.commentID, selectedNote.ParentId, selectedNote.commentedBy);
        confirmDeletePopupClose();
    };



    const handleDelete = async (CommentID, ParentId, commentedBy) => {
        setLoading(true);

        const sessionId = getSessionId();
        const deleteFormData = new FormData();
        deleteFormData.append('CommentId', CommentID);
        deleteFormData.append('LoanId', loanId);
        deleteFormData.append('ParentId', ParentId);
        deleteFormData.append('ModifiedBy', commentedBy);
        deleteFormData.append('SessionId', sessionId.toString());

        try {
            const response = await axios.post("/api/LoanApplication/DeleteNotesById", deleteFormData, {
                params: {
                    CommentID: CommentID,
                    LoanId: loanId,
                    ModifiedBy: commentedBy,
                    ParentId: ParentId,
                    SessionId: getSessionId(),
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.status === 200) {
                if (ParentId) {
                    setNotes(prevNotes => prevNotes.map(note =>
                        note.commentID === ParentId
                            ? {
                                ...note,
                                lstCommentHistory: note.lstCommentHistory.filter(reply => reply.id !== CommentID),
                                replies: note.replies - 1
                            }
                            : note
                    ));
                } else {
                    setNotes(prevNotes => prevNotes.filter(note => note.commentID !== CommentID));
                }

                dispatch(
                    reducersAndActions.actions.toasterActions.showToaster({
                        visible: true,
                        message: "Note Deleted successfully",
                        type: "success",
                    })
                );
                setTimeout(
                    () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                    Constants.TOASTERHIDETIME
                );
            } else {
                throw new Error(response.data.message || "Error occurred while Deleting the note");
            }
        } catch (error) {
            console.error("Error deleting note:", error);
            dispatch(
                reducersAndActions.actions.toasterActions.showToaster({
                    visible: true,
                    message: error.response?.data?.message || error.message || "Error occurred while Deleting the note",
                    type: "error",
                })
            );
            setTimeout(
                () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                Constants.TOASTERHIDETIME
            );
        } finally {
            setLoading(false);
        }
    }


    const handleReplyChange = (commentID, value) => {
        setReplies(prevReplies => ({
            ...prevReplies,
            [commentID]: value,
        }));
    };

    const replayOnComment = async (ApplicationComment, commentID) => {
        if (ApplicationComment && typeof ApplicationComment === 'string') {
            const trimmedReply = ApplicationComment.trim();
            if (trimmedReply.trim() === '') {
                dispatch(
                    reducersAndActions.actions.toasterActions.showToaster({
                        message: "Reply text is required",
                        type: "error",
                    })
                );
                setTimeout(
                    () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                    Constants.TOASTERHIDETIME
                );
                return;
            }

            const sessionId = getSessionId();
            const CreatedName = Name;
            const UserRole = userRole;
            const Id = id;

            const replayFormData = new FormData();
            replayFormData.append('Id', newCommentID);
            replayFormData.append('CommentId', commentID);
            replayFormData.append('ApplicationComment', ApplicationComment);
            replayFormData.append('myTimeZoneId', myTimeZoneId);
            replayFormData.append('CommentedOn', formatDate(commentedOn));
            replayFormData.append('CreatedBy', Id);
            replayFormData.append('RoleName', UserRole);
            replayFormData.append('CreatedName', CreatedName);
            replayFormData.append('ParentId', commentID);
            replayFormData.append('SessionId', sessionId.toString());

            axios.post("/api/LoanApplication/ReplayOnComment", replayFormData, {
                params: {
                    myTimeZoneId: myTimeZoneId,
                    SessionId: getSessionId(),
                }
            })
                .then(response => {
                    setLoading(false);
                    if (response.data.status === 200) {
                        dispatch(
                            reducersAndActions.actions.toasterActions.showToaster({
                                visible: true,
                                message: "Note Replied successfully",
                                type: "success",
                            })
                        );
                        setTimeout(
                            () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                            Constants.TOASTERHIDETIME
                        );

                        // Create a new reply object
                        const newReply = {
                            id: response.data.id || Date.now(),
                            createdName: CreatedName,
                            roleName: UserRole,
                            commentedOn: format(new Date(), 'MMM dd, yyyy, h:mm a'),
                            applicationComment: ApplicationComment,
                            profileImage: profileLogo,
                            createdBy: Id,
                            parentId: commentID
                        };

                        // Update the notes state
                        setNotes(prevNotes => prevNotes.map(note =>
                            note.commentID === commentID
                                ? {
                                    ...note,
                                    lstCommentHistory: [...(note.lstCommentHistory || []), newReply],
                                    replies: (note.replies || 0) + 1
                                }
                                : note
                        ));

                        // Clear the input for this specific comment
                        setReplies(prevReplies => ({
                            ...prevReplies,
                            [commentID]: ''
                        }));

                        getsNotesListByLoanId();
                    } else {
                        throw new Error(response.data.message || "Error occurred while Replying the note");
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.error("Error saving note:", error);
                    dispatch(
                        reducersAndActions.actions.toasterActions.showToaster({
                            visible: true,
                            message: error.response?.data?.message || error.message || "Error occurred while Replying the note",
                            type: "error",
                        })
                    );
                    setTimeout(
                        () => dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
                        Constants.TOASTERHIDETIME
                    );
                });
        } else {
            console.error('Reply text is undefined or not a string');
        }

    };

    const statusClassMap = {
        "New": "new",
        "new": "new",
        "In Progress": "in-progress",
        "Completed": "closed",
        "Closed": "closed",
        "Review Required": "review-required",
        "Document Required": "document-required",
        "On Hold": "on-hold",
    };

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="notes-wrap pb-5">
                    <div className="d-flex justify-content-between mt-3 mt-sm-0 mb-4">
                        <h3 className="notes-page-head">Notes</h3>
                        <a onClick={() => setAffiliatesDrawer(true)} className="lm-button3"><span>+</span> Add a note</a>
                    </div>
                    {notesLoading ? (
                        <Preloader />
                    ) : (
                        <>
                            {notes.map((note, index) => {
                                const { firstNameFirstLetter, lastNameFirstLetter } = getInitials(note.createdName);

                                const statusClass = statusClassMap[note.currentStatus] || 'status-unknown';

                                const isVisible = note.visible_All ||
                                    userRole === 'Admin' ||
                                    (userRole === 'Advisor' && (note.visible_Advisors || note.userId === id)) ||
                                    (userRole === 'Lender' && (note.visible_Lenders || note.userId === id)) ||
                                    (userRole === 'Partner' && (note.visible_Partners || note.userId === id)) ||
                                    (userRole === 'Borrower' && (note.visible_borrowers || note.userId === id)) ||
                                    (note.visible_Self && note.userId === id) ||
                                    (note.userId === id);

                                if (isVisible) {
                                    return (
                                        <div className="note-card" key={index}>
                                            <div className="note-card-heading">
                                                <div className="user-dtls d-flex">
                                                    <div className="user-image mr-2 mr-sm-3">
                                                        <div className="profile-img">
                                                            {note.profileImage ? (
                                                                <img src={`data:image/jpeg;base64,${note.profileImage}`} alt={`${note.createdName}'s Profile`} />
                                                            ) : (
                                                                firstNameFirstLetter && lastNameFirstLetter ? (
                                                                    <div className="profile-text">{firstNameFirstLetter}{lastNameFirstLetter}</div>
                                                                ) : (
                                                                    <img src={dummy_avtar} alt="User Avatar" />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="mt-0 mt-sm-1">
                                                        <div className="user-name">{note.createdName}•{note.userRole}</div>
                                                        <div className="note-timing">{new Date(note.commentedOn).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</div>                                        </div>
                                                </div>
                                                <div>
                                                    <span className={`status ${statusClass}`}>{note.currentStatus}</span>
                                                    {(userRole === 'Admin' ||
                                                        (userRole === 'Advisor' && (note.userId === id)) ||
                                                        (userRole === 'Lender' && (note.userId === id)) ||
                                                        (userRole === 'Partner' && (note.userId === id))) && (
                                                            <img
                                                                onClick={() => onDeleteClick(note.commentID, note.parentId = null, note.userId)}
                                                                className="cursor-pointer note-trash ml-2 ml-sm-3"
                                                                src={lm_trash_icon}
                                                                alt="Delete Icon"
                                                            />
                                                        )}

                                                </div>
                                            </div>
                                            <div className="note-card-body">
                                                <p>{note.applicationComment}</p>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <a className="reply-btn" onClick={() => toggleReplyInput(note.commentID)}>Reply</a>
                                                <div className="replies-count">
                                                    <img src={note_reply_icon} alt="" />
                                                    {note.lstCommentHistory.length} Replies
                                                </div>
                                            </div>
                                            {showReplyInput[note.commentID] && (
                                                <>
                                                    <div className="d-flex reply-txt-box mt-3">
                                                        <div className="user-image mr-2 mr-sm-3">
                                                            <div className="profile-img">
                                                                {profileLogo ? (
                                                                    <img src={`data:image/jpeg;base64,${profileLogo}`} alt="profile Logo" />
                                                                ) : (
                                                                    firstNameFirstword && lastNameFirstword ? (
                                                                        <div className="profile-text">{firstNameFirstLetter}{lastNameFirstLetter}</div>
                                                                    ) : (
                                                                        <img src={dummy_avtar} alt="User Avatar" />
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control w-100"
                                                            id={`lstCommentHistory_${note.commentID}`}
                                                            name={`lstCommentHistory_${note.commentID}`}
                                                            placeholder="Enter a reply"
                                                            value={replies[note.commentID] || ''}
                                                            isClearable={true}
                                                            onChange={(e) => handleReplyChange(note.commentID, e.target.value)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                    replayOnComment(replies[note.commentID], note.commentID);
                                                                }
                                                            }}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="lm-button2 ml-3"
                                                            onClick={() => replayOnComment(replies[note.commentID], note.commentID)}>
                                                            Send
                                                        </button>
                                                    </div>

                                                    <div className="note-reply-sec">
                                                        {note.lstCommentHistory && note.lstCommentHistory.length > 0 && note.lstCommentHistory.map((comment, cIndex) => {
                                                            const { firstNameFirstLetter: commentFirstNameFirstLetter, lastNameFirstLetter: commentLastNameFirstLetter } = getInitials(comment.createdName);
                                                            return (
                                                                <div key={cIndex} className="note-reply-card">
                                                                    <div className="note-card-heading">
                                                                        <div className="user-dtls d-flex">
                                                                            <div className="user-image mr-2 mr-sm-3">
                                                                                <div className="profile-img">
                                                                                    {comment.profileImage ? (
                                                                                        <img src={`data:image/jpeg;base64,${comment.profileImage}`} alt={`${comment.createdName}'s Profile`} />
                                                                                    ) : (
                                                                                        commentFirstNameFirstLetter && commentLastNameFirstLetter ? (
                                                                                            <div className="profile-text">{commentFirstNameFirstLetter}{commentLastNameFirstLetter}</div>
                                                                                        ) : (
                                                                                            <img src={dummy_avtar} alt="User Avatar" />
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-0 mt-sm-1">
                                                                                <div className="user-name">{comment.createdName} • {comment.roleName}</div>
                                                                                <div className="note-timing">{new Date(comment.commentedOn).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {(userRole === 'Admin' ||
                                                                                (userRole === 'Advisor' && (comment.createdBy === id)) ||
                                                                                (userRole === 'Lender' && (comment.createdBy === id)) ||
                                                                                (userRole === 'Partner' && (comment.createdBy === id))) && (
                                                                                    <img
                                                                                        onClick={() => onDeleteClick(comment.id, comment.parentId, comment.createdBy)}
                                                                                        className="cursor-pointer note-trash ml-2 ml-sm-3"
                                                                                        src={lm_trash_icon}
                                                                                        alt="Delete Icon"
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="note-card-body">
                                                                        <p>{comment.applicationComment}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </>
                    )}


                </div>


                {/* <!--affiliates form drawer start--> */}
                <Modal
                    className="fade-right drawer-modal"
                    show={affiliatesDrawerShow}
                    onHide={() => setAffiliatesDrawer(false)}
                    aria-labelledby="affiliatesDrawerLabel"
                >
                    <div className="modal-dialog">
                        <form action="">
                            <div className="modal-content">
                                <div className="drawer-head d-flex justify-content-between">
                                    <h3>Add Note</h3>
                                    <button type="button" className="close" onClick={affiliatesDrawerClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <div className="drawer-body note-form">
                                    <div className="user-dtls d-flex mb-3 mt-2">
                                        <div className="user-image mr-2">
                                            <div className="profile-img">
                                                {profileLogo ? (
                                                    <img src={`data:image/jpeg;base64,${profileLogo}`} alt="profileLogo" />
                                                ) : (
                                                    firstNameFirstword && lastNameFirstword ? (
                                                        <div className="profile-text">{firstNameFirstword}{lastNameFirstword}</div>
                                                    ) : (
                                                        <img src={dummy_avtar} alt="User Avatar" />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="user-name">{firstName} {lastName}</div>
                                            <div className="note-timing">Updated: {currentDateTime.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</div>
                                        </div>
                                    </div>
                                    {userRole !== 'Borrower' && (
                                        <div className="form-group">
                                            <label htmlFor="ApplicationStatus">Application status</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                }}
                                                options={applicationStatus}
                                                onChange={(selectedOption) => {
                                                    NotesForm.patchValue({ ApplicationStatus: selectedOption ? selectedOption.value : null });
                                                }}
                                                id="ApplicationStatus"
                                                name="ApplicationStatus"
                                            />
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <div className="label mb-2">Visible to</div>
                                        <div className="d-flex flex-wrap">
                                            {Object.entries(visibleTo).map(([key, value]) => (
                                                <div className="form-check mr-4 mb-2" key={key}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={key}
                                                        checked={value}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label" htmlFor={key}>
                                                        {key.replace('_', ' ')}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Comment">Comment</label>
                                        <textarea
                                            className="form-control"
                                            id="ApplicationComment"
                                            name="ApplicationComment"
                                            onChange={(e) => setComment(e.target.value)}
                                            placeholder="Enter text"
                                            required={true}>
                                        </textarea>
                                    </div>
                                    {userRole !== 'Borrower' && (
                                        <div className="form-group">
                                            <div className="label">Send time</div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="Send_time"
                                                    id="Send_time_Send_now"
                                                    value="send_now"
                                                    checked={sendTimeOption === 'send_now'}
                                                    onChange={handleSendTimeChange}
                                                />
                                                <label className="form-check-label" htmlFor="Send_time_Send_now">Send now</label>
                                            </div>
                                            {/* Conditionally show Schedule option if userRole is not Borrower */}
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="Send_time"
                                                    id="Send_time_Schedule"
                                                    value="schedule"
                                                    checked={sendTimeOption === 'schedule'}
                                                    onChange={handleSendTimeChange}
                                                />
                                                <label className="form-check-label" htmlFor="Send_time_Schedule">Schedule</label>
                                            </div>

                                        </div>
                                    )}
                                    {sendTimeOption === 'schedule' && userRole !== 'Borrower' && (
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group input-date">
                                                    <label htmlFor="Date">Date</label>
                                                    <DatePicker
                                                        showIcon
                                                        toggleCalendarOnIconClick
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        isClearable={true}
                                                        dateFormat="MMM d, yyyy"
                                                        placeholderText="Month DD, YYYY"
                                                        yearDropdownItemNumber={100}
                                                        scrollableYearDropdown
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label htmlFor="Time">Time</label>
                                                    <input
                                                        type="time"
                                                        onChange={(e) => setSelectedTime(e.target.value)}
                                                        value={selectedTime}
                                                        disableClock={true}
                                                        clearIcon={null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="drawer-footer d-flex">
                                    <button onClick={() => handleSave()} type="button" className="lm-button1 w-100">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>
                {/* <!--affiliates form drawer end--> */}


                {/* <!--Confirm Delete Popup / Modal --> */}
                <Modal
                    size="sm"
                    show={confirmDeletePopupShow}
                    onHide={confirmDeletePopupClose}
                    aria-labelledby="confirmDeletePopupLabel"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body signout-modal">
                                <h3 className="pt-4">
                                    Are you sure you want to delete this note?
                                </h3>
                            </div>
                            <div className="trash-modal-footer">
                                <button type="button" className="lm-button2" onClick={confirmDeletePopupClose}>Cancel</button>
                                <button type="button" className="lm-button1" onClick={handleConfirmDelete}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* <!--Confirm Delete Popup / Modal --> */}

            </div>)}

        </>
    );
}

export default Notes;