import React, { useCallback, useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosConfig'; // Adjust the path to where your Axios instance is defined
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';
import Preloader from '../../../plugins/Preloader';

const NotificationSettings = ({ activeTab }) => {
    const [preloader,setPreloader]=useState(false)
    const [toggleFirst,setToggleFirst]=useState(false)
    const [toggleSecond,setToggleSecond]=useState(false)
    const [toggleThird,setToggleThird]=useState(false)
    const dispatch=useDispatch()

    useEffect(()=>{

        const storedUserId = localStorage?.getItem('user');
        const parsedUserId = JSON.parse(storedUserId);
        const storedSessionId = localStorage?.getItem('sessionId');
        setPreloader(true)
        axiosInstance.get(`/api/User/MyProfile?userId=${parsedUserId?.id}&SessionId=${storedSessionId}`)
        .then((res)=>{
            setPreloader(false)
            setToggleFirst(res.data.resultObject.docuSignIs)
            setToggleSecond(res.data.resultObject.agdemailDoc)
            setToggleThird(res.data.resultObject.isTransactionAssigned)
        })
        .catch(error=>{
            
            setPreloader(false)
            console.log(error)})
    },[])
    const handleToggleSwitch = useCallback((toggleName, isChecked) => {
       if (toggleName=="docusign") {
        setToggleFirst(isChecked)
       }else if (toggleName=="AGD") {
        setToggleSecond(isChecked)
       } else {
        setToggleThird(isChecked)
       }
       // Exit early if toggle is off
       setPreloader(true)
        const storedUserId = localStorage?.getItem('user');
        const parsedUserId = JSON.parse(storedUserId);
        const storedSessionId = localStorage?.getItem('sessionId');
        const storageToken = localStorage?.getItem('token'); // Get the token from local storage


        // Ensure both userId and sessionId are available before making the API call
        if (storedUserId && parsedUserId && storedSessionId && storageToken) {

            const queryParams = `?userId=${parsedUserId.id}&status=${toggleName}&SessionId=${storedSessionId}`;

            axiosInstance.post(`/api/User/SaveNotficationStatus${queryParams}`)
                .then(response => { 
                    setPreloader(false)
                      if (response.data?.status == "200") {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message}));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                  }else{
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type:"error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                  
                  }
                })
                .catch(error => {
                    console.error('Error:', error);
                    // Handle the error
                });

        } else {
            setPreloader(false)
            console.error('User ID, Session ID, or token is not available');
        }
    }, []);
    
  // Render component
    return (
        <div className={`tab-pane fade ${activeTab === "Notifications" ? "show active" : ""}`} id="Notifications">
          { preloader ?<Preloader/>:<div className="lm-card">
                <h3 className="lm-card-heading">Notifications</h3>
                <div className="notification-setting d-flex justify-content-between">
                    <h6>Send email to advisor upon completion of e-signature.</h6>
                    <div className="lm-toggle-switch">
                        <input type="checkbox" 
                        id="DocuSign" 
                        checked={toggleFirst} 
                        onChange={(e) => handleToggleSwitch('docusign', e.target.checked)} />
                        <label htmlFor="DocuSign">Toggle</label>
                    </div>
                </div>
                <div className="notification-setting d-flex justify-content-between">
                    <h6>Send email to users when new document is uploaded</h6>
                    <div className="lm-toggle-switch">
                        <input type="checkbox" id="document_uploaded" checked={toggleSecond} onChange={(e) => handleToggleSwitch('AGD', e.target.checked)} />
                        <label htmlFor="document_uploaded">Toggle</label>
                    </div>
                </div>
                <div className="notification-setting d-flex justify-content-between">
                    <h6>Send email to users when application is assigned to advisor, lender or partner.</h6>
                    <div className="lm-toggle-switch">
                        <input type="checkbox" id="application_assigned" checked={toggleThird} onChange={(e) => handleToggleSwitch('assigned', e.target.checked)} />
                        <label htmlFor="application_assigned">Toggle</label>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default NotificationSettings;
