import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../plugins/Preloader';
import React, { useState, useEffect } from 'react';
import pass_eye from '../assets/img/pass_eye.svg';
import dummy_avtar from '../assets/img/dummy_avtar.png';
import email_icon from '../assets/icons/email_icon.svg';
import copy_btn from '../assets/icons/copy_btn.svg';
import warning_icon from '../assets/icons/warning_icon.svg';
import ChangePassword from "./setting-tabs/changePassword/ChangePassword";
import NotificationSettings from "./setting-tabs/notificationStatus/NotificationSettings";
import AccountSettings from "./setting-tabs/accountSetting.js/AccountSettings";
import EditProfile from "./setting-tabs/editProfile/Editprofile";
import CreatableSelect from 'react-select/creatable';
import close_popup from '../assets/icons/close_popup.svg';
import axiosInstance from '../helpers/axiosConfig';
import Modal from 'react-bootstrap/Modal'
import validator from 'email-validator';
import { useDispatch } from "react-redux";
import reducersAndActions from "../_redux/slices";
import Constants from "../helpers/Constants";
import CopyLinkComponent from "./user/commonFunctions/CopyLinkComponent";
import { json } from "react-router-dom";
function Settings() {
    // Meta Title
    useEffect(() => {
        document.title = "Settings";
    }, []);
    var Userdata = localStorage.getItem('user');
    var data=JSON.parse(Userdata)
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    
    // Email Recipient Dropdown options 
    const RecipientsOptions = []
    
    const handleRecipientsChange = (selectedOptions) => {
        setSelectedRecipients(selectedOptions);
    };

    const isValidEmailOption = (inputValue) => {
        return validator.validate(inputValue);
    };
    // Preloader Start
    
    const [sendEmailPopupShow, setSendEmailPopup] = useState(false);
    const sendEmailPopupClose = () => setSendEmailPopup(false);
    const [loading, setLoading] = useState(true);
    const dispatch=useDispatch()
    const sendFinalEmail=async()=>{
      try{  setLoading(true)
        const storedSessionId = localStorage.getItem('sessionId');
        
        const emailIds = selectedRecipients.map(recipient => recipient.value).join(',');
      const response= await axiosInstance.post(`/api/User/SendMarketingMail?SessionId=${storedSessionId}&emailId=${emailIds}&marketingLink=${Constants.FRONTEND_URL}/register?code=0${data?.id}`)
        setLoading(false)
        if (response.status =='200') {
          setLoading(false)
          setSendEmailPopup(false)
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message,type:"success"}));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          
        } else {
          setLoading(false)
          setSendEmailPopup(false)
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: 'error' }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
      } catch (error) {
        setLoading(false)
        setSendEmailPopup(false)
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: 'error' }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);  
      
      }
    }
    
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("URL copied to clipboard!");
        }).catch((error) => {
            console.error("Failed to copy URL: ", error);
        });
    };

    const marketingLinkdata = "https://users.loanmantra.com/Home/Register?AdvisorCode=06904";

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);
    // Preloader End

    // settings tab function
    const [activeTab, setActiveTab] = useState("Edit_Profile");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                < div className="dashboard-wrap">
                    <div className="lm-nav-tabs padding-reg pb-0 pt-2">
                        <ul className="nav nav-tabs" id="laTab" role="tablist">
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === "Edit_Profile" ? "active" : ""}`} onClick={() => handleTabClick("Edit_Profile")}>
                                    Edit Profile
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === "Change_Password" ? "active" : ""}`} onClick={() => handleTabClick("Change_Password")}>
                                    Change Password
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === "Notifications" ? "active" : ""}`} onClick={() => handleTabClick("Notifications")}>
                                    Notifications
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === "Account_Settings" ? "active" : ""}`} onClick={() => handleTabClick("Account_Settings")}>
                                    Account Settings
                                </button>
                            </li>
                            {(data?.roleName !== "Admin" && data?.roleName !== "Borrower") && (
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === "Marketing" ? "active" : ""}`} onClick={() => handleTabClick("Marketing")}>
                                        Referral
                                    </button>
                                </li>
                            )}

                        </ul>
                    </div>

                    {/* <!-- loan application tab content start --> */}
                    <div className="padding-reg">
                        <div className="tab-content" id="laTabContent">
                            {/* <!-- Edit_Profile section start --> */}
                            <EditProfile activeTab={activeTab}/>
                            
                            {/* <!-- Edit_Profile section end --> */}

                            {/* <!-- Change Password section start --> */}
                          <ChangePassword 
                          warning_icon={warning_icon}
                          pass_eye={pass_eye}
                          activeTab={activeTab}/>
                            {/* <!-- Change Password section end --> */}

                            {/* <!-- Notifications section start --> */}
                            <NotificationSettings activeTab={activeTab}/>
                            {/* <!-- Notifications section end --> */}

                            {/* <!-- Account_Settings section start --> */}
                            <AccountSettings activeTab={activeTab} />
                            {/* <!-- Account_Settings section end --> */}

                            {/* <!-- Marketing section start --> */}
                           <div className={`tab-pane fade ${activeTab === "Marketing" ? "show active" : ""}`} id="Marketing">
                                <div className="lm-card">
                                    <h3 className="lm-card-heading">Referral Link</h3>
                                    <div className="link-box d-flex justify-content-between">
                                        <span>{`${window.location.origin}/register?code=0${data?.id}`}
                                            <span >
                                                    
                                                    <CopyLinkComponent 
                                                        url={`${Constants.FRONTEND_URL}/register`} 
                                                        text="" 
                                                        imgSrc={copy_btn} 
                                                        data={`0${data?.id}`}
                                                        code={`0${data?.id}`}
                                                    />
                                            </span>
                                        </span>
                                        <div>
                                            <img className="cursor-pointer" onClick={() => setSendEmailPopup(true)} src={email_icon} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Marketing section end --> */}
                            {/*Send Email Popup / Modal */}
                     <Modal
                        size="md"
                        show={sendEmailPopupShow}
                        onHide={() => setSendEmailPopup(false)}
                        aria-labelledby="sendEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="sendEmailPopupLabel">Send email
                                    </h5>
                                    <button type="button" className="close" onClick={sendEmailPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Recipient">Recipient (s)</label>
                                            <CreatableSelect
                                            className="basic-single emailbox-input"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                            placeholder={
                                                <div className="email-list-placeholder">
                                                    <span className="placeholder-add-sign">+</span> Add
                                                </div>
                                            }
                                            isMulti
                                            options={RecipientsOptions}
                                            onChange={handleRecipientsChange}
                                            isValidNewOption={isValidEmailOption}
                                        />
                                        </div>

                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={sendEmailPopupClose}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={sendFinalEmail}>Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Send Email Popup / Modal */}


                        </div>
                    </div>
                    {/* <!-- loan application tab content start --> */}
                </div>

            </div>)}


        </>
    );
}

export default Settings;