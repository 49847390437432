import React from 'react';
import { FieldControl, Validators } from 'react-reactive-form';

const FormTextarea = ({
  id,
  label,
  name,
  placeholder,
  validators,
  required,
  errorMessage,
  patternMessage,
  disabled,
  maxlength
}) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    <FieldControl
      name={name}
      validators={validators}
      render={({ handler, touched, hasError }) => (
        <div>
          <textarea
            className={`form-control ${
              touched && hasError('required') ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={placeholder}
            {...handler()}
            required={required}
            disabled={disabled}
            maxLength={maxlength}
          />
          {touched && hasError('required') && (
            <div className="invalid-feedback">{errorMessage || 'This field is required.'}</div>
          )}
          {touched && hasError('pattern') && (
            <div className="invalid-feedback">{patternMessage || 'Invalid input.'}</div>
          )}
        </div>
      )}
    />
  </div>
);

export default FormTextarea;
