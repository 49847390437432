import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Select from 'react-select';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Preloader from '../plugins/Preloader';
import GARPH from '../assets/img/Graph.png';
import MAP from '../assets/img/Map.png';
import ScroeCard from '../assets/img/ScroeCard.png';
import ScroeCardItems from '../assets/img/ScroeCardItems.png';
import PieChart from '../assets/img/pie-chart.png';
import no_data_icon from '../assets/img/no_data_icon.svg';

function Dashboard() {
    useEffect(() => {
        document.title = "Dashboard";
    }, []);

    // Preloader Start
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);
    // Preloader End

    // select Dropdown options
    const statusOptions = [
        { value: 'Year', label: 'Year' },
        { value: 'Month', label: 'Month' },
        { value: 'Week', label: 'Week' },
        { value: 'Day', label: 'Day' },
    ]

    const compnayOptions = [
        { value: 'SBA loans', label: 'SBA loans' },
    ]

    const countryOptions = [
        { value: 'Indiana, IA', label: 'Indiana, IA' },
    ]

    const periodOptions = [
        { value: 'Quarter', label: 'Quarter' },
    ]

    const SelectePeriodOptions = [
        { value: '4', label: '4' },
    ]

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="dashboard-wrap dashboard-screen">
                    {/* if data is not availble then show this below div */}
                    <div className='db-card'>
                        <div className='no-data-card text-center place-content-center'>
                            <img src={no_data_icon} alt="" />
                            <h3 className='f-700 mb-2'>No data yet</h3>
                            <p className='text-light'>All relevant information will be displayed
                                <br/>
                            here in the nearest future</p>
                        </div>
                    </div>

                    {/* if data is availble then show this below div */}
                    <div className="row" style={{display:'none'}}>
                        <div className="col-md-8">
                            <div className="card-row">

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">Average loan amount</span>
                                    <h3 className="db-sm-card-value">$588,450,00</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">Interest rate grade</span>
                                    <h3 className="db-sm-card-value">5.5%</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">Average term of loan</span>
                                    <h3 className="db-sm-card-value">2 years</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">LTV</span>
                                    <h3 className="db-sm-card-value">10%</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">Debt Service Ratio</span>
                                    <h3 className="db-sm-card-value">10%</h3>
                                </div>

                                <div className="db-card db-sm-card">
                                    <span className="db-sm-card-title">Average touch to finish</span>
                                    <h3 className="db-sm-card-value">90 days</h3>
                                </div>
                            </div>

                            {/* Closed loans card */}
                            <div className="db-card">
                                <div className="d-flex justify-content-between closed-loans-head">
                                    <h4 className="db-card-title mt-2">Closed loans</h4>
                                    <div className="d-flex">
                                        <Select
                                            className="basic-single cust-date-dp"
                                            classNamePrefix="select"
                                            id="status"
                                            name="status"
                                            options={statusOptions}
                                            defaultValue={statusOptions[0]}
                                        />
                                        <div className="year-selector">
                                            <button><div className="lm-angle-left-icon"></div></button>
                                            <div className="year">2023</div>
                                            <button><div className="lm-angle-right-icon"></div></button>
                                        </div>
                                    </div>
                                </div>

                                {/* graph */}
                                <div className="graph">
                                    <img src={GARPH} alt="" />
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-7">
                                    <div className="db-card">
                                        <h4 className="db-card-title">Loan locations</h4>
                                        {/* map */}
                                        <div className="map">
                                            <img src={MAP} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="db-card">
                                        <h4 className="db-card-title">Average FICO score</h4>
                                        {/* ScroeCard */}
                                        <div className="ScroeCard mb-3">
                                            <img src={ScroeCard} alt="" />
                                        </div>
                                        <img className="mt-3" src={ScroeCardItems} alt="" />
                                    </div>
                                </div>
                            </div>

                            {/* Market comparison card */}
                            <div className="db-card market-comparison-card">
                                <div className="row market-comparison-filters">
                                    <div className="col-md-3 col-6 px-2">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            id="status"
                                            name="status"
                                            options={compnayOptions}
                                            defaultValue={compnayOptions[0]}
                                        />
                                    </div>
                                    <div className="col-md-3 col-6 px-2">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            id="status"
                                            name="status"
                                            options={countryOptions}
                                            defaultValue={countryOptions[0]}
                                        />
                                    </div>
                                    <div className="col-md-3 col-6 px-2">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            id="status"
                                            name="status"
                                            options={periodOptions}
                                            defaultValue={periodOptions[0]}
                                        />
                                    </div>
                                    <div className="col-md-3 col-6 px-2">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            id="status"
                                            name="status"
                                            options={SelectePeriodOptions}
                                            defaultValue={SelectePeriodOptions[0]}
                                        />
                                    </div>
                                </div>
                                <div className="row cust-bar lm-bar place-items-center mb-3">
                                    <div className="col-md-2 col-3">
                                        <span className="cust-bar-label">Loan Mantra</span>
                                    </div>
                                    <div className="col-8 d-flex">
                                        <div className="ratio-bar">
                                        </div>
                                    </div>
                                </div>
                                <div className="row cust-bar standard-bar place-items-center">
                                    <div className="col-2">
                                        <span className="cust-bar-label">Standard</span>
                                    </div>
                                    <div className="col-8 d-flex">
                                        <div className="ratio-bar">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="db-card">
                                {/* item */}
                                <div>
                                    <h4 className="db-card-title">Total requests</h4>
                                    <div className="db-pie-chart">
                                        <img className="mt-1" src={PieChart} alt="" />
                                    </div>
                                    <hr></hr>
                                </div>
                                {/* item */}
                                <div>
                                    <h4 className="db-card-title">Reason for the loan</h4>
                                    <div className="db-pie-chart">
                                        <img className="mt-1" src={PieChart} alt="" />
                                    </div>
                                    <hr></hr>
                                </div>
                                {/* item */}
                                <div>
                                    <h4 className="db-card-title">Loans approved by NAICS</h4>
                                    <div className="db-pie-chart">
                                        <img className="mt-1" src={PieChart} alt="" />
                                    </div>
                                    <hr></hr>
                                </div>
                                {/* item */}
                                <div>
                                    <h4 className="db-card-title">Gender breakdowns</h4>
                                    <div className="db-pie-chart">
                                        <img className="mt-1" src={PieChart} alt="" />
                                    </div>
                                    <hr></hr>
                                </div>
                                {/* item */}
                                <div>
                                    <h4 className="db-card-title">Minority breakdowns</h4>
                                    <div className="db-pie-chart">
                                        <img className="mt-1" src={PieChart} alt="" />
                                    </div>
                                    <hr></hr>
                                </div>
                                {/* item */}
                                <div>
                                    <h4 className="db-card-title">Franchise Transaction History</h4>
                                    <div className="db-pie-chart">
                                        <img className="mt-1" src={PieChart} alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>)}



        </>
    );
}

export default Dashboard;