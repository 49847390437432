export default {
    ADMINEMAIL: "",
    ADMINPASSWORD: "",
    TOASTERHIDETIME: 4000,

    // For Production 
    // BACKEND_URL: "https://api.loanmantra.com",
    // FRONTEND_URL: "https://app.loanmantra.com",
    // IPGEOLOCATION_API_KEY: '3bf6683caa57465685f783df1eba5806',  // Production keys
    // ReCAPTCHAKEY: '6LdptBYqAAAAAB5jpM5AjQUzcODXjD9fe3UxjzIj', // production key
    // isCheckCaptcha: true,
    // isCheckForLocationAccess: true,

    // For Developemnt 
    BACKEND_URL: 'http://52.142.1.144:8080',
    FRONTEND_URL: 'http://159.89.234.66:9053',
    IPGEOLOCATION_API_KEY: '7181cf9967944127b0e49d83851565c0',      // Development keys (Manish's account)
    ReCAPTCHAKEY: '6LfD3PIbAAAAAJs_eEHvoOl75_83eXSqpPSRFJ_u', // dummy test key for local
    isCheckCaptcha: false,
    isCheckForLocationAccess: false,

}