import { Header, Footer } from "@components";
import { Link } from "react-router-dom";
import './styles/WebsiteStyle.css';
import not_found_404 from '../assets/img/404.svg';

function Register() {
    return (
        <>
            {/* Header */}
            <Header />

            {/* Main Body */}
            <div className="website-wrap">
                <div className="not-found-page-body">
                    <div className="success-page-box not-found-box">
                        <img src={not_found_404} />
                        <h2>
                            Hmmm... it appears this page is 
                            <br></br>
                            not working correctly.
                        </h2>
                        <p>
                            Our mantra is to keep you and your small business thriving, 
                            <br></br>
                            so please check back later for updates.
                        </p>
                        <Link to="/" className="back-btn">
                            Back to Homepage
                        </Link>

                    </div>
                </div>


            </div>



            {/* Footer */}
            <Footer />
        </>
    );
}

export default Register;
