import React, { useState, useEffect, createRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from "../../plugins/Preloader";
import form_note_icon from "../../assets/icons/form-note-icon.svg";
import lm_times_light from "../../assets/icons/lm-times-light.svg";
import { FieldGroup, FormBuilder, Validators, FieldControl, } from "react-reactive-form";
import FormField from "../../components/FormField/FormField";
import { getUSAStates } from "../../services/commonService";
import { GetBussinessNameListByLoanId, GetBusinessHistoryDetailsById, InsertUpdateBusinessHistory, } from "../../services/loanApplicationServices";
import { getSessionId } from "../../services/storageData";
import { decryptText, formatDate } from "../../helpers/DynamicFunctions";
import Constants from "../../helpers/Constants";
import reducersAndActions from "../../_redux/slices/index";

let loanId;
let BusinessNameOptionsTemp = [];
let stateLists = [];

let stateListstemp = [];

// Reactive Form Group
const BusinessHistoryForm = FormBuilder.group({
  BusinessHistoryID: [0],
  YearOfBusinessEstablished: [""],
  AcquireBusiness: ["", [Validators.required]],
  NatureOfbusiness: ["", [Validators.required]],
  KeyCustomers: ["", [Validators.required]],
  TargetMarket: ["", [Validators.required]],
  MajorCompetitors: ["", [Validators.required]],
  Competitors: ["", [Validators.required]],
  MajorSuppliers: ["", [Validators.required]],
  GeneralGeographicMarket: ["", [Validators.required]],
  LoanBenefit: ["", [Validators.required]],
  LoanFacilitate: ["", [Validators.required]],
  NoSBAEmployee: [""],
  NoFormerSBAEmployee: [""],
  NoMemberOfCongress: [""],
  NoGovernmentEmployee: [""],
  NoMemberEmployeeOfSmallBusiness: [""],
  EstablishedDate: ["", [Validators.required]],
  EstablishedState: ["", [Validators.required]],
  BusinessName: [""],
  AdditionalInformation: [""],
});
function BusinessHistory({ backPreviousTab }) {
  const dispatch = useDispatch();
  const stateRef = createRef();
  const location = useLocation()
  const params = useParams();

  const [BusinessOptions, setBusinessOptions] = useState([]);
  const [selectedBusinessOption, setSelectedBusinessOption] = useState(null);
  const [businessError, setBusinessError] = useState("");
  const [loading, setLoading] = useState(true);

  // Popup Function
  const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
  const PreviousTabPopupClose = () => setPreviousTabPopup(false);

  // datepicker function

  const userdetails = location.state?.data
  useEffect(() => {
    document.title = "Business History - Loan Application";
    let oid = decryptText(params.loanId);

    if (userdetails) {
      loanId = userdetails.data ? userdetails.data.loanId : userdetails.ApplicationNumber.slice(-4)
    } else {
      loanId = oid;
    }
    BusinessHistoryForm.reset();
    getMasterArrayList();
  }, []);


  const getMasterArrayList = async () => {
    try {
      setLoading(true);
      const states = await getUSAStates();
      stateLists = states;
      stateListstemp = states;

      // Fetch business names
      let queryObj = { LoanId: loanId, SessionId: getSessionId() };
      let data2 = await GetBussinessNameListByLoanId(queryObj);

      // Check if data2 is valid and has a successful status
      if (data2 && data2.status === 200) {
        let responseData2 = JSON.parse(data2.resultObject);

        const BusinessNameOptionsTemp = responseData2.map(item => ({
          label: item.BusinessName + (item.IsPrimary ? " (Primary)" : ""),
          value: item.Id,
          isPrimary: item.IsPrimary
        }));

        setBusinessOptions(BusinessNameOptionsTemp);
        const primaryOption = BusinessNameOptionsTemp.find(option => option.isPrimary);
        const defaultOption = primaryOption || BusinessNameOptionsTemp[0];
        setSelectedBusinessOption(defaultOption);

        if (defaultOption) {
          await GetBusinessHistoryDetail(defaultOption.value);
        }

      } else {
        console.error('Failed to fetch data or no data available');
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  const GetBusinessHistoryDetail = React.useCallback(async (businessId) => {
    try {
      setLoading(true);
      BusinessHistoryForm.reset();

      if (businessId && parseInt(businessId) > 0) {
        let queryObj = { LoanId: businessId, SessionId: getSessionId(), };
        BusinessHistoryForm.patchValue({ LoanId: businessId });

        const modelBusinessHistoryDetail = await GetBusinessHistoryDetailsById(queryObj);

        if (modelBusinessHistoryDetail.status == 200) {
          let finalData = modelBusinessHistoryDetail.resultObject ? JSON.parse(modelBusinessHistoryDetail.resultObject) : "";

          if (finalData && Array.isArray(finalData) && finalData.length > 0) {
            BusinessHistoryForm.patchValue({
              BusinessHistoryID: finalData[0].BusinessHistoryID,
              YearOfBusinessEstablished: finalData[0].YearOfBusinessEstablished,
              AcquireBusiness: finalData[0].AcquireBusiness,
              NatureOfbusiness: finalData[0].NatureOfbusiness,
              KeyCustomers: finalData[0].KeyCustomers,
              TargetMarket: finalData[0].TargetMarket,
              MajorCompetitors: finalData[0].MajorCompetitors,
              Competitors: finalData[0].Competitors,
              MajorSuppliers: finalData[0].MajorSuppliers,
              GeneralGeographicMarket: finalData[0].GeneralGeographicMarket,
              LoanBenefit: finalData[0].LoanBenefit,
              LoanFacilitate: finalData[0].LoanFacilitate,
              NoSBAEmployee: finalData[0].NoSBAEmployee,
              NoFormerSBAEmployee: finalData[0].NoFormerSBAEmployee,
              NoMemberOfCongress: finalData[0].NoMemberOfCongress,
              NoGovernmentEmployee: finalData[0].NoGovernmentEmployee,
              AdditionalInformation: finalData[0].AdditionalInformation,
              NoMemberEmployeeOfSmallBusiness: finalData[0].NoMemberEmployeeOfSmallBusiness,
              EstablishedDate: finalData[0].EstablishedDate != "" ? formatDate(finalData[0].EstablishedDate) : "",
              EstablishedState: finalData[0].EstablishedState,
              BusinessName: finalData[0].BusinessName,
            });

            if (finalData[0].EstablishedState != "") {
              for (let elem = 0; elem < stateListstemp.length; elem++) {
                let elemvalue = stateListstemp[elem];
                if (elemvalue.value == finalData[0].EstablishedState) {
                  BusinessHistoryForm.patchValue({ EstablishedState: elemvalue, });
                }
              }
            }

            if (finalData[0].BusinessName != "") {
              for (let elem = 0; elem < BusinessNameOptionsTemp.length; elem++) {
                let elemvalue = BusinessNameOptionsTemp[elem];
                if (elemvalue.value == finalData[0].BusinessName) {
                  BusinessHistoryForm.patchValue({ BusinessName: elemvalue, });
                }
              }
            }
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in GetSelectedBusinessDetail:", error);
    } finally {
      setLoading(false);
    }
  }, [BusinessHistoryForm, setLoading]);;

  useEffect(() => {
    if (BusinessOptions.length > 0 && !selectedBusinessOption) {
      const primaryOption = BusinessOptions.find(option => option.isPrimary);
      const defaultOption = primaryOption || BusinessOptions[0];
      setSelectedBusinessOption(defaultOption);
      if (defaultOption) {
        GetBusinessHistoryDetail(defaultOption.value);
      }
    }
  }, [BusinessOptions, selectedBusinessOption, GetBusinessHistoryDetail]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (BusinessHistoryForm.status !== "INVALID") {
      setLoading(true);

      let finalObj = {
        ...BusinessHistoryForm.value,
        LoanId: BusinessHistoryForm.value.BusinessName?.value || selectedBusinessOption?.value,
        SessionId: getSessionId(),
        EstablishedDate: formatDate(BusinessHistoryForm.value.EstablishedDate),
      };

      if (finalObj.BusinessHistoryID == null || finalObj.BusinessHistoryID === undefined) {
        finalObj.BusinessHistoryID = 0;
      }

      finalObj = {
        ...finalObj,
        modifiedBy: JSON.parse(localStorage.getItem("user"))?.id || 0,
        BusinessName: BusinessHistoryForm.value.BusinessName?.value || selectedBusinessOption?.value,
        EstablishedState: BusinessHistoryForm.value.EstablishedState.value,
        NoSBAEmployee: BusinessHistoryForm.value.NoSBAEmployee == "Yes" ? true : false,
        IsPrimaryLinked: BusinessHistoryForm.value.IsPrimaryLinked == "Yes" ? true : false,
        NoMemberOfCongress: BusinessHistoryForm.value.NoMemberOfCongress == "Yes" ? true : false,
        NoFormerSBAEmployee: BusinessHistoryForm.value.NoFormerSBAEmployee == "Yes" ? true : false,
        NoGovernmentEmployee: BusinessHistoryForm.value.NoGovernmentEmployee == "Yes" ? true : false,
        NoMemberEmployeeOfSmallBusiness: BusinessHistoryForm.value.NoMemberEmployeeOfSmallBusiness == "Yes" ? true : false,
      };

      InsertUpdateBusinessHistory(finalObj)
        .then((data) => {
          if (data.status == 200) {
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          } else {
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        });
    } else {
      markAllAsTouched(BusinessHistoryForm);
      setLoading(false);
    }
  };

  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    if (stateRef.current) stateRef.current.focus();
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control) {
        control.active = true;
        control.touched = true;
        if (controlName !== 'EstablishedDate' && document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
        if (document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
      }
    });
  };

  const handleSelectChange = (event) => {
    setSelectedBusinessOption(event);
    if (event) {
      setBusinessError("");
      GetBusinessHistoryDetail(event.value);
    } else {
      setBusinessError("Please select a business");
    }
  };

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (
        <Preloader />
      ) : (
        <div>
          <div className="lm-form-box pb-5">
            <FieldGroup
              control={BusinessHistoryForm}
              render={({ get, invalid }) => (
                <form action="">
                  <div className="lm-card">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <FieldControl
                            name="BusinessName"
                            render={({ handler, touched, hasError, meta }) => (
                              <div className="form-group">
                                <label htmlFor="BusinessName">Select Business<span className="asterisk">*</span>
                                </label>
                                <Select
                                  className={`basic-single ${businessError ? 'is-invalid' : ''}`}
                                  classNamePrefix="select"
                                  id="BusinessName"
                                  name="BusinessName"
                                  options={BusinessOptions}
                                  onChange={handleSelectChange}
                                  value={selectedBusinessOption}
                                />
                                {businessError && <div className="invalid-feedback">{businessError}</div>}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* <!-- lm common divider to break the section  --> */}
                      <div className="lm-divider my-3 mx-3"></div>
                      {/* <!-- lm common divider to break the section  --> */}

                      <div className="col-12 label text-dark mb-2">
                        <b>OVERVIEW</b>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                          <FieldControl
                            name="EstablishedState"
                            render={({ handler, touched, hasError, meta }) => (
                              <div className="form-group">
                                <label htmlFor="Established_state">
                                  Established state
                                  <span className="asterisk">*</span>
                                </label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  id="EstablishedState"
                                  name="EstablishedState"
                                  ref={stateRef}
                                  options={stateLists}
                                  {...handler()}
                                />

                                {/* Error messages below */}
                                <span className="error-message">
                                  {touched &&
                                    hasError("required") &&
                                    `Established state is required`}
                                </span>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group input-date">
                          <FieldControl
                            name="EstablishedDate"
                            render={({ handler, touched, hasError, meta }) => (
                              <div className="form-group input-date">
                                <label htmlFor="EstablishedDate">
                                  Established Date
                                </label>
                                <DatePicker
                                  showIcon
                                  toggleCalendarOnIconClick
                                  {...handler()}
                                  selected={
                                    BusinessHistoryForm.value.EstablishedDate
                                  }
                                  onChange={(date) => {
                                    BusinessHistoryForm.patchValue({
                                      EstablishedDate: date ? formatDate(date) : null,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                  isClearable={true}
                                  id="EstablishedDate"
                                  dateFormat="MM/d/yyyy"
                                  placeholderText="MM/DD/YYYY"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  showYearDropdown
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="AcquireBusiness"
                            label=" When and how did current owners acquire the business?"
                            placeholder="Enter When and how did current owners acquire the business?"
                            id="AcquireBusiness"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="NatureOfbusiness"
                            label=" Nature of business"
                            placeholder="Enter Nature of business"
                            id="NatureOfbusiness"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="KeyCustomers"
                            label="Key customers"
                            placeholder="Enter Key customers"
                            id="KeyCustomers"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="TargetMarket"
                            label="Target market"
                            placeholder="Enter Target market"
                            id="TargetMarket"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="MajorCompetitors"
                            label="Major competitors"
                            placeholder="Enter Major competitors"
                            id="MajorCompetitors"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="Competitors"
                            label="Current competitive advantage"
                            placeholder="Enter Current competitive advantage"
                            id="Competitors"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="MajorSuppliers"
                            label="Major suppliers"
                            placeholder="Enter Major suppliers"
                            id="MajorSuppliers"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="GeneralGeographicMarket"
                            label="General geographic market"
                            placeholder="Enter General geographic market"
                            id="GeneralGeographicMarket"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="LoanBenefit"
                            label="How will the proposed loan benefit the business?"
                            placeholder="Enter How will the proposed loan benefit the business?"
                            id="LoanBenefit"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="LoanFacilitate"
                            label="How will the proposed loan facilitate new employment
                            opportunities?"
                            placeholder="Enter How will the proposed loan facilitate new employment
                            opportunities?"
                            id="LoanFacilitate"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">

                        <div className="form-group">
                          <FormField
                            name="AdditionalInformation"
                            label="Additional information"
                            placeholder="Enter Additional information"
                            id="AdditionalInformation"
                            maxlength={250}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sticky-btn-footer d-flex justify-content-end">
                    <button
                      type="button"
                      className="lm-button2"
                      onClick={() => setPreviousTabPopup(true)}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="lm-button1 ml-3"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
            />
          </div>

          {/* <!--back to Previous Tab Popup / Modal --> */}
          <Modal
            className="mark-comp-popup-cust-size"
            show={PreviousTabPopupShow}
            onHide={() => setPreviousTabPopup(false)}
            aria-labelledby="PreviousTabPopupLabel"
            backdrop="static"
            keyboard={false}
            centered
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body signout-modal">
                  <h3 className="pt-4">
                    Going back will lead to the loss of unsaved data
                  </h3>
                </div>
                <div className="modal-footer la-app-modal-footer">
                  <button
                    type="button"
                    className="lm-button2"
                    onClick={PreviousTabPopupClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="lm-button1"
                    onClick={backPreviousTab}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* <!--back to Previous Tab Popup / Modal --> */}
        </div>
      )}
    </>
  );
}

export default BusinessHistory;
