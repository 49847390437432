import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import lm_verified from '../../assets/icons/lm-verified.svg';
import lm_unverified from '../../assets/icons/lm-unverified.svg';
import { useParams } from "react-router-dom";
import { getSessionId } from "../../services/storageData";
import { decryptText } from "../../helpers/DynamicFunctions";
import { GetVerification } from "../../services/loanApplicationServices";
import no_data_icon from '../../assets/img/no_data_icon.svg';

function Verification() {
    const [loading, setLoading] = useState(true);
    const [verificationData, setVerificationData] = useState([]);
    const params = useParams();

    useEffect(() => {
        document.title = "Verification - Loan Application";
        let oid = decryptText(params.loanId);
        getVerification(oid);
    }, [params.loanId]);

    const getVerification = async (loanId) => {
        try {
            setLoading(true);
            if (loanId && parseInt(loanId) > 0) {
                let queryObj = {
                    LoanId: loanId,
                    SessionId: getSessionId(),
                };
                const response = await GetVerification(queryObj);
                if (response.status === 200) {
                    console.log("response", response);
                    setVerificationData(response.resultObject);
                }
            }
        } catch (error) {
            console.error("Error in Get Verification Details:", error);
        } finally {
            setLoading(false);
        }
    }

    const createTableData = (data) => [
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">Borrower First Name</div>
                    <div className="verification-value">{data.name || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.nameMsg || "-"}</div>
            ),
            status: data.status,
        },
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">Borrower Last Name</div>
                    <div className="verification-value">{data.lastName || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.lastNameMsg || "-"}</div>
            ),
            status: data.lStatus,
        },
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">Borrower Email Address</div>
                    <div className="verification-value">{data.bEmail || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.bEmailMsg || "-"}</div>
            ),
            status: data.bEmailStatus,
        },
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">Borrower Contact Number</div>
                    <div className="verification-value">{data.bContactNo || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.bContactNoMsg || "-"}</div>
            ),
            status: data.bContactNoStatus,
        },
        // { 
        //     thirdParty: (
        //         <>
        //             <div className="verification-label text-light">Residential Address</div>
        //             <div className="verification-value">{data.residentialAddress || "-"}</div>
        //         </>
        //     ),
        //     borrower: (
        //         <div className="verification-value">{data.residentialAddressMsg || "-"}</div>
        //     ),
        //     status: data.addressStatus,
        // },
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">City</div>
                    <div className="verification-value">{data.city || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.cityMsg || "-"}</div>
            ),
            status: data.cityStatus,
        },
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">State</div>
                    <div className="verification-value">{data.state || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.stateMsg || "-"}</div>
            ),
            status: data.stateStatus,
        },
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">ZipCode</div>
                    <div className="verification-value">{data.zipCode || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.zipCodeMsg || "-"}</div>
            ),
            status: data.zipCodeStatus,
        },
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">Date of Birth</div>
                    <div className="verification-value">{data.dob || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.dobMsg || "-"}</div>
            ),
            status: data.dobStatus,
        },
        { 
            thirdParty: (
                <>
                    <div className="verification-label text-light">Last four digits of SSN</div>
                    <div className="verification-value">{data.ssn || "-"}</div>
                </>
            ),
            borrower: (
                <div className="verification-value">{data.ssnMsg || "-"}</div>
            ),
            status: data.ssnStatus,
        },
    ];

    return (
        <>
            <SidePanel />
            <ProfileHeader />
            {loading ? (<Preloader />) : (
                <div>
                    <div className="verification-wrap pb-5">
                        {verificationData.length > 0 ? (
                            verificationData.map((data, index) => (
                                <div key={index} className="lm-card p-0 mb-4">
                                    <div className="d-flex justify-content-between p-3 table-head">
                                        <h3 className="lm-card-heading mb-0">{data.businessName || `Verification Data ${index + 1}`}</h3>
                                    </div>
                                    <table className="lm-inner-form-grid">
                                        <thead>
                                            <tr>
                                                <th className="left">THIRD PARTY</th>
                                                <th className="center">BORROWER</th>
                                                <th className="right">STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {createTableData(data).map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td className="left">{row.thirdParty}</td>
                                                    <td className="center">{row.borrower}</td>
                                                    <td className={`right ${(row.status || '').toLowerCase()}`}>
                                                        <div>
                                                            <img 
                                                                className="lm-grid-edit-icon"
                                                                src={row.status === "Verified" ? lm_verified : lm_unverified} 
                                                                alt="" 
                                                            />
                                                            {row.status || 'N/A'}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))
                        ) : (
                            <div className='db-card mt-4'>
                                <div className='no-data-card text-center place-content-center'>
                                    <img src={no_data_icon} alt="" />
                                    <h3 className='f-700 mb-2'>No data found</h3>
                                    <p className='text-light'>All relevant information will be displayed
                                        <br />
                                        here in the nearest future</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}



export default Verification;