import { createRef, useEffect, useRef, useState } from "react";
import { Header, Footer } from "@components";
import Select, { createFilter } from 'react-select';
import { Link } from 'react-router-dom';
import '../../styles/Register.css';
import '../../styles/WebsiteStyle.css';
import { Dropdown } from "bootstrap";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import Toaster from "../../../plugins/Toaster";
import ReCAPTCHA from 'react-google-recaptcha';
import Constants from "../../../helpers/Constants";
import { getUSAStates, register } from "../../../services/commonService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import reducersAndActions from "../../../_redux/slices/index";
import { getMasterList } from "../../../services/utilServices";
import Preloader from "../../../plugins/Preloader";
import login_error_icon from '../../../assets/icons/login-error-icon.svg';

const advisorCodeValidator = (control) => {
  if (!control.value) {
    return null;
  } else if (control.value && control.value.length !== 5) {
    return { advisorCodeTooShort: true };
  }
  return null;
};

const zipCodeValidator = (control) => {
  if (!control.value) {
    return null;
  } else if (control.value && control.value.length !== 5) {
    return { zipCodeTooShort: true };
  }
  return null;
};

const businessNameValidator = (control) => {
  // check if control.value only contains space or not
  if (control.value && control.value.trim() === "") {
    return { extraSpaces: true };
  }
  if (!control.value) {
    return null;
  } else if (control.value && control.value.length > 100) {
    return { businessNameIssue: true };
  }
  return null;
};

const borrowAmountValidator = (control) => {
  if (!control.value) {
    return null;
  } else if (control.value && Number(control.value.split(",").join("")) <= 25000) {
    return { borrowAmountIssue: true };
  } else if (control.value && Number(control.value.split(",").join("")) > 10000000000) {
    return { borrowAmountMaxIssue: true };
  }
  return null;
};

const formatNumberWithCommas = (number) => {
  if (number.includes(".")) {
    let [integerPart, decimalPart] = number.toString().split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (decimalPart) {
      // limit the decimalPart digits upto 2 is its more than 2
      if (decimalPart.length > 2) {
        decimalPart = decimalPart.substring(0, 2);
      }
      return integerPart + "." + decimalPart;
    } else {
      return integerPart + ".";
    }
  } else {
    let integerPart = number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return integerPart;
  }
}

const contactNumberValidate = (control) => {
  // check if control.value only contains space or not
  let phoneValue = control.value && control.value.split("(").join("").split(")").join("").split("-").join("").split(" ").join("");
  if (phoneValue && phoneValue.trim() === "") {
    return { required: true };
  } else if (!phoneValue) {
    return { required: true };
  }
  if (!control.value) {
    return null;
  }
  if (phoneValue && phoneValue.length !== 10) {
    return { contactNumberIssue: true };
  }
  return null;
};

// Form Groups
const firstForm = FormBuilder.group({
  BorrowAmount: ["", [Validators.required, borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
  Purpose: ["", Validators.required],
  TypeOfBusiness: ["", Validators.required],
  BusinessName: ["", [Validators.required, businessNameValidator]],
  State: ["", Validators.required],
  advisorCode: ["", advisorCodeValidator],
});

const secondForm = FormBuilder.group({
  FirstName: ["", [Validators.required, Validators.pattern("^[a-zA-Z]*$")]],
  LastName: ["", Validators.pattern("^[a-zA-Z]*$")],
  BZipCode: ["", [Validators.required, zipCodeValidator, Validators.pattern("^[0-9]*$")]],
  ContactNo: ["", [Validators.required, contactNumberValidate, Validators.pattern("^[0-9 ()-]*$")]],
  EmailAddress: ["", [Validators.required, Validators.email]],
});

// select Dropdown options
let objectiveOptions = [];
let businessTypeOptions = [];
let stateLists = [];
const DropdownOptionsForStatus = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' }
];


function UseForm({ formData, onFormDataChange }) {
  // Meta Title
  useEffect(() => {
    document.title = "Request form - Loan Mantra";
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const errorbox = useSelector((state) => state.errorbox);
  const objectiveRef = createRef();
  const businessRef = createRef();
  const stateRef = createRef();
  let isVerified = false;
  let policyFlag = false;

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "start"
  };

  // Hooks below
  useEffect(() => {
    let queryArr = ["TypeOfBusness", "LoanReason"];
    getCountryStates();
    for (let i = 0; i < queryArr.length; i++) {
      getMasterList(queryArr[i]).then(data => {
        if (data.status == 200) {
          if (queryArr[i] == "TypeOfBusness") {
            businessTypeOptions = JSON.parse(data.resultObject);
            // businessTypeOptions.unshift({ value: null, label: "Select.." });
          } else {
            objectiveOptions = JSON.parse(data.resultObject);
            // objectiveOptions.unshift({ value: null, label: "Select.." });
          }
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }, []);

  // Unmounting the component below
  useEffect(() => {
    return () => {
      firstForm.reset();
      secondForm.reset();
    }
  }, []);


  const getCountryStates = async () => {
    try {
      const states = await getUSAStates();
      stateLists = states;
    } catch (err) {
      console.log(err);
    }
  }



  const FieldControlInput = ({ name, label, id, placeholder, required, errorMessage, patternMessage, patternRequired, value, onChange }) => {
    return (
      <FieldControl
        name={name}
        render={({ handler, touched, hasError, meta }) => {
          const { value: handlerValue, onChange: handlerOnChange, ...restHandler } = handler();

          const handleChange = (e) => {
            handlerOnChange(e);  // Update the internal state of FieldControl
            onChange(e.target.value);  // Update the parent component state
          };

          return (
            <div className="form-group">
              <label htmlFor={name}>
                {label}{required && <span className="asterisk"></span>}
              </label>
              <input
                type="text"
                className="form-control"
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                {...restHandler}
              />

              {/* Error messages below */}
              {required && <>
                <span className="error-message">{touched && hasError("required") && `${errorMessage}`}</span>
                {touched && hasError("required") && <br />}
              </>}
              {(required || patternRequired) && <>
                <span className="error-message">{touched && hasError("pattern") && `${patternMessage}`}</span>
                {touched && hasError("pattern") && <br />}
              </>}
              {required && <>
                <span className="error-message">{touched && hasError("zipCodeTooShort") && `Zip Code should be of 5 digits.`}</span>
              </>}
            </div>
          );
        }}
        meta={{ placeholder: placeholder }}
      />
    );
  };



  const handleSecondForm = (e) => {
    e.preventDefault();
  }


  return (
    <>

      {/* Loader */}
      {loading ? <Preloader /> : null}

      {/* Main Body */}


      <div >

        {/* validation-box start*/}
        {/* validation-box start*/}
        {
          errorbox.visible && <div className="register-validation-box">
            <img className="mr-2" src={login_error_icon} alt="" />
            {errorbox.message}
          </div>
        }





        <FieldGroup
          control={secondForm}
          render={({ get, invalid }) => (
            <form>
              <div className="row mt-4 mb-5 pb-2">
                <div className="col-md-4 col-lg-3">
                  <FieldControlInput
                    name="firstName"
                    label="First name"
                    id="FirstName"
                    placeholder="Peter"
                    required={true}
                    value={formData.firstName}
                    errorMessage="First name is required"
                    patternMessage="First name should only contain text"
                    onChange={(value) => onFormDataChange('firstName', value)}
                  />
                </div>
                <div className="col-md-4 col-lg-3">
                  <FieldControlInput
                    name="lastName"
                    label="Last name"
                    id="LastName"
                    placeholder="Darvin"
                    required={false}
                    value={formData.lastName}
                    errorMessage="Last name is required"
                    patternMessage="Last name should only contain text"
                    onChange={(value) => onFormDataChange('lastName', value)}
                  />
                </div>
                <div className="col-md-4 col-lg-3">
                  <FieldControlInput
                    name="company"
                    label="Company / Entity"
                    id="Company"
                    placeholder="Enter Company / Entity"
                    required={false}
                    value={formData.company}
                    errorMessage="Company name is required"
                    patternMessage="Company name should only contain text"
                    onChange={(value) => onFormDataChange('company', value)}
                  />
                </div>
                <div className="col-md-4 col-lg-3">
                  <FieldControlInput
                    name="email"
                    label="Email address"
                    id="EmailAddress"
                    placeholder="peterdarvinosco@gmail.com"
                    required={true}
                    value={formData.email}
                    errorMessage="Email is required"
                    patternMessage="Email should be valid"
                    onChange={(value) => onFormDataChange('email', value)}
                  />
                </div>
                <div className="col-md-4 col-lg-3">
                  <FieldControl
                    name="contactNo"
                    render={({ handler, touched, hasError, meta }) => {
                      const { value: handlerValue, onChange: handlerOnChange, ...restHandler } = handler();

                      const standardizePhoneNumber = (e) => {
                        if (e.target.value && e.target.value[0] === "-") {
                          e.target.value = e.target.value.slice(1, e.target.value.length);
                        }
                        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                        const formattedPhoneNumber = e.target.value.replace(phoneRegex, "($1) $2-$3");
                        e.target.value = formattedPhoneNumber;
                        handlerOnChange(e);
                        onFormDataChange('contactNo', e.target.value);
                      };

                      return (
                        <div className="form-group">
                          <label htmlFor="ContactNo">
                            Mobile number<span className="asterisk"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="ContactNo"
                            placeholder="(877) 847-1134"
                            value={formData.contactNo}
                            onChange={standardizePhoneNumber}
                            {...restHandler}
                          />

                          {/* Error messages below */}
                          {touched && hasError("required") && <><span className="error-message">Mobile Number is required</span><br /></>}
                          {touched && hasError("contactNumberIssue") && <><span className="error-message">Mobile number should be of 10 digits.</span><br /></>}
                          {touched && hasError("pattern") && <><span className="error-message">Mobile number should be of numeric value.</span><br /></>}
                        </div>
                      );
                    }}
                    meta={{ placeholder: "(877) 847-1134" }}
                  />
                </div>
                <div className="col-md-4 col-lg-3">
                  <FieldControl
                    name="state"
                    render={({ handler, touched, hasError, meta }) =>
                    (<div className="form-group">
                      <label htmlFor="State"> Status <span className="asterisk"></span></label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        id="State"
                        name="State"
                        options={DropdownOptionsForStatus}
                        {...handler()}
                        value={formData.state}
                        onChange={(selected) => onFormDataChange("state", selected)}
                      />

                      {/* Error messages below */}
                      <span className="error-message">{touched && hasError("required") && `Business state is required`}</span>
                    </div>)
                    }
                  />
                </div>
              </div>
            </form>
          )}
        />


      </div>


    </>
  );
}

export default UseForm;
