import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import edit_pen from '../../assets/icons/edit-pen.svg';
import download_icon from '../../assets/icons/download-icon.svg';
import Info_tooltip from '../../assets/icons/Info-tooltip.svg';
import DynamicTable from '../../plugins/DynamicTable';
import { businessCashFlowColoums, personalCashFlowColoums } from '../../helpers/DropdownsAndMappers';
import { getOwnerListofBusinessCashflow, GetOwnersListofPersonalCashflowById, GetGlobalCashFlowDetailsById, InsertAndUpdateBusinessCashflow, GetCashflowDetailsReport } from "../../services/cashflowServices";
import { getSessionId } from '../../services/storageData';
import FormField from "../../components/FormField/FormField";
import { FieldControl, FieldGroup, FormBuilder } from 'react-reactive-form';
import { encryptText, formatNumberWithCommas } from '../../helpers/DynamicFunctions';
import reducersAndActions from '../../_redux/slices';
import Constants from '../../helpers/Constants';
import { useDispatch } from 'react-redux';
import AmountContainer from '../../components/Amount/AmountContainer';


let annualSummaryGroup = FormBuilder.group({
    gcfId: [""],
    totalTransaction: [""],
    bankLoanfield: [""],
    bankLoanfield1: [""],
    bInterestRate: [""],
    bTerm: [""],
    bProjectedMonthlyPayment: [""],
    debenture: [""],
    debenture1: [""],
    dInterestRate: [""],
    dTerm: [""],
    dProjectedMonthlyPayment: [""],
    borrowerInjection: [""],
    borrowerInjection1: [""],
    ocCashFlow1: [""],
    ocCashFlow2: [""],
    ocCashFlow3: [""],
    ocCashFlow4: [""],
    oDiscretionaryIncome1: [""],
    oDiscretionaryIncome2: [""],
    oDiscretionaryIncome3: [""],
    oDiscretionaryIncome4: [""],
    o2DiscretionaryIncome1: [""],
    o2DiscretionaryIncome2: [""],
    o2DiscretionaryIncome3: [""],
    o2DiscretionaryIncome4: [""],
    o3DiscretionaryIncome1: [""],
    o3DiscretionaryIncome2: [""],
    o3DiscretionaryIncome3: [""],
    o3DiscretionaryIncome4: [""],
    o4DiscretionaryIncome1: [""],
    o4DiscretionaryIncome2: [""],
    o4DiscretionaryIncome3: [""],
    o4DiscretionaryIncome4: [""],
    total1: [""],
    total2: [""],
    total3: [""],
    total4: [""],
    projectedAggregateDebt: [""],
    globalDebtCoverageRatio1: [""],
    globalDebtCoverageRatio2: [""],
    globalDebtCoverageRatio3: [""],
    globalDebtCoverageRatio4: [""],
    month: [""],
});

function CashFlow(props) {
    // Meta Title
    useEffect(() => {
        document.title = "Cash Flow - Loan Application";
    }, []);

    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    // Popup Function
    const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
    const PreviousTabPopupClose = () => setPreviousTabPopup(false);

    // Table check box functionallity
    const [selectedRows, setSelectedRows] = useState({});
    const [allSelected, setAllSelected] = useState(false);

    const [businessCashFlow, setBusinessCashFlow] = useState([]);
    const [totalDataBCF, setTotalDataBCF] = useState(0);
    const [inquiriesBCF, setInquiriesBCF] = useState([]);
    const [pageIndexBCF, setPageIndexBCF] = useState(1);
    const [pageSizeBCF, setPageSizeBCF] = useState(5);


    const [personalCashflow, setPeronalCashflow] = useState([]);
    const [totalDataPCF, setTotalDataPCF] = useState(0);
    const [inquiriesPCF, setInquiriesPCF] = useState([]);
    const [pageIndexPCF, setPageIndexPCF] = useState(1);
    const [pageSizePCF, setPageSizePCF] = useState(5);

    const currentYear = new Date().getFullYear();
    const yearsList = [currentYear - 3, currentYear - 2, currentYear - 1, currentYear];

    useEffect(() => {
        const getAllCashFlowData = async () => {
            try {
                setLoading(true);
                await Promise.all([getBusinessCashflowList(), getPersonalCashflowList(), getGlobalCashFlowDetails()]);
                setLoading(false);
            } catch (error) {
                console.error("Error from CashFlow", error);
            }
        }
        getAllCashFlowData();
    }, [pageIndexBCF, pageSizeBCF, pageIndexPCF, pageSizePCF]);

    const getBusinessCashflowList = async () => {
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid,
            PageIndex: pageIndexBCF,
            PageSize: pageSizeBCF
        };
        const data = await getOwnerListofBusinessCashflow(params);
        if (data.resultObject) {
            setBusinessCashFlow(JSON.parse(data.resultObject));
            let total = data.message.split(" ");
            setTotalDataBCF(Number(total[1]));
        }
    }
    const getPersonalCashflowList = async () => {
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid,
            PageIndex: pageIndexPCF,
            PageSize: pageSizePCF
        };
        const data = await GetOwnersListofPersonalCashflowById(params);
        if (data.resultObject) {
            setPeronalCashflow(JSON.parse(data.resultObject));
            let total = data.message.split(" ");
            setTotalDataPCF(Number(total[1]));
        }
    }

    const getGlobalCashFlowDetails = async () => {
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid
        };
        const data = await GetGlobalCashFlowDetailsById(params);
        if (data.resultObject) {
            annualSummaryGroup.patchValue(data.resultObject);
        }
    }

    const businessCashFlowTable = (business) => {
        return (
            <tr key={business.Id} className={selectedRows[business.Id] ? 'selected-row' : ''}>
                <td>{business.BusinessName}</td>
                <td>{business.TaxID}</td>
                <td><span className="text-light">{business.InceptionDate}</span></td>
                <td>{business.NAICS_Code}</td>
                <td>
                    <div className={business.entityType}>
                        <div className={`d-flex oc-epc-tag ${business.IsOC === "True" ? "OC" : business.IsEPC === "True" ? "EPC" : " "}`}>
                            <span className="epc-tag">EPC</span>
                            <span className="oc-tag">OC</span>
                        </div>
                    </div>
                </td>
                <td>
                    <Link to={`/cash-flow-business?data=${encryptText(`appLoanId=${props.loanid}&businessID=${business.Id}&appNumber=${props.applicationNumber}`)}`}>
                        <img className="lm-grid-edit-icon" src={edit_pen} alt="" />
                    </Link>
                </td>
            </tr >
        )
    }

    const personalCashFlowTable = (personal) => {
        const personalName = JSON.stringify(personal.Name + "%20" + personal.LastName);
        return (
            <tr key={personal.ID}>
                <td>{personal.Name + " " + personal.LastName}</td>
                <td>
                    {personal.Title}
                </td>
                <td>{personal.Email}</td>
                <td>{personal.Ownership}</td>
                <td>
                    <Link to={`/cash-flow-personal?data=${encodeURIComponent(encryptText(`applicationName=${personalName}&personalID=${personal.ID}&appNumber=${props.applicationNumber}&appLoanId=${props.loanid}`))}`}>
                        <img className="lm-grid-edit-icon" src={edit_pen} alt="" />
                    </Link>
                </td>
            </tr>
        )
    }

    const calculateBankLoanPercentage = () => {
        return toFixedOrZero(100 - (getValue(annualSummaryGroup.value.borrowerInjection) + getValue(annualSummaryGroup.value.debenture)));
    };

    const calculateBankLoanAmount = () => {
        return (getValue(annualSummaryGroup.value.totalTransaction) * calculateBankLoanPercentage()) / 100
    };

    const calculateDebentureAmount = () => {
        return getValue(annualSummaryGroup.value.totalTransaction) - (calculateBankLoanAmount()) - getValue(annualSummaryGroup.value.borrowerInjection1)
    };

    const PMT = (rate, nper, pv, fv = 0, type = 0) => {
        if (nper === 0) {
            return 0
        }

        if (rate === 0) {
            return -(pv + fv) / nper;
        } else {
            const pvif = Math.pow(1 + rate, nper);
            if (pvif === 1) {
                // Avoid division by zero when rate is extremely small
                return -(pv + fv) / nper;
            }

            let pmt = rate * (pv * pvif + fv) / (pvif - 1);

            if (type === 1) {
                pmt /= (1 + rate);
            }

            return -pmt;
        }

    }

    const getValue = (value) => {
        if (typeof value === "string") {
            value = value.replace(/,/g, '');
        }

        value = parseFloat(value);
        return isNaN(value) ? 0 : value;
    };

    const calculateTotalValue = (Year) => {
        const totalValue = getValue(annualSummaryGroup.value[`ocCashFlow${Year}`]) +
            getValue(annualSummaryGroup.value[`oDiscretionaryIncome${Year}`]) + getValue(annualSummaryGroup.value[`o2DiscretionaryIncome${Year}`]) +
            getValue(annualSummaryGroup.value[`o3DiscretionaryIncome${Year}`]) + getValue(annualSummaryGroup.value[`o4DiscretionaryIncome${Year}`]);
        return toFixedOrZero(totalValue);
    };

    const calulateAggregateProjectedDebt = () => {
        const businessProjected = PMT(getValue(annualSummaryGroup.value.bInterestRate) / 100 / 12, getValue(annualSummaryGroup.value.bTerm) * 12, -calculateBankLoanAmount())
        const debentureProjected = PMT(getValue(annualSummaryGroup.value.dInterestRate) / 100 / 12, getValue(annualSummaryGroup.value.dTerm) * 12, -calculateDebentureAmount())
        return toFixedOrZero((businessProjected + debentureProjected) * 12);
    }


    const handleSaveGlobalCashFlow = async () => {
        setLoading(true);
        const projectedAggregateDebt = calulateAggregateProjectedDebt();

        let data = {
            LoanId: props.loanid,
            ...annualSummaryGroup.value,
            gcfId: props.loanid,
            bankLoanfield: calculateBankLoanPercentage(),
            bankLoanfield1: calculateBankLoanAmount(),
            debenture1: calculateDebentureAmount(),

            total1: calculateTotalValue(1),
            total2: calculateTotalValue(2),
            total3: calculateTotalValue(3),
            total4: calculateTotalValue(4),

            globalDebtCoverageRatio1: calculateTotalValue(1) / projectedAggregateDebt,
            globalDebtCoverageRatio2: calculateTotalValue(2) / projectedAggregateDebt,
            globalDebtCoverageRatio3: calculateTotalValue(3) / projectedAggregateDebt,
            globalDebtCoverageRatio4: calculateTotalValue(4) / projectedAggregateDebt,

            projectedAggregateDebt: projectedAggregateDebt
        };

        Object.keys(data).map(key => {
            if (key !== "month")
                data[key] = getValue(data[key]);
        })

        const update = await InsertAndUpdateBusinessCashflow(data, "InsertUpdateGlobalCashFlow");
        if (update.status === 200) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: update.message, type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: update?.response?.data?.title, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        setLoading(false);
    }

    const handleDownloadCashflowReport = async () => {
        setLoading(true);
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid
        };
        const data = await GetCashflowDetailsReport(params);
        if (data.status === 200) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "File downloaded successfully", type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Something went wrong!", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        setLoading(false);
    }

    const toFixedOrZero = (value) => {
        const number = parseFloat(value);
        return isNaN(number) ? '0.00' : number.toFixed(2);
    };


    const safeDivide = (numerator, denominator) => {
        if (getValue(denominator) === 0) {
            return 0; // Return 0 when dividing by zero to avoid Infinity
        }
        return getValue(numerator) / getValue(denominator);
    };


    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="cashflow-wrap pb-5">

                    <div className='d-flex justify-content-end'>
                        <a className="lm-button3" onClick={handleDownloadCashflowReport}>
                            <img className="mr-1 ml-auto" src={download_icon} alt="" /> Download report
                        </a>
                    </div>

                    {/* <!-- Business cash flow start --> */}
                    <div className="grid-head d-flex justify-content-between mt-3">
                        <h3>Business Cash Flow</h3>
                    </div>

                    <DynamicTable
                        data={businessCashFlow}
                        dynamicTableDisplay={businessCashFlowTable}
                        columns={businessCashFlowColoums}
                        changePageSize={setPageSizeBCF}
                        pageSize={pageSizeBCF}
                        total={totalDataBCF}
                        setList={setInquiriesBCF}
                        setPageIndex={setPageIndexBCF}
                        pageIndex={pageIndexBCF}
                    />

                    {/* <!-- Business cash flow end --> */}

                    {/* <!-- Personal cash flow start --> */}
                    <div className="grid-head d-flex justify-content-between mt-3">
                        <h3>Personal Cash Flow</h3>
                    </div>

                    <DynamicTable
                        data={personalCashflow}
                        dynamicTableDisplay={personalCashFlowTable}
                        columns={personalCashFlowColoums}
                        changePageSize={setPageSizePCF}
                        pageSize={pageSizePCF}
                        total={totalDataPCF}
                        setList={setInquiriesPCF}
                        setPageIndex={setPageIndexPCF}
                        pageIndex={pageIndexPCF}
                    />

                    {/* <!-- Personal cash flow end --> */}

                    {/* Annual summary start */}
                    <FieldGroup
                        control={annualSummaryGroup}
                        render={({ get, invalid }) => (
                            <form>
                                <div className="lm-card p-0 mt-3">
                                    <div className="d-flex justify-content-between p-3">
                                        <h3 className="lm-card-heading mb-0">Annual summary</h3>
                                    </div>
                                    <div className="p-3">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-7 col-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <AmountContainer
                                                            name="totalTransaction"
                                                            id="Total_Transaction"
                                                            placeholder="0.00"
                                                            label="Total Transaction"
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group input-percentage">
                                                            <AmountContainer
                                                                name="bInterestRate"
                                                                id="Interest_Rate"
                                                                placeholder="0.00"
                                                                label="Interest Rate"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="bTerm"
                                                                id="Term_years"
                                                                placeholder="Enter a Term (years)"
                                                                label="Term (years)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group input-percentage">
                                                            <AmountContainer
                                                                name="debenture"
                                                                id="Debenture"
                                                                placeholder="0.00"
                                                                label="Debenture (%)"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group input-percentage">
                                                            <AmountContainer
                                                                name="dInterestRate"
                                                                id="Debenture_Interest_Rate"
                                                                placeholder="0.00"
                                                                label="Interest Rate"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group input-dollar">
                                                            <FormField
                                                                name="dTerm"
                                                                id="Debenture_Term_years"
                                                                placeholder="0.00"
                                                                label="Term (years)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group input-percentage">
                                                            <AmountContainer
                                                                name="borrowerInjection"
                                                                id="Borrower_Injection_percentage"
                                                                placeholder="0.00"
                                                                label="Borrower Injection (%)" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                name="borrowerInjection1"
                                                                id="Borrower_Injection_dollar"
                                                                placeholder="0.00"
                                                                label="Borrower Injection ($)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-1 col-12">

                                            </div>
                                            <div className="col-lg-4 col-md-4 col-12">
                                                <div className="cf-value-card">
                                                    <div className="title">
                                                        bank loan (% of transaction)
                                                    </div>
                                                    <div className="value">
                                                        {calculateBankLoanPercentage()} %
                                                    </div>
                                                </div>
                                                <div className="cf-value-card">
                                                    <div className="title">
                                                        bank loan ($ of transaction)
                                                    </div>
                                                    <div className="value">
                                                        $ {formatNumberWithCommas(calculateBankLoanAmount()) || "0.00"}
                                                    </div>
                                                </div>
                                                <div className="cf-value-card">
                                                    <div className="title">
                                                        Projected Monthly Payment
                                                    </div>
                                                    <div className="value">
                                                        {toFixedOrZero(PMT(getValue(annualSummaryGroup.value.bInterestRate) / 100 / 12, getValue(annualSummaryGroup.value.bTerm) * 12, -calculateBankLoanAmount())) || "0.00"} %
                                                    </div>
                                                </div>
                                                <div className="cf-value-card">
                                                    <div className="title">
                                                        Debenture ($)
                                                    </div>
                                                    <div className="value">
                                                        $ {formatNumberWithCommas(calculateDebentureAmount()) || "0.00"}
                                                    </div>
                                                </div>
                                                <div className="cf-value-card">
                                                    <div className="title">
                                                        Projected Monthly Payment
                                                    </div>
                                                    <div className="value">
                                                        {toFixedOrZero(PMT(getValue(annualSummaryGroup.value.dInterestRate) / 100 / 12, getValue(annualSummaryGroup.value.dTerm) * 12, -calculateDebentureAmount())) || "0.00"} %
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <!-- lm common divider to break the section  --> */}
                                    <div className="lm-divider mb-0"></div>
                                    {/* <!-- lm common divider to break the section  --> */}
                                    <div className="p-3">
                                        <span className="label text-dark mb-0"><b>GLOBAL CASH FLOW PER PERIOD</b></span>
                                    </div>


                                    <table className="lm-inner-form-grid table-sheet">
                                        <thead className="bg-grey-thead">
                                            <tr>
                                                <th>
                                                    Name
                                                </th>
                                                {
                                                    yearsList.map((year, index) => (
                                                        <th key={index} className="text-center">
                                                            {year}
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>OC Cash Flow</b></td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="ocCashFlow1"
                                                            id="OC_Cash_Flow_2020"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="ocCashFlow2"
                                                            id="OC_Cash_Flow_2021"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="ocCashFlow3"
                                                            id="OC_Cash_Flow_2022"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="ocCashFlow4"
                                                            id="OC_Cash_Flow_2023"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Owner #1</b></td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="oDiscretionaryIncome1"
                                                            id="Owner_1_Discretionary_Income_2020"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="oDiscretionaryIncome2"
                                                            id="Owner_1_Discretionary_Income_2021"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="oDiscretionaryIncome3"
                                                            id="Owner_1_Discretionary_Income_2022"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <FormField
                                                            name="oDiscretionaryIncome4"
                                                            id="Owner_1_Discretionary_Income_2023"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Owner #2</b></td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o2DiscretionaryIncome1"
                                                            id="Owner_2_Discretionary_Income_2020"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o2DiscretionaryIncome2"
                                                            id="Owner_2_Discretionary_Income_2021"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o2DiscretionaryIncome3"
                                                            id="Owner_2_Discretionary_Income_2022"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o2DiscretionaryIncome4"
                                                            id="Owner_2_Discretionary_Income_2023"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Owner #3</b></td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o3DiscretionaryIncome1"
                                                            id="Owner_3_Discretionary_Income_2020"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o3DiscretionaryIncome2"
                                                            id="Owner_3_Discretionary_Income_2021"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o3DiscretionaryIncome3"
                                                            id="Owner_3_Discretionary_Income_2022"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o3DiscretionaryIncome4"
                                                            id="Owner_3_Discretionary_Income_2023"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Owner #4</b></td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o4DiscretionaryIncome1"
                                                            id="Owner_4_Discretionary_Income_2020"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o4DiscretionaryIncome2"
                                                            id="Owner_4_Discretionary_Income_2021"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o4DiscretionaryIncome3"
                                                            id="Owner_4_Discretionary_Income_2022"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-dollar">
                                                        <AmountContainer
                                                            name="o4DiscretionaryIncome4"
                                                            id="Owner_4_Discretionary_Income_2023"
                                                            placeholder="0.00"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="text-light">Total</span>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{calculateTotalValue(1)}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{calculateTotalValue(2)}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{calculateTotalValue(3)}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{calculateTotalValue(4)}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="text-light">Projected Aggregate Debt</span>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{calulateAggregateProjectedDebt()}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{calulateAggregateProjectedDebt()}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{calulateAggregateProjectedDebt()}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{calulateAggregateProjectedDebt()}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="text-light">Global Debt Coverage Ratio</span>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{toFixedOrZero(safeDivide(calculateTotalValue(1), calulateAggregateProjectedDebt())) || "-"}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{toFixedOrZero(safeDivide(calculateTotalValue(2), calulateAggregateProjectedDebt())) || "-"}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{toFixedOrZero(safeDivide(calculateTotalValue(3), calulateAggregateProjectedDebt())) || "-"}</b>
                                                </td>
                                                <td>
                                                    <b className="ml-3">{toFixedOrZero(safeDivide(calculateTotalValue(4), calulateAggregateProjectedDebt())) || "-"}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button2" onClick={() => setPreviousTabPopup(true)}>Back</button>
                                    <button type="button" className="lm-button1 ml-3" onClick={handleSaveGlobalCashFlow}>Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>


                {/* <!--back to Previous Tab Popup / Modal --> */}
                <Modal
                    className='mark-comp-popup-cust-size'
                    show={PreviousTabPopupShow}
                    onHide={() => setPreviousTabPopup(false)}
                    aria-labelledby="PreviousTabPopupLabel"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body signout-modal">
                                <h3 className="pt-4">
                                    Going back will lead to the loss of unsaved data
                                </h3>
                            </div>
                            <div className="modal-footer la-app-modal-footer">
                                <button type="button" className="lm-button2" onClick={PreviousTabPopupClose}>Cancel</button>
                                <button type="button" className="lm-button1" onClick={props.backPreviousTab}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* <!--back to Previous Tab Popup / Modal --> */}

            </div>)}

        </>
    );
}

export default CashFlow;