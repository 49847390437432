import axios from "../helpers/axiosConfig";
import Constants from "../helpers/Constants";

// Getting list of data for dropdowns
export const getMasterList = async (identifier) => {
    try {
        let queryObj = {};
        if(identifier) {
            queryObj["Identifier"] = identifier;
        }
        const response = await axios.get(`/api/User/GetMasterList`, { params: queryObj });
        if (response.data) {
            return response.data;
        } else {
            return response.error;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}