export const AllcheckformatDate = (dateString) => {
  // Check if dateString is valid (not null or undefined)
  if (dateString == null) {
    return null;
  }

  // Remove any trailing characters like a dot
  dateString = dateString.trim().replace(/\.$/, '');

  // Define regex patterns for various date formats
  const fullDatePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?$/;  // YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS
  const monthYearPattern = /^\d{2}\/\d{4}$/; // MM/YYYY
  const yearMonthPattern = /^\d{4}-\d{2}$/; // YYYY-MM
  const monthDayYearPattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY
  const monthDayYearSingleDigitPattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/; // M/D/YYYY or MM/D/YYYY or M/DD/YYYY

  // Check if the dateString matches the full date pattern
  if (fullDatePattern.test(dateString)) {
    // Validate that the date is correct (e.g., no 'NaN' values)
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return dateString;
    }
  }
  
  // Check if the dateString matches the month/year pattern
  if (monthYearPattern.test(dateString)) {
    const [month, year] = dateString.split('/').map(Number);
    if (month >= 1 && month <= 12 && year >= 1000 && year <= 9999) {
      return `${year}-${String(month).padStart(2, '0')}-01`;
    }
  }

  // Check if the dateString matches the year/month pattern
  if (yearMonthPattern.test(dateString)) {
    const [year, month] = dateString.split('-').map(Number);
    if (month >= 1 && month <= 12 && year >= 1000 && year <= 9999) {
      return `${year}-${String(month).padStart(2, '0')}-01`;
    }
  }

  // Check if the dateString matches the month/day/year pattern
  if (monthDayYearPattern.test(dateString)) {
    const [month, day, year] = dateString.split('/').map(Number);
    if (month >= 1 && month <= 12 && day >= 1 && day <= 31 && year >= 1000 && year <= 9999) {
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }
  }

  // Check if the dateString matches the month/day/year pattern with single digit month or day
  if (monthDayYearSingleDigitPattern.test(dateString)) {
    const [month, day, year] = dateString.split('/').map(Number);
    if (month >= 1 && month <= 12 && day >= 1 && day <= 31 && year >= 1000 && year <= 9999) {
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }
  }

  // Handle incomplete or invalid formats
  return null;
};
