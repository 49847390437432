import React, { createRef, useState, useEffect, useCallback } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import makeAnimated from 'react-select/animated';
import Preloader from '../plugins/Preloader';
import no_data_icon from '../assets/img/no_data_icon.svg';
import "./styles/LoanApplication.css";
import info_tooltip from '../assets/icons/Info-tooltip.svg';
import Modal from 'react-bootstrap/Modal'
import axiosInstance from '../helpers/axiosConfig';
import generate_report from '../assets/icons/generate_report.svg';
import new_application from '../assets/icons/new_application.svg';
import confirm_email from '../assets/icons/confirm_email.svg';
import trash_icon_grid from '../assets/icons/trash-icon-grid.svg';
import dummy_avtar from '../assets/img/dummy_avtar.png';
import close_popup from '../assets/icons/close_popup.svg';
import { Link } from 'react-router-dom';
import { AssignedAdvisorPartnerLenderToLoan, GetAdvisorPartnerLenderDetailsByLoanId, getDropdownAdvisorPartnerLenderList, getLoanApplicationList } from '../services/loanApplicationServices';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionId } from '../services/storageData';
import reducersAndActions from '../_redux/slices/index';
import Constants from '../helpers/Constants';
import { getMasterList } from '../services/utilServices';
import { formatNumberWithCommas, getInitials, encryptText, filterDataByRole, businessNameValidator, borrowAmountValidator, contactNumberValidate, validateNumber } from '../helpers/DynamicFunctions';
import DynamicTable from '../plugins/DynamicTable';
import { getUSAStates } from "../services/commonService";
import { InsertOrUpdateNewLoanExistingUser } from "../services/loanApplicationServices";
import { loanApplicationHeader } from '../helpers/DropdownsAndMappers';
import { debounce } from 'lodash';
import { FieldControl, FieldGroup, FormBuilder, Validators } from 'react-reactive-form';
import FormField from '../components/FormField/FormField';
import moment from 'moment';

const NewApplicationFormGroup = FormBuilder.group({
  BorrowAmount: ["", [Validators.required, borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
  Loandate: ["", Validators.required],
  Purpose: ["", Validators.required],
  TypeOfBusiness: ["", Validators.required],
  BusinessName: ["", [Validators.required, businessNameValidator]],
  BState: ["", Validators.required],
  BCity: ["", Validators.required],
  UserId: [""],
  Category: [""]
});

const formatDate = (date) => {
  return date ? moment(date).format('MM/DD/YYYY') : '';
};

let loanId;
let stateLists = [];
let stateListstemp = [];
let TypeOfBusnessOptionsTemp = [];
let LoanReasonOptionsTemp = [];
function LoanApplication() {

  const dispatch = useDispatch();
  const params = useParams();

  const [TypeOfBusnessOptions, setTypeOfBusnessOptions] = useState([]);

  const [LoanReasonOptions, setLoanReasonOptions] = useState([]);


  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [currentRole, setCurrentRole] = useState("");
  const [currentLoanId, setCurrentLoanId] = useState("");
  const [loanStatus, setLoanStatus] = useState({ value: "All", label: "All" });
  const [searchText, setSearchText] = useState("");

  const user = useSelector((state) => state.auth);

  const [loanApplicationList, setLoanApplicationList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [roleDropdownList, setRoleDropdownList] = useState([]);
  const [roleListing, setRoleListing] = useState([]);
  const [tempRolesAssigned, setTempRolesAssigned] = useState([]);

  // Popup function
  const [partnersPopupShow, setPartnersPopup] = useState(false);
  const partnersPopupClose = () => {
    setTempRolesAssigned([]);
    setRoleListing([]);
    setPartnersPopup(false)
  };

  // Cofirm modal pop function
  const [deleteApplicationData, setDeleteApplicationData] = useState({});
  const [newApplicationData, setNewApplicationData] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const confirmPopupClose = () => setConfirmPopup(false);
  const [fetchedUserId, setFetchedUserId] = useState(null);

  const businessRef = createRef();
  const objectiveRef = createRef();

  //customized Popup function
  const [isModalOpen, setApplicationPopup] = useState(false);

  const applicationPopupShow = () => {
    setApplicationPopup(true);
    NewApplicationFormGroup.reset();
    document.body.style.overflow = 'hidden'; // Prevent scrolling
  };

  const applicationPopupClose = () => {
    setApplicationPopup(false);
    document.body.style.overflow = ''; // Allow scrolling
  };


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Loan Application";

    const fetchData = async () => {
      await Promise.all([getTypesOfBusiness(), loanReason(), getStates(), getMasterListFunction()]);
    };
    fetchData();
  }, []);



  const getMasterListFunction = async () => {
    getMasterList("ApplicationStatus").then(data => {
      if (data.status == 200) {
        let responseData = JSON.parse(data.resultObject);
        responseData.push({ label: "All", value: "All" });
        responseData.sort((a, b) => a.label.localeCompare(b.label));
        setStatusOptions(responseData);
      }
    }).catch(err => {
      console.log(err);
    });
  }


  const getTypesOfBusiness = async () => {
    const data = await getMasterList("TypeOfBusness");
    if (data && data.status == 200) {
      let responseData1 = JSON.parse(data.resultObject);
      const desiredOrder = ["Sole Proprietor", "Limited Liability Company (LLC)", "C-Corporation", "Partnership", "Independent Contractor", "S-Corporation"];
      responseData1.sort((a, b) => {
        return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
      });

      TypeOfBusnessOptionsTemp = responseData1;
      setTypeOfBusnessOptions(responseData1);
    }
  }

  const loanReason = async () => {
    const data = await getMasterList("LoanReason");
    if (data && data.status == 200) {
      let responseData1 = JSON.parse(data.resultObject);
      const desiredOrder = [
        "Accounts Receivables Financing",
        "Business Acquisition",
        "Business Expansion",
        "Construction or Remodeling",
        "Equipment Financing",
        "Franchise Financing",
        "Partner Buyout",
        "Real Estate Financing",
        "Refinance Existing Debt",
        "Start a Business",
        "Working Capital",
        "Other"
      ];
      responseData1.sort((a, b) => {
        return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
      });

      LoanReasonOptionsTemp = responseData1;
      setLoanReasonOptions(responseData1);
    }
  }

  const getStates = async () => {
    const states = await getUSAStates();
    stateLists = states;
    stateListstemp = states;
  }

  // Fetch inquiries whenever page size or page index changes
  const debouncedGetLoanApplications = useCallback(debounce(() => {
    setLoading(true);
    loanApplicationDetails()
  }, 1000), [pageSize, pageIndex, searchText, loanStatus]);


  useEffect(() => {
    debouncedGetLoanApplications();
    return () => {
      debouncedGetLoanApplications.cancel();
    };
  }, [debouncedGetLoanApplications]);


  // Fetching LoanApplication Details
  const loanApplicationDetails = async () => {
    try {
      setLoading(true);
      let queryObj = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        UserId: user.user.id,
        RoleName: user.user.roleName,
        SessionId: getSessionId(),
        Status: loanStatus.value,
        SearchText: searchText
      };
      let response = await getLoanApplicationList(queryObj);
      if (response.status == 200 && response.resultObject) {

        let modifiedData = filterDataByRole(JSON.parse(response.resultObject), user.user.roleName);

        const userId = response.resultObject.UserId;
        setFetchedUserId(userId);


        setLoanApplicationList(modifiedData);
        let total = response.message.split(" ");
        setTotalData(Number(total[1]));
        setLoading(false);
        return modifiedData;
      } else {
        setLoading(false);
        setLoanApplicationList([]);
        setTotalData(0);
        return [];
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setLoanApplicationList([]);
      setTotalData(0);
    }
  }

  const assignModal = async (roleType, loanId) => {
    try {
      setPartnersPopup(true);
      setCurrentRole(roleType);
      setCurrentLoanId(loanId);
      setRoleDropdownList([]);

      let queryObj = {
        Type: roleType,
        SessionId: getSessionId()
      };
      setLoading(true)
      const dropdownlisting = await getDropdownAdvisorPartnerLenderList(queryObj);
      setLoading(false)
      if (dropdownlisting.status == 200) {
        if (Array.isArray(dropdownlisting.resultObject) && dropdownlisting.resultObject.length > 0) {
          setRoleDropdownList(dropdownlisting.resultObject);
        } else {
          setRoleDropdownList([]);
        }
      }

      queryObj["LoanId"] = loanId;
      setLoading(true)
      const modalRoleListing = await GetAdvisorPartnerLenderDetailsByLoanId(queryObj);
      setLoading(false)
      if (modalRoleListing.status == 200) {
        let finalData = modalRoleListing.resultObject ? JSON.parse(modalRoleListing.resultObject) : "";
        if (finalData && Array.isArray(finalData) && finalData.length > 0) {
          let assignObj = [];
          for (let elem of finalData) {
            elem.value = Number(elem.ID);
            elem.label = elem.FullName;

            assignObj.push({ label: elem.FullName, value: Number(elem.ID) });
          }
          setRoleListing(finalData);
          setTempRolesAssigned(assignObj);
        } else {
          setRoleListing([]);
          setTempRolesAssigned([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const assignUserModal = async (roleType) => {
    try {
      let userArr = [];
      for (let ids of tempRolesAssigned) {
        userArr.push(ids.value);
      }

      let queryObj = {
        Type: roleType,
        SessionId: getSessionId(),
        Users: userArr.join(","),
        LoanId: currentLoanId
      };
      setLoading(true)
      const assignedSuccess = await AssignedAdvisorPartnerLenderToLoan(queryObj);
      if (assignedSuccess.status == 200) {
        setLoading(false)
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: assignedSuccess.message, type: "success" }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        partnersPopupClose();
        loanApplicationDetails();
      }
    } catch (err) {
      setLoading(false)
      console.log(err);
    }
  }

  const selectAssignedRoles = (options) => {
    setTempRolesAssigned(options)
  }

  const convertInPresentableForTable = (users, type) => {
    if (!users) {
      return "";
    }
    let usersArr = users.split(",").filter(user => user).map(user => user.trim());
    if (usersArr.length > 1) {
      return `${usersArr.length} ${type}`;
    } else {
      return usersArr[0];
    }
  }


  const ConfirmEmail = (data) => {
    axiosInstance.get(`/api/LoanApplication/ResendUserInvitation?LoanId=${data.Id}&SessionId=${getSessionId()}&WebURL=${Constants.FRONTEND_URL}`)
      .then((data) => {
        setLoading(false);
        if (data.status == 200) {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.data.message, type: "success", }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        else {
          setLoading(false);
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      });
  }

  const deleteApplication = async () => {
    const parms = {
      params: { LoanId: deleteApplicationData.Id, SessionId: getSessionId(), ModifiedBy: user.user.id }
    }
    const deleteStatus = await axiosInstance.get(`/api/LoanApplication/DeleteLoanApplicationById`, parms)
    if (deleteStatus.status == 200) {
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: deleteStatus.data.message, type: "success", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      setConfirmPopup(false);
      loanApplicationDetails();
    }
    else {
      setLoading(false);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: deleteStatus.message, type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }

  // Generic Table Component
  const dynamicTableDisplay = (data) => {
    let classMapper = {
      "new": "new",
      "documents required": "new",
      "on hold": "new",
      "review required": "new",
      "closed": "closed",
      "completed": "closed",
      "in progress": "in progress",
      "complete": "closed",
    };
    const { ApplicationStatus, VerfiedStatus, BorrowAmount, BorrowerName, BusinessName, BusinessCategory, CreatedOn, SelectedAdvisors, SelectedPartners, SelectedLenders } = data;
    const { roleName } = user.user;
    const applicationStatus = ApplicationStatus.toLowerCase();
    const newApplicationList = ["new", "documents required", "on hold", "review required"];
    const closedApplicationList = ["closed", "completed", "complete"];
    const inProgressApplicationList = ["in progress"];
    const allApplicationList = ["all"];

    const formatName = (names) => names.split(',')[0] || names.split(',')[1];
    const extraCount = (names) => (names.split(',').slice(2).length || names.split(',').slice(1).length);

    const renderTooltip = (names) => (
      <span className="lm-tooltip" data-tooltip={names.split(',').slice(1)} data-tooltip-pos="up" data-tooltip-length="large">
        {extraCount(names) > 0 && <span className='plus-button'>+{extraCount(names)}</span>}
      </span>
    );

    const renderAssigned = (role, names, label) => (
      <td hidden={!data.hasOwnProperty(`Selected${label}s`) || roleName === role}>
        {names ? (
          <>
            {roleName === role ? (
              <span>{formatName(names)} {renderTooltip(names)}</span>
            ) : (
              <a onClick={() => { assignModal(label, data.Id) }} href="#" className={label.toLowerCase()}>{convertInPresentableForTable(names, label)}</a>
            )}
          </>
        ) : roleName !== role && (
          <a onClick={() => { assignModal(label, data.Id) }} data-toggle="modal" href="#" className={`add_${label.toLowerCase()} text-light`}>Add {label}</a>
        )}
      </td>
    );

    const renderRow = (statusClass) => (
      <tr className={statusClass}>
        <td>
          <div className='d-flex application_no_div'>
            <Link to={`/application-detail/${encryptText(data.Id)}`} className={`application_no d-flex ${VerfiedStatus === "False" ? "application_noColor" : ""}`}>
              {data.ApplicationNumber}
            </Link>
            {VerfiedStatus === "False" && (
              <span className="lm-tooltip" data-tooltip="Needs to be verified (e-mail address)." data-tooltip-pos="up" data-tooltip-length="large">
                <img src={info_tooltip} alt="" />
              </span>
            )}
          </div>
          <span className="created_date text-light">Created on {CreatedOn}</span>
        </td>
        <td>
          <div className="business_name small-text" title={BusinessName}>{BusinessName}</div>
        </td>
        <td hidden={["Admin", "Partner", "Advisor"].includes(roleName)}>
          <div className="borrower" title={BusinessCategory}>{BusinessCategory}</div>
        </td>
        <td hidden={roleName === "Lender"}>
          <div className="borrower" title={BorrowerName}>
            {BorrowerName ? BorrowerName.replace(/null/gi, '').trim() : 'No Borrower Name'}
          </div>
        </td>
        {renderAssigned("Advisor", SelectedAdvisors, "Advisor")}
        {renderAssigned("Partner", SelectedPartners, "Partner")}
        {renderAssigned("Lender", SelectedLenders, "Lender")}
        <td>
          <div className="loan_amount"><span className="text-light extra-small-text">$ </span>{formatNumberWithCommas(BorrowAmount)}</div>
        </td>
        <td>
          <div className="status">{ApplicationStatus.charAt(0).toUpperCase() + ApplicationStatus.slice(1).toLowerCase()}</div>
        </td>
        <td className="action-col">
          <div className="options_btn">
            <span className="option_dots">...</span>
            <div id="popoverContent" className="app_options">
              {roleName !== "Borrower" && (
                <>
                  <div><img src={generate_report} alt="Generate report" /> Generate report</div>
                  {VerfiedStatus === "False" && <div onClick={() => ConfirmEmail(data)}><img src={confirm_email} alt="Confirm email" /> Confirm e-mail address</div>}
                  {ApplicationStatus.toLowerCase() === "new" && roleName === "Admin" && (
                    <div onClick={() => { setConfirmPopup(true); setDeleteApplicationData(data); }}>
                      <img src={trash_icon_grid} alt="Delete application" /> Delete Application
                    </div>
                  )}
                </>
              )}
              <div onClick={() => { applicationPopupShow(true); setNewApplicationData(data); }}>
                <img src={new_application} alt="New Application" /> New application
              </div>
            </div>
          </div>
        </td>
      </tr>
    );

    if (newApplicationList.includes(applicationStatus)) {
      return renderRow("new");
    } else if (closedApplicationList.includes(applicationStatus)) {
      return renderRow("closed");
    } else if (inProgressApplicationList.includes(applicationStatus)) {
      return renderRow("in-progress");
    } else if (allApplicationList.includes(applicationStatus)) {
      return renderRow(classMapper[data.ApplicationStatus]);  // Assuming classMapper is provided somewhere else
    }

    return null;
  }

  const onDropDownChange = async (option) => {
    try {
      setPageIndex(1);
      setLoanStatus(option);
    } catch (err) {
      console.log(err);
    }
  }


  // datepicker function
  const [startDate, setStartDate] = useState(new Date());
  const animatedComponents = makeAnimated();


  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control && control.status === "INVALID") {
        control.active = true;
        control.touched = true;
        document.getElementById(controlName).focus();
      }
    });
  };

  const handleNewApplication = async () => {
    if (NewApplicationFormGroup.status !== "INVALID") {
      setLoading(true);
      let finalObj = {
        UserId: newApplicationData.UserId,
        BorrowAmount: NewApplicationFormGroup.value.BorrowAmount.replace(/,/g, ""),
        Loandate: formatDate(NewApplicationFormGroup.value.Loandate),
        Purpose: NewApplicationFormGroup.value.Purpose.value,
        TypeOfBusiness: NewApplicationFormGroup.value.TypeOfBusiness.value,
        BusinessName: NewApplicationFormGroup.value.BusinessName,
        BState: NewApplicationFormGroup.value.BState.value,
        BCity: NewApplicationFormGroup.value.BCity,
        Category: NewApplicationFormGroup.value.Category || "",
      };

      InsertOrUpdateNewLoanExistingUser(finalObj)
        .then((data) => {
          if (data.status == 200) {
            dispatch(
              reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: data.message,
                type: "success",
              })
            );
            setTimeout(
              () =>
                dispatch(
                  reducersAndActions.actions.toasterActions.hideToaster()
                ),
              Constants.TOASTERHIDETIME
            );
            setLoading(false);
            applicationPopupClose()
            loanApplicationDetails();
          } else {
            setLoading(false);
            dispatch(
              reducersAndActions.actions.toasterActions.showToaster({
                visible: true,
                message: data.message,
                type: "error",
              })
            );
            setTimeout(
              () =>
                dispatch(
                  reducersAndActions.actions.toasterActions.hideToaster()
                ),
              Constants.TOASTERHIDETIME
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          dispatch(
            reducersAndActions.actions.toasterActions.showToaster({
              visible: true,
              message: err.message,
              type: "error",
            })
          );
          setTimeout(
            () =>
              dispatch(reducersAndActions.actions.toasterActions.hideToaster()),
            Constants.TOASTERHIDETIME
          );
        });
    } else {
      markAllAsTouched(NewApplicationFormGroup);
    }
    // const newDat
  }

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (<Preloader />) :
        (<div>
          <div className="dashboard-wrap loan-app-screen">
            <div className="d-flex justify-content-between filter-search-group">
              <Select
                className="basic-single"
                classNamePrefix="select"
                id="status"
                name="status"
                value={loanStatus}
                options={statusOptions}
                onChange={onDropDownChange}
              />
              <div className="lm-search-bar">
                <input
                  type="text"
                  id="searchInput"
                  placeholder="Search by number, name, or other"
                  autoComplete="off"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                    setPageIndex(1)
                  }} />
              </div>
            </div>

            {/* loan application table */}
            {
              loanApplicationList.length > 0 ?
                <DynamicTable data={loanApplicationList} dynamicTableDisplay={dynamicTableDisplay} columns={loanApplicationHeader} changePageSize={setPageSize} pageSize={pageSize} total={totalData} setList={setLoanApplicationList} setPageIndex={setPageIndex} pageIndex={pageIndex} />
                :
                <div className='db-card mt-4'>
                  <div className='no-data-card text-center place-content-center'>
                    <img src={no_data_icon} alt="" />
                    <h3 className='f-700 mb-2'>No data found</h3>
                    <p className='text-light'>All relevant information will be displayed
                      <br />
                      here in the nearest future</p>
                  </div>
                </div>
            }
            {/*Add New Application Popup / Modal */}
            {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
            <div className={`custom-modal cust-modal-lg modal-open modal-backdrop ${isModalOpen ? "cust-modal-show" : "cust-modal-hide"}`}>
              <div className="modal-box">
                <div className="modal-header">
                  <h5 className="modal-title" id="applicationPopupLabel">New application</h5>
                  <button type="button" className="close" onClick={applicationPopupClose}>
                    <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                  </button>
                </div>
                <FieldGroup
                  control={NewApplicationFormGroup}
                  render={({ get, invalid }) => (
                    <form>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group input-dollar">
                              <FieldControl
                                name="BorrowAmount"
                                render={({
                                  handler,
                                  touched,
                                  hasError,
                                  meta,
                                }) => {
                                  const handlerProps = handler();
                                  const handleChange = (e) => {


                                    const rawValue = e.target.value.replace(
                                      /,/g,
                                      ""
                                    ); // Remove existing commas
                                    const formattedValue =
                                      formatNumberWithCommas(rawValue);
                                    e.target.value = formattedValue;
                                    if (handlerProps.onChange) {
                                      handlerProps.onChange(e);
                                    }
                                  };

                                  return (
                                    <div className="form-group input-dollar">
                                      <label htmlFor="BorrowAmount">
                                        How much would you like to borrow?
                                        <span className="asterisk">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="BorrowAmount"
                                        placeholder="400,000.00"
                                        {...handlerProps}
                                        onChange={handleChange}
                                        required={true}
                                      />

                                      {/* Error messages below */}
                                      {touched && hasError("required") && (
                                        <>
                                          <span className="error-message">
                                            Borrow amount is required
                                          </span>
                                          <br />
                                        </>
                                      )}
                                      {
                                        hasError("borrowAmountIssue") && (
                                          <>
                                            <span className="error-message">
                                              Amount should be greater than
                                              $25,000
                                            </span>
                                            <br />
                                          </>
                                        )}
                                      {hasError("pattern") && (
                                        <>
                                          <span className="error-message">
                                            Amount should be only numeric
                                            values
                                          </span>
                                          <br />
                                        </>
                                      )}
                                      {
                                        hasError(
                                          "borrowAmountMaxIssue"
                                        ) && (
                                          <>
                                            <span className="error-message">
                                              Amount should be less than or
                                              equal to 10000000000
                                            </span>
                                            <br />
                                          </>
                                        )}
                                    </div>
                                  );
                                }}
                                meta={{ placeholder: "400,000.00" }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <FieldControl
                              name="Loandate"
                              render={({ handler, touched, hasError, meta }) => (
                                <div className="form-group input-date">
                                  <label htmlFor="date">Choose the date by which you want to get the loan<span className="asterisk">*</span></label>
                                  <DatePicker
                                    showIcon
                                    toggleCalendarOnIconClick
                                    {...handler()}
                                    selected={NewApplicationFormGroup.value.Loandate}
                                    value={NewApplicationFormGroup.value.Loandate ? moment(NewApplicationFormGroup.value.Loandate).format('ll') : ""}
                                    isClearable={NewApplicationFormGroup.value.Loandate ? true : false}
                                    id='Loandate'
                                    placeholderText="Month DD, YYYY"
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown
                                    showMonthDropdown
                                    showYearDropdown
                                    onChange={(date) => {
                                      const formattedDate = date ? moment(date).format('MM-DD-YYYY') : '';
                                      handler().onChange(formattedDate);
                                    }}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                  {/* Error messages below */}
                                  {touched && hasError("required") && (
                                    <span className="error-message">Business state is required</span>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <FieldControl
                              name="Purpose"
                              render={({
                                handler,
                                touched,
                                hasError,
                                meta,
                              }) => (
                                <div className="form-group">
                                  <label htmlFor="Purpose">
                                    How will you use the loan?{" "}
                                    <span className="asterisk">*</span>
                                  </label>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id="Purpose"
                                    name="Purpose"
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: base => ({ ...base, zIndex: 9999 })
                                    }}
                                    ref={objectiveRef}
                                    options={LoanReasonOptions}
                                    onChange={selectedOption => {
                                      handler().onChange(selectedOption?.value);
                                    }}
                                    {...handler()}

                                  />

                                  {/* Error messages below */}
                                  {touched && hasError("required") && (
                                    <span className="error-message">Loan Reason is required</span>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <FieldControl
                              name="TypeOfBusiness"
                              render={({ handler, touched, hasError, meta }) => (
                                <div className="form-group">
                                  <label htmlFor="TypeOfBusiness">
                                    Type of Business <span className="asterisk">*</span>
                                  </label>
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id="TypeOfBusiness"
                                    name="TypeOfBusiness"
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                    ref={businessRef}
                                    options={TypeOfBusnessOptions}
                                    onChange={(selectedOption) => {
                                      handler().onChange(selectedOption?.value);
                                    }}
                                    {...handler()}
                                  />
                                  <span className="error-message">
                                    {touched && hasError("required") && `Business type is required`}
                                  </span>
                                </div>
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <FormField
                                name="BusinessName"
                                label="Business Name"
                                id="BusinessName"
                                placeholder="Enter Business Name"
                                required={true}
                                errorMessage="Business name is required"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <FormField
                                name="BCity"
                                label="Business City"
                                id="BCity"
                                placeholder="Enter a Business City"
                                required={true}
                                errorMessage="City is required"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <FieldControl
                              name="BState"
                              render={({ handler, touched, hasError, meta }) => (
                                <div className="form-group">
                                  <label htmlFor="business_state">Business state </label>
                                  <span className="asterisk">*</span>
                                  <Select
                                    id="BState"
                                    name="BState"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                    options={stateLists}
                                    onChange={(selectedOption) => {
                                      handler().onChange(selectedOption?.value);
                                    }}
                                    {...handler()}
                                    required={true}
                                  />
                                  {touched && hasError("required") && (
                                    <span className="error-message">Business state is required</span>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer la-app-modal-footer">
                        <button onClick={applicationPopupClose} type="button" className="lm-button2">Cancel</button>
                        <button type="button" className="lm-button1" onClick={handleNewApplication}>Save</button>
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
            {/*Add New Application Popup / Modal */}

            {/*Add New Partner Popup / Modal */}
            <Modal
              size="md"
              show={partnersPopupShow}
              onHide={() => setPartnersPopup(false)}
              aria-labelledby="partnersPopupLabel"
              backdrop="static"
              keyboard={false}
              centered
            >
              <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable assign-mem-popup">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="partnersPopupLabel">Assigned {currentRole}(s)</h5>
                    <button type="button" className="close" onClick={partnersPopupClose}>
                      <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                    </button>
                  </div>
                  <Modal.Body>
                    <div className="form-group">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 })
                        }}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={roleDropdownList}
                        onChange={selectAssignedRoles}
                        value={tempRolesAssigned}
                      />
                    </div>

                    <div className="assigned-partners-grp">
                      {
                        roleListing && roleListing.length > 0 && roleListing.map(assignedUser => {
                          return (
                            <div className="partner-item d-flex">
                              <div className="partner-img">
                                {
                                  assignedUser.ProfileImage ? (
                                    <img src={dummy_avtar} alt="" />
                                  ) : (
                                    <div className="text-profile">{getInitials(assignedUser.FullName)}</div>
                                  )
                                }
                              </div>
                              <div className="partner-dtls">
                                <h5>
                                  {assignedUser.FullName}
                                </h5>
                                <span className="text-light">
                                  {assignedUser.Email}
                                </span>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </Modal.Body>
                  {user.user.roleName !== "Advisor" && user.user.roleName !== "Lender" && user.user.roleName !== "Partner" && <div className="modal-footer la-app-modal-footer">
                    <button type="button" className="lm-button2" onClick={partnersPopupClose}>Cancel</button>
                    <button type="button" className="lm-button1" onClick={() => assignUserModal(currentRole)}>Save</button>
                  </div>}
                </div>
              </div>
            </Modal>
            {/* Add New Partner Popup / Modal */}


            {/*Confirm delete application / Modal */}
            <Modal
              size="md"
              show={confirmPopup}
              onHide={() => setConfirmPopup(false)}
              aria-labelledby="confirmDeletePopupLabel"
              backdrop="static"
              keyboard={false}
              centered
            >
              <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable assign-mem-popup">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="partnersPopupLabel">{deleteApplicationData.ApplicationNumber}</h5>
                    <button type="button" className="close" onClick={() => setConfirmPopup(false)}>
                      <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                    </button>
                  </div>
                  <Modal.Body>
                    Are you sure you would like to delete this application?
                  </Modal.Body>
                  <div className="modal-footer la-app-modal-footer">
                    <button type="button" className="lm-button2" onClick={() => setConfirmPopup(false)}>Cancel</button>
                    <button type="button" className="lm-button1" onClick={deleteApplication}>Ok</button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Confirm delete application / Modal */}
          </div>
        </div>)}
    </>
  );
}

export default LoanApplication;
