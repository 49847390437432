import axios from "../helpers/axiosConfig";
import { getLocalStorageData, getSessionId } from "./storageData";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const GetEntityListofDocumentsByLoanId = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetEntityListofDocumentsByLoanId`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const GetDocumentList = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetDocumentList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const UpdateDocumentVerify_Active = async (data) => {
    try {
        const response = await axios.get(`/api/Document/UpdateDocumentVerify_Active`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const UploadDocument = async (formData, params) => {
    try {
        const response = await axios.post(`/api/Document/UploadDocument`, formData, { params });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}


export const GetDocumentsHistory = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetDocumentsHistory`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}


export const GetDownloadAllDocuments = async (data, url) => {
    try {
        const response = await axios.get(`/api/Document/${url}`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const GetDownloadDocumentHistoryFile = async (data) => {
    try {
        const zip = new JSZip();
        await Promise.all(
            Object.values(data).map(async (element, index) => {
                let dataParams = {};
                let DownloadDocumentURL = `/api/Document/GetDownloadDocumentHistoryFile`;

                if (element.DocumentType === "BGD" && element.Status === "To sign") {
                    dataParams = {
                        LoanId: element.LoanId,
                        fileName: element.TD_Name || element.FileName1,
                        DwnFileName: element.Name || element.FileName,
                        ownerId: element.OwnerID,
                        UserId: element.UserId,
                        Doctype: element.DocType
                    };
                    DownloadDocumentURL = `/api/Document/GetDownload_BGD_TemplateSystemDocuments`;
                } else {
                    dataParams = {
                        filename: element.FileName,
                        isSigned: element.Status === "Completed",
                        Status: element.Status,
                        DocumentType: element.DocumentType
                    };
                }
                // Fetch the document from the server
                const response = await axios.get(DownloadDocumentURL, {
                    params: {
                        ...dataParams,
                        SessionId: getSessionId()
                    },
                    responseType: 'arraybuffer'
                });

                if (response.data) {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    // Add document to the zip if more than 1
                    if (Object.values(data).length > 1) {
                        zip.file(`${element.FileName || element.filename || element.Name}.pdf`, blob);
                    } else {
                        // Directly download the single document
                        const link = document.createElement('a');
                        link.download = `${element.FileName || element.filename || element.Name}.pdf`;
                        link.href = window.URL.createObjectURL(blob);
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                    }
                }
            })
        );

        // Check if multiple documents were selected and download a zip
        if (Object.values(data).length > 1) {
            const zipBlob = await zip.generateAsync({ type: "blob" });
            saveAs(zipBlob, 'documents.zip'); // Save the ZIP file
        }
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};


export const ViewDocumentInBrowser = async (data, DocType = "") => {
    try {
        const ViewDocumentURL = DocType !== "" ? `/api/Document/GetDownload_BGD_TemplateSystemDocuments` : `/api/Document/GetDownloadDocumentHistoryFile`;
        const response = await axios.get(ViewDocumentURL, {
            params: { ...data, SessionId: getSessionId() },
            responseType: 'arraybuffer'
        });
        if (response.data) {
            return response;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}


export const SendDocumentToEmail = async (data, params) => {
    try {
        const response = await axios.post(`/api/Document/SendDocumentToEmail`, data,
            {
                params: {
                    SessionId: getSessionId(),
                    ...params
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const MarkAsCompletedDocuments = async (data) => {
    try {
        const response = await axios.post(`/api/Document/MarkAsCompletedDocuments`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const GetAllDocumentList = async () => {
    try {
        const response = await axios.get(`/api/Document/GetAllDocumentList`, { params: { SessionId: getSessionId() } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const GetAGDDocumentDetailsById = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetAGDDocumentDetailsById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const DownloadZipFileWithURL = async (FileName) => {
    try {
        const response = await axios.get(`/api/Document/DownloadZip`, {
            params: { FileName: FileName },
            responseType: 'blob',
        });

        if (response.data) {
            const blob = new Blob([response.data], { type: 'application/zip' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'sample.zip';
            link.click();

            // Revoke the URL to free memory
            window.URL.revokeObjectURL(link.href);
            return response;
        }
    } catch (error) {
        console.log(error);

    }
}

export const InsertUpdateAGDDocument = async (data, params) => {
    try {
        const response = await axios.post(`/api/Document/InsertUpdateAGDDocument`, data,
            {
                params: {
                    SessionId: getSessionId(),
                    ...params
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const SaveDocumentCoordinates = async (data) => {
    try {
        const response = await axios.post(`/api/Document/SaveDocumentCoordinates`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const SentSignatureForAGD = async (data) => {
    try {
        const response = await axios.post(`/api/Document/SentSignatureForAGD`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const SentSignatureForBGD = async (data) => {
    try {
        const response = await axios.post(`/api/Document/SentSignatureForBGD`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const MultipleDocumentSentSignature = async (data, userId) => {
    try {
        const response = await axios.post(`/api/Document/MultipleDocumentSentSignature`, data,
            {
                params: {
                    SessionId: getSessionId(),
                    CreatedBy : userId
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const GetHelloSignDownloadRequest = async (data) => {
    try {
        data['Type'] = data['Type'].replaceAll("'", "");
        data['events'] = data['event']
        delete data['event']
        const instance = axios.create()
        const response = await instance.get(`/api/Document/GetHelloSignDownloadRequest`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const CancelHelloSignSignature = async (data) => {
    try {
        const response = await axios.get(`/api/Document/CancelHelloSignSignature`, { params: { ...data, SessionId: getSessionId() } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}