import axios from '../helpers/axiosConfig';

// Get Loan application listing
export const getInquiryList = async (data) => {
    try {
        const response = await axios.get(`/api/Inqueries/GetInquiriesList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const getAdminAdviserList = async (data) => {
    try {
        const response = await axios.get(`/api/User/GetUserList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loanApplicationAffiliateList = async (data) => {
    try {
        const response = await axios.get(`/api/User/GetUserList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}