import React, { useCallback, useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosConfig'; // Adjust the path to where your Axios instance is defined
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';
import Preloader from '../../../plugins/Preloader';

const AccountSettings = ({ activeTab }) => {
    const [preloader,setPreloader]=useState(false)
    const [tfaChecked, setTfaChecked] = useState(false);
     const [autoExpirePasswordChecked, setAutoExpirePasswordChecked] = useState(false);
const dispatch=useDispatch()

useEffect(()=>{

    const storedUserId = localStorage?.getItem('user');
    const parsedUserId = JSON.parse(storedUserId);
    const storedSessionId = localStorage?.getItem('sessionId');
    setPreloader(true)
    axiosInstance.get(`/api/User/MyProfile?userId=${parsedUserId?.id}&SessionId=${storedSessionId}`)
    .then((res)=>{
        setPreloader(false)
        setTfaChecked(res.data.resultObject.isTFAChecked)
        setAutoExpirePasswordChecked(res.data.resultObject.ispasswordExpired)
        
    })
    .catch(error=>{
        
        setPreloader(false)
        console.log(error)})
},[])
const handleToggleSwitch = useCallback((toggleName, isChecked) => {
    if (toggleName === '0') {
        setTfaChecked(isChecked);
    } else if (toggleName === '1') {
        setAutoExpirePasswordChecked(isChecked);
    }

    const storedUserId = localStorage?.getItem('user');
    const parsedUserId = JSON.parse(storedUserId);
    const storedSessionId = localStorage?.getItem('sessionId');
    setPreloader(true);

    if (storedUserId && parsedUserId && storedSessionId) {
        const apiUrl = '/api/User/SaveTFAStatus';
        const status = isChecked ? 1 : 0; // Use isChecked to set the status directly

        // Construct the query string
        const queryParams = `?userId=${parsedUserId.id}&status=${status}&SessionId=${storedSessionId}&Type=${toggleName === '0' ? "TFS" : "AutoExpire"}`;

        axiosInstance.post(`${apiUrl}${queryParams}`)
            .then(response => {
                setPreloader(false);
                if (response.data?.status == 200) {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                } else {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
            })
            .catch(error => {
                setPreloader(false);
                console.error('Error:', error);
            });
    } else {
        setPreloader(false);
        console.error('User ID, Session ID, or token is not available');
    }
}, [dispatch]);


    return (
        <div className={`tab-pane fade ${activeTab === "Account_Settings" ? "show active" : ""}`} id="Account_Settings">
            {preloader?<Preloader/>:<div className="lm-card">
                <h3 className="lm-card-heading">Account Settings</h3>
                <div className="notification-setting d-flex justify-content-between">
                    <h6>Two-factor authentication</h6>
                    <div className="lm-toggle-switch">
                        <input 
                        type="checkbox" 
                        id="0" 
                        checked={tfaChecked}
                         onChange={(e) => handleToggleSwitch('0', e.target.checked)} />
                        <label htmlFor="0">Toggle</label>
                    </div>
                </div>
                <div className="notification-setting d-flex justify-content-between">
                    <h6>Auto expire password</h6>
                    <div className="lm-toggle-switch">
                        <input type="checkbox" id="1"  checked={autoExpirePasswordChecked} onChange={(e) => handleToggleSwitch('1', e.target.checked)} />
                        <label htmlFor="1">Toggle</label>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default AccountSettings;
